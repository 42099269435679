import { render, staticRenderFns } from "./pdstore.vue?vue&type=template&id=115f6b15&scoped=true&"
import script from "./pdstore.vue?vue&type=script&lang=js&"
export * from "./pdstore.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "115f6b15",
  null
  
)

export default component.exports