<template>
  <el-dialog
    title="代码生成"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rule">
      <el-form-item label="表名" :label-width="formLabelWidth" prop="tbName">
        <el-input
          v-model="form.tbName"
          autocomplete="off"
          :disabled="false"
        ></el-input>
      </el-form-item>
      <el-form-item label="功能名" :label-width="formLabelWidth" prop="tbDesc">
        <el-input v-model="form.tbDesc" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="模块名"
        :label-width="formLabelWidth"
        prop="moduleName"
      >
        <el-input v-model="form.moduleName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="子模块名"
        :label-width="formLabelWidth"
        prop="subModuleName"
      >
        <el-input v-model="form.subModuleName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="包名"
        :label-width="formLabelWidth"
        prop="packageBase"
      >
        <el-input v-model="form.packageBase" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="基类"
        :label-width="formLabelWidth"
        prop="superEntityClass"
      >
        <el-select
          v-model="form.superEntityClass"
          placeholder="请选择基类"
          clearable
        >
          <el-option
            value="com.castle.fortress.admin.core.entity.BaseEntity"
            label="BaseEntity"
          ></el-option>
          <el-option
            value="com.castle.fortress.admin.core.entity.DataAuthBaseEntity"
            label="DataAuthBaseEntity"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="表前缀"
        :label-width="formLabelWidth"
        prop="tbPrefix"
      >
        <el-input v-model="form.tbPrefix" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="作者" :label-width="formLabelWidth" prop="author">
        <el-input v-model="form.author" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="后端输出地址"
        :label-width="formLabelWidth"
        prop="backOutPutDir"
      >
        <el-input v-model="form.backOutPutDir" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="前端输出地址"
        :label-width="formLabelWidth"
        prop="frontOutPutDir"
      >
        <el-input v-model="form.frontOutPutDir" autocomplete="off"></el-input>
      </el-form-item>
      <template v-if="form.frontOutPutDir || form.backOutPutDir">
        <el-form-item
          label="页面类型"
          :label-width="formLabelWidth"
          prop="viewType"
        >
          <el-radio-group v-model="form.viewType">
            <el-radio-button :label="1">表格</el-radio-button>
            <el-radio-button :label="2">左树右表</el-radio-button>
            <el-radio-button :label="3">树形表格</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.viewType == 2">
          <el-divider content-position="left">左树数据配置</el-divider>
          <el-form-item
            label="数据表"
            :label-width="formLabelWidth"
            prop="treeTableName"
          >
            <el-input
              v-model="form.treeTableName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="接口地址"
            :label-width="formLabelWidth"
            prop="treeUrl"
          >
            <el-input v-model="form.treeUrl" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="value"
            :label-width="formLabelWidth"
            prop="treeValue"
          >
            <el-input v-model="form.treeValue" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="label"
            :label-width="formLabelWidth"
            prop="treeLabel"
          >
            <el-input v-model="form.treeLabel" autocomplete="off"></el-input>
          </el-form-item>
          <el-divider content-position="left">右表数据配置</el-divider>
          <el-form-item
            label="关联字段"
            :label-width="formLabelWidth"
            prop="tableCol"
          >
            <el-input v-model="form.tableCol" autocomplete="off"></el-input>
          </el-form-item>
        </template>
      </template>

      <el-form-item
        label="是否启用swagger"
        :label-width="formLabelWidth"
        prop="swagger2Flag"
      >
        <el-switch
          v-model="form.swagger2Flag"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
        <span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66"
          >启用</span
        >
        <span v-if="form.status == 2" style="margin-left: 17px; color: #ff4949"
          >禁用</span
        >
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" type="danger" size="medium"
        >取 消</el-button
      >
      <el-button type="primary" @click="submitForm('form')" size="medium"
        >保存</el-button
      >
      <el-button type="danger" @click="doCodeGen('form')" size="medium"
        >生成代码</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { tbconfigInfo, tbconfigEdit } from "@/api/devtools/devtbconfig.js";
import { codeGen } from "@/api/devtools/devcode.js";
export default {
  data() {
    return {
      visible: false,
      form: {
        id: "",
        dbId: "",
        tbName: "",
        tbDesc: "",
        moduleName: "",
        packageBase: "",
        tbPrefix: "",
        author: "",
        backOutPutDir: "",
        frontOutPutDir: "",
        subModuleName: "",
        superEntityClass: "",
        swagger2Flag: "",
        viewType: 1,
        treeUrl: "",
        treeValue: "",
        treeLabel: "",
        tableCol: ""
      },
      formLabelWidth: "120px",
      rule: {
        tbName: [{ required: true, message: "请输入表名", trigger: "blur" }],
        tbDesc: [{ required: true, message: "请输入功能名", trigger: "blur" }],
        // frontOutPutDir: [
        //   { required: true, message: "请选择前端输出路径", trigger: "blur" },
        // ],
        // backOutPutDir: [
        //   { required: true, message: "请输入后端输出路径", trigger: "blur" },
        // ],
        packageBase: [
          { required: true, message: "请输入包名", trigger: "blur" },
        ],
        moduleName: [
          { required: true, message: "请输入模块名", trigger: "blur" },
        ],
        treeTableName: [
          { required: true, message: "请输入树形结构的数据表", trigger: "blur" },
        ],
        treeUrl: [
          { required: true, message: "请输入接口地址", trigger: "blur" },
        ],
        treeValue: [
          { required: true, message: "请输入value", trigger: "blur" },
        ],
        treeLabel: [
          { required: true, message: "请输入label", trigger: "blur" },
        ],
        tableCol: [
          { required: true, message: "请输入关联字段", trigger: "blur" },
        ],
        viewType: [
          { required: true, message: "请选择页面类型", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() { },
  methods: {
    init(id) {
      this.resetForm();
      //修改
      if (id) {
        tbconfigInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
        //新增
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.visible = false;
          //修改
          if (this.form.id) {
            tbconfigEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //生成代码
    doCodeGen() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.visible = false;
          codeGen(this.form).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.$emit("refreshData");
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        id: "",
        dbId: "",
        tbName: "",
        tbDesc: "",
        moduleName: "",
        packageBase: "",
        tbPrefix: "",
        author: "",
        backOutPutDir: "",
        frontOutPutDir: "",
        subModuleName: "",
        superEntityClass: "",
        swagger2Flag: "",
        viewType: 1
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped></style>
