<template>
    <div class="padding20">
        <el-row style="margin-bottom: 7px">
            <el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
                <el-form-item  >
                    <el-input v-model="queryForm.name" autocomplete="off" placeholder="请输入名称" clearable></el-input>
                </el-form-item>

                <el-form-item  >
                    <el-input v-model="queryForm.imgUrl" autocomplete="off" placeholder="请输入图片链接" clearable></el-input>
                </el-form-item>

                <el-form-item  v-show="collapse.flag" >
                    <el-input v-model="queryForm.jump" autocomplete="off" placeholder="请输入跳转" clearable></el-input>
                </el-form-item>

                <el-form-item  v-show="collapse.flag" >
                    <el-input v-model="queryForm.sort" autocomplete="off" placeholder="请输入排序" clearable></el-input>
                </el-form-item>

                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.isShow"
                      :dictList="YesNoList"
                      placeholder="请选择是否显示"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({current:1})">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="text" @click="handleCollapseState"
                    >{{ collapse.label }}<i :class="collapse.icon"></i
                    ></el-button>
                </el-form-item>
            </el-form>
            <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    v-if="$tools.hasPermission('pdworld:banner:banner:save')"
                    @click="handleEdit()"
            >添加</el-button
            >
            <el-button
                    v-if="$tools.hasPermission('pdworld:banner:banner:deleteBatch')"
                    size="medium"
                    type="danger"
                    icon="el-icon-delete"
                    @click="handleDeleteBatch()"
            >删除</el-button
            >
            <div style="float: right; padding-right: 17px">
                <i
                  class="el-icon-download"
                  style="margin-right: 10px; cursor: pointer"
                  @click="handleExport"
                ></i>
                <i
                        class="el-icon-refresh"
                        style="margin-right: 10px; cursor: pointer"
                        @click="refresh"
                ></i>

                <el-popover placement="bottom" width="180" trigger="click" title="列展示" popper-class="columnShows">
                    <el-checkbox-group v-model="tableShowList">
                        <el-checkbox
                                v-for="item in tableList"
                                :label="item.propName"
                                :key="item.propName"
                                :value="item.propName"
                        >{{ item.propDesc }}</el-checkbox
                        >
                    </el-checkbox-group>
                    <i
                            slot="reference"
                            class="el-icon-s-operation"
                            style="cursor: pointer"
                    ></i>
                </el-popover>
            </div>
        </el-row>
        <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
        >
            <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>

            <el-table-column
              v-if="$tools.isShow('name', tableShowList)"
              prop="name"
              label="名称"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column label="操作" fixed="right" min-width="180">
                <template slot-scope="scope">
                    <el-button
                            v-if="$tools.hasPermission('pdworld:banner:banner:info')"
                            size="mini"
                            plain
                            @click="handleInfo(scope.row.id)"
                    >详情</el-button
                    >
                    <el-button
                            v-if="$tools.hasPermission('pdworld:banner:banner:edit')"
                            size="mini"
                            plain
                            @click="handleEdit(scope.row.id)"
                    >编辑</el-button
                    >
                    <el-button
                            v-if="$tools.hasPermission('pdworld:banner:banner:delete')"
                            size="mini"
                            plain
                            type="danger"
                            @click="handleDelete(scope.row)"
                    >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="page.total" :current-page="page.current" :page-size="page.size" @refreshData="queryList"></pagination>
        <!-- 新增、编辑 -->
        <form-edit
                v-if="formEditVisible"
                ref="formEdit"
                @refreshData="queryList"
        ></form-edit>
        <!-- 详情 -->
        <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
    </div>
</template>
<script>
    import { bannerPage, bannerDel, bannerDelBatch, bannerDynamicExport, } from "@/api/pdworld/banner/banner.js";
    import { viewTableList } from "@/api/public.api.js";
    import FormEdit from "./banner-edit";
    import FormInfo from "./banner-info";
    import FortressPagination from "@/components/fortress-pagination.vue";
    import { enumList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                tableData: [],
                formEditVisible: false,
                formInfoVisible: false,
                queryForm: {
                        name:"",
                        imgUrl:"",
                        jump:"",
                        sort:"",
                        isShow:"",
                },
                page: {
                    current: 1,
                    size: 10,
                    total: 0,
                },
                collapse: {
                    icon: "el-icon-arrow-down el-icon--right",
                    label: "展开",
                    flag: false,
                },
                tableList: [],
                tableShowList: [],
                YesNoList:[],
            };
        },
        components: {
            "form-edit": FormEdit,
            "form-info": FormInfo,
            pagination: FortressPagination,
        },
        created() {
            this.queryEnumList();
            this.initTableList();
            this.queryList();
        },
        methods: {
            queryEnumList(){
              this.YesNoList = [];
                enumList("YesNo","").then((resp) => {
                if (resp.code == 0) {
                  this.YesNoList = resp.data;
                }
              });
            },
            refresh() {
              this.tableData = [];
              this.queryList({ current: 1 });
            },
            //查询表格数据
            queryList(page) {
                if (page) {
                    this.page.current = page.current?page.current:this.page.current;
                    this.page.size = page.size?page.size:this.page.size;
                }
                this.queryForm.current = this.page.current;
                this.queryForm.size = this.page.size;
                bannerPage(this.queryForm).then((resp) => {
                    if (resp.code == 0) {
                        this.tableData = resp.data.records;
                        this.page.total = resp.data.total;
                        //查询页大于总页数，重新查询
                        let cu = this.page.total%this.page.size==0?parseInt(this.page.total/this.page.size):parseInt((this.page.total/this.page.size)+1);
                        if(cu>0 && this.page.current>cu){
                          this.queryList({current:cu});
                        }
                    } else {
                        this.$msg({
                            message: resp.msg,
                            type: "error",
                        });
                    }
                });
            },
            //打开编辑窗口
            handleEdit(id) {
                this.formEditVisible = true;
                this.$nextTick(() => {
                    this.$refs.formEdit.init(id);
                });
            },
            //打开详情窗口
            handleInfo(id) {
                this.formInfoVisible = true;
                this.$nextTick(() => {
                    this.$refs.formInfo.init(id);
                });
            },
            //删除
            handleDelete(row) {
                this.$confirm('确认删除该记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                        bannerDel(row.id).then((resp) => {
                        if (resp.code == 0) {
                            this.$message({
                                type: "success",
                                message: "操作成功!",
                            });
                           //判断是否最后一页的最后一条记录
                           let totalPages =
                              (this.page.total - 1) % this.page.size == 0
                                  ? (this.page.total - 1) / this.page.size
                                  : (this.page.total - 1) / this.page.size + 1;
                           if (this.page.current > totalPages && this.page.current != 1) {
                            this.page.current = this.page.current - 1;
                           }
                            this.queryList();
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }).catch(() => {
                });
            },
            //批量删除
            handleDeleteBatch() {
                let checkedRow = this.$refs.multipleTable.selection;
                if (checkedRow.length > 0) {
                    let ids = [];
                    checkedRow.forEach((item) => {
                        ids.push(item.id);
                    });
                    this.$confirm("确认删除该记录, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                            .then(() => {
                                bannerDelBatch(ids).then((resp) => {
                                    if (resp.code == 0) {
                                        this.$message({
                                            type: "success",
                                            message: "操作成功!",
                                        });
                                      //判断是否最后一页的最后一条记录
                                      let totalPages =
                                          (this.page.total - checkedRow.length) % this.page.size == 0
                                              ? (this.page.total - checkedRow.length) / this.page.size
                                              : (this.page.total - checkedRow.length) / this.page.size +
                                              1;
                                      if (this.page.current > totalPages && this.page.current != 1) {
                                        this.page.current = this.page.current - 1;
                                      }
                                        this.queryList();
                                    } else {
                                        this.$msg({
                                            message: resp.msg,
                                            type: "error",
                                        });
                                    }
                                });
                            })
                            .catch(() => {});
                } else {
                    this.$msg({
                        message: "请选择数据进行删除",
                        type: "error",
                    });
                }
            },
            //重置查询框
            resetQueryForm() {
                this.queryForm = {
                        name:"",
                        imgUrl:"",
                        jump:"",
                        sort:"",
                        isShow:"",
                };
            },
            //切换查询条件收缩框
            handleCollapseState() {
                this.collapse.flag = !this.collapse.flag;
                if (this.collapse.flag) {
                    this.collapse.icon = "el-icon-arrow-up el-icon--right";
                    this.collapse.label = "收起";
                } else {
                    this.collapse.icon = "el-icon-arrow-down el-icon--right";
                    this.collapse.label = "展开";
                }
            },
            //视图表格列表初始化
            initTableList() {
                viewTableList("banner").then((resp) => {
                    if (resp.code == 0) {
                        this.tableList = resp.data;
                        this.tableList.forEach((item) => {
                            this.tableShowList.push(item.propName);
                        });
                    }
                });
            },
            //可见字段excel导出
            handleExport() {
              if (!this.tableShowList || this.tableShowList.length < 1) {
                this.$msg({
                  message: "请选择要导出的列",
                  type: "error",
                });
                return;
              }
              let colNum = 1;
              let headerList = [];
              this.tableShowList.forEach((item) => {
                let nameData = this.tableList.find((item2) => {
                  return item2.propName == item;
                });
                let name = "";
                if (nameData && nameData.propDesc) {
                  name = nameData.propDesc;
                } else {
                  name = "未定义字段" + colNum++;
                }
                headerList.push({ name: name, code: item });
              });
            bannerDynamicExport({ headerList: headerList, dto: this.queryForm });
            },
        },
    };
</script>
<style scoped>
</style>
