<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="所属车型" :label-width="formLabelWidth" prop="parentId">
                <fortress-select
                        v-model="form.parentId"
                        :dictList="parentIdList"
                        placeholder="请选择所属车型"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="车长" :label-width="formLabelWidth" prop="carLong">
                <el-input
                        v-model="form.carLong"
                        autocomplete="off"
                        placeholder="请输入车长"
                ></el-input>
            </el-form-item>
            <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                <el-input
                        v-model="form.remark"
                        autocomplete="off"
                        placeholder="请输入备注"
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { pdcartypelongInfo, pdcartypelongSave, pdcartypelongEdit } from "@/api/pdworld/pdcartypelong.js";
    import { urlList, } from "@/api/public.api.js";
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    parentId:"",
                    carLong:"",
                    remark:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
                parentIdList:[],
            };
        },
        created() {
        },
        methods: {
            queryUrlList(){
                this.parentIdList = [];
                urlList("/pdworld/pdCarType/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.parentIdList.push({ code: item.id, name: item.carName });
                      });
                    }
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryUrlList();
                //修改
                if (id) {
                    pdcartypelongInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        pdcartypelongEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        pdcartypelongSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    parentId:"",
                    carLong:"",
                    remark:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
