import request from "@/common/request"

//商品规格项目分页展示
export const goodsspecitemPage = (queryForm) => {
    return request("get", "/goods/goodsSpecItem/page", queryForm);
}
//商品规格项目列表展示
export const goodsspecitemList = (queryForm) => {
    return request("get", "/goods/goodsSpecItem/list", queryForm);
}
//商品规格项目详情
export const goodsspecitemInfo = (id) => {
    return request('get', '/goods/goodsSpecItem/info', {
        id: id
    })
}
//商品规格项目信息保存
export const goodsspecitemSave = (data) => {
    return request('post', '/goods/goodsSpecItem/save', data)
}
//商品规格项目信息修改
export const goodsspecitemEdit = (data) => {
    return request('post', '/goods/goodsSpecItem/edit', data)
}
//商品规格项目信息删除
export const goodsspecitemDel = (id) => {
    return request('post', '/goods/goodsSpecItem/delete?id=' + id)
}
//商品规格项目批量删除
export const goodsspecitemDelBatch = (ids) => {
    return request('post', '/goods/goodsSpecItem/deleteBatch', ids)
}
//商品规格项目动态表头导出
export const goodsspecitemDynamicExport = (data) => {
    return request("eptpost", '/goods/goodsSpecItem/exportDynamic', data)
}
