<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">中文:</label>
                    <div class="form-item-content">{{ objInfo.name }}
                      <fortress-copy :copyData="objInfo.name"></fortress-copy>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">富文本:</label>
                    <div class="form-item-content" v-html="objInfo.content"></div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">字母:</label>
                    <div class="form-item-content">{{ objInfo.auth }}
                      <fortress-copy :copyData="objInfo.auth"></fortress-copy>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">手机号:</label>
                    <div class="form-item-content">{{ objInfo.phone }}
                      <fortress-copy :copyData="objInfo.phone"></fortress-copy>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">邮箱:</label>
                    <div class="form-item-content">{{ objInfo.email }}
                      <fortress-copy :copyData="objInfo.email"></fortress-copy>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">图片:</label>
                    <div class="form-item-content" ><img :src="objInfo.images" /></div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">文件:</label>
                    <div class="form-item-content" >
                      <template v-for="(item, indexs) in objInfo.files">
                        <a :key="indexs" :href="item.url" target="_blank">{{
                          item.name
                          }}</a>
                        <br />
                      </template>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">视频:</label>
                    <div class="form-item-content" >
                      <fortress-upload
                          v-if="objInfo.video"
                          type="videoShow"
                          name="video"
                          :formData="objInfo"
                          :videoSrc="objInfo.video"
                      ></fortress-upload>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">数字:</label>
                    <div class="form-item-content">{{ objInfo.vueNumber }}
                      <fortress-copy :copyData="objInfo.vueNumber"></fortress-copy>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">单选:</label>
                    <div class="form-item-content">{{ objInfo.vueRadioName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">多行文本:</label>
                    <div class="form-item-content">{{ objInfo.vueTextarea }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">复选:</label>
                    <div class="form-item-content">{{ objInfo.vueCheckboxName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">下拉:</label>
                    <div class="form-item-content">{{ objInfo.vueSelectName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">枚举下拉:</label>
                    <div class="form-item-content">{{ objInfo.selectEnumName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">url下拉:</label>
                    <div class="form-item-content">{{ objInfo.selectUrlName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">json下拉:</label>
                    <div class="form-item-content">{{ objInfo.selectJsonName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">日期:</label>
                    <div class="form-item-content">{{ objInfo.vueDate }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">日期时间:</label>
                    <div class="form-item-content">{{ objInfo.vueDatetime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">状态:</label>
                    <div class="form-item-content" v-if="objInfo.status == 1">启用</div>
                    <div class="form-item-content" v-else>禁用</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建人:</label>
                    <div class="form-item-content">{{ objInfo.createUserName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">枚举单选:</label>
                    <div class="form-item-content">{{ objInfo.redioEnumName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">url单选:</label>
                    <div class="form-item-content">{{ objInfo.radioUrlName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">json单选:</label>
                    <div class="form-item-content">{{ objInfo.radioJsonName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">枚举复选:</label>
                    <div class="form-item-content">{{ objInfo.checkboxEnumName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">url复选:</label>
                    <div class="form-item-content">{{ objInfo.checkboxUrlName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">json复选:</label>
                    <div class="form-item-content">{{ objInfo.checkboxJsonName }}</div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { tmpdemogenerateInfo } from "@/api/demo/tmpdemogenerate.js";
    import { dictList,enumList,urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                dictDemoList:[],
                DataPermissionPostList:[],
                selectUrlList:[],
                radioUrlList:[],
                checkboxUrlList:[],
                selectJsonList:[],
                radioJsonList:[],
                checkboxJsonList:[],
            }
        },
        created() {
        },
        methods: {
          queryDictList(){
              this.dictDemoList = [];
              dictList("dictDemo").then((resp) => {
                if (resp.code == 0) {
                  this.dictDemoList = resp.data;
                }
              });
          },
          queryEnumList(){
              this.DataPermissionPostList = [];
              enumList("DataPermissionPost","").then((resp) => {
                if (resp.code == 0) {
                  this.DataPermissionPostList = resp.data;
                }
              });
          },
          queryUrlList(){
              this.selectUrlList = [];
              urlList("/system/sysRole/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.selectUrlList.push({ code: item.id, name: item.name });
                    });
                  }
                }
              });
              this.radioUrlList = [];
              urlList("/system/sysRole/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.radioUrlList.push({ code: item.id, name: item.name });
                    });
                  }
                }
              });
              this.checkboxUrlList = [];
              urlList("/system/sysRole/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.checkboxUrlList.push({ code: item.id, name: item.name });
                    });
                  }
                }
              });
          },
          queryJsonList(){
              this.selectJsonList = [];
              let selectJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
              this.selectJsonList = JSON.parse(selectJsonListData);
              this.radioJsonList = [];
              let radioJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
              this.radioJsonList = JSON.parse(radioJsonListData);
              this.checkboxJsonList = [];
              let checkboxJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
              this.checkboxJsonList = JSON.parse(checkboxJsonListData);
          },
              getvideoDesc(data) {
                var video = {
                  video: {
                    type: "gallery",
                    default: data.video,
                    attrs: {
                      type: "video",
                      size: 100,
                    },
                  },
                };
                return video;
              },

            init(id) {
                this.queryDictList();
                this.queryEnumList();
                this.queryUrlList();
                this.queryJsonList();
                if (id) {
                   tmpdemogenerateInfo(id).then(res => {
                        this.objInfo = res.data
                              this.objInfo.files = JSON.parse(res.data.files);
                              let vueRadioObj = this.dictDemoList.find((item) => {
                                return item.code == this.objInfo.vueRadio;
                              });
                              this.objInfo.vueRadioName = vueRadioObj ? vueRadioObj.name : "";
                            this.objInfo.vueCheckboxName = "";
                            if (this.objInfo.vueCheckbox) {
                                let vueCheckboxArray = this.objInfo.vueCheckbox.split(";");
                                this.dictDemoList.forEach(item =>{
                                        vueCheckboxArray.forEach((o) => {
                                        if (o == item.code) {
                                            this.objInfo.vueCheckboxName += item.name + " ";
                                        }
                                    });
                                })
                            }
                              let vueSelectObj = this.dictDemoList.find((item) => {
                                return item.code == this.objInfo.vueSelect;
                              });
                              this.objInfo.vueSelectName = vueSelectObj ? vueSelectObj.name : "";
                              let selectEnumObj = this.DataPermissionPostList.find((item) => {
                                return item.code == this.objInfo.selectEnum;
                              });
                              this.objInfo.selectEnumName = selectEnumObj ? selectEnumObj.name : "";
                              let selectUrlObj = this.selectUrlList.find((item) => {
                                return item.code == this.objInfo.selectUrl;
                              });
                              this.objInfo.selectUrlName = selectUrlObj ? selectUrlObj.name : "";
                              let selectJsonObj = this.selectJsonList.find((item) => {
                                return item.code == this.objInfo.selectJson;
                              });
                              this.objInfo.selectJsonName = selectJsonObj ? selectJsonObj.name : "";
                              let redioEnumObj = this.DataPermissionPostList.find((item) => {
                                return item.code == this.objInfo.redioEnum;
                              });
                              this.objInfo.redioEnumName = redioEnumObj ? redioEnumObj.name : "";
                              let radioUrlObj = this.radioUrlList.find((item) => {
                                return item.code == this.objInfo.radioUrl;
                              });
                              this.objInfo.radioUrlName = radioUrlObj ? radioUrlObj.name : "";
                              let radioJsonObj = this.radioJsonList.find((item) => {
                                return item.code == this.objInfo.radioJson;
                              });
                              this.objInfo.radioJsonName = radioJsonObj ? radioJsonObj.name : "";
                            this.objInfo.checkboxEnumName = "";
                            if (this.objInfo.checkboxEnum) {
                                let checkboxEnumArray = this.objInfo.checkboxEnum.split(";");
                                this.DataPermissionPostList.forEach(item =>{
                                        checkboxEnumArray.forEach((o) => {
                                        if (o == item.code) {
                                            this.objInfo.checkboxEnumName += item.name + " ";
                                        }
                                    });
                                })
                            }
                            this.objInfo.checkboxUrlName = "";
                            if (this.objInfo.checkboxUrl) {
                                let checkboxUrlArray = this.objInfo.checkboxUrl.split(";");
                                this.checkboxUrlList.forEach(item =>{
                                        checkboxUrlArray.forEach((o) => {
                                        if (o == item.code) {
                                            this.objInfo.checkboxUrlName += item.name + " ";
                                        }
                                    });
                                })
                            }
                            this.objInfo.checkboxJsonName = "";
                            if (this.objInfo.checkboxJson) {
                                let checkboxJsonArray = this.objInfo.checkboxJson.split(";");
                                this.checkboxJsonList.forEach(item =>{
                                        checkboxJsonArray.forEach((o) => {
                                        if (o == item.code) {
                                            this.objInfo.checkboxJsonName += item.name + " ";
                                        }
                                    });
                                })
                            }
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
