<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    custom-class="dialog-edit"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="上级部门" :label-width="formLabelWidth" prop="parentId">
        <el-select v-model="form.parentId" clearable placeholder="请选择">
          <el-option :label="selParent.label" :value="selParent.id">
            <el-tree
              :data="allDept"
              :props="defaultProps"
              accordion
              highlight-current
              @node-click="handleNodeClick"
            ></el-tree>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth">
        <el-input v-model="form.remark" autocomplete="off" type="textarea" :rows="2"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')" size="medium">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { deptInfo, deptSave, deptEdit } from "@/api/auth/sysdept.js"
export default {
  props: ["allDept"],
  data () {
    return {
      visible: false,
      selParent: {
        id: "",
        label: "",
      },
      form: {
        id: "",
        name: "",
        parentId: "",
        remark: "",
      },
      formLabelWidth: "120px",
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
    }
  },
  created () { },
  methods: {
    init (id) {
      this.resetForm()
      //修改
      if (id) {
        this.selParent.id = ""
        this.selParent.label = ""
        deptInfo(id).then(resp => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              }
              if (this.form.parentId) {
                this.findParentCode(this.form.parentId, this.allDept)
              }
            })
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            })
          }
        })
        //新增
      }
      this.visible = true
    },
    //树形菜单展示
    handleNodeClick (data) {
      this.selParent.id = data.id
      this.selParent.label = data.name
      this.form.parentId = data.id
    },
    //查找父节点
    findParentCode (parentId, dataScope) {
      dataScope.forEach(m => {
        if (parentId == m.id) {
          this.selParent.id = m.id
          this.selParent.label = m.name
          return
        } else if (m.children) {
          this.findParentCode(parentId, m.children)
        }
      })
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.visible = false
          //修改
          if (this.form.id) {
            deptEdit(this.form).then(resp => {
              if (resp.code == 0) {
                this.$notify({
                  title: "修改成功",
                  type: "success",
                  message: "您已成功修改一个部门!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
            //新增
          } else {
            deptSave(this.form).then(resp => {
              if (resp.code == 0) {
                this.$notify({
                  title: "添加成功",
                  type: "success",
                  message: "您已成功添加一个部门!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.form = {
        id: "",
        name: "",
        parentId: "",
        remark: "",
      }
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields()
        }
      })
    },
  },
}
</script>
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
