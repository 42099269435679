import request from "@/common/request"

//手机验证码分页展示
export const syscaptchaPage = (queryForm) => {
    return request("get", "/system/sysCaptcha/page", queryForm);
}
//手机验证码列表展示
export const syscaptchaList = (queryForm) => {
    return request("get", "/system/sysCaptcha/list", queryForm);
}
//手机验证码详情
export const syscaptchaInfo = (id) => {
    return request('get', '/system/sysCaptcha/info', {
        id: id
    })
}
//手机验证码信息保存
export const syscaptchaSave = (data) => {
    return request('post', '/system/sysCaptcha/save', data)
}
//手机验证码信息修改
export const syscaptchaEdit = (data) => {
    return request('post', '/system/sysCaptcha/edit', data)
}
//手机验证码信息删除
export const syscaptchaDel = (id) => {
    return request('post', '/system/sysCaptcha/delete?id=' + id)
}
//手机验证码批量删除
export const syscaptchaDelBatch = (ids) => {
    return request('post', '/system/sysCaptcha/deleteBatch', ids)
}
