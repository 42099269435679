<template>
  <el-dialog
    title="数据权限"
    :visible.sync="visible"
    custom-class="dialog-dataauth-edit"
  >
    <el-tree
      :data="data"
      show-checkbox
      node-key="id"
      :props="defaultProps"
      :default-checked-keys="checkedData"
      ref="tree"
    ></el-tree>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger"
        >取 消</el-button
      >
      <el-button type="primary" @click="submitForm()" size="medium"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { dataAuthInfo, dataAuthSave } from "@/api/auth/sysrole.js";
export default {
  data() {
    return {
      visible: false,
      roleId: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      dataAuth: [],
      checkedData: [],
    };
  },
  methods: {
    init(roleId) {
      this.roleId = roleId;
      this.checkedData = [];
      dataAuthInfo(roleId).then((resp) => {
        if (resp.code == 0) {
          this.data = resp.data.allData;
          if (resp.data.checkedData) {
            resp.data.checkedData.forEach((item) => {
              this.checkedData.push(item.id);
            });
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.dataAuth = [];
      let checkedNodes = [];
      checkedNodes = this.$refs.tree.getCheckedNodes();
      if (checkedNodes) {
        checkedNodes.forEach((item) => {
          this.dataAuth.push({ roleId: this.roleId, deptId: item.id });
        });
      }
      dataAuthSave(this.roleId, this.dataAuth).then((resp) => {
        if (resp.code == 0) {
          this.visible = false;
          this.$notify({
            title: "修改成功",
            type: "success",
            message: "数据权限修改成功!",
          });
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style scoped></style>
