import request from "@/common/request"

//全局网点/园区路线始发地网点分页展示
export const pdparkPage = (queryForm) => {
    return request("get", "/pdworld/pdPark/page", queryForm);
}
//全局网点/园区路线始发地网点列表展示
export const pdparkList = (queryForm) => {
    return request("get", "/pdworld/pdPark/list", queryForm);
}
//全局网点/园区路线始发地网点详情
export const pdparkInfo = (id) => {
    return request('get', '/pdworld/pdPark/info', {
        id: id
    })
}
//全局网点/园区路线始发地网点信息保存
export const pdparkSave = (data) => {
    return request('post', '/pdworld/pdPark/save', data)
}
//全局网点/园区路线始发地网点信息修改
export const pdparkEdit = (data) => {
    return request('post', '/pdworld/pdPark/edit', data)
}
//全局网点/园区路线始发地网点信息删除
export const pdparkDel = (id) => {
    return request('post', '/pdworld/pdPark/delete?id=' + id)
}
//全局网点/园区路线始发地网点批量删除
export const pdparkDelBatch = (ids) => {
    return request('post', '/pdworld/pdPark/deleteBatch', ids)
}
//全局网点/园区路线始发地网点动态表头导出
export const pdparkDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdPark/exportDynamic', data)
}
