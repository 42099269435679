<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-form
        :inline="true"
        :model="queryForm"
        @submit.native.prevent
        @keyup.enter.native="queryList"
      >
        <!-- <el-form-item>
          <el-input
            v-model="queryForm.userName"
            autocomplete="off"
            placeholder="请输入用户名"
            clearable
          ></el-input>
        </el-form-item> -->

        <el-form-item>
          <el-input
            v-model="queryForm.phone"
            autocomplete="off"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item >
          <el-input
            v-model="queryForm.nickName"
            autocomplete="off"
            placeholder="请输入昵称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item >
          <el-input
            v-model="queryForm.provinceName"
            autocomplete="off"
            placeholder="请输入商家所在省"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item >
          <el-input
            v-model="queryForm.cityName"
            autocomplete="off"
            placeholder="请输入商家所在市"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item >
          <el-input
            v-model="queryForm.areaName"
            autocomplete="off"
            placeholder="请输入商家所在区"
            clearable
          ></el-input>
        </el-form-item>

<!--        <el-form-item >-->
<!--          <fortress-select-->
<!--            v-model="queryForm.type"-->
<!--            :dictList="MemberTypeList"-->
<!--            placeholder="请选择会员类型[会员/商家]"-->
<!--            clearable-->
<!--            filterable-->
<!--          ></fortress-select>-->
<!--        </el-form-item>-->
        <el-form-item >
          <fortress-select
            v-model="queryForm.auditStatus"
            :dictList="YesNoList"
            placeholder="请选择审核状态（是否实名）"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item >
          <fortress-select
            v-model="queryForm.isPayMember"
            :dictList="YesNoList"
            placeholder="请选择是否付费会员"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <!-- <el-form-item   >
                    <fortress-select
                      v-model="queryForm.branch"
                      :dictList="branchList"
                      placeholder="请选择所属网点"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item> -->
        <!--        <el-form-item >-->
        <!--          <fortress-select-->
        <!--            v-model="queryForm.isRec"-->
        <!--            :dictList="YesNoList"-->
        <!--            placeholder="请选择是否推荐商家(搜索页右侧)"-->
        <!--            clearable-->
        <!--            filterable-->
        <!--          ></fortress-select>-->
        <!--        </el-form-item>-->
        <!-- <el-form-item   >
                    <fortress-select
                      v-model="queryForm.isAgentrec"
                      :dictList="YesNoList"
                      placeholder="请选择是否货运代理推荐(首页)"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item> -->

        <!--        <el-form-item >-->
        <!--          <fortress-select-->
        <!--            v-model="queryForm.isIndex"-->
        <!--            :dictList="YesNoList"-->
        <!--            placeholder="请选择是否首页推荐"-->
        <!--            clearable-->
        <!--            filterable-->
        <!--          ></fortress-select>-->
        <!--        </el-form-item>-->

        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-search"
            @click="queryList({ current: 1 })"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-refresh-left"
            @click="resetQueryForm"
            >重置</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="handleCollapseState"
            >{{ collapse.label }}<i :class="collapse.icon"></i
          ></el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        v-if="$tools.hasPermission('pdworld:pdMember:save')"
        @click="handleEdit()"
        >添加</el-button
      >
      <el-button
        v-if="$tools.hasPermission('pdworld:pdMember:deleteBatch')"
        size="medium"
        type="danger"
        icon="el-icon-delete"
        @click="handleDeleteBatch()"
        >删除</el-button
      >
      <div style="float: right; padding-right: 17px">
        <i
          class="el-icon-download"
          style="margin-right: 10px; cursor: pointer"
          @click="handleExport"
        ></i>
        <i
          class="el-icon-refresh"
          style="margin-right: 10px; cursor: pointer"
          @click="refresh"
        ></i>

        <el-popover
          placement="bottom"
          width="180"
          trigger="click"
          title="列展示"
          popper-class="columnShows"
        >
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
              v-for="item in tableList"
              :label="item.propName"
              :key="item.propName"
              :value="item.propName"
              >{{ item.propDesc }}</el-checkbox
            >
          </el-checkbox-group>
          <i
            slot="reference"
            class="el-icon-s-operation"
            style="cursor: pointer"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
    >
      <el-table-column
        type="selection"
        width="55"
        show-overflow-tooltip
      ></el-table-column>

      <!-- <el-table-column
        v-if="$tools.isShow('userName', tableShowList)"
        prop="userName"
        label="用户名"
        :show-overflow-tooltip="true"
      >
      </el-table-column> -->
      <el-table-column
        v-if="$tools.isShow('phone', tableShowList)"
        prop="phone"
        label="手机号"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('nickName', tableShowList)"
        prop="nickName"
        label="昵称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
          prop="sort"
          label="权重"
          :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
        v-if="$tools.isShow('type', tableShowList)"
        prop="type"
        label="会员类型[会员/商家]"
        :show-overflow-tooltip="true"
        :formatter="typeFmt"
      >
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('auditStatus', tableShowList)"
        prop="auditStatus"
        label="审核状态（是否实名）"
        :show-overflow-tooltip="true"
        :formatter="auditStatusFmt"
      >
      </el-table-column>

      <!-- <el-table-column
                    v-if="$tools.isShow('branch', tableShowList)"
                    prop="branch"
                    label="所属网点"
                    :show-overflow-tooltip="true"
                    :formatter="branchFmt"
            >
            </el-table-column> -->
      <!--      <el-table-column-->
      <!--        v-if="$tools.isShow('isRec', tableShowList)"-->
      <!--        prop="isRec"-->
      <!--        label="是否推荐商家(搜索页右侧)"-->
      <!--        :show-overflow-tooltip="true"-->
      <!--        :formatter="isRecFmt"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
          prop="auditStatus"
          label="是否付费会员"
          :show-overflow-tooltip="true"
          :formatter="isPayMemberFmt"
      >
      </el-table-column>
      <el-table-column
          prop="consumerTime"
          label="会员开通时间"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          prop="maturityTime"
          label="会员到期时间"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <!-- <el-table-column
                    v-if="$tools.isShow('isAgentrec', tableShowList)"
                    prop="isAgentrec"
                    label="是否货运代理推荐(首页)"
                    :show-overflow-tooltip="true"
                    :formatter="isAgentrecFmt"
            >
            </el-table-column> -->
      <el-table-column label="操作" fixed="right" min-width="180">
        <template slot-scope="scope">
          <el-button
              v-if="$tools.hasPermission('pdworld:pdShopinfo:info')"
              size="mini"
              plain
              @click="
              jump(scope.row.id, scope.row.enterpriseName, scope.row.avatar)
            "
            >其他信息</el-button
          >
          <el-button
              v-if="$tools.hasPermission('pdworld:pdMemberAccount:detail')"
              size="mini"
              plain
              @click="
			$router.push('/pdworld/memberInfo?id=' + scope.row.id + '&name=' + scope.row.nickName)
            "
            >账户信息</el-button
          >
          <el-button
              v-if="$tools.hasPermission('pdworld:pdMember:recharge')"
              size="mini"
              plain
              @click="handleRecharge(scope.row.id)"
          >充值会员</el-button
          >
          <el-button
            v-if="$tools.hasPermission('pdworld:pdMember:info')"
            size="mini"
            plain
            @click="handleInfo(scope.row.id)"
            >基础信息详情</el-button
          >
          <el-button
            v-if="$tools.hasPermission('pdworld:pdMember:edit')"
            size="mini"
            plain
            @click="handleEdit(scope.row.id)"
            >基础信息编辑</el-button
          >
          <el-button
            v-if="$tools.hasPermission('pdworld:pdMember:delete')"
            size="mini"
            plain
            type="danger"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <!-- 新增、编辑 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>
    <!-- 详情 -->
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
    <form-recharge v-if="formRechargeVisible" ref="formRecharge"  @refreshData="queryList"></form-recharge>
  </div>
</template>
<script>
import {
  pdmemberPage,
  pdmemberDel,
  pdmemberDelBatch,
  pdmemberDynamicExport,
} from "@/api/pdworld/pdmember.js";
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./pdmember-edit";
import FormInfo from "./pdmember-info";
import FormRecharge from "./pdmember-recharge";
import FortressPagination from "@/components/fortress-pagination.vue";
import { dictList, enumList, urlList } from "@/api/public.api.js";
export default {
  data() {
    return {
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      formRechargeVisible: false,
      queryForm: {
        userName: "",
        phone: "",
        nickName: "",
        type: 0,
        auditStatus: "",
        branch: "",
        isRec: "",
        isAgentrec: "",
        isIndex: "",
        provinceName:"",
        cityName:"",
        areaName:"",
        isPayMember:"",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
      sexList: [],
      MemberTypeList: [],
      YesNoList: [],
      branchList: [],
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    "form-recharge": FormRecharge,
    pagination: FortressPagination,
  },
  created() {
    this.queryDictList();
    this.queryEnumList();
    this.queryUrlList();
    this.initTableList();
    this.queryList();
  },
  methods: {
    jump(id, name, avatar) {
      localStorage.setItem("memberAvatar", avatar);
      this.$router.push("/operation/basics?id=" + id + "&name=" + name);
    },
    queryDictList() {
      this.sexList = [];
      dictList("sex").then((resp) => {
        if (resp.code == 0) {
          this.sexList = resp.data;
        }
      });
    },
    queryEnumList() {
      this.MemberTypeList = [];
      enumList("MemberType", "").then((resp) => {
        if (resp.code == 0) {
          this.MemberTypeList = resp.data;
        }
      });
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    queryUrlList() {
      this.branchList = [];
      urlList("/pdworld/pdPark/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.branchList.push({ code: item.id, name: item.storeName });
            });
          }
        }
      });
    },
    refresh() {
      this.tableData = [];
      this.queryList({ current: 1 });
    },
    //查询表格数据
    queryList(page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      pdmemberPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu =
            this.page.total % this.page.size == 0
              ? parseInt(this.page.total / this.page.size)
              : parseInt(this.page.total / this.page.size + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({ current: cu });
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    handleRecharge(id) {
      //打开充值页面
      this.formRechargeVisible = true;
      this.$nextTick(() => {
        this.$refs.formRecharge.init(id);
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          pdmemberDel(row.id).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              //判断是否最后一页的最后一条记录
              let totalPages =
                (this.page.total - 1) % this.page.size == 0
                  ? (this.page.total - 1) / this.page.size
                  : (this.page.total - 1) / this.page.size + 1;
              if (this.page.current > totalPages && this.page.current != 1) {
                this.page.current = this.page.current - 1;
              }
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    },
    //批量删除
    handleDeleteBatch() {
      let checkedRow = this.$refs.multipleTable.selection;
      if (checkedRow.length > 0) {
        let ids = [];
        checkedRow.forEach((item) => {
          ids.push(item.id);
        });
        this.$confirm("确认删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            pdmemberDelBatch(ids).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                //判断是否最后一页的最后一条记录
                let totalPages =
                  (this.page.total - checkedRow.length) % this.page.size == 0
                    ? (this.page.total - checkedRow.length) / this.page.size
                    : (this.page.total - checkedRow.length) / this.page.size +
                      1;
                if (this.page.current > totalPages && this.page.current != 1) {
                  this.page.current = this.page.current - 1;
                }
                this.queryList();
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          })
          .catch(() => {});
      } else {
        this.$msg({
          message: "请选择数据进行删除",
          type: "error",
        });
      }
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        userName: "",
        phone: "",
        nickName: "",
        type: 0,
        auditStatus: "",
        branch: "",
        isRec: "",
        isAgentrec: "",
        isIndex: "",
        provinceName:"",
        cityName:"",
        areaName:"",
        isPayMember:"",
      };
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList() {
      viewTableList("pd_member").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
    //会员类型[会员/商家]格式化返回
    typeFmt(row) {
      let name = "";
      if (this.MemberTypeList) {
        this.MemberTypeList.forEach((item) => {
          if (item.code == row.type) {
            name = item.name;
          }
        });
      }
      return name;
    },
    //审核状态（是否实名）格式化返回
    auditStatusFmt(row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach((item) => {
          if (item.code == row.auditStatus) {
            name = item.name;
          }
        });
      }
      return name;
    },
    //所属网点格式化返回
    branchFmt(row) {
      let name = "";
      if (this.branchList) {
        this.branchList.forEach((item) => {
          if (item.code == row.branch) {
            name = item.name;
          }
        });
      }
      return name;
    },
    //是否推荐商家(搜索页右侧)格式化返回
    // isRecFmt(row) {
    //   let name = "";
    //   if (this.YesNoList) {
    //     this.YesNoList.forEach((item) => {
    //       if (item.code == row.isRec) {
    //         name = item.name;
    //       }
    //     });
    //   }
    //   return name;
    // },
    isPayMemberFmt(row) {
      let name = "否";
      if (this.YesNoList) {
        this.YesNoList.forEach((item) => {
          if (item.code == row.isPayMember) {
            name = item.name;
          }
        });
      }
      return name;
    },
    // //是否货运代理推荐(首页)格式化返回
    // isAgentrecFmt(row) {
    //     let name = "";
    //     if (this.YesNoList) {
    //         this.YesNoList.forEach(item =>{
    //             if(item.code == row.isAgentrec){
    //                 name = item.name;
    //             }
    //         })
    //     }
    //     return name;
    // },
    //可见字段excel导出
    handleExport() {
      if (!this.tableShowList || this.tableShowList.length < 1) {
        this.$msg({
          message: "请选择要导出的列",
          type: "error",
        });
        return;
      }
      let colNum = 1;
      let headerList = [];
      this.tableShowList.forEach((item) => {
        let nameData = this.tableList.find((item2) => {
          return item2.propName == item;
        });
        let name = "";
        if (nameData && nameData.propDesc) {
          name = nameData.propDesc;
        } else {
          name = "未定义字段" + colNum++;
        }
        headerList.push({ name: name, code: item });
      });
      pdmemberDynamicExport({ headerList: headerList, dto: this.queryForm });
    },
  },
};
</script>
<style scoped>
</style>
