<template>
  <div class="padding20">
    <el-row style="margin-bottom: 17px">
      <el-button
        type="primary"
        size="medium"
        v-if="$tools.hasPermission('system:menuManage:save')"
        @click="handleEdit()"
        icon="el-icon-plus"
      >添加</el-button>
    </el-row>
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="类型" :formatter="typeFmt"></el-table-column>
        <el-table-column prop="icon" label="图标" :formatter="iconFmt"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="180">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('system:menuManage:info')"
              size="mini"
              @click="handleInfo(scope.row.id)"
            >详情</el-button>
            <el-button
              v-if="$tools.hasPermission('system:menuManage:edit')"
              size="mini"
              @click="handleEdit(scope.row.id)"
            >编辑</el-button>
            <el-button
              v-if="$tools.hasPermission('system:menuManage:delete')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              plain
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      :menuTypeList="menuTypeList"
      :allMenu="tableData"
      @refreshData="queryMenuList"
    ></form-edit>
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
  </div>
</template>
<script>
import { optMenuList, menuDel } from "@/api/system/menu.js"
import { enumList } from "@/api/public.api.js"
import FormEdit from "./systemmenu-edit"
import FormInfo from "./systemmenu-info"
export default {
  data () {
    return {
      parentShow: '',
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      menuTypeList: [],
    }
  },
  components: {
    "form-edit": FormEdit,
    FormInfo,
  },
  activated () {
    this.tableData = []
    this.queryMenuList()
  },
  created () {
    this.queryMenuTypeList()
    this.queryMenuList()
  },
  methods: {
    //查询菜单类型
    queryMenuTypeList () {
      enumList("MenuType").then(resp => {
        if (resp.code == 0) {
          this.menuTypeList = resp.data
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })
    },
    //查询菜单列表
    queryMenuList () {
      this.parentShow = true
      optMenuList().then(resp => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false
            this.tableData = resp.data
          }, 600)
        } else {
          this.parentShow = false
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })
    },
    // 菜单类型格式化
    typeFmt (row) {
      let typeName = ""
      this.menuTypeList.forEach(item => {
        if (row.type == item.code) {
          typeName = item.name
        }
      })
      return typeName
    },
    //图标格式化
    iconFmt (row) {
      return <span class={row.icon}></span>
    },
    //打开编辑窗口
    handleEdit (id) {
      this.formEditVisible = true
      this.$nextTick(() => {
        this.$refs.formEdit.init(id)
      })
    },

    //删除
    handleDelete (row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        menuDel(row.id).then(resp => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            })
            this.queryMenuList()
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            })
          }
        })
      })
    },
    //查看详情
    handleInfo (id) {
      this.formInfoVisible = true
      this.$nextTick(() => {
        this.$refs.formInfo.init(id)
      })
    },
  },
}
</script>
<style scoped></style>
