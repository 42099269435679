import request from "@/common/request"

//获取当前用户可访问的字典分页展示
export const sysDictPage = (queryParam) => {
    return request("get", "/system/dict/page", queryParam);
}
//字典子集分页展示
export const subSysDictPage = (queryParam) => {
    return request("get", "/system/dict/subpage", queryParam);
}
//获取当前用户可访问的字典列表
export const sysDictList = () => {
    return request("get", "/system/dict/listAllParent");
}
//字典详情
export const sysDictInfo = (id) => {
    return request('get', '/system/dict/info', {
        id: id
    })
}
//保存字典信息
export const sysDictSave = (data) => {
    return request('post', '/system/dict/save', data)
}
//修改字典信息
export const sysDictEdit = (data) => {
    return request('post', '/system/dict/edit', data)
}
//删除字典信息
export const sysDictDel = (id) => {
    return request('post', '/system/dict/delete?id=' + id)
}
