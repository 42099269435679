import request from "@/common/request"

//线路和会员收藏关系表分页展示
export const pdcollectionPage = (queryForm) => {
    return request("get", "/pdworld/pdCollection/page", queryForm);
}
//线路和会员收藏关系表列表展示
export const pdcollectionList = (queryForm) => {
    return request("get", "/pdworld/pdCollection/list", queryForm);
}
//线路和会员收藏关系表详情
export const pdcollectionInfo = (id) => {
    return request('get', '/pdworld/pdCollection/info', {
        id: id
    })
}
//线路和会员收藏关系表信息保存
export const pdcollectionSave = (data) => {
    return request('post', '/pdworld/pdCollection/save', data)
}
//线路和会员收藏关系表信息修改
export const pdcollectionEdit = (data) => {
    return request('post', '/pdworld/pdCollection/edit', data)
}
//线路和会员收藏关系表信息删除
export const pdcollectionDel = (id) => {
    return request('post', '/pdworld/pdCollection/delete?id=' + id)
}
//线路和会员收藏关系表批量删除
export const pdcollectionDelBatch = (ids) => {
    return request('post', '/pdworld/pdCollection/deleteBatch', ids)
}
//线路和会员收藏关系表动态表头导出
export const pdcollectionDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdCollection/exportDynamic', data)
}
