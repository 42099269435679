<template>
	<el-dialog :visible.sync="visible" width="80vw" title="选择商家" :close-on-click-modal="false" append-to-body modal-append-to-body>
		<div class="padding20">
			<el-row style="margin-bottom: 7px">
				<el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
					<el-form-item>
						<fortress-select
							v-model="queryForm.isTransit"
							:dictList="YesNoList"
							placeholder="请选择是否中转性质"
							clearable
							filterable
						></fortress-select>
					</el-form-item>

          <!--					<el-form-item>-->
          <!--						<fortress-select-->
          <!--							v-model="queryForm.isIndexrec"-->
          <!--							:dictList="YesNoList"-->
          <!--							placeholder="请选择是否首页推荐"-->
          <!--							clearable-->
          <!--							filterable-->
          <!--						></fortress-select>-->
          <!--					</el-form-item>-->
          <!--					<el-form-item v-show="collapse.flag">-->
          <!--						<fortress-select-->
          <!--							v-model="queryForm.isSearchrec"-->
          <!--							:dictList="YesNoList"-->
          <!--							placeholder="请选择是否搜索页推荐 搜索页置顶。"-->
          <!--							clearable-->
          <!--							filterable-->
          <!--						></fortress-select>-->
          <!--					</el-form-item>-->

          <!--省市区-->
          <el-form-item prop="startPlace">
						<fortress-region ref="startPlaceFortressRegion" @ftregion="startPlaceHandler"></fortress-region>
					</el-form-item>

					<el-form-item prop="destination">
						<fortress-region ref="destinationFortressRegion" @ftregion="destinationHandler"></fortress-region>
					</el-form-item>

					<!--        <el-form-item v-show="collapse.flag">-->
					<!--          <el-input-->
					<!--            v-model="queryForm.sort"-->
					<!--            autocomplete="off"-->
					<!--            placeholder="请输入显示顺序"-->
					<!--            clearable-->
					<!--          ></el-input>-->
					<!--        </el-form-item>-->

					<el-form-item>
						<el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({ current: 1 })">查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="text" @click="handleCollapseState">
							{{ collapse.label }}
							<i :class="collapse.icon"></i>
						</el-button>
					</el-form-item>
				</el-form>
				<div style="float: right; padding-right: 17px">
					<i class="el-icon-download" style="margin-right: 10px; cursor: pointer" @click="handleExport"></i>
					<i class="el-icon-refresh" style="margin-right: 10px; cursor: pointer" @click="refresh"></i>

					<el-popover placement="bottom" width="180" trigger="click" title="列展示" popper-class="columnShows">
						<el-checkbox-group v-model="tableShowList">
							<el-checkbox v-for="item in tableList" :label="item.propName" :key="item.propName" :value="item.propName">
								{{ item.propDesc }}
							</el-checkbox>
						</el-checkbox-group>
						<i slot="reference" class="el-icon-s-operation" style="cursor: pointer"></i>
					</el-popover>
				</div>
			</el-row>
			<el-table ref="multipleTable" :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border>
				<el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
				<!--
		</el-table-column> -->
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('startPlace', tableShowList)"-->
				<!--        prop="startPlace"-->
				<!--        label="始发地"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('destination', tableShowList)"-->
				<!--        prop="destination"-->
				<!--        label="到达地"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<el-table-column
					prop="isTransit"
					label="是否中转性质"
					:show-overflow-tooltip="true"
					:formatter="isTransitFmt"
				></el-table-column>

        <!--				<el-table-column-->
        <!--					v-if="$tools.isShow('transportation', tableShowList)"-->
        <!--					prop="transportation"-->
        <!--					label="运输时效"-->
        <!--					:show-overflow-tooltip="true"-->
        <!--				></el-table-column>-->
        <!--            <el-table-column-->
        <!--                    v-if="$tools.isShow('type', tableShowList)"-->
        <!--                    prop="type"-->
        <!--                    label="类型（专线运输/货运代理）"-->
        <!--                    :show-overflow-tooltip="true"-->
        <!--                    :formatter="typeFmt"-->
        <!--            >-->
        <!--            </el-table-column>-->
        <!--				<el-table-column-->
        <!--					v-if="$tools.isShow('isIndexrec', tableShowList)"-->
        <!--					prop="isIndexrec"-->
        <!--					label="是否首页推荐"-->
        <!--					:show-overflow-tooltip="true"-->
        <!--					:formatter="isIndexrecFmt"-->
        <!--				></el-table-column>-->
        <!--				<el-table-column-->
        <!--					v-if="$tools.isShow('isSearchrec', tableShowList)"-->
        <!--					prop="isSearchrec"-->
        <!--					label="是否搜索页推荐 搜索页置顶。"-->
        <!--					:show-overflow-tooltip="true"-->
        <!--					:formatter="isSearchrecFmt"-->
        <!--				></el-table-column>-->
        <!--				<el-table-column prop="indexrecSort" label="推荐权重" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column
					v-if="$tools.isShow('sort', tableShowList)"
					prop="sort"
					label="权重"
					:show-overflow-tooltip="true"
				></el-table-column>
				<el-table-column prop="phone" label="联系电话" :show-overflow-tooltip="true"></el-table-column>
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('consigner', tableShowList)"-->
				<!--        prop="consigner"-->
				<!--        label="发货省"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<el-table-column
					v-if="$tools.isShow('consignerName', tableShowList)"
					prop="consignerName"
					label="发货省"
					:show-overflow-tooltip="true"
				></el-table-column>
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('consignerCity', tableShowList)"-->
				<!--        prop="consignerCity"-->
				<!--        label="发货市"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<el-table-column
					v-if="$tools.isShow('consignerCityName', tableShowList)"
					prop="consignerCityName"
					label="发货市"
					:show-overflow-tooltip="true"
				></el-table-column>
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('consignerArea', tableShowList)"-->
				<!--        prop="consignerArea"-->
				<!--        label="发货区"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<el-table-column
					v-if="$tools.isShow('consignerAreaName', tableShowList)"
					prop="consignerAreaName"
					label="发货区"
					:show-overflow-tooltip="true"
				></el-table-column>
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('province', tableShowList)"-->
				<!--        prop="province"-->
				<!--        label="收货省"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<el-table-column
					v-if="$tools.isShow('provinceName', tableShowList)"
					prop="provinceName"
					label="收货省"
					:show-overflow-tooltip="true"
				></el-table-column>
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('provinceCity', tableShowList)"-->
				<!--        prop="provinceCity"-->
				<!--        label="收货市"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<el-table-column
					v-if="$tools.isShow('provinceCityName', tableShowList)"
					prop="provinceCityName"
					label="收货市"
					:show-overflow-tooltip="true"
				></el-table-column>
				<!--      <el-table-column-->
				<!--        v-if="$tools.isShow('provinceArea', tableShowList)"-->
				<!--        prop="provinceArea"-->
				<!--        label="收货区"-->
				<!--        :show-overflow-tooltip="true"-->
				<!--      >-->
				<!--      </el-table-column>-->
				<el-table-column
					v-if="$tools.isShow('areaName', tableShowList)"
					prop="areaName"
					label="收货区"
					:show-overflow-tooltip="true"
				></el-table-column>
				<el-table-column label="操作" fixed="right" min-width="180">
					<template slot-scope="scope">
						<el-button size="mini" plain type="primary" @click="onSelection(scope.row)">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="page.total" :current-page="page.current" :page-size="page.size" @refreshData="queryList"></pagination>
			<!-- 新增、编辑 -->
			<form-edit v-if="formEditVisible" ref="formEdit" @refreshData="queryList"></form-edit>
			<!-- 详情 -->
			<form-info v-if="formInfoVisible" ref="formInfo"></form-info>
		</div>
	</el-dialog>
</template>
<script>
import { pdroutePage, pdrouteDel, pdrouteDelBatch, pdrouteDynamicExport } from "@/api/pdworld/pdroute.js"
import { viewTableList } from "@/api/public.api.js"
import FormEdit from "./pdroute-edit"
import FormInfo from "./pdroute-info"
import FortressPagination from "@/components/fortress-pagination.vue"
import { enumList } from "@/api/public.api.js"

let callback = null

export default {
	props: {
		member: {
			default: () => ({}),
		},
	},
	data() {
		return {
			tableData: [],
			formEditVisible: false,
			formInfoVisible: false,
			queryForm: {
				memberId: "",
				parkId: "",
				startPlace: "",
				destination: "",
				type: "",
				isIndexrec: "",
				isSearchrec: "",
				sort: "",
				province: "",
				provinceName: "",
				provinceCity: "",
				provinceCityName: "",
				provinceArea: "",
				areaName: "",
				isTransit: "",
			},
			page: {
				current: 1,
				size: 10,
				total: 0,
			},
			collapse: {
				icon: "el-icon-arrow-down el-icon--right",
				label: "展开",
				flag: false,
			},
			tableList: [],
			tableShowList: [],
			PDTypeList: [],
			YesNoList: [],
			memberIdList: [],
			parkIdList: [],

			visible: false,
		}
	},
	components: {
		"form-edit": FormEdit,
		"form-info": FormInfo,
		pagination: FortressPagination,
	},
	created() {
		this.queryEnumList()
		// this.queryUrlList();
		this.initTableList()
		// this.queryList()
	},
	methods: {
    show(cb, memberId) {
      this.resetQueryForm()
      this.member.id = memberId
      this.refresh()
      this.visible = true

      callback = cb
    },
    onSelection(row) {
      callback && callback(row)
      this.visible = false
    },
    startPlaceHandler(regions) {
			this.queryForm.consigner = regions[0].id
			this.queryForm.consignerCity = regions[1].id
			this.queryForm.consignerArea = regions[2].id
		},
		destinationHandler(regions) {
			this.queryForm.province = regions[0].id
			this.queryForm.provinceCity = regions[1].id
			this.queryForm.provinceArea = regions[2].id
		},
		queryEnumList() {
			this.PDTypeList = []
			enumList("PDType", "").then(resp => {
				if (resp.code == 0) {
					this.PDTypeList = resp.data
				}
			})
			this.YesNoList = []
			enumList("YesNo", "").then(resp => {
				if (resp.code == 0) {
					this.YesNoList = resp.data
				}
			})
		},
		// queryUrlList() {
		//   // this.memberIdList = [];
		//   // urlList("/pdworld/pdMember/list").then((resp) => {
		//   //   if (resp.code == 0) {
		//   //     if (resp.data) {
		//   //       resp.data.forEach((item) => {
		//   //         this.memberIdList.push({ code: item.id, name: item.nickName });
		//   //       });
		//   //     }
		//   //   }
		//   // });
		//   this.parkIdList = [];
		//   urlList("/pdworld/pdPark/list").then((resp) => {
		//     if (resp.code == 0) {
		//       if (resp.data) {
		//         resp.data.forEach((item) => {
		//           this.parkIdList.push({ code: item.id, name: item.storeName });
		//         });
		//       }
		//     }
		//   });
		// },
		refresh() {
			this.tableData = []
			this.queryList({ current: 1 })
		},
		//查询表格数据
		queryList(page) {
			if (page) {
				this.page.current = page.current ? page.current : this.page.current
				this.page.size = page.size ? page.size : this.page.size
			}
			this.queryForm.memberId = this.member.id || ""
			this.queryForm.current = this.page.current
			this.queryForm.size = this.page.size
			pdroutePage(this.queryForm).then(resp => {
				if (resp.code == 0) {
					this.tableData = resp.data.records
					this.page.total = resp.data.total
					//查询页大于总页数，重新查询
					let cu =
						this.page.total % this.page.size == 0
							? parseInt(this.page.total / this.page.size)
							: parseInt(this.page.total / this.page.size + 1)
					if (cu > 0 && this.page.current > cu) {
						this.queryList({ current: cu })
					}
				} else {
					this.$msg({
						message: resp.msg,
						type: "error",
					})
				}
			})
		},
		//打开编辑窗口
		handleEdit(memberId, id) {
			this.formEditVisible = true
			this.$nextTick(() => {
				this.$refs.formEdit.init(memberId, id)
			})
		},
		//打开详情窗口
		handleInfo(id) {
			this.formInfoVisible = true
			this.$nextTick(() => {
				this.$refs.formInfo.init(id)
			})
		},
		//删除
		handleDelete(row) {
			this.$confirm("确认删除该记录, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					pdrouteDel(row.id).then(resp => {
						if (resp.code == 0) {
							this.$message({
								type: "success",
								message: "操作成功!",
							})
							//判断是否最后一页的最后一条记录
							let totalPages =
								(this.page.total - 1) % this.page.size == 0
									? (this.page.total - 1) / this.page.size
									: (this.page.total - 1) / this.page.size + 1
							if (this.page.current > totalPages && this.page.current != 1) {
								this.page.current = this.page.current - 1
							}
							this.queryList()
						} else {
							this.$msg({
								message: resp.msg,
								type: "error",
							})
						}
					})
				})
				.catch(() => {})
		},
		//批量删除
		handleDeleteBatch() {
			let checkedRow = this.$refs.multipleTable.selection
			if (checkedRow.length > 0) {
				let ids = []
				checkedRow.forEach(item => {
					ids.push(item.id)
				})
				this.$confirm("确认删除该记录, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						pdrouteDelBatch(ids).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								//判断是否最后一页的最后一条记录
								let totalPages =
									(this.page.total - checkedRow.length) % this.page.size == 0
										? (this.page.total - checkedRow.length) / this.page.size
										: (this.page.total - checkedRow.length) / this.page.size + 1
								if (this.page.current > totalPages && this.page.current != 1) {
									this.page.current = this.page.current - 1
								}
								this.queryList()
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					})
					.catch(() => {})
			} else {
				this.$msg({
					message: "请选择数据进行删除",
					type: "error",
				})
			}
		},
		//重置查询框
		resetQueryForm() {
      this.$nextTick(() => {
        if (this.$refs.startPlaceFortressRegion) {
          this.$refs.startPlaceFortressRegion.init([])
        }
        if (this.$refs.destinationFortressRegion) {
          this.$refs.destinationFortressRegion.init([])
        }
      })
      this.queryForm = {
        memberId: this.member.id,
        parkId: "",
        startPlace: "",
        destination: "",
        type: "",
        isIndexrec: "",
        isSearchrec: "",
        sort: "",
        province: "",
        provinceName: "",
        provinceCity: "",
				provinceCityName: "",
				provinceArea: "",
				areaName: "",
				isTransit: "",
			}
		},
		//切换查询条件收缩框
		handleCollapseState() {
			this.collapse.flag = !this.collapse.flag
			if (this.collapse.flag) {
				this.collapse.icon = "el-icon-arrow-up el-icon--right"
				this.collapse.label = "收起"
			} else {
				this.collapse.icon = "el-icon-arrow-down el-icon--right"
				this.collapse.label = "展开"
			}
		},
		//视图表格列表初始化
		initTableList() {
			viewTableList("pd_route").then(resp => {
				if (resp.code == 0) {
					this.tableList = resp.data
					this.tableList.forEach(item => {
						this.tableShowList.push(item.propName)
					})
				}
			})
		},
		//所属商家ID格式化返回
		memberIdFmt(row) {
			let name = ""
			if (this.memberIdList) {
				this.memberIdList.forEach(item => {
					if (item.code == row.memberId) {
						name = item.name
					}
				})
			}
			return name
		},
		//始发地网点ID格式化返回
		parkIdFmt(row) {
			let name = ""
			if (this.parkIdList) {
				this.parkIdList.forEach(item => {
					if (item.code == row.parkId) {
						name = item.name
					}
				})
			}
			return name
		},
		//类型（专线运输/货运代理）格式化返回
		// typeFmt(row) {
		//     let name = "";
		//     if (this.PDTypeList) {
		//         this.PDTypeList.forEach(item =>{
		//             if(item.code == row.type){
		//                 name = item.name;
		//             }
		//         })
		//     }
		//     return name;
		// },
		//是否首页推荐格式化返回
		isIndexrecFmt(row) {
			let name = ""
			if (this.YesNoList) {
				this.YesNoList.forEach(item => {
					if (item.code == row.isIndexrec) {
						name = item.name
					}
				})
			}
			return name
		},
		//是否中转性质格式化返回
		isTransitFmt(row) {
			let name = ""
			if (this.YesNoList) {
				this.YesNoList.forEach(item => {
					if (item.code == row.isTransit) {
						name = item.name
					}
				})
			}
			return name
		},
		//是否搜索页推荐 搜索页置顶。格式化返回
		isSearchrecFmt(row) {
			let name = ""
			if (this.YesNoList) {
				this.YesNoList.forEach(item => {
					if (item.code == row.isSearchrec) {
						name = item.name
					}
				})
			}
			return name
		},
		//可见字段excel导出
		handleExport() {
			if (!this.tableShowList || this.tableShowList.length < 1) {
				this.$msg({
					message: "请选择要导出的列",
					type: "error",
				})
				return
			}
			let colNum = 1
			let headerList = []
			this.tableShowList.forEach(item => {
				let nameData = this.tableList.find(item2 => {
					return item2.propName == item
				})
				let name = ""
				if (nameData && nameData.propDesc) {
					name = nameData.propDesc
				} else {
					name = "未定义字段" + colNum++
				}
				headerList.push({ name: name, code: item })
			})
			pdrouteDynamicExport({ headerList: headerList, dto: this.queryForm })
		},
	},
}
</script>
<style scoped></style>
