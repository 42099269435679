<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="上级" :label-width="formLabelWidth" prop="parentId">
        <el-select v-model="form.parentId" clearable placeholder="请选择">
          <el-option :label="selParent.label" :value="selParent.id">
            <el-tree
              :data="allData"
              :props="defaultProps"
              accordion
              highlight-current
              @node-click="handleNodeClick"
            ></el-tree>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="权限"
        :label-width="formLabelWidth"
        prop="dataAuthType"
      >
        <fortress-radio-group
          v-model="form.dataAuthType"
          :dictList="dataAuthTypeList"
          placeholder="请选择"
          clearable
        ></fortress-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { postInfo, postSave, postEdit } from "@/api/auth/syspost.js";
export default {
  props: ["allData", "dataAuthTypeList", "deptId"],
  data() {
    return {
      visible: false,
      selParent: {
        id: "",
        label: "",
      },
      form: {
        id: "",
        name: "",
        parentId: "",
        deptId: "",
        dataAuthType: "",
      },
      formLabelWidth: "120px",
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        dataAuthType: [
          { required: true, message: "请选择权限", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(id) {
      this.resetForm();
      //修改
      if (id) {
        this.selParent.id = "";
        this.selParent.label = "";
        postInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              if (this.form.parentId) {
                this.findParentCode(this.form.parentId, this.allData);
              }
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
        //新增
      }
      this.visible = true;
    },
    //树形菜单展示
    handleNodeClick(data) {
      this.selParent.id = data.id;
      this.selParent.label = data.name;
      this.form.parentId = data.id;
    },
    //查找父节点
    findParentCode(parentId, dataScope) {
      dataScope.forEach((m) => {
        if (parentId == m.id) {
          this.selParent.id = m.id;
          this.selParent.label = m.name;
          return;
        } else if (m.children) {
          this.findParentCode(parentId, m.children);
        }
      });
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.deptId = this.deptId;
          //修改
          if (this.form.id) {
            postEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.visible = false;
                this.$notify({
                  title: "修改成功",
                  type: "success",
                  message: "您已成功修改一个职位!",
                });
                this.$emit("refreshData", this.deptId);
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            postSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.visible = false;
                this.$notify({
                  title: "添加成功",
                  type: "success",
                  message: "您已成功添加一个职位!",
                });
                this.$emit("refreshData", this.deptId);
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        parentId: "",
        dataAuthType: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
