<template>
	<div class="payscene-setting-wx">
		<ALIPAYAPP v-if="active === 'ALIPAY_APP'" :data="message"></ALIPAYAPP>
		<ALIPAYMINI v-else-if="active === 'ALIPAY_MINI'" :data="message"></ALIPAYMINI>
		<ALIPAYPC v-else-if="active === 'ALIPAY_PC'" :data="message"></ALIPAYPC>
		<ALIPAYWAP v-else-if="active === 'ALIPAY_WAP'" :data="message"></ALIPAYWAP>
	</div>
</template>

<script>
import request from "@/common/request"
import ALIPAYAPP from "./ali/ALIPAY_APP.vue"
import ALIPAYMINI from "./ali/ALIPAY_MINI.vue"
import ALIPAYPC from "./ali/ALIPAY_PC.vue"
import ALIPAYWAP from "./ali/ALIPAY_WAP.vue"

export default {
	name: "payscene-setting-ali",
	props: {
		current: {
			type: Number,
			default: 0,
		},
		active: {
			type: String,
			default: "",
		},
		data: {},
	},
	data() {
		return {}
	},
	computed: {
		message() {
			return this.data[this.current]
		},
	},
	methods: {},
	components: {
		ALIPAYAPP,
		ALIPAYMINI,
		ALIPAYPC,
		ALIPAYWAP,
	},
}
</script>

<style lang="scss" scoped>
.payscene-setting-wx {
}
</style>
