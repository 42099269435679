<template>
	<div class="payscene-setting-wx">
		<v-app v-if="active === 'APP'" :data="message"></v-app>
		<v-public v-else-if="active === 'JSAPI'" :data="message"></v-public>
		<v-native v-else-if="active === 'NATIVE'" :data="message"></v-native>
		<v-h v-else-if="active === 'H5'" :data="message"></v-h>
		<v-mine v-else-if="active === 'MINI'" :data="message"></v-mine>
	</div>
</template>

<script>
import request from "@/common/request"
import VApp from "./wx/APP.vue"
import VPublic from "./wx/public.vue"
import VNative from "./wx/native.vue"
import VH from "./wx/H5.vue"
import VMine from "./wx/mini.vue"

export default {
	name: "payscene-setting-wx",
	props: {
		current: {
			type: Number,
			default: 0,
		},
		active: {
			type: String,
			default: "",
		},
		data: {},
	},
	data() {
		return {}
	},
	computed: {
		message() {
			return this.data[this.current]
		},
	},
	methods: {},
	components: {
		VApp,
		VPublic,
		VNative,
		VH,
		VMine,
	},
}
</script>

<style lang="scss" scoped>
.payscene-setting-wx {
}
</style>
