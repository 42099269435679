<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false" append-to-body modal-append-to-body>
		<el-form ref="form" :model="form" :rules="rules">
			<!-- <el-form-item label="分站ID" :label-width="formLabelWidth" prop="siteId">
				<el-input v-model="form.siteId" autocomplete="off" placeholder="请输入分站ID"></el-input>
			</el-form-item>
			<el-form-item label="分站所属市编码subsite" :label-width="formLabelWidth" prop="siteCityCode">
				<el-input v-model="form.siteCityCode" autocomplete="off" placeholder="请输入分站所属市编码subsite"></el-input>
			</el-form-item> -->
			<el-form-item v-if="!form.id" label="商家" :label-width="formLabelWidth" prop="memberId">
        <el-input
            v-model="member.nickName"
            readonly
            autocomplete="off"
            placeholder="请选择商家"
            @click.native="onSelectvSelectPdmemberRef"
            :disabled="!!form.id"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="!form.id" label="要推荐的线路" :label-width="formLabelWidth" prop="routeId">
        <el-input
            readonly
            :value="routeName"
            autocomplete="off"
            placeholder="请选择线路"
            :disabled="!member.id || form.id"
            @click.native="onSelectRouteRef"
        ></el-input>
      </el-form-item>
			<el-form-item label="排序权重/倒序" :label-width="formLabelWidth" prop="sort">
				<el-input-number v-model="form.sort"></el-input-number>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
			<el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
		</div>
		<vSelectPdmember ref="vSelectPdmemberRef"></vSelectPdmember>
		<vSelectRoute ref="vSelectRouteRef"></vSelectRoute>
	</el-dialog>
</template>
<script>
import { subsiterouteInfo, subsiterouteSave, subsiterouteEdit } from "@/api/pdworld/subsiteroute.js"
import vSelectPdmember from "./v-select-pdmember.vue"
import vSelectRoute from "./v-select-route.vue"

export default {
	props: {
		row: {},
	},
	data() {
		return {
			visible: false,
			form: {
				siteId: "",
				siteCityCode: "",
				routeId: "",
				sort: "",
			},
			formLabelWidth: "120px",
			rules: {},
			member: {},
			routeMessage: {},
		}
	},
	created() {},
	computed: {
		routeName() {
			if (this.routeMessage.id) {
				return `${this.routeMessage.consignerName}${this.routeMessage.consignerCityName}${this.routeMessage.consignerAreaName}-${this.routeMessage.provinceName}${this.routeMessage.provinceCityName}${this.routeMessage.areaName}`
			}
			return ""
		},
	},
	methods: {
		onSelectvSelectPdmemberRef() {
			this.$refs.vSelectPdmemberRef.show(member => {
				this.member = member
				this.form.memberId = member.id
			})
			//清空线路信息
			this.routeMessage = ""
			this.form.routeId = ""
		},
		onSelectRouteRef() {
			if (!this.member.id) return
			this.$refs.vSelectRouteRef.show(routeMessage => {
				this.routeMessage = routeMessage
				this.form.routeId = routeMessage.id
			}, this.member.id)
		},
		init(id) {
			this.resetForm()
			//修改
			if (id) {
				this.form.id = id
				subsiterouteInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
			}
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			this.form.siteId = this.row.id
			this.form.siteCityCode = this.row.siteCityCode

			if (!this.form.routeId) {
				this.$message({
					type: "error",
					message: "请选择要推荐的线路!",
				})
				return
			}
			this.$refs["form"].validate(valid => {
				if (valid) {
					//修改
					if (this.form.id) {
						subsiterouteEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						subsiterouteSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.routeMessage = ""
			this.member = {}
			this.form = {
				memberId: "",
				siteId: "",
				siteCityCode: "",
				routeId: "",
				sort: "",
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
	components: {
		vSelectPdmember,
		vSelectRoute,
	},
}
</script>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
