<!--  -->
<template>
	<div class="padding20">
		<div class="tit">
			<span>基本信息</span>
			<div class="btnList">
				<el-button type="primary" size="medium" @click="submitForm" :loading="load">提交</el-button>
				<!-- <el-button type="primary" size="medium" @click="save"
          >保存基本信息</el-button
        > -->
				<!-- <el-button type="primary" size="medium" v-print="printObj">打印</el-button> -->
			</div>
		</div>
		<el-form ref="form" :model="form" :rules="rules">
			<!-- <el-form-item label="会员ID" :label-width="formLabelWidth" prop="memberId">
                <fortress-select
                        v-model="form.memberId"
                        :dictList="memberIdList"
                        placeholder="请选择会员ID"
                        clearable
                ></fortress-select>
            </el-form-item> -->
			<el-form-item label="商家头像" :label-width="formLabelWidth" prop="shopAvatar">
				<div class="img" style="float: left">
					<fortress-upload type="image" name="shopAvatar" :formData="form"></fortress-upload>
				</div>
			</el-form-item>
			<el-form-item label="公司名称" :label-width="formLabelWidth" prop="enterpriceName">
				<el-input v-model="form.enterpriceName" autocomplete="off" placeholder="请输入公司名称"></el-input>
			</el-form-item>
			<el-form-item label="公司简介" :label-width="formLabelWidth" prop="shopInfo">
				<fortress-editor ref="fEditorRef" v-model="form.shopInfo"></fortress-editor>
				<!--        <el-input-->
				<!--          type="textarea"-->
				<!--          :rows="4"-->
				<!--          v-model="form.shopInfo"-->
				<!--          autocomplete="off"-->
				<!--          placeholder="请输入公司简介"-->
				<!--        ></el-input>-->
			</el-form-item>

			<el-form-item label="服务语" :label-width="formLabelWidth" prop="serviceWord">
				<el-input type="textarea" :rows="4" v-model="form.serviceWord" autocomplete="off" placeholder="请输入服务语"></el-input>
			</el-form-item>

			<el-form-item label="商家二维码" :label-width="formLabelWidth" prop="qrcode">
				<div class="img" style="float: left">
					<fortress-upload type="image" name="qrcode" :formData="form" nowater></fortress-upload>
				</div>
			</el-form-item>
			<el-form-item label="公司负责人" :label-width="formLabelWidth" prop="corpn">
				<el-input v-model="form.corpn" autocomplete="off" placeholder="请输入公司负责人"></el-input>
			</el-form-item>
			<el-form-item label="公司地址" :label-width="formLabelWidth" prop="address">
				<el-input v-model="form.address" autocomplete="off" placeholder="请输入公司地址"></el-input>
			</el-form-item>
			<el-form-item label="服务监督电话" :label-width="formLabelWidth" prop="tel">
				<el-input v-model="form.tel" autocomplete="off" placeholder="请输入服务监督电话"></el-input>
			</el-form-item>
			<!--      <el-form-item-->
			<!--        label="公司网址"-->
			<!--        :label-width="formLabelWidth"-->
			<!--        prop="wesite"-->
			<!--      >-->
			<!--        <el-input-->
			<!--          v-model="form.wesite"-->
			<!--          autocomplete="off"-->
			<!--          placeholder="请输入公司网址"-->
			<!--        ></el-input>-->
			<!--      </el-form-item>-->
			<!--      <el-form-item-->
			<!--        label="服务监督电话"-->
			<!--        :label-width="formLabelWidth"-->
			<!--        prop="superviseTel"-->
			<!--      >-->
			<!--        <el-input-->
			<!--          v-model="form.superviseTel"-->
			<!--          autocomplete="off"-->
			<!--          placeholder="请输入服务监督电话"-->
			<!--        ></el-input>-->
			<!--      </el-form-item>-->
			<el-form-item label="域名后缀" :label-width="formLabelWidth" prop="shopurl">
				<el-input disabled v-model="form.shopurl" autocomplete="off" placeholder="请输入域名后缀"></el-input>
			</el-form-item>
			<el-form-item label="地图选点" :label-width="formLabelWidth">
				<fortress-map @ftmap="onFtmap" ref="fortressMapRef"></fortress-map>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { pdshopotherInfo, pdshopinfoSave, pdshopinfoEdit } from "@/api/pdworld/pdshopinfo.js"
import { urlList } from "@/api/public.api.js"
import fortressMap from "@/components/fortress-map/fortress-map.vue"

export default {
	data() {
		return {
			load: false,
			visible: false,
			form: {
				memberId: this.$route.query.id,
				shopAvatar: "",
				enterpriceName: "",
				shopNo: "",
				shopInfo: "",
				corpn: "",
				address: "",
				tel: "",
				wesite: "",
				superviseTel: "",
				shopurl: "",
				qrcode: "",
				serviceWord: "",
				point: "",
			},
			formLabelWidth: "120px",
			rules: {
				shopAvatar: [
					{
						required: true,
						message: "请上传商家头像",
						trigger: "blur",
					},
				],
				enterpriceName: [
					{
						required: true,
						message: "请输入公司名称",
						trigger: "change",
					},
				],
				shopInfo: [
					{
						required: true,
						message: "请输入公司简介",
						trigger: "change",
					},
				],
				qrcode: [
					{
						required: true,
						message: "请上传商家二维码",
						trigger: "change",
					},
				],
				corpn: [
					{
						required: true,
						message: "请输入公司负责人",
						trigger: "change",
					},
				],
				address: [
					{
						required: true,
						message: "请输入公司地址",
						trigger: "change",
					},
				],
				wesite: [
					{
						required: true,
						message: "请输入公司网址",
						trigger: "change",
					},
				],
				tel: [
					{
						required: true,
						message: "请输入服务监督电话",
						trigger: "change",
					},
				],
			},
			memberIdList: [],
		}
	},
	created() {
		this.init(this.$route.query.id)
	},
	methods: {
		queryUrlList() {
			this.memberIdList = []
			urlList("/pdworld/pdMember/list").then(resp => {
				if (resp.code == 0) {
					if (resp.data) {
						resp.data.forEach(item => {
							this.memberIdList.push({ code: item.id, name: item.nickName })
						})
					}
				}
			})
		},
		init(id) {
			this.resetForm()
			this.queryUrlList()
			//修改
			if (id) {
				pdshopotherInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
							if (resp.data.point) {
								try {
									const point = JSON.parse(resp.data.point)
									if (typeof point === "object") {
										this.$refs.fortressMapRef.setAddress([point.lng, point.lat], point.address)
									} else {
										this.$refs.fortressMapRef.setAddress([121.45, 37.46], "")
									}
								} catch (error) {
									console.log(error)
								}
							}
						})
					}
				})
			} else {
				this.$refs.fortressMapRef.setAddress([121.45, 37.46], "")
			}
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					this.load = true
					//修改
					if (this.form.id) {
						pdshopinfoEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.load = false
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.init(this.$route.query.id)
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						pdshopinfoSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.load = false
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.init(this.$route.query.id)
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				memberId: this.$route.query.id,
				shopAvatar: "",
				enterpriceName: "",
				shopInfo: "",
				shopNo: "",
				corpn: "",
				address: "",
				tel: "",
				wesite: "",
				superviseTel: "",
				shopurl: "",
				qrcode: "",
				point: "",
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},

		onFtmap([lng, lat], address) {
			console.log(address)
			this.form.point = JSON.stringify({
				lng,
				lat,
				address,
			})
		},
	},
	components: {
		fortressMap,
	},
}
</script>
<style scoped lang="scss">
.tit {
	margin-bottom: 20px;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #eee;

	.btnList {
		display: flex;
		align-items: center;
	}
}
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
