import request from "@/common/coderequest"

//枚举列表展示
export const enumList = (name) => {
    return request("get", "/code/enums", { enumName: name });
}
//生成代码
export const codeGen = (data) => {
    return request('post', '/code/generateVue', data)
}
//菜单列表
export const menuList = () => {
    return request('get', '/code/menu/list')
}
//生成菜单
export const codeMenu = (data) => {
    return request('post', '/code/generateMenu', data)
}

