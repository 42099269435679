import request from "@/common/request"

//对外开放接口秘钥分页展示
export const apisecretPage = (queryForm) => {
    return request("get", "/system/apiSecret/page", queryForm);
}
//对外开放接口秘钥列表展示
export const apisecretList = (queryForm) => {
    return request("get", "/system/apiSecret/list", queryForm);
}
//对外开放接口秘钥详情
export const apisecretInfo = (id) => {
    return request('get', '/system/apiSecret/info', {
        id: id
    })
}
//对外开放接口秘钥信息保存
export const apisecretSave = (data) => {
    return request('post', '/system/apiSecret/save', data)
}
//对外开放接口秘钥信息修改
export const apisecretEdit = (data) => {
    return request('post', '/system/apiSecret/edit', data)
}
//对外开放接口秘钥信息删除
export const apisecretDel = (id) => {
    return request('post', '/system/apiSecret/delete?id=' + id)
}
//对外开放接口秘钥批量删除
export const apisecretDelBatch = (ids) => {
    return request('post', '/system/apiSecret/deleteBatch', ids)
}
//随机生成秘钥对
export const genSecret = () => {
    return request('get', '/system/apiSecret/genSecret')
}
