<template>
	<div>
		<el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
			<div class="info-item">
				<h5 class="from-title">基本信息</h5>
				<div class="form-item">
					<label class="form-item-label">编码:</label>
					<div class="form-item-content">{{ infoList.code || "无" }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">字典值:</label>
					<div class="form-item-content">{{ infoList.dictKey || "无" }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">字典名称:</label>
					<div class="form-item-content">{{ infoList.dictValue || "无" }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">排序:</label>
					<div class="form-item-content">{{ infoList.sort || "无" }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">状态:</label>
					<div class="form-item-content" v-if="infoList.status == 1">启用</div>
					<div class="form-item-content" v-else>禁用</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
import { sysDictInfo } from "@/api/system/sysdict.js"
export default {
	data() {
		return {
			drawer: true,
			direction: "rtl",
			infoList: {},
		}
	},
	created() {},
	methods: {
		init(id) {
			if (id) {
				sysDictInfo(id).then(res => {
					this.infoList = res.data
				})
			}
			this.drawer = true
		},
		// 关闭抽屉
		handleClose(done) {
			done()
		},
	},
}
</script>
<style scoped></style>
