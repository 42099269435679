import request from "@/common/coderequest"

//数据源分页展示
export const dbconfigPage = (queryParam) => {
    return request("get", "/dbconfig/page", queryParam);
}
//数据源列表展示
export const dbconfigList = () => {
    return request("get", "/dbconfig/list");
}
//数据源详情
export const dbconfigInfo = (id) => {
    return request('get', '/dbconfig/info', {
        id: id
    })
}
//保存数据源信息
export const dbconfigSave = (data) => {
    return request('post', '/dbconfig/save', data)
}
//修改数据源信息
export const dbconfigEdit = (data) => {
    return request('post', '/dbconfig/edit', data)
}
//删除数据源信息
export const dbconfigDel = (id) => {
    return request('post', '/dbconfig/delete?id=' + id)
}
//测试数据源连接状态
export const testDbConnection = (data) => {
    return request('post', '/dbconfig/testConnection', data)
}

