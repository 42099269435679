import request from "@/common/request"

//分站广告分页展示
export const subsiteadvertisingPage = (queryForm) => {
    return request("get", "/pdworld/subSiteAdvertising/page", queryForm);
}
//分站广告列表展示
export const subsiteadvertisingList = (queryForm) => {
    return request("get", "/pdworld/subSiteAdvertising/list", queryForm);
}
//分站广告详情
export const subsiteadvertisingInfo = (id) => {
    return request('get', '/pdworld/subSiteAdvertising/info', {
        id: id
    })
}
//分站广告信息保存
export const subsiteadvertisingSave = (data) => {
    return request('post', '/pdworld/subSiteAdvertising/save', data)
}
//分站广告信息修改
export const subsiteadvertisingEdit = (data) => {
    return request('post', '/pdworld/subSiteAdvertising/edit', data)
}
//分站广告信息删除
export const subsiteadvertisingDel = (id) => {
    return request('post', '/pdworld/subSiteAdvertising/delete?id=' + id)
}
//分站广告批量删除
export const subsiteadvertisingDelBatch = (ids) => {
    return request('post', '/pdworld/subSiteAdvertising/deleteBatch', ids)
}
//分站广告动态表头导出
export const subsiteadvertisingDynamicExport = (data) => {
    return request("eptpost", '/pdworld/subSiteAdvertising/exportDynamic', data)
}
