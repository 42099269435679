<!--  -->
<template>
  <div class="padding20">
    <!-- <h6>表格设计</h6> -->
    <p style="textAlign:right;padding-right:20px">
      <el-button icon="el-icon-back" type="text" @click="back">返回</el-button>
      <el-button icon="el-icon-document" type="text" @click="save">保存</el-button>
    </p>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="saveData"
        tooltip-effect="dark"
        style="width: 100%"
        border="border"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="选中状态" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="字段名">
          <template slot-scope="scope">{{ scope.row.model||scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="组件类型" prop="tag"></el-table-column>
        <el-table-column label="查询方式" show-overflow-tooltip>
          <template slot-scope="scope" prop="queryType">
            <el-select v-model="scope.row.queryType" placeholder="请选择">
              <el-option
                v-for="item in DbQueryConditionList"
                :key="item.code"
                :label="item.desc"
                :value="item.code"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column width="60"></el-table-column> -->
      </el-table>
    </div>
  </div>
</template>
<script>
import {
  formconfigInfo, gridManage
} from "@/api/form/formconfig.js"
import { enumList } from "@/api/public.api";

export default {
  data () {
    return {
      saveData: [],
      formConfigData: {},
      tableData: [],
      DbQueryConditionList: [],
      multipleSelection: [],

    }
  },
  created () {
    this.formConfigData.id = this.$route.query.id + '';
    if (this.formConfigData.id) {
      this.initInfo(this.formConfigData.id)
    }
    this.getEnumList()
  },
  methods: {
    initInfo (id) {
      let _that = this
      formconfigInfo(id).then((resp) => {
        if (resp.data.queryCondition) {
          _that.saveData = JSON.parse(resp.data.queryCondition)

        } else {
          _that.tableData = JSON.parse(resp.data.tbContent)
          _that.tableData.forEach(item => {
            _that.saveData.push({
              checked: null,
              model: item.__vModel__,
              title: item.__config__.label,
              tag: item.__config__.tag,
              queryType: ''

            })
          })
        }
      });
    },
    save () {
      let data = JSON.stringify(this.saveData)
      this.formConfigData.queryCondition = data
      this.$confirm('确定要提交修改？', '提示', { type: 'warning' }).then(
        () => {
          gridManage(this.formConfigData).then(res => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.$router.push({ path: "/form/formconfig" });
            } else {
              this.$msg({
                message: res.msg,
                type: "error",
              });
            }
          })
        })

    },
    //获取menuList
    getEnumList () {
      enumList(
        'DbQuery',
        'form'
      ).then(resp => {
        if (resp.code == 0) {
          this.DbQueryConditionList = resp.data;
        }
      })
    },
    back () {
      //返回
      this.$router.push({ path: "/form/formconfig" });
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    }
  }
}
</script>

<style lang='scss' scoped>
</style>