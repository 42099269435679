<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="任务名" :label-width="formLabelWidth" prop="taskName">
        <el-input v-model="form.taskName" autocomplete="off" placeholder="请输入任务名"></el-input>
      </el-form-item>
      <el-form-item label="任务ID" :label-width="formLabelWidth" prop="taskId">
        <el-input v-model="form.taskId" autocomplete="off" placeholder="请输入任务ID"></el-input>
      </el-form-item>
      <el-form-item label="调用参数" :label-width="formLabelWidth" prop="invokeParams">
        <el-input v-model="form.invokeParams" autocomplete="off" placeholder="请输入调用参数"></el-input>
      </el-form-item>
      <el-form-item label="调用状态" :label-width="formLabelWidth" prop="invokeStatus">
        <el-input v-model="form.invokeStatus" autocomplete="off" placeholder="请输入调用状态"></el-input>
      </el-form-item>
      <el-form-item label="调用时间" :label-width="formLabelWidth" prop="invokeTime">
        <el-input v-model="form.invokeTime" autocomplete="off" placeholder="请输入调用时间"></el-input>
      </el-form-item>
      <el-form-item label="耗时(毫秒)" :label-width="formLabelWidth" prop="elapsedTime">
        <el-input v-model="form.elapsedTime" autocomplete="off" placeholder="请输入耗时(毫秒)"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" :label-width="formLabelWidth" prop="createTime">
        <el-input v-model="form.createTime" autocomplete="off" placeholder="请输入创建时间"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { logjobInfo, logjobSave, logjobEdit } from "@/api/log/logjob.js";

export default {
  data () {
    return {
      visible: false,
      form: {
        id: "",
        taskName: "",
        taskId: "",
        invokeParams: "",
        invokeStatus: "",
        invokeTimeStart: "",
        invokeTimeEnd: "",
        elapsedTime: "",
        createTime: "",
      },
      formLabelWidth: "120px",
      rules: {
      },

    };
  },
  created () {
  },
  methods: {
    init (id) {
      this.resetForm();
      //修改
      if (id) {
        logjobInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.visible = false;
          //修改
          if (this.form.id) {
            logjobEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            logjobSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm () {
      this.form = {
        id: "",
        taskName: "",
        taskId: "",
        invokeParams: "",
        invokeStatus: "",
        invokeTime: "",
        elapsedTime: "",
        createTime: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>