<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="分类名称" :label-width="formLabelWidth" prop="name">
        <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入分类名称"
        ></el-input>
      </el-form-item>
<!--      <el-form-item label="排序字段" :label-width="formLabelWidth" prop="sort">-->
<!--        <el-input-->
<!--            v-model="form.sort"-->
<!--            autocomplete="off"-->
<!--            placeholder="请输入排序字段"-->
<!--        ></el-input>-->
<!--      </el-form-item>-->
      <!--      <el-form-item label="是否删除" :label-width="formLabelWidth" prop="isDeleted">-->
      <!--        <el-switch-->
      <!--            v-model="form.isDeleted"-->
      <!--            active-color="#13ce66"-->
      <!--            inactive-color="#ff4949"-->
      <!--            :active-value="1"-->
      <!--            :inactive-value="2"-->
      <!--        ></el-switch>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="状态" :label-width="formLabelWidth" prop="status">-->
      <!--        <el-switch-->
      <!--            v-model="form.status"-->
      <!--            active-color="#13ce66"-->
      <!--            inactive-color="#ff4949"-->
      <!--            :active-value="1"-->
      <!--            :inactive-value="2"-->
      <!--        ></el-switch>-->
      <!--      </el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  logisticsdynamicstypeInfo,
  logisticsdynamicstypeSave,
  logisticsdynamicstypeEdit
} from "@/api/pdworld/logisticsdynamicstype.js";
import {enumList,} from "@/api/public.api.js";
import * as ftVal from "@/common/fortressValidator.js";

export default {
  data() {
    return {
      visible: false,
      form: {
        name: "",
        sort: null,
        isDeleted: "2",
        status: "1",
      },
      formLabelWidth: "120px",
      rules: {
        // sort:[
        //       { validator: ftVal.validateInteger,trigger: "blur"},
        //   ],
        name: [
          {required: true, message: "请输入名称", trigger: "blur"},
        ],
      },
      YesNoList: [],
    };
  },
  created() {
  },
  methods: {
    queryEnumList() {
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    init(id) {
      this.resetForm();
      this.queryEnumList();
      //修改
      if (id) {
        logisticsdynamicstypeInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            logisticsdynamicstypeEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            logisticsdynamicstypeSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        name: "",
        sort: null,
        isDeleted: "2",
        status: "1",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
