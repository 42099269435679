<!--  -->
<template >
  <div class="inner" v-if="show">
    <div class="search">
      <el-input
        v-model="input"
        placeholder="请输入内容"
        style="margin-right:10px"
        prefix-icon="el-icon-search"
      ></el-input>
      <el-button @click="searchHandle" size="medium">查询</el-button>
      <el-button type="primary" @click="clearHandle" size="medium">重置</el-button>
    </div>
    <div class="box">
      <div class="category" v-for="(items,indexs) in allData" :key="indexs">
        <div @click.stop="handoff(indexs)" class="title">
          <div class="l">
            <i :class="current==indexs?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
            {{items.name}}
          </div>
          <div class="r">
            全选
            <el-checkbox
              :indeterminate="items.isIndeterminate"
              v-model="items.checkAll"
              @change="handleCheckAllChange($event,items)"
            ></el-checkbox>
          </div>
        </div>
        <my-item :data="items.children" v-if="current==indexs" :all="allData"></my-item>
      </div>
    </div>
  </div>
</template>
<script>
import myItem from "@/components/jurisdiction/item"
export default {
  name: 'my-jurisdiction',
  props: {
    data: {
      type: Array,
      required: true
    },
    checked: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  created () {
    this.show = true
    //重置
    this.allData = this.data
    this.checkedArray = this.checked
    if (this.allData.length > 0) {
      this.reconstitutionDataHandle(this.allData, this.reconstitutionData)
    }
    this.loop(this.allData)
  },
  beforeDestroy () {
    this.show = false
  },
  components: {
    myItem
  },
  data () {
    return {
      input: "",
      sonVisible: false,
      show: false,
      current: -1,
      checkAll: false,
      isIndeterminate: false,
      allData: [],
      checkedArray: [],
      reconstitutionData: [],
      firstItem: {}
    };
  },
  computed: {

  },

  methods: {
    clearHandle () {
      this.input = ''
      this.current = -1
    },
    searchHandle () {
      this.reconstitutionData.forEach((item, index) => {
        if (item.name == this.input) {
          this.findParentEle(item)
        }
      })
      this.reconstitutionData.forEach((item, index) => {
        if (this.firstItem.name == item.name) {
          this.current = index
        }
      })
    },
    findParentEle (aa) {
      if (aa.parentId) {
        console.log('22');
        this.reconstitutionData.forEach(item => {
          if (aa.parentId == item.id) {
            this.findParentEle(item)
          }
        })
      } else {
        this.firstItem = aa

      }

    },
    //遍历添加字段
    loop (aData) {
      aData.forEach(item => {
        if (item.type != 3) {
          this.$set(item, 'isIndeterminate', false)
          this.$set(item, 'checkAll', false)
          if (this.checkedArray.length > 0) {
            this.checkedArray.forEach(items => {
              if (item.id == items) {
                this.$set(item, 'isIndeterminate', false)
                this.$set(item, 'checkAll', true)
                this.getIsCheckAll(item, true)
              }
            })
          }
        } else {
          this.$set(item, 'checkAll', false)
          if (this.checkedArray.length > 0) {
            this.checkedArray.forEach(items => {
              if (item.id == items) {
                this.$set(item, 'checkAll', true)
                this.getIsCheckAll(item, true)
              }
            })
          }
        }
        if (item.children) {
          //子组件遍历
          this.loop(item.children)
        }


      })

    },
    getIsCheckAll (data, e) {
      this.reconstitutionData.forEach(item => {
        if (item.id == data.parentId) {
          var tickCount = 0, unTickCount = 0, ArrLength = item.children.length
          for (var j = 0; j < ArrLength; j++) {//全选checkbox状态
            if (item.children[j].checkAll == true && (item.children[j].isIndeterminate == false || item.children[j].isIndeterminate == null)) tickCount++
            if (item.children[j].checkAll == false && (item.children[j].isIndeterminate == false || item.children[j].isIndeterminate == null)) unTickCount++
          }
          if (tickCount == ArrLength) {//二级全勾选
            this.$set(item, 'checkAll', true)
            this.$set(item, 'isIndeterminate', false)
          } else if (unTickCount == ArrLength) {//二级全不勾选
            this.$set(item, 'checkAll', false)
            this.$set(item, 'isIndeterminate', false)
          } else {
            this.$set(item, 'checkAll', false)
            this.$set(item, 'isIndeterminate', true) //添加一级不确定状态
          }
          this.getIsCheckAll(item, e)
        }

      })

    },

    //切换显示
    handoff (i) {
      if (i == this.current) {
        this.current = -1
      } else {
        this.current = i
      }

    },

    selectLoop (data, status) {
      if (data.children) {
        data.children.forEach(item => {
          item.checkAll = status
          if (item.isIndeterminate != undefined) {
            this.$set(data, 'isIndeterminate', false)
          }
          this.selectLoop(item, status)
        })
      }

    },
    handleCheckAllChange (e, data) {
      this.$set(data, 'checkAll', e)
      if (e == true) {
        this.$set(data, 'isIndeterminate', false)
        this.selectLoop(data, e)
      } else {
        this.$set(data, 'isIndeterminate', false)
        this.selectLoop(data, e)
      }
    },
    //重构数据
    reconstitutionDataHandle (data, dd) {
      let children = [];
      for (var i in data) {
        dd.push(data[i]);
        if (data[i].children) {
          for (var j in data[i].children) {
            children.push(data[i].children[j]);
          }
        }
      }
      if (children.length > 0) {
        this.reconstitutionDataHandle(children, dd);
      }
    },
  }
}

</script>
<style lang='scss' scoped>
.search {
  display: flex;
  // align-items: center;
  // height: 40px;
  margin-bottom: 10px;
}
.box {
  max-height: 500px;
  overflow: auto;
  .category {
    .title {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 10px;
      color: #656465;
      background: #f5f7fa;
      border-radius: 2px;
    }
  }
}
</style>