import request from "@/common/request"

//全局招聘分页展示
export const pdrecruitPage = (queryForm) => {
    return request("get", "/pdworld/pdRecruit/page", queryForm);
}
//全局招聘列表展示
export const pdrecruitList = (queryForm) => {
    return request("get", "/pdworld/pdRecruit/list", queryForm);
}
//全局招聘详情
export const pdrecruitInfo = (id) => {
    return request('get', '/pdworld/pdRecruit/info', {
        id: id
    })
}
//全局招聘信息保存
export const pdrecruitSave = (data) => {
    return request('post', '/pdworld/pdRecruit/save', data)
}
//全局招聘信息修改
export const pdrecruitEdit = (data) => {
    return request('post', '/pdworld/pdRecruit/edit', data)
}
//全局招聘信息删除
export const pdrecruitDel = (id) => {
    return request('post', '/pdworld/pdRecruit/delete?id=' + id)
}
//全局招聘批量删除
export const pdrecruitDelBatch = (ids) => {
    return request('post', '/pdworld/pdRecruit/deleteBatch', ids)
}
//全局招聘动态表头导出
export const pdrecruitDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdRecruit/exportDynamic', data)
}
