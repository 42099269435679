import request from "@/common/request"

//快递网点表分页展示
export const pdexpressagebranchPage = (queryForm) => {
    return request("get", "/pdworld/pdExpressageBranch/page", queryForm);
}
//快递网点表列表展示
export const pdexpressagebranchList = (queryForm) => {
    return request("get", "/pdworld/pdExpressageBranch/list", queryForm);
}
//快递网点表详情
export const pdexpressagebranchInfo = (id) => {
    return request('get', '/pdworld/pdExpressageBranch/info', {
        id: id
    })
}
//快递网点表信息保存
export const pdexpressagebranchSave = (data) => {
    return request('post', '/pdworld/pdExpressageBranch/save', data)
}
//快递网点表信息修改
export const pdexpressagebranchEdit = (data) => {
    return request('post', '/pdworld/pdExpressageBranch/edit', data)
}
//快递网点表信息删除
export const pdexpressagebranchDel = (id) => {
    return request('post', '/pdworld/pdExpressageBranch/delete?id=' + id)
}
//快递网点表批量删除
export const pdexpressagebranchDelBatch = (ids) => {
    return request('post', '/pdworld/pdExpressageBranch/deleteBatch', ids)
}
//快递网点表动态表头导出
export const pdexpressagebranchDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdExpressageBranch/exportDynamic', data)
}
