import request from "@/common/request"

//获取当前用户可访问的用户
export const userPage = (page) => {
    return request("get", "/system/sysUser/page", page);
}
//用户详情
export const userInfo = (id) => {
    return request('get', '/system/sysUser/info', {
        id: id
    })
}
//保存用户信息
export const userSave = (data) => {
    return request('post', '/system/sysUser/save', data)
}
//修改用户信息
export const userEdit = (data) => {
    return request('post', '/system/sysUser/edit', data)
}
//删除用户信息
export const userDel = (id) => {
    return request('post', '/system/sysUser/delete?id=' + id)
}
