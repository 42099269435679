<template>
  <div class="scroll">
    <el-row style="margin-bottom: 17px">
      <el-button
        type="primary"
        v-if="$tools.hasPermission('system:sysDept:save')"
        @click="handleEdit()"
        size="medium"
        icon="el-icon-plus"
        >添加</el-button
      >
    </el-row>
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="name"
          label="名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('system:sysDept:info')"
              size="mini"
              @click="handleInfo(scope.row.id)"
              plain
              >详情</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:sysDept:edit')"
              size="mini"
              @click="handleEdit(scope.row.id)"
              plain
              >编辑</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:sysDept:delete')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      :allDept="tableData"
      @refreshData="queryList"
    ></form-edit>
    <form-info
      v-if="formInfoVisible"
      ref="formInfo"
      :allDept="tableData"
    ></form-info>
  </div>
</template>
<script>
import { deptList, deptDel } from "@/api/auth/sysdept.js";
import FormEdit from "./sysdept-edit";
import FormInfo from "./sysdept-info";
export default {
  data() {
    return {
      parentShow: "",
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
    };
  },
  components: {
    "form-edit": FormEdit,
    FormInfo,
  },
  created() {
    this.tableData = [];
    this.queryList();
  },
  methods: {
    //查询表格数据
    queryList() {
      this.parentShow = true;
      deptList().then((resp) => {
        if (resp.code == 0) {
          this.parentShow = false;
          this.tableData = resp.data;
        } else {
          this.parentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },

    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deptDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$notify({
              title: "删除成功",
              type: "warning",
              message: "部门已成功被删除!",
            });
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    //查看详情
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
  },
};
</script>
<style scoped>
.scroll {
  overflow: auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}
</style>
