<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <!-- <el-form-item label="所属商家ID" :label-width="formLabelWidth" prop="memberId">
                <fortress-select
                        v-model="form.memberId"
                        :dictList="memberIdList"
                        placeholder="请选择所属商家ID"
                        clearable
                ></fortress-select>
            </el-form-item> -->
      <el-form-item
        label="网点名"
        :label-width="formLabelWidth"
        prop="storeName"
      >
        <el-input
          v-model="form.storeName"
          autocomplete="off"
          placeholder="请输入网点名"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="网点地址"
        :label-width="formLabelWidth"
        prop="address"
      >
        <el-input
          v-model="form.address"
          autocomplete="off"
          placeholder="请输入网点地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="网点电话" :label-width="formLabelWidth" prop="tel">
        <el-input
          v-model="form.tel"
          autocomplete="off"
          placeholder="请输入网点电话"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="联系人"
        :label-width="formLabelWidth"
        prop="contacts"
      >
        <el-input
          v-model="form.contacts"
          autocomplete="off"
          placeholder="请输入联系人"
        ></el-input>
      </el-form-item>
<!--      <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">-->
<!--        <el-input-number step-strictly :min="0" v-model="form.sort" autocomplete="off"></el-input-number>-->
<!--      </el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger"
        >取 消</el-button
      >
      <el-button type="primary" size="medium" @click="submitForm('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  pdstoreInfo,
  pdstoreSave,
  pdstoreEdit,
} from "@/api/pdworld/pdstore.js";
import { urlList } from "@/api/public.api.js";

export default {
  data() {
    return {
      visible: false,
      form: {
        memberId: this.memberId,
        storeName: "",
        address: "",
        tel: "",
        contacts: "",
        sort: "",
      },
      formLabelWidth: "120px",
      rules: {},
      memberIdList: [],
      memberId: "",
    };
  },
  created() {},
  methods: {
    queryUrlList() {
      this.memberIdList = [];
      urlList("/pdworld/pdMember/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.memberIdList.push({ code: item.id, name: item.nickName });
            });
          }
        }
      });
    },
    init(memberId, id) {
      this.memberId = memberId;
      this.resetForm();
      this.queryUrlList();
      //修改
      if (id) {
        pdstoreInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            pdstoreEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            pdstoreSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        memberId: this.memberId,
        storeName: "",
        address: "",
        tel: "",
        contacts: "",
        sort: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
