<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="邮箱SMTP地址" :label-width="formLabelWidth" prop="smtp">
                <el-input
                        v-model="form.smtp"
                        autocomplete="off"
                        placeholder="请输入邮箱SMTP地址"
                ></el-input>
            </el-form-item>
            <el-form-item label="端口号" :label-width="formLabelWidth" prop="port">
                <el-input
                        v-model="form.port"
                        autocomplete="off"
                        placeholder="请输入端口号"
                ></el-input>
            </el-form-item>
            <el-form-item label="邮箱账号" :label-width="formLabelWidth" prop="mail">
                <el-input
                        v-model="form.mail"
                        autocomplete="off"
                        placeholder="请输入邮箱账号"
                ></el-input>
            </el-form-item>
            <el-form-item label="邮箱密码" :label-width="formLabelWidth" prop="password">
                <el-input
                        v-model="form.password"
                        autocomplete="off"
                        placeholder="请输入邮箱密码"
                ></el-input>
            </el-form-item>
            <el-form-item label="邮箱昵称" :label-width="formLabelWidth" prop="nickName">
                <el-input
                        v-model="form.nickName"
                        autocomplete="off"
                        placeholder="请输入邮箱昵称"
                ></el-input>
            </el-form-item>
            <el-form-item label="是否启用" :label-width="formLabelWidth" prop="status">
                <fortress-radio-group
                        v-model="form.status"
                        :dictList="YesNoList"
                >
                </fortress-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { castleconfigmailInfo, castleconfigmailSave, castleconfigmailEdit } from "@/api/message/mail/castleconfigmail.js";
    import { enumList, } from "@/api/public.api.js";

    export default {
        data() {
            return {
                visible: false,
                form: {
                    smtp:"",
                    port:"",
                    mail:"",
                    password:"",
                    nickName:"",
                    status:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
                YesNoList:[],
            };
        },
        created() {
        },
        methods: {
            queryEnumList(){
                this.YesNoList = [];
                enumList("YesNo","").then((resp) => {
                  if (resp.code == 0) {
                    this.YesNoList = resp.data;
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryEnumList();
                //修改
                if (id) {
                    castleconfigmailInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        castleconfigmailEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        castleconfigmailSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    smtp:"",
                    port:"",
                    mail:"",
                    password:"",
                    nickName:"",
                    status:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
