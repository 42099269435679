import request from "@/common/request"

//商品分类表分页展示
export const goodscategoryPage = (queryForm) => {
    return request("get", "/goods/goodsCategory/page", queryForm);
}
//商品分类表列表展示
export const goodscategoryList = (queryForm) => {
    return request("get", "/goods/goodsCategory/list", queryForm);
}
//商品分类表详情
export const goodscategoryInfo = (id) => {
    return request('get', '/goods/goodsCategory/info', {
        id: id
    })
}
//商品分类表信息保存
export const goodscategorySave = (data) => {
    return request('post', '/goods/goodsCategory/save', data)
}
//商品分类表信息修改
export const goodscategoryEdit = (data) => {
    return request('post', '/goods/goodsCategory/edit', data)
}
//商品分类表信息删除
export const goodscategoryDel = (id) => {
    return request('post', '/goods/goodsCategory/delete?id=' + id)
}
//商品分类表批量删除
export const goodscategoryDelBatch = (ids) => {
    return request('post', '/goods/goodsCategory/deleteBatch', ids)
}
//商品分类表动态表头导出
export const goodscategoryDynamicExport = (data) => {
    return request("eptpost", '/goods/goodsCategory/exportDynamic', data)
}
//商品分类 树列表
export const treeList = () => {
    return request("get", '/goods/goodsCategory/treeList')
}
