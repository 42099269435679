<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="接收人ID" :label-width="formLabelWidth" prop="receiveId">
                <fortress-select
                        v-model="form.receiveId"
                        :dictList="receiveIdList"
                        placeholder="请选择接收人ID"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="是否已读" :label-width="formLabelWidth" prop="status">
                <el-switch
                      v-model="form.status"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="2"
                ></el-switch>
            </el-form-item>
            <el-form-item label="是否删除" :label-width="formLabelWidth" prop="isDeleted">
                <el-switch
                      v-model="form.isDeleted"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="2"
                ></el-switch>
            </el-form-item>
            <el-form-item label="创建人" :label-width="formLabelWidth" prop="createUser">
                <el-input
                        v-model="form.createUser"
                        autocomplete="off"
                        placeholder="请输入创建人"
                ></el-input>
            </el-form-item>
            <el-form-item label="消息" :label-width="formLabelWidth" prop="noticeId">
                <fortress-select
                        v-model="form.noticeId"
                        :dictList="noticeIdList"
                        placeholder="请选择消息"
                        clearable
                ></fortress-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { noticerecordInfo, noticerecordSave, noticerecordEdit } from "@/api/pdworld/noticerecord.js";
    import { enumList,urlList, } from "@/api/public.api.js";
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    receiveId:"",
                    status:"2",
                    isDeleted:"2",
                    createUser:"",
                    noticeId:"",
                },
                formLabelWidth: "120px",
                rules: {
                    receiveId:[
                          { required: true, message: "请输入接收人ID", trigger: "blur" },
                      ],
                    status:[
                          { required: true, message: "请输入是否已读", trigger: "blur" },
                      ],
                    isDeleted:[
                          { required: true, message: "请输入是否删除", trigger: "blur" },
                      ],
                    noticeId:[
                          { required: true, message: "请输入消息", trigger: "blur" },
                      ],
                },
                YesNoList:[],
                receiveIdList:[],
                noticeIdList:[],
            };
        },
        created() {
        },
        methods: {
            queryEnumList(){
                this.YesNoList = [];
                enumList("YesNo","").then((resp) => {
                  if (resp.code == 0) {
                    this.YesNoList = resp.data;
                  }
                });
            },
            queryUrlList(){
                this.receiveIdList = [];
                urlList("/pdworld/pdMember/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.receiveIdList.push({ code: item.id, name: item.userName });
                      });
                    }
                  }
                });
                this.noticeIdList = [];
                urlList("/pdworld/notice/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.noticeIdList.push({ code: item.id, name: item.id });
                      });
                    }
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryEnumList();
                this.queryUrlList();
                //修改
                if (id) {
                    noticerecordInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        noticerecordEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        noticerecordSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    receiveId:"",
                    status:"2",
                    isDeleted:"2",
                    createUser:"",
                    noticeId:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
