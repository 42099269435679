<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false" append-to-body modal-append-to-body>
		<el-form ref="form" :model="form" :rules="rules">
			<!-- <el-form-item label="对应分站ID" :label-width="formLabelWidth" prop="siteId">
        <el-input
            v-model="form.siteId"
            autocomplete="off"
            placeholder="请输入对应分站ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="分站所属市编码subsite" :label-width="formLabelWidth" prop="siteCityCode">
        <el-input
            v-model="form.siteCityCode"
            autocomplete="off"
            placeholder="请输入分站所属市编码subsite"
        ></el-input>
      </el-form-item> -->
			<el-form-item label="图片" :label-width="formLabelWidth" prop="imgUrl">
				<fortress-upload type="image" name="imgUrl" :formData="form"></fortress-upload>
			</el-form-item>
			<el-form-item label="跳转链接" :label-width="formLabelWidth" prop="href">
				<el-input v-model="form.href" autocomplete="off" placeholder="请输入跳转链接"></el-input>
			</el-form-item>
			<el-form-item label="是否显示" :label-width="formLabelWidth" prop="isShow">
				<fortress-radio-group v-model="form.isShow" :dictList="YesNoList"></fortress-radio-group>
			</el-form-item>
			<el-form-item label="顺序" :label-width="formLabelWidth" prop="sort">
				<el-input-number v-model="form.sort"></el-input-number>
			</el-form-item>
			<el-form-item label="广告位置" :label-width="formLabelWidth" prop="position">
				<fortress-select
					v-model="form.position"
					:dictList="AdvPositionList"
					placeholder="请选择广告位置"
					clearable
				></fortress-select>
			</el-form-item>
			<el-form-item label="标题" :label-width="formLabelWidth" prop="title">
				<el-input v-model="form.title" autocomplete="off" placeholder="请输入标题"></el-input>
			</el-form-item>
			<el-form-item label="备注" :label-width="formLabelWidth" prop="remake">
				<el-input v-model="form.remake" autocomplete="off" placeholder="请输入备注"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
			<el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { subsiteadvertisingInfo, subsiteadvertisingSave, subsiteadvertisingEdit } from "@/api/pdworld/subsiteadvertising.js"
import { enumList } from "@/api/public.api.js"

export default {
	props: {
		row: {},
	},
	data() {
		return {
			visible: false,
			form: {
				siteId: "",
				siteCityCode: "",
				imgUrl: "",
				href: "",
				isShow: "",
				sort: "",
				position: "",
				title: "",
				remake: "",
			},
			formLabelWidth: "120px",
			rules: {},
			YesNoList: [],
			AdvPositionList: [],
		}
	},
	created() {},
	methods: {
		queryEnumList() {
			this.YesNoList = []
			enumList("YesNo", "").then(resp => {
				if (resp.code == 0) {
					this.YesNoList = resp.data
				}
			})
			this.AdvPositionList = []
			enumList("AdvPosition", "").then(resp => {
				if (resp.code == 0) {
					this.AdvPositionList = resp.data
				}
			})
		},
		init(id) {
			this.resetForm()
			this.queryEnumList()
			//修改
			if (id) {
				subsiteadvertisingInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
			}
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			this.form.siteId = this.row.id
			this.form.siteCityCode = this.row.siteCityCode

			if (!this.form.imgUrl) {
				this.$message({
					type: "error",
					message: "请上传图片!",
				})
				return
			}
			if (!this.form.position) {
				this.$message({
					type: "error",
					message: "请选择广告位置!",
				})
				return
			}

			this.$refs["form"].validate(valid => {
				if (valid) {
					//修改
					if (this.form.id) {
						subsiteadvertisingEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						subsiteadvertisingSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				siteId: "",
				siteCityCode: "",
				imgUrl: "",
				href: "",
				isShow: "",
				sort: "",
				position: "",
				title: "",
				remake: "",
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
}
</script>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
