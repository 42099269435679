import request from "@/common/request"

//商家的企业实景。分页展示
export const pdmemberimgPage = (queryForm) => {
    return request("get", "/pdworld/pdMemberImg/page", queryForm);
}
//商家的企业实景。列表展示
export const pdmemberimgList = (queryForm) => {
    return request("get", "/pdworld/pdMemberImg/list", queryForm);
}
//商家的企业实景。详情
export const pdmemberimgInfo = (id) => {
    return request('get', '/pdworld/pdMemberImg/info', {
        id: id
    })
}
//商家的企业实景。信息保存
export const pdmemberimgSave = (data) => {
    return request('post', '/pdworld/pdMemberImg/save', data)
}
//商家的企业实景。信息修改
export const pdmemberimgEdit = (data) => {
    return request('post', '/pdworld/pdMemberImg/edit', data)
}
//商家的企业实景。信息删除
export const pdmemberimgDel = (id) => {
    return request('post', '/pdworld/pdMemberImg/delete?id=' + id)
}
//商家的企业实景。批量删除
export const pdmemberimgDelBatch = (ids) => {
    return request('post', '/pdworld/pdMemberImg/deleteBatch', ids)
}
//商家的企业实景。动态表头导出
export const pdmemberimgDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdMemberImg/exportDynamic', data)
}
