<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    custom-class="dialog-edit"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickname">
        <el-input v-model="form.nickname" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
        <el-input v-model="form.email" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="性别" :label-width="formLabelWidth" prop="gender">
        <el-radio-group v-model="form.gender">
          <el-radio label="f">女</el-radio>
          <el-radio label="m">男</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="出生日期" :label-width="formLabelWidth" prop="birthday">
        <el-date-picker v-model="form.birthday" type="date" placeholder="选择日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="头像" :label-width="formLabelWidth" prop="avatar">
       <div style=" display: flex;justify-content: flex-start;">
          <fortress-upload type="image" name="avatar" :formData="form"></fortress-upload>
       </div>
        <!-- <el-upload class="avatar-uploader" :action="fileUploadUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="upfile">
					<img v-if="form.avatar" :src="fileUrl" class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>-->
      </el-form-item>
      <el-form-item label="证件类型" :label-width="formLabelWidth" prop="idcardType">
        <el-select v-model="form.idcardType" placeholder="请选择">
          <el-option
            v-for="item in idcardTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号码" :label-width="formLabelWidth" prop="idcard">
        <el-input v-model="form.idcard" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')" size="medium">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as ftVal from "@/common/fortressValidator.js";
import { userInfo, userSave, userEdit } from "@/api/auth/sysuser.js";
import { deptList } from "@/api/auth/sysdept.js";
import { postList } from "@/api/auth/syspost.js";
import { roleList } from "@/api/auth/sysrole.js";
import { dictList } from "@/api/public.api.js";
import md5 from "crypto-js/md5";
export default {
  data () {
    return {
      ftVal:ftVal,
      fileUploadUrl: window.$conf.commonConf.baseUrl + "/system/oss/upload",
      fileUrl: "",
      visible: false,
      form: {
        id: "",
        nickname: "",
        email: "",
        gender: "",
        birthday: "",
        avatar: "",
        idcardType: "",
        idcard: "",
      },
      idcardTypeList: [],
      formLabelWidth: "80px",
      rules: {
        nickname: [
          { required: true, message: "请输入昵称", trigger: "blur" }
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }, { validator: ftVal.validateEMail, trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        birthday: [{ required: true, message: "请选择生日", trigger: "blur" }],
        // avatar: [{ required: true, message: "请上传头像", trigger: "blur" }],
        idcardType: [{ required: true, message: "请选择证件类型", trigger: "blur" }],
        idcard: [{ required: true, message: "请填写证件号码", trigger: "blur" }, { validator: ftVal.validateIdNo, trigger: "blur" }],
      },
      deptList: [],
      postList: [],
      roleList: [],
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      selDept: {},
      selPost: {},
      roleNames: [],
      isAdmin: false,
      currentRoles: [],
    };
  },
  computed: {},

  mounted () {
    // console.log(window.$conf.commonConf.baseUrl)
    // console.log("isAdmin", this.isAdmin)
  },
  updated () { },
  methods: {
    // 部门改变

    // 角色改变时触发
    roleChange ($event) {
      this.currentRoles = [];
      let currentSelect = $event;
      currentSelect.forEach((item) => {
        this.roleList.forEach((item1) => {
          if (item1.id === item) {
            this.currentRoles.push(item1);
          }
        });
      });
      this.isAdmin = this.currentRoles.some((item) => {
        return item.isAdmin == 1;
      });
      this.rules.postId[0].required = !this.isAdmin;
      this.rules.deptId[0].required = !this.isAdmin;
      this.form.deptId = "";
      this.form.postId = "";
    },
    //查询部门列表数据
    queryDeptList () {
      this.deptList = [];
      deptList().then((resp) => {
        if (resp.code == 0) {
          this.deptList = resp.data;
        }
      });
    },
    //查询职位列表
    queryPostList (deptId) {
      this.postList = [];
      postList({ deptId: deptId }).then((resp) => {
        if (resp.code == 0) {
          this.postList = resp.data;
        }
      });
    },
    //查询角色列表
    queryRoleList () {
      this.roleList = [];
      roleList().then((resp) => {
        if (resp.code == 0) {
          this.roleList = resp.data;
        }
      });
    },
    //字典
    queryDictList () {
      this.idcardTypeList = [];
      dictList("idcardType").then((resp) => {
        if (resp.code == 0) {
          this.idcardTypeList = resp.data;
        }
      });
    },
    init (id) {
      this.resetForm();
      this.queryDeptList();
      this.queryRoleList();
      this.queryDictList();
      //修改
      if (id) {
        userInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              let admin = resp.data.roles.some((item) => {
                return item.isAdmin === 1;
              });
              if (admin) {
                this.rules.postId[0].required = !admin;
                this.rules.deptId[0].required = !admin;
              }
              if (resp.data.postId == "-1" && resp.data.deptId != "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                  postId: "",
                  createPostName: "",
                };
              }
              if (resp.data.postId != "-1" && resp.data.deptId == "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                  deptId: "",
                  createDeptName: "",
                };
              }
              if (resp.data.postId == "-1" && resp.data.deptId == "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                  postId: "",
                  createPostName: "",
                  deptId: "",
                  createDeptName: "",
                };
              }
              if (resp.data.deptId != "-1" && resp.data.postId != "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                };
              }

              if (this.form.avatar) {
                this.fileUrl = this.$store.state.prefixUrl + this.form.avatar;
              }
              this.selDept = {
                id: resp.data.deptId,
                label: resp.data.createDeptName,
              };
              this.selPost = {
                id: resp.data.postId,
                label: resp.data.createPostName,
              };
              if (resp.data.roles) {
                resp.data.roles.forEach((item) => {
                  this.form.roleIds.push(item.id);
                });
                this.isAdmin = this.form.roles.some((item) => {
                  return item.isAdmin == 1;
                });
              }
            });
            if (resp.data.deptId) {
              this.queryPostList(resp.data.deptId);
            }
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //用户角色
          let selRoles = this.form.roleIds;
          this.form.roles = [];
          selRoles.forEach((item) => {
            this.form.roles.push({ id: item });
          });
          //修改
          if (this.form.id) {
            //校验密码是否修改
            if (this.form.passwordUpdate) {
              this.form.password = md5(this.form.passwordUpdate).toString();
            } else {
              this.form.password = "";
            }
            userEdit({
              ...this.form,
              deptId: this.form.deptId || -1,
              postId: this.form.postId || -1,
            }).then((resp) => {
              if (resp.code == 0) {
                this.visible = false;
                this.$notify({
                  title: "修改成功",
                  type: "success",
                  message: "修改扩展信息成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            this.form.password = md5(this.form.password).toString();
            userSave({
              ...this.form,
              deptId: this.form.deptId || -1,
              postId: this.form.postId || -1,
            }).then((resp) => {
              if (resp.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm () {
      this.form = {
        id: "",
        loginName: "",
        nickname: "",
        realName: "",
        password: "",
        email: "",
        phone: "",
        gender: "",
        birthday: "",
        avatar: "",
        idcardType: "",
        idcard: "",
        deptId: "",
        postId: "",
        remark: "",
        status: 1,
        roleIds: [],
        roles: [],
        passwordUpdate: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
    //上传成功
    handleAvatarSuccess (res, file) {
      if (res && res.code == 0) {
        this.form.avatar = res.data.path;
        this.fileUrl = res.data.url;
      }
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //部门选择
    handleNodeClickDept (data) {
      this.selDept.id = data.id;
      this.selDept.label = data.name;
      this.form.deptId = data.id;
      this.selPost.id = "";
      this.selPost.label = "";
      this.form.postId = "";
      this.queryPostList(this.form.deptId);
    },
    //职位选择
    handleNodeClickPost (data) {
      this.selPost.id = data.id;
      this.selPost.label = data.name;
      this.form.postId = data.id;
    },
  },
};
</script>
<style scoped></style>
