import request from "@/common/request"

//车源图片分页展示
export const pdcarimgPage = (queryForm) => {
    return request("get", "/pdworld/pdCarImg/page", queryForm);
}
//车源图片列表展示
export const pdcarimgList = (queryForm) => {
    return request("get", "/pdworld/pdCarImg/list", queryForm);
}
//车源图片详情
export const pdcarimgInfo = (id) => {
    return request('get', '/pdworld/pdCarImg/info', {
        id: id
    })
}
//车源图片信息保存
export const pdcarimgSave = (data) => {
    return request('post', '/pdworld/pdCarImg/save', data)
}
//车源图片信息修改
export const pdcarimgEdit = (data) => {
    return request('post', '/pdworld/pdCarImg/edit', data)
}
//车源图片信息删除
export const pdcarimgDel = (id) => {
    return request('post', '/pdworld/pdCarImg/delete?id=' + id)
}
//车源图片批量删除
export const pdcarimgDelBatch = (ids) => {
    return request('post', '/pdworld/pdCarImg/deleteBatch', ids)
}
//车源图片动态表头导出
export const pdcarimgDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdCarImg/exportDynamic', data)
}
