<template>
  <div class="padding20">
    <el-row style="margin-bottom: 17px">
      <el-form :inline="true" :model="queryForm">
        <el-form-item>
          <fortress-select
            v-model="queryForm.platform"
            :dictList="ossTypeList"
            autocomplete="off"
            placeholder="请输入平台类型"
            clearable
          ></fortress-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="queryForm.ftCode"
            autocomplete="off"
            placeholder="请输入存储编码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.status"
            placeholder="请选择状态"
            clearable
          >
            <el-option label="启用" value="1"></el-option>
            <el-option label="未启用" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-search"
            @click="queryList({ current: 1 })"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-refresh-left"
            @click="resetQueryForm"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        v-if="$tools.hasPermission('system:configOss:save')"
        @click="handleEdit()"
        icon="el-icon-plus"
        >添加</el-button
      >
    </el-row>
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="platform"
          label="类型"
          :formatter="platformFmt"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="ftCode"
          label="存储编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag type="success" size="mini" v-if="scope.row.status == 1"
              >已启用</el-tag
            >
            <el-tag type="danger" size="mini" v-else>未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="180">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('system:configOss:info')"
              size="mini"
              @click="handleInfo(scope.row.id)"
              >详情</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:configOss:edit')"
              size="mini"
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:configOss:delete')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      :platformList="ossTypeList"
      @refreshData="queryList"
    ></form-edit>
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
  </div>
</template>
s
<script>
import { configOssPage, configOssDel } from "@/api/system/configoss.js";
import { enumList } from "@/api/public.api.js";
import FormEdit from "./configoss-edit";
import FormInfo from "./configoss-info";
import FortressPagination from "@/components/fortress-pagination.vue";
export default {
  data() {
    return {
      parentShow: "",
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      ossTypeList: [],
      queryForm: {
        platform: "",
        ftCode: "",
        status: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  components: {
    "form-edit": FormEdit,
    pagination: FortressPagination,
    FormInfo,
  },
  created() {
    this.queryOssTypeList();
    this.tableData = [];
    this.queryList();
  },
  methods: {
    //查询文件存储配置类型
    queryOssTypeList() {
      enumList("Oss").then((resp) => {
        if (resp.code == 0) {
          this.ossTypeList = resp.data;
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //查询文件存储列表
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      configOssPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false;
            this.tableData = resp.data.records;
            this.page.total = resp.data.total;
            //查询页大于总页数，重新查询
            let cu =
              this.page.total % this.page.size == 0
                ? parseInt(this.page.total / this.page.size)
                : parseInt(this.page.total / this.page.size + 1);
            if (cu > 0 && this.page.current > cu) {
              this.queryList({ current: cu });
            }
          }, 600);
        } else {
          this.parentShow = false;

          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    // 存储平台类型格式化
    platformFmt(row) {
      let typeName = "";
      this.ossTypeList.forEach((item) => {
        if (row.platform == item.code) {
          typeName = item.name;
        }
      });
      return typeName;
    },
    //状态格式化返回
    statusFmt(row) {
      let typeName = "未启用";
      if (row.status == 1) {
        typeName = "已启用";
      }
      return typeName;
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },

    //删除
    handleDelete(row) {
      configOssDel(row.id).then((resp) => {
        if (resp.code == 0) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          //判断是否最后一页的最后一条记录
          let totalPages =
            (this.page.total - 1) % this.page.size == 0
              ? (this.page.total - 1) / this.page.size
              : (this.page.total - 1) / this.page.size + 1;
          if (this.page.current > totalPages && this.page.current != 1) {
            this.page.current = this.page.current - 1;
          }
          this.queryList();
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //查看详情
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        platform: "",
        ftCode: "",
        status: "",
      };
    },
  },
};
</script>
<style scoped></style>
