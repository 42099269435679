<template>
	<div class="test padding20">
		<el-form label-width="80px">
			<!-- <el-form-item label="支付类型">
				<el-input v-model="formData.payType" placeholder="请输入测试订单号" clearable></el-input>
			</el-form-item> -->
			<el-form-item label="订单号">
				<el-input v-model="formData.orderId" placeholder="请输入测试订单号" clearable></el-input>
			</el-form-item>
			<el-form-item label="订单名称">
				<el-input v-model="formData.orderName" placeholder="请输入订单名称" clearable></el-input>
			</el-form-item>
			<el-form-item label="订单金额">
				<el-input type="number" v-model="formData.orderAmount" placeholder="请输入测试订单金额(元)" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item label="openid">
				<el-input v-model="formData.openId" placeholder="请输入测试订单号" clearable></el-input>
			</el-form-item> -->
			<el-form-item>
				<el-button type="primary" @click="submit">提交</el-button>
			</el-form-item>
		</el-form>
		<el-alert show-icon v-if="result.msg" :title="result.msg" :type="result.code == 0 ? 'success' : 'error'"></el-alert>
		<div id="qrcode"></div>
	</div>
</template>

<script>
import request from "@/common/request"
import QRCode from "qrcodejs2"

export default {
	name: "test",
	data() {
		return {
			formData: {
				orderId: "",
				orderAmount: "",
				// payType: "",
				orderName: "",
				// openId: "",
			},
			result: {
				code: 0,
				msg: "",
				data: {},
			},
		}
	},
	computed: {
		isWechat() {
			return this.$route.query.payType === "WXPAY_NATIVE"
		},
	},
	methods: {
		async submit() {
			try {
				this.result = await request("post", "/topay/uniorder", {
					payType: this.$route.query.payType,
					sceneCode: this.$route.query.sceneCode,
					...this.formData,
				})
				if (this.result.code == 0) {
					if (this.isWechat) {
						new QRCode(document.querySelector("#qrcode"), {
							text: this.result.data.codeUrl,
							width: 200,
							height: 200,
							correctLevel: QRCode.CorrectLevel.Q,
						})
					} else {
						document.querySelector("#qrcode").style.display = "none"
						document.querySelector("#qrcode").innerHTML = this.result.data.body
						this.$nextTick(() => {
							document.querySelector("#qrcode form").submit()
						})
					}
				}
			} catch (error) {
				this.result.code = 1
				this.result.msg = error
			}
		},
	},
	components: {},
}
</script>

<style lang="scss" scoped>
.test {
	#qrcode {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
