<template>
  <div>
    <el-drawer
      title=""
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">会员，预约人:</label>
          <div class="form-item-content">{{ objInfo.memberIdName }}</div>
        </div>
        <!-- <div class="form-item">
                    <label class="form-item-label">商家ID:</label>
                    <div class="form-item-content">{{ objInfo.shopIdName }}</div>
                </div> -->
        <div class="form-item">
          <label class="form-item-label">线路ID:</label>
          <div class="form-item-content">{{ objInfo.lineIdName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">订单号:</label>
          <div class="form-item-content">{{ objInfo.orderNo }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">评分:</label>
          <div class="form-item-content">{{ objInfo.score }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">预约时间:</label>
          <div class="form-item-content">{{ objInfo.scheduledDate }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">评分时间:</label>
          <div class="form-item-content">{{ objInfo.scoreDate }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">预约人手机号:</label>
          <div class="form-item-content">{{ objInfo.scheduledPhone }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">货物名称:</label>
          <div class="form-item-content">{{ objInfo.goodsName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">货物重量:</label>
          <div class="form-item-content">{{ objInfo.goodsWeight }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">货物件数:</label>
          <div class="form-item-content">{{ objInfo.goodsNember }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">货物体积:</label>
          <div class="form-item-content">{{ objInfo.goodsVolume }}</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { pdorderInfo } from "@/api/pdworld/pdorder.js";
import { urlList } from "@/api/public.api.js";
export default {
  data() {
    return {
      drawer: true,
      direction: "rtl",
      objInfo: {},
      memberIdList: [],
      shopIdList: [],
      lineIdList: [],
    };
  },
  created() {},
  methods: {
    queryUrlList() {
      this.memberIdList = [];
      urlList("/pdworld/pdMember/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.memberIdList.push({ code: item.id, name: item.nickName });
            });
          }
        }
      });
      this.shopIdList = [];
      urlList("/pdworld/pdMember/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.shopIdList.push({ code: item.id, name: item.nickName });
            });
          }
        }
      });
      this.lineIdList = [];
      urlList("/pdworld/pdRoute/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.lineIdList.push({ code: item.id, name: item.startPlace });
            });
          }
        }
      });
    },
    init(id) {
      this.queryUrlList();
      if (id) {
        pdorderInfo(id).then((res) => {
          this.objInfo = res.data;
          let memberIdObj = this.memberIdList.find((item) => {
            return item.code == this.objInfo.memberId;
          });
          this.objInfo.memberIdName = memberIdObj ? memberIdObj.name : "";
          let shopIdObj = this.shopIdList.find((item) => {
            return item.code == this.objInfo.shopId;
          });
          this.objInfo.shopIdName = shopIdObj ? shopIdObj.name : "";
          let lineIdObj = this.lineIdList.find((item) => {
            return item.code == this.objInfo.lineId;
          });
          this.objInfo.lineIdName = lineIdObj ? lineIdObj.name : "";
        });
      }
      this.drawer = true;
    },
    // 关闭抽屉
    handleClose(done) {
      done();
    },
  },
};
</script>
<style scoped>
.from-title {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  color: #17233d;
  border-bottom: 1px solid #eee;
}
.info-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 20px;
  font-size: 14px;
}
.form-item-label {
  text-align: right;
  float: left;
  width: 25%;
}
.form-item-content {
  margin-left: 30%;
  width: 70%;
}
</style>
