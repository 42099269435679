<template>
  <div>
    <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose"
               custom-class="drawer-info">
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">分类:</label>
          <div class="form-item-content">{{ objInfo.typeIdName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">联系人名称:</label>
          <div class="form-item-content">{{ objInfo.contactName }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">联系人电话:</label>
          <div class="form-item-content">{{ objInfo.contactPhone }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">内容:</label>
          <div class="form-item-content" v-html="objInfo.content"></div>
        </div>
        <div class="form-item">
          <label class="form-item-label">有效期:</label>
          <div class="form-item-content">{{ objInfo.timeChoseName }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">位置:</label>
          <div class="form-item-content">{{ objInfo.location }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建时间:</label>
          <div class="form-item-content">{{ objInfo.createTime }}
          </div>
        </div>
        <!--        <div class="form-item">-->
        <!--          <label class="form-item-label">创建人:</label>-->
        <!--          <div class="form-item-content">{{ objInfo.createUserName }}</div>-->
        <!--        </div>-->
        <div class="form-item">
          <label class="form-item-label">审核时间:</label>
          <div class="form-item-content">{{ objInfo.auditTime }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">审核状态:</label>
          <div class="form-item-content">{{ objInfo.auditStatusName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">状态:</label>
          <div class="form-item-content">{{ objInfo.status == 1 ? '正常' : '到期' }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">发布人:</label>
          <div class="form-item-content">{{ objInfo.publisherName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">审核人:</label>
          <div class="form-item-content">{{ objInfo.auditUserName }}</div>
        </div>
        <div class="form-item" v-if=" this.objInfo.imgs.length > 0">
          <label class="form-item-label">图片:</label>
          <div class="form-item-content">
            <img v-for="(item,index) in this.objInfo.imgs" :key="index" :src="item.img"
                 style="width: 100px; height: 100px"/>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {logisticsdynamicsInfo} from "@/api/pdworld/logisticsdynamics.js";
import {enumList, urlList,} from "@/api/public.api.js";

export default {
  data() {
    return {
      drawer: true,
      direction: "rtl",
      objInfo: {
        imgs: [],
        publisherName: '',
        auditStatusName: '',
        auditUserName: '',
        timeChoseName: '',
        typeIdName: ''
      },
      YesNoList: [],
      LogisticsAuditStatusList: [],
      typeIdList: [],
      publisherIdList: [],
      auditUserList: [],
      timeChoseList: [],
    }
  },
  created() {
  },
  methods: {
    queryEnumList() {
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
      this.LogisticsAuditStatusList = [];
      enumList("LogisticsAuditStatus", "").then((resp) => {
        if (resp.code == 0) {
          this.LogisticsAuditStatusList = resp.data;
        }
      });
      this.timeChoseList = [];
      enumList("TimeChose", "").then((resp) => {
        if (resp.code == 0) {
          this.timeChoseList = resp.data;
        }
      });
    },
    queryUrlList() {
      const promise = [
        new Promise(resolve => {
          this.typeIdList = [];
          urlList("/pdworld/logisticsDynamicsType/list").then((resp) => {
            if (resp.code == 0) {
              if (resp.data) {
                resp.data.forEach((item) => {
                  this.typeIdList.push({code: item.id, name: item.name});
                });
              }
              resolve()
            }
          });
        }),
        new Promise(resolve => {
          this.publisherIdList = [];
          urlList("/pdworld/pdMember/list").then((resp) => {
            if (resp.code == 0) {
              if (resp.data) {
                resp.data.forEach((item) => {
                  this.publisherIdList.push({code: item.id, name: item.nickName});
                });
              }
              resolve()
            }
          });
        }),
        new Promise(resolve => {
          this.auditUserList = [];
          urlList("/system/sysUser/list").then((resp) => {
            if (resp.code == 0) {
              if (resp.data) {
                resp.data.forEach((item) => {
                  this.auditUserList.push({code: item.id, name: item.realName});
                });
              }
              resolve()
            }
          });
        })
      ]
      return Promise.all(promise)
    },
    init(id) {
      this.objInfo.typeIdName = ''
      this.objInfo.publisherName = ''
      this.objInfo.auditStatusName = ''
      this.objInfo.auditUserName = ''
      this.objInfo.timeChoseName = ''
      this.queryEnumList();

      if (id) {
        logisticsdynamicsInfo(id).then(res => {
          this.objInfo = Object.assign(this.objInfo, res.data)
          this.queryUrlList().then(() => {
            let typeIdObj = this.typeIdList.find((item) => {
              return item.code == this.objInfo.typeId;
            });
            this.objInfo.typeIdName = typeIdObj ? typeIdObj.name : "";

            let timeChoseObj = this.timeChoseList.find((item) => {
              return item.code == this.objInfo.timeChose;
            });
            this.objInfo.timeChoseName = timeChoseObj ? timeChoseObj.name : "";

            let auditStatusObj = this.LogisticsAuditStatusList.find((item) => {
              return item.code == this.objInfo.auditStatus;
            });
            this.objInfo.auditStatusName = auditStatusObj ? auditStatusObj.name : "";
            if (this.objInfo.auditUser == -1) {
              this.objInfo.auditUserName = '超级管理员';
            }

          });

        })
      }
      this.drawer = true
    },
    // 关闭抽屉
    handleClose(done) {
      done()
    },
  },
}
</script>
<style scoped>
.from-title {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  color: #17233d;
  border-bottom: 1px solid #eee;
}

.info-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 20px;
  font-size: 14px;
}

.form-item-label {
  text-align: right;
  float: left;
  width: 25%;
}

.form-item-content {
  margin-left: 30%;
  width: 70%;
}
</style>
