<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">发布人ID:</label>
                    <div class="form-item-content">{{ objInfo.memberIdName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">货物名称:</label>
                    <div class="form-item-content">{{ objInfo.goodsName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">货物重量:</label>
                    <div class="form-item-content">{{ objInfo.goodsWeight }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">货物类型:</label>
                    <div class="form-item-content">{{ objInfo.goodsType }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">货物体积:</label>
                    <div class="form-item-content">{{ objInfo.goodsVolume }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">预期价格:</label>
                    <div class="form-item-content">{{ objInfo.price }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">需求车辆:</label>
                    <div class="form-item-content">{{ objInfo.carType }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">运输类型(整车配货/物流公司):</label>
                    <div class="form-item-content">{{ objInfo.typeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">信息有效期[截止时间]:</label>
                    <div class="form-item-content">{{ objInfo.endTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">说明备注:</label>
                    <div class="form-item-content">{{ objInfo.remake }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">联系人:</label>
                    <div class="form-item-content">{{ objInfo.person }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">联系电话:</label>
                    <div class="form-item-content">{{ objInfo.phone }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发布时间:</label>
                    <div class="form-item-content">{{ objInfo.releaseTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">始发地:</label>
                    <div class="form-item-content">{{ objInfo.startPlace }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">到达地:</label>
                    <div class="form-item-content">{{ objInfo.destination }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { pdgoodsInfo } from "@/api/pdworld/pdgoods.js";
    import { enumList,urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                TransportTypeList:[],
                memberIdList:[],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.TransportTypeList = [];
              enumList("TransportType","").then((resp) => {
                if (resp.code == 0) {
                  this.TransportTypeList = resp.data;
                }
              });
          },
          queryUrlList(){
              this.memberIdList = [];
              urlList("/pdworld/pdMember/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.memberIdList.push({ code: item.id, name: item.nickName });
                    });
                  }
                }
              });
          },
            init(id) {
                this.queryEnumList();
                this.queryUrlList();
                if (id) {
                   pdgoodsInfo(id).then(res => {
                        this.objInfo = res.data
                              let memberIdObj = this.memberIdList.find((item) => {
                                return item.code == this.objInfo.memberId;
                              });
                              this.objInfo.memberIdName = memberIdObj ? memberIdObj.name : "";
                              let typeObj = this.TransportTypeList.find((item) => {
                                return item.code == this.objInfo.type;
                              });
                              this.objInfo.typeName = typeObj ? typeObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
