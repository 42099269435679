<template>
  <el-row :gutter="20">
    <el-col :lg="8" :xl="8" style="margin-bottom: 17px">
      <div class="padding10 tree" ref="tree" :style="{'height':treeHeight+'px'}">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" clearable style="margin-bottom:10px"></el-input>
        <el-tree
          class="filter-tree"
          :data="deptData"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          highlight-current
          ref="tree"
          @node-click="chooseDept"
        ></el-tree>
      </div>
    </el-col>
    <el-col :lg="16" :xl="16">
      <div class="padding10">
        <el-row style="margin-bottom: 17px">
          <el-button
            type="primary"
            size="medium"
            v-if="$tools.hasPermission('system:sysPost:save') && this.sleDeptId"
            @click="handleEdit()"
            icon="el-icon-plus"
          >添加</el-button>
        </el-row>
        <loading :show="parentShow">
          <el-table
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            default-expand-all
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="dataAuthType" label="权限" :formatter="dataAuthTypeFmt"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="$tools.hasPermission('system:sysPost:info')"
                  size="mini"
                  @click="handleInfo(scope.row.id)"
                >详情</el-button>
                <el-button
                  v-if="$tools.hasPermission('system:sysPost:edit')"
                  size="mini"
                  @click="handleEdit(scope.row.id)"
                >编辑</el-button>
                <el-button
                  v-if="$tools.hasPermission('system:sysPost:delete')"
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </loading>
        <form-edit
          v-if="formEditVisible"
          ref="formEdit"
          :allData="tableData"
          :dataAuthTypeList="dataAuthTypeList"
          :deptId="sleDeptId"
          @refreshData="queryList"
        ></form-edit>
        <form-info v-if="formInfoVisible" ref="formInfo" :allData="tableData"></form-info>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { deptList } from "@/api/auth/sysdept.js"
import { postList, postDel } from "@/api/auth/syspost.js"
import { enumList } from "@/api/public.api.js"
import FormEdit from "./syspost-edit"
import FormInfo from "./syspost-info"
export default {
  data () {
    return {
      parentShow: '',
      formEditVisible: false,
      formInfoVisible: false,
      filterText: "",
      deptData: [],
      tableData: [],
      dataAuthTypeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      sleDeptId: "",
      treeHeight: 0
    }
  },
  components: {
    "form-edit": FormEdit,
    FormInfo,
  },
  activated () {
    this.tableData = []
    this.queryList()

  },
  created () {
    this.queryDataAuthTypeList()
    this.queryDeptList()
    this.queryList()
    this.treeHeight = window.innerHeight * 0.8
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    //查询部门数据
    queryDeptList () {
      deptList().then(resp => {
        if (resp.code == 0) {
          this.deptData = resp.data
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })
    },
    //点选部门树
    chooseDept (data) {
      this.sleDeptId = data.id
      this.queryList(data.id)
    },
    //查询职位列表
    queryList (deptId) {
      this.parentShow = true
      postList({ deptId: deptId }).then(resp => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false
            this.tableData = resp.data
          }, 600)
        } else {
          this.parentShow = false
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })
    },
    //数据权限数据格式化
    dataAuthTypeFmt (row) {
      let typeName = ""
      this.dataAuthTypeList.forEach(item => {
        if (row.dataAuthType == item.code) {
          typeName = item.name
        }
      })
      return typeName
    },
    //查询菜单类型
    queryDataAuthTypeList () {
      enumList("DataPermissionPost").then(resp => {
        if (resp.code == 0) {
          this.dataAuthTypeList = resp.data
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })
    },
    //删除
    handleDelete (row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          postDel(row.id).then(resp => {
            if (resp.code == 0) {
              this.$notify({
                title: "删除成功",
                type: "warning",
                message: "您已成功删除一个职位!",
              })
              this.queryList(this.sleDeptId)
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              })
            }
          })
        })
    },
    //打开编辑窗口
    handleEdit (id) {
      this.formEditVisible = true
      this.$nextTick(() => {
        this.$refs.formEdit.init(id)
      })
    },
    //查看详情
    handleInfo (id) {
      this.formInfoVisible = true
      this.$nextTick(() => {
        this.$refs.formInfo.init(id)
      })
    },
  },
}
</script>
<style scoped>
.padding10 {
  padding: 10px 20px;
  background-color: #fff;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}
.padding10 /deep/ .el-tree-node__label {
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tree {
  overflow: auto;
}
</style>
