var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.form.id ? '编辑' : '新增',"visible":_vm.visible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[(!_vm.form.id)?_c('el-form-item',{attrs:{"label":"始发地","label-width":_vm.formLabelWidth,"prop":"consigner"}},[_c('el-cascader',{attrs:{"options":_vm.regionList,"props":{
                      label: 'name',
                      value: 'id',
                      leaf: 'leaf',
                    },"disabled":!!_vm.form.id},on:{"change":_vm.onChangeConsigner},model:{value:(_vm.consignerName),callback:function ($$v) {_vm.consignerName=$$v},expression:"consignerName"}})],1):_vm._e(),(!_vm.form.id)?_c('el-form-item',{attrs:{"label":"到达地","label-width":_vm.formLabelWidth,"prop":"province"}},[_c('el-cascader',{attrs:{"options":_vm.regionList,"props":{
                    label: 'name',
                    value: 'id',
                    leaf: 'leaf',
                  },"disabled":!!_vm.form.id},on:{"change":_vm.onChangeProvince},model:{value:(_vm.provinceName),callback:function ($$v) {_vm.provinceName=$$v},expression:"provinceName"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"备注","label-width":_vm.formLabelWidth,"prop":"remark"}},[_c('el-input',{attrs:{"autocomplete":"off","placeholder":"请输入备注"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1),_c('el-form-item',{attrs:{"label":"跳转url","label-width":_vm.formLabelWidth,"prop":"path"}},[_c('el-input',{attrs:{"autocomplete":"off","placeholder":"请输入跳转url"},model:{value:(_vm.form.path),callback:function ($$v) {_vm.$set(_vm.form, "path", $$v)},expression:"form.path"}})],1),_c('el-form-item',{attrs:{"label":"图片url","label-width":_vm.formLabelWidth,"prop":"imgUrl"}},[_c('fortress-upload',{attrs:{"type":"image","name":"imgUrl","formData":_vm.form}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"medium","type":"danger"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }