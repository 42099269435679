<template>
  <div class="padding20">
    <el-row style="margin-bottom: 17px">
      <el-button
        type="primary"
        v-if="$tools.hasPermission('devtools:dbconfig:save')"
        @click="handleEdit()"
        size="medium"
        icon="el-icon-plus"
        >添加</el-button
      >
    </el-row>
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
      >
        <el-table-column
          prop="dbName"
          label="连接名"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="dbDriverName"
          label="数据库类型"
          :show-overflow-tooltip="true"
          :formatter="dbDriverNameFmt"
        ></el-table-column>
        <el-table-column
          prop="dbUrl"
          label="数据库URL"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="dbUsername"
          label="用户名"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="dbPassword"
          label="密码"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scoped">{{
            stringHanle(scoped.row.dbPassword)
          }}</template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :show-overflow-tooltip="true"
          :formatter="statusFmt"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('devtools:dbconfig:info')"
              size="mini"
              @click="handleInfo(scope.row.id)"
              >详情</el-button
            >
            <el-button
              v-if="$tools.hasPermission('devtools:dbconfig:edit')"
              size="mini"
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-if="$tools.hasPermission('devtools:dbconfig:delete')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>
  </div>
</template>
<script>
import { dbconfigPage, dbconfigDel } from "@/api/devtools/devdbconfig.js";
import FormEdit from "./devdbconfig-edit";
import FortressPagination from "@/components/fortress-pagination.vue";
export default {
  data() {
    return {
      parentShow: "",
      tableData: [],
      formEditVisible: false,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  components: {
    "form-edit": FormEdit,
    pagination: FortressPagination,
  },
  computed: {
    stringHanle(data) {
      return function (data) {
        let top = data.substr(0, 2);
        let bottom = data.substr(data.length - 1, 2);
        let length = data.length - 3;
        let star = "";
        for (let i = 0; i <= length; i++) {
          star += "*";
        }
        return top + star + bottom;
      };
    },
  },
  created() {
    this.tableData = [];
    this.queryList();
  },
  methods: {
    //查询列表
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      dbconfigPage(this.page)
        .then((resp) => {
          if (resp.code == 0) {
            setTimeout(() => {
              this.parentShow = false;
              this.tableData = resp.data.records;
              this.page.total = resp.data.total;
              //查询页大于总页数，重新查询
              let cu =
                this.page.total % this.page.size == 0
                  ? parseInt(this.page.total / this.page.size)
                  : parseInt(this.page.total / this.page.size + 1);
              if (cu > 0 && this.page.current > cu) {
                this.queryList({ current: cu });
              }
            }, 600);
          } else {
            this.parentShow = false;
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          this.parentShow = false;
        });
    },
    //数据库类型格式化返回
    dbDriverNameFmt(row) {
      let dbDriverName = "";
      if (row.dbDriverName == "com.mysql.jdbc.Driver") {
        dbDriverName = "MYSQL";
      }
      return dbDriverName;
    },
    //状态格式化返回
    statusFmt(row) {
      let typeName = "未启用";
      if (row.status == 1) {
        typeName = "已启用";
      }
      return typeName;
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },

    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        dbconfigDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
              (this.page.total - 1) % this.page.size == 0
                ? (this.page.total - 1) / this.page.size
                : (this.page.total - 1) / this.page.size + 1;
            if (this.page.current > totalPages && this.page.current != 1) {
              this.page.current = this.page.current - 1;
            }
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    //查看详情
    handleInfo(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id, true);
      });
    },
  },
};
</script>
<style scoped></style>
