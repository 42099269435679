import request from "@/common/request"

//对外开放api调用日志分页展示
export const logopenapiPage = (queryForm) => {
    return request("get", "/log/logOpenapi/page", queryForm);
}
//对外开放api调用日志列表展示
export const logopenapiList = (queryForm) => {
    return request("get", "/log/logOpenapi/list", queryForm);
}
//对外开放api调用日志详情
export const logopenapiInfo = (id) => {
    return request('get', '/log/logOpenapi/info', {
        id: id
    })
}
//对外开放api调用日志信息保存
export const logopenapiSave = (data) => {
    return request('post', '/log/logOpenapi/save', data)
}
//对外开放api调用日志信息修改
export const logopenapiEdit = (data) => {
    return request('post', '/log/logOpenapi/edit', data)
}
//对外开放api调用日志信息删除
export const logopenapiDel = (id) => {
    return request('post', '/log/logOpenapi/delete?id=' + id)
}
//对外开放api调用日志批量删除
export const logopenapiDelBatch = (ids) => {
    return request('post', '/log/logOpenapi/deleteBatch', ids)
}
