<template>
	<div>
		<el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
			<div class="info-item">
				<h5 class="from-title">基本信息</h5>
				<div class="form-item">
					<label class="form-item-label">短信编码:</label>
					<div class="form-item-content">
						{{ objInfo.smsCode }}
						<fortress-copy :copyData="objInfo.smsCode"></fortress-copy>
					</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">平台类型:</label>
					<div class="form-item-content">{{ objInfo.platformName }}</div>
				</div>
				<!-- 阿里云 -->
				<div v-if="objInfo.platform == '1'">
					<div class="form-item">
						<label class="form-item-label">AccessKeyId:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.aliyunAccessKeyId }}
							<fortress-copy :copyData="objInfo.smsPlatFormDto.aliyunAccessKeyId"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">AccessKeySecret:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.aliyunAccessKeySecret }}
							<fortress-copy :copyData="objInfo.smsPlatFormDto.aliyunAccessKeySecret"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">短信签名:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.aliyunSignName }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">模板编码:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.aliyunTemplateCode }}
							<fortress-copy :copyData="objInfo.smsPlatFormDto.aliyunTemplateCode"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">区域ID:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.aliyunRegionId }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">访问域名:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.aliyunEndpoint }}
						</div>
					</div>
				</div>
				<!-- 腾讯云 -->
				<div v-if="objInfo.platform == '2'">
					<div class="form-item">
						<label class="form-item-label">SdkAppId:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.tencentcloudSdkAppId }}
							<fortress-copy :copyData="objInfo.smsPlatFormDto.tencentcloudSdkAppId"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">SecretId:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.tencentcloudSecretId }}
							<fortress-copy :copyData="objInfo.smsPlatFormDto.tencentcloudSecretId"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">SecretKey:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.tencentcloudSecretKey }}
							<fortress-copy :copyData="objInfo.smsPlatFormDto.tencentcloudSecretKey"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">签名:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.tencentcloudSign }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">模板ID:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.tencentcloudTemplateId }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">区域:</label>
						<div class="form-item-content">
							{{ objInfo.smsPlatFormDto.tencentcloudRegion }}
						</div>
					</div>
				</div>

				<div class="form-item">
					<label class="form-item-label">备注:</label>
					<div class="form-item-content">{{ objInfo.remark }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">状态:</label>
					<div class="form-item-content">
						{{ objInfo.status == 1 ? "已启用" : "未启用" }}
					</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">创建人:</label>
					<div class="form-item-content">{{ objInfo.createUserName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">创建部门:</label>
					<div class="form-item-content">{{ objInfo.createDeptName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">创建时间:</label>
					<div class="form-item-content">{{ objInfo.createTime }}</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
import { configsmsInfo } from "@/api/message/sms/configsms.js"
export default {
	data() {
		return {
			drawer: true,
			direction: "rtl",
			objInfo: {
				smsCode: "",
				platform: "1",
				smsConfig: "",
				remark: "",
				status: 1,
				smsPlatFormDto: {},
			},
		}
	},
	created() {},
	methods: {
		init(id) {
			if (id) {
				configsmsInfo(id).then(res => {
					this.objInfo = {
						...this.objInfo,
						...res.data,
					}
					if (this.objInfo.platform == "1") {
						this.objInfo.platformName = "阿里云"
					} else if (this.objInfo.platform == "2") {
						this.objInfo.platformName = "腾讯云"
					}
					if (this.objInfo.platform == "3") {
						this.objInfo.platformName = "七牛云"
					}
				})
			}
			this.drawer = true
		},
		// 关闭抽屉
		handleClose(done) {
			done()
		},
	},
}
</script>
<style scoped>
.from-title {
	margin-bottom: 20px;
	padding: 0 20px 20px 20px;
	font-size: 16px;
	color: #17233d;
	border-bottom: 1px solid #eee;
}
.info-item {
	margin-bottom: 40px;
}

.form-item {
	margin-bottom: 20px;
	font-size: 14px;
}
.form-item-label {
	text-align: right;
	float: left;
	width: 25%;
}
.form-item-content {
	margin-left: 30%;
	width: 70%;
}
</style>
