<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="发布人ID" :label-width="formLabelWidth" prop="memberId">
                <fortress-select
                        v-model="form.memberId"
                        :dictList="memberIdList"
                        placeholder="请选择发布人ID"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="货物名称" :label-width="formLabelWidth" prop="goodsName">
                <el-input
                        v-model="form.goodsName"
                        autocomplete="off"
                        placeholder="请输入货物名称"
                ></el-input>
            </el-form-item>
            <el-form-item label="货物重量/体积" :label-width="formLabelWidth" prop="goodsWeight">
                <el-input
                        v-model="form.goodsWeight"
                        autocomplete="off"
                        placeholder="请输入货物重量/体积"
                ></el-input>
            </el-form-item>
            <el-form-item label="货物类型" :label-width="formLabelWidth" prop="goodsType">
                <el-input
                        v-model="form.goodsType"
                        autocomplete="off"
                        placeholder="请输入货物类型"
                ></el-input>
            </el-form-item>
<!--            <el-form-item label="货物体积" :label-width="formLabelWidth" prop="goodsVolume">-->
<!--                <el-input-->
<!--                        v-model="form.goodsVolume"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="请输入货物体积"-->
<!--                ></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="预期价格" :label-width="formLabelWidth" prop="price">
                <el-input
                        v-model="form.price"
                        autocomplete="off"
                        placeholder="请输入预期价格"
                ></el-input>
            </el-form-item>
            <el-form-item label="需求车辆" :label-width="formLabelWidth" prop="carType">
                <el-input
                        v-model="form.carType"
                        autocomplete="off"
                        placeholder="请输入需求车辆"
                ></el-input>
            </el-form-item>
<!--            <el-form-item label="运输类型(整车配货/物流公司)" :label-width="formLabelWidth" prop="type">-->
<!--                <fortress-select-->
<!--                        v-model="form.type"-->
<!--                        :dictList="TransportTypeList"-->
<!--                        placeholder="请选择运输类型(整车配货/物流公司)"-->
<!--                        clearable-->
<!--                ></fortress-select>-->
<!--            </el-form-item>-->
            <el-form-item label="信息有效期[截止时间]" :label-width="formLabelWidth" prop="endTime">
                <el-date-picker
                        v-model="form.endTime"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="说明备注" :label-width="formLabelWidth" prop="remake">
                <el-input
                        v-model="form.remake"
                        autocomplete="off"
                        placeholder="请输入说明备注"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系人" :label-width="formLabelWidth" prop="person">
                <el-input
                        v-model="form.person"
                        autocomplete="off"
                        placeholder="请输入联系人"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" :label-width="formLabelWidth" prop="phone">
                <el-input
                        v-model="form.phone"
                        autocomplete="off"
                        placeholder="请输入联系电话"
                ></el-input>
            </el-form-item>


          <el-form-item label="出发地" :label-width="formLabelWidth" prop="startPlace">
            <fortress-region
                ref="startPlaceFortressRegion"
                @ftregion="startPlaceHandler"
            ></fortress-region>
          </el-form-item>

          <el-form-item label="到达地" :label-width="formLabelWidth" prop="destination">
            <fortress-region
                ref="destinationFortressRegion"
                @ftregion="destinationHandler"
            ></fortress-region>
          </el-form-item>
<!--            <el-form-item label="始发地" :label-width="formLabelWidth" prop="startPlace">-->
<!--                <el-input-->
<!--                        v-model="form.startPlace"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="请输入始发地"-->
<!--                ></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="到达地" :label-width="formLabelWidth" prop="destination">-->
<!--                <el-input-->
<!--                        v-model="form.destination"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="请输入到达地"-->
<!--                ></el-input>-->
<!--            </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { pdgoodsInfo, pdgoodsSave, pdgoodsEdit } from "@/api/pdworld/pdgoods.js";
    import { enumList,urlList, } from "@/api/public.api.js";

    export default {
        data() {
            return {
              startPlaceRegion:[],
              destinationRegion:[],
                visible: false,
                form: {
                    memberId:"",
                    goodsName:"",
                    goodsWeight:"",
                    goodsType:"",
                    goodsVolume:"",
                    price:"",
                    carType:"",
                    type:"",
                    endTime:"",
                    remake:"",
                    person:"",
                    phone:"",
                    startPlace:"",
                    destination:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
                TransportTypeList:[],
                memberIdList:[],
            };
        },
        created() {
        },
        methods: {
          startPlaceHandler(regions) {
            console.log("regionHandler", regions);
            this.form.consigner=regions[0].id;
            this.form.consignerName=regions[0].name;
            this.form.consignerCity=regions[1].id;
            this.form.consignerCityName=regions[1].name;
            this.form.consignerArea=regions[2].id;
            this.form.consignerAreaName=regions[2].name;
          },
          destinationHandler(regions) {
            console.log("regionHandler", regions);
            this.form.province=regions[0].id;
            this.form.provinceName=regions[0].name;
            this.form.provinceCity=regions[1].id;
            this.form.provinceCityName=regions[1].name;
            this.form.provinceArea=regions[2].id;
            this.form.areaName=regions[2].name;
          },
            queryEnumList(){
                this.TransportTypeList = [];
                enumList("TransportType","").then((resp) => {
                  if (resp.code == 0) {
                    this.TransportTypeList = resp.data;
                  }
                });
            },
            queryUrlList(){
                this.memberIdList = [];
                urlList("/pdworld/pdMember/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.memberIdList.push({ code: item.id, name: item.nickName });
                      });
                    }
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryEnumList();
                this.queryUrlList();
                //修改
                if (id) {
                    pdgoodsInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };

                              this.startPlaceRegion.push(this.form.consigner);
                              this.startPlaceRegion.push(this.form.consignerCity);
                              this.startPlaceRegion.push(this.form.consignerArea);
                              this.$refs.startPlaceFortressRegion.init(this.startPlaceRegion);

                              this.destinationRegion.push(this.form.province);
                              this.destinationRegion.push(this.form.provinceCity);
                              this.destinationRegion.push(this.form.provinceArea);
                              this.$refs.destinationFortressRegion.init(this.destinationRegion);

                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                else{
                  this.$nextTick(() => {
                    this.$refs.startPlaceFortressRegion.init([]);
                    this.$refs.destinationFortressRegion.init([]);
                  });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        pdgoodsEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        pdgoodsSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
              this.startPlaceRegion=[],
               this.destinationRegion=[],
                this.form = {
                    memberId:"",
                    goodsName:"",
                    goodsWeight:"",
                    goodsType:"",
                    goodsVolume:"",
                    price:"",
                    carType:"",
                    type:"",
                    endTime:"",
                    remake:"",
                    person:"",
                    phone:"",
                    startPlace:"",
                    destination:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
