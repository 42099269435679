<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">接收人ID:</label>
                    <div class="form-item-content">{{ objInfo.receiveIdName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否已读:</label>
                    <div class="form-item-content" v-if="objInfo.status == 1">启用</div>
                    <div class="form-item-content" v-else>禁用</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否删除:</label>
                    <div class="form-item-content" v-if="objInfo.isDeleted == 1">启用</div>
                    <div class="form-item-content" v-else>禁用</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建人:</label>
                    <div class="form-item-content">{{ objInfo.createUserName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">消息:</label>
                    <div class="form-item-content">{{ objInfo.noticeIdName }}</div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { noticerecordInfo } from "@/api/pdworld/noticerecord.js";
    import { enumList,urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                YesNoList:[],
                receiveIdList:[],
                noticeIdList:[],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.YesNoList = [];
              enumList("YesNo","").then((resp) => {
                if (resp.code == 0) {
                  this.YesNoList = resp.data;
                }
              });
          },
          queryUrlList(){
              this.receiveIdList = [];
              urlList("/pdworld/pdMember/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.receiveIdList.push({ code: item.id, name: item.userName });
                    });
                  }
                }
              });
              this.noticeIdList = [];
              urlList("/pdworld/notice/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.noticeIdList.push({ code: item.id, name: item.id });
                    });
                  }
                }
              });
          },
            init(id) {
                this.queryEnumList();
                this.queryUrlList();
                if (id) {
                   noticerecordInfo(id).then(res => {
                        this.objInfo = res.data
                              let receiveIdObj = this.receiveIdList.find((item) => {
                                return item.code == this.objInfo.receiveId;
                              });
                              this.objInfo.receiveIdName = receiveIdObj ? receiveIdObj.name : "";
                              let noticeIdObj = this.noticeIdList.find((item) => {
                                return item.code == this.objInfo.noticeId;
                              });
                              this.objInfo.noticeIdName = noticeIdObj ? noticeIdObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
