import request from "@/common/request"

//用户操作记录日志表分页展示
export const logoperationPage = (queryForm) => {
    return request("get", "/log/logOperation/page", queryForm);
}
//用户操作记录日志表列表展示
export const logoperationList = (queryForm) => {
    return request("get", "/log/logOperation/list", queryForm);
}
//用户操作记录日志表详情
export const logoperationInfo = (id) => {
    return request('get', '/log/logOperation/info', {
        id: id
    })
}
//用户操作记录日志表信息保存
export const logoperationSave = (data) => {
    return request('post', '/log/logOperation/save', data)
}
//用户操作记录日志表信息修改
export const logoperationEdit = (data) => {
    return request('post', '/log/logOperation/edit', data)
}
//用户操作记录日志表信息删除
export const logoperationDel = (id) => {
    return request('post', '/log/logOperation/delete?id=' + id)
}
//用户操作记录日志表批量删除
export const logoperationDelBatch = (ids) => {
    return request('post', '/log/logOperation/deleteBatch', ids)
}
