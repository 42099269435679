<template>
  <el-dialog title="菜单权限" :visible.sync="visible">
    <my-jurisdiction :data="data" :checked="checkedData" v-if="visible"></my-jurisdiction>
    <!-- <el-tree
      :data="data"
      show-checkbox
      node-key="id"
      :props="defaultProps"
      :default-checked-keys="checkedData"
      ref="tree"
    ></el-tree>-->
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible=false" size="medium">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm()">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import myJurisdiction from "@/components/jurisdiction/index"
import { menuAuthInfo, menuAuthSave } from "@/api/auth/sysrole.js"
export default {
  data () {
    return {
      visible: false,
      jurisdictionVisible: false,
      roleId: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      menuAuth: [],
      tempNode: {},
      checkedData: [],
      reData: [],
      submitData: [],
    }
  },
  components: {
    myJurisdiction
  },
  create () {

  },

  methods: {
    init (roleId) {
      this.checkedData = []
      this.menuAuth = []
      this.roleId = roleId
      menuAuthInfo(roleId).then(resp => {
        if (resp.code == 0) {
          this.data = resp.data.allData
          if (resp.data.checkedData) {
            resp.data.checkedData.forEach(item => {
              this.checkedData.push(item.id)
            })
          }
          this.visible = true
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })


    },

    //提交表单数据
    submitForm () {
      this.submitData = []
      this.reData = []
      this.reconstitutionDataHandle(this.data, this.reData);
      this.reData.forEach(item => {
        if (item.checkAll||item.isIndeterminate) {
          this.submitData.push(item.id)
        }
      })
      this.menuAuth = []
      let checkedNodes = []
      checkedNodes = this.submitData
      if (checkedNodes) {
        checkedNodes.forEach(element => {
          let temp = element
          this.pushMenuAuth(temp)
          while (temp.parentId) {
            this.tempNode = {}
            this.findNode(temp.parentId, this.data)
            if (this.tempNode) {
              temp = this.tempNode
              this.pushMenuAuth(temp)
            }
          }
        })
      }
      menuAuthSave(this.menuAuth).then(resp => {
        if (resp.code == 0) {
          this.visible = false
          this.$notify({
            title: "修改成功",
            type: "success",
              message: "菜单权限修改成功!",
          })
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })
    },
    //重构数据
    reconstitutionDataHandle (data, dd) {
      let children = [];
      for (var i in data) {
        dd.push(data[i]);
        if (data[i].children) {
          for (var j in data[i].children) {
            children.push(data[i].children[j]);
          }
        }
      }
      if (children.length > 0) {
        this.reconstitutionDataHandle(children, dd);
      }
    },
    //查找父节点
    pushMenuAuth (id) {
      let find = this.menuAuth.find(item => {
        return item.menuId == id
      })
      if (!find) {
        this.menuAuth.push({
          roleId: this.roleId,
          menuId: id,
        })
      }
    },
    findNode (id, nodeArray) {
      let _this = this
      for (let item of nodeArray) {
        if (item.id == id) {
          _this.tempNode = item
          break
        } else if (item.children) {
          _this.findNode(id, item.children)
        }
      }
    },
  },
}
</script>
<style scoped>
</style>
