<template>
  <el-dialog
    title="生成菜单"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rule">
      <el-form-item
        label="上级菜单"
        :label-width="formLabelWidth"
        prop="parentId"
      >
        <el-select
          v-model="form.parentId"
          clearable
          placeholder="请选择"
          :disabled="editFlag"
        >
          <el-option :label="selParent.label" :value="selParent.id">
            <el-tree
              :data="treeSelectMenuData"
              :props="defaultProps"
              accordion
              highlight-current
              @node-click="handleNodeClick"
            ></el-tree>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input
          v-model="form.name"
          autocomplete="off"
          :disabled="editFlag"
        ></el-input>
      </el-form-item>
      <el-form-item label="图标" :label-width="formLabelWidth" prop="icon">
        <el-popover
          ref="iconPopover"
          placement="bottom-start"
          trigger="click"
          visible-arrow
          :disabled="editFlag"
          popper-class="iconsGroup"
        >
          <span
            v-for="(icon, index) in iconList"
            :key="icon + index"
            @click="handleIconClick(icon)"
          >
            <i :class="icon"></i>
          </span>
          <el-input
            placeholder="请选择图标"
            slot="reference"
            v-model="form.icon"
            :disabled="editFlag"
          ></el-input>
        </el-popover>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" v-if="!editFlag">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { menuList, codeMenu } from "@/api/devtools/devcode.js";
import iconList from "@/common/iconList.js";
export default {
  data() {
    return {
      visible: false,
      editFlag: false,
      treeSelectMenuData: [],
      selParent: {
        id: "",
        label: "",
      },
      form: {
        id: "",
        name: "",
        parentId: "",
        icon: "",
      },
      formLabelWidth: "120px",
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      iconList: iconList,
      rule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
    };
  },
  computed: {},
  created() {
    //查询菜单列表
    menuList().then((resp) => {
      if (resp.code == 0) {
        this.treeSelectMenuData = resp.data;
      } else {
        this.$msg({
          message: resp.msg,
          type: "error",
        });
      }
    });
  },
  methods: {
    init(id) {
      this.resetForm();
      this.form.id = id;
      this.visible = true;
    },
    //树形菜单展示
    handleNodeClick(data) {
      this.selParent.id = data.id;
      this.selParent.label = data.name;
      this.form.parentId = data.id;
    },
    //初始化图标列表
    queryIcons(queryString, cb) {
      var iconList = this.iconList;
      // 调用 callback 返回建议列表的数据
      cb(iconList);
    },
    //选择图标
    handleIconClick(data) {
      this.form.icon = data;
      this.$refs.iconPopover.doClose();
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          codeMenu(this.form).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
          this.visible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        parentId: "",
        icon: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
.icons_ul li i {
  display: block;
  font-size: 27px;
  float: left;
  padding: 2px;
}
</style>
