var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.form.id ? '编辑' : '新增',"visible":_vm.visible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"线路id","label-width":_vm.formLabelWidth,"prop":"routeId"}},[_c('el-input',{attrs:{"autocomplete":"off","placeholder":"请输入线路id"},model:{value:(_vm.form.routeId),callback:function ($$v) {_vm.$set(_vm.form, "routeId", $$v)},expression:"form.routeId"}})],1),_c('el-form-item',{attrs:{"label":"所属商家ID","label-width":_vm.formLabelWidth,"prop":"memberId"}},[_c('el-input',{attrs:{"autocomplete":"off","placeholder":"请输入所属商家ID"},model:{value:(_vm.form.memberId),callback:function ($$v) {_vm.$set(_vm.form, "memberId", $$v)},expression:"form.memberId"}})],1),_c('el-form-item',{attrs:{"label":"开始时间","label-width":_vm.formLabelWidth,"prop":"startTime"}},[_c('el-time-select',{attrs:{"placeholder":"起始时间","picker-options":{
                  start: '08:00',
                  step: '00:30',
                  end: '23:00',
                }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1),_c('el-form-item',{attrs:{"label":"结束时间","label-width":_vm.formLabelWidth,"prop":"endTime"}},[_c('el-time-select',{attrs:{"placeholder":"结束时间","picker-options":{
                  start: '08:00',
                  step: '00:30',
                  end: '23:00',
                  minTime: _vm.form.startTime
                }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1),_c('el-form-item',{attrs:{"label":"间隔时间(分钟)","label-width":_vm.formLabelWidth,"prop":"interval"}},[_c('el-input-number',{attrs:{"min":1,"max":60,"label":"描述文字"},model:{value:(_vm.form.interval),callback:function ($$v) {_vm.$set(_vm.form, "interval", $$v)},expression:"form.interval"}})],1),_c('el-form-item',{attrs:{"label":"是否每天刷新","label-width":_vm.formLabelWidth,"prop":"isEveryday"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":1,"inactive-value":2},model:{value:(_vm.form.isEveryday),callback:function ($$v) {_vm.$set(_vm.form, "isEveryday", $$v)},expression:"form.isEveryday"}})],1),_c('el-form-item',{attrs:{"label":"状态","label-width":_vm.formLabelWidth,"prop":"status"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":1,"inactive-value":2},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"medium","type":"danger"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }