import request from "@/common/request"

//会员诚保资金流水分页展示
export const memberaccountintegrityserialPage = (queryForm) => {
    return request("get", "/account/memberAccountIntegritySerial/page", queryForm);
}
//会员诚保资金流水列表展示
export const memberaccountintegrityserialList = (queryForm) => {
    return request("get", "/account/memberAccountIntegritySerial/list", queryForm);
}
//会员诚保资金流水详情
export const memberaccountintegrityserialInfo = (id) => {
    return request('get', '/account/memberAccountIntegritySerial/info', {
        id: id
    })
}
//会员诚保资金流水信息保存
export const memberaccountintegrityserialSave = (data) => {
    return request('post', '/account/memberAccountIntegritySerial/save', data)
}
//会员诚保资金流水信息修改
export const memberaccountintegrityserialEdit = (data) => {
    return request('post', '/account/memberAccountIntegritySerial/edit', data)
}
//会员诚保资金流水信息删除
export const memberaccountintegrityserialDel = (id) => {
    return request('post', '/account/memberAccountIntegritySerial/delete?id=' + id)
}
//会员诚保资金流水批量删除
export const memberaccountintegrityserialDelBatch = (ids) => {
    return request('post', '/account/memberAccountIntegritySerial/deleteBatch', ids)
}
//会员诚保资金流水动态表头导出
export const memberaccountintegrityserialDynamicExport = (data) => {
    return request("eptpost", '/account/memberAccountIntegritySerial/exportDynamic', data)
}
