<template>
	<el-dialog :visible.sync="visible" class="setting" width="60%" title="启用配置">
		<el-tag
			v-if="tagVisible"
			type="primary"
			style="margin-bottom: 10px; margin-top: -25px; display: block; display: flex; justify-content: space-between; align-items: center"
			closable
			@close="onTagClose"
		>
			<span>
				使用教程请参考：
				<a href="https://doc.icuapi.com/#/cframe/公共/支付管理" target="_blank">支付配置管理</a>
			</span>
		</el-tag>
		<el-tag
			v-if="tagVisible2"
			type="warning"
			style="margin-bottom: 10px; margin-top: -5px; display: block; display: flex; justify-content: space-between; align-items: center"
			closable
			@close="onTagClose2"
		>
			<span>
				配置默认不可用，只有配置了参数后可启停
			</span>
		</el-tag>
		<el-row :gutter="20">
			<el-col :span="12">
				<el-card shadow="never">
					<template #header>
						<div class="cards-header">
							微信支付

							<a href="https://pay.weixin.qq.com/" target="kongzhitai">
								<i class="el-icon-monitor"></i>
								跳转控制台
							</a>
						</div>
					</template>
					<div class="item" v-for="(item, index) in wxList" :key="index">
						<div class="name">{{ item.payTypeName }}</div>
						<div class="tools">
							<div v-if="item.status != 3">
								<el-switch
									:active-value="1"
									:inactive-value="2"
									active-text="启用"
									inactive-text="禁用"
									v-model="item.status"
									@change="changeStatus('wechat', item)"
								></el-switch>
								<a
									:href="'/pay/test?payType=WXPAY_NATIVE&sceneCode=' + item.sceneCode"
									target="test"
									v-if="item.payTypeCode === 'NATIVE' && item.status == 1"
								>
									去测试
								</a>
							</div>
							<el-button v-else type="text" @click="toSetting('wx', item.sceneCode, index)">去配置</el-button>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card shadow="never">
					<template #header>
						<div class="cards-header">
							支付宝支付
							<a href="https://b.alipay.com/page/product-mall/all-product" target="kongzhitai">
								<i class="el-icon-monitor"></i>
								跳转控制台
							</a>
						</div>
					</template>
					<div class="item" v-for="(item, index) in aliList" :key="index">
						<div class="name">{{ item.payTypeName }}</div>
						<div class="tools">
							<div v-if="item.status != 3">
								<el-switch
									:active-value="1"
									:inactive-value="2"
									active-text="启用"
									inactive-text="禁用"
									v-model="item.status"
									@change="changeStatus('ali', item)"
								></el-switch>
								<a
									:href="'/pay/test?payType=ALIPAY_PC&sceneCode=' + item.sceneCode"
									target="test"
									v-if="item.payTypeCode === 'ALIPAY_PC' && item.status == 1"
								>
									去测试
								</a>
							</div>
							<el-button v-else type="text" @click="toSetting('ali', item.sceneCode, index)">去配置</el-button>
						</div>
					</div>
				</el-card>
			</el-col>
		</el-row>
	</el-dialog>
</template>

<script>
import request from "@/common/request"

let row = null
export default {
	name: "setting",
	props: {},
	data() {
		return {
			visible: false,
			data: {
				ALI: [],
				WX: [],
			},
			tagVisible: true,
			tagVisible2: true,
		}
	},
	computed: {
		wxList() {
			return this.data.WX
		},
		aliList() {
			return this.data.ALI
		},
	},
	methods: {
		show(propsRow) {
			this.visible = true
			row = propsRow
			this.getData()
		},
		async getData() {
			try {
				const { code, data, msg } = await request("get", "/pay/payScene/configList", {
					code: row.code,
				})
				if (code !== 0) {
					throw new Error(msg)
				}
				this.data.WX = data.WX
				this.data.ALI = data.ALI
			} catch (error) {
				this.$message.error(error)
			}
		},
		async changeStatus(type, item) {
			if (type === "wechat") {
				try {
					const { code, msg } = await request("post", "/pay/payWxConfig/edit", item)
					if (code !== 0) {
						throw new Error(msg)
					}
				} catch (error) {
					this.$message.error(error)
				}
			} else if (type === "ali") {
				try {
					const { code, msg } = await request("post", "/pay/payAliConfig/edit", item)
					if (code !== 0) {
						throw new Error(msg)
					}
				} catch (error) {
					this.$message.error(error)
				}
			}
		},
		onTagClose() {
			this.tagVisible = false
		},
		onTagClose2() {
			this.tagVisible2 = false
		},
		toSetting(type, code, index) {
			this.$router.push(`/pay/payscene-setting?type=${type}&code=${code}&current=${index}`)
		},
	},
	components: {},
}
</script>

<style lang="scss" scoped>
.setting {
	.item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 2.5;
	}
}
.cards-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	a {
		color: #409eff;
	}
}
.tools {
	a {
		margin-left: 10px;
		color: #409eff;
		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
