<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">会员ID:</label>
                    <div class="form-item-content">{{ objInfo.memberId }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商家基础信息ID:</label>
                    <div class="form-item-content">{{ objInfo.shopId }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">类型（banner图/公司场景/公司荣誉）:</label>
                    <div class="form-item-content">{{ objInfo.typeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">图片地址:</label>
                    <div class="form-item-content">{{ objInfo.url }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { pdshopinfoimgInfo } from "@/api/pdworld/pdshopinfoimg.js";
    import { enumList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                ShopImgTypeList:[],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.ShopImgTypeList = [];
              enumList("ShopImgType","").then((resp) => {
                if (resp.code == 0) {
                  this.ShopImgTypeList = resp.data;
                }
              });
          },
            init(id) {
                this.queryEnumList();
                if (id) {
                   pdshopinfoimgInfo(id).then(res => {
                        this.objInfo = res.data
                              let typeObj = this.ShopImgTypeList.find((item) => {
                                return item.code == this.objInfo.type;
                              });
                              this.objInfo.typeName = typeObj ? typeObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
