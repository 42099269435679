import request from "@/common/request"

//会员账户表分页展示
export const memberaccountPage = (queryForm) => {
    return request("get", "/account/memberAccount/page", queryForm);
}
//会员账户表列表展示
export const memberaccountList = (queryForm) => {
    return request("get", "/account/memberAccount/list", queryForm);
}
//会员账户表详情
export const memberaccountInfo = (id) => {
    return request('get', '/account/memberAccount/info', {
        id: id
    })
}
//会员账户表信息保存
export const memberaccountSave = (data) => {
    return request('post', '/account/memberAccount/save', data)
}
//会员账户表信息修改
export const memberaccountEdit = (data) => {
    return request('post', '/account/memberAccount/edit', data)
}
//会员账户表信息删除
export const memberaccountDel = (id) => {
    return request('post', '/account/memberAccount/delete?id=' + id)
}
//会员账户表批量删除
export const memberaccountDelBatch = (ids) => {
    return request('post', '/account/memberAccount/deleteBatch', ids)
}
//会员账户表动态表头导出
export const memberaccountDynamicExport = (data) => {
    return request("eptpost", '/account/memberAccount/exportDynamic', data)
}
