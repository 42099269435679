<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">商品名称:</label>
                    <div class="form-item-content">{{ objInfo.name }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">所属分类:</label>
                    <div class="form-item-content">{{ objInfo.categoryId }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">类型:</label>
                    <div class="form-item-content">{{ objInfo.typeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">排序:</label>
                    <div class="form-item-content">{{ objInfo.sort }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">主图片:</label>
                    <div class="form-item-content" >
                      <el-image
                          v-if="objInfo.thumb"
                          :src="objInfo.thumb"
                          style="width: 100px; height: 100px"
                          :preview-src-list="[objInfo.thumb]"
                      />
<!--                      <img :src="objInfo.thumb" />-->
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">主视频:</label>
                    <div class="form-item-content" >
                      <fortress-upload
                          v-if="objInfo.video"
                          type="videoShow"
                          name="video"
                          :formData="objInfo"
                          :videoSrc="objInfo.video"
                      ></fortress-upload>
                    </div>
                </div>
              <div class="form-item">
                <label class="form-item-label">其他图片:</label>
                <div class="form-item-content" >
                  <el-image
                      v-for="(item,index) in objInfo.imgList"
                      :src="item"
                      :key="index"
                      style="width: 100px; height: 100px"
                      :preview-src-list="objInfo.imgList"
                  />
                </div>
              </div>
                <div class="form-item">
                    <label class="form-item-label">详情信息:</label>
                    <div class="form-item-content" v-html="objInfo.content"></div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">条码:</label>
                    <div class="form-item-content" ><img v-if="objInfo.productSn" :src="objInfo.productSn" /></div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">编码:</label>
                    <div class="form-item-content">{{ objInfo.goodsSn }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">原价:</label>
                    <div class="form-item-content">{{ objInfo.productPrice }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">现价:</label>
                    <div class="form-item-content">{{ objInfo.marketPrice }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">减库存方式:</label>
                    <div class="form-item-content">{{ objInfo.totalCnfName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">库存:</label>
                    <div class="form-item-content">{{ objInfo.total }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">购买赠送积分:</label>
                    <div class="form-item-content">{{ objInfo.credit }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">单次最多购买量:</label>
                    <div class="form-item-content">{{ objInfo.maxBuy }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">用户最多购买量:</label>
                    <div class="form-item-content">{{ objInfo.userMaxBuy }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">重量:</label>
                    <div class="form-item-content">{{ objInfo.weight }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">配送方式:</label>
                    <div class="form-item-content">{{ objInfo.dispatchTypeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否为新上:</label>
                    <div class="form-item-content">{{ objInfo.isNewName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否为热卖:</label>
                    <div class="form-item-content">{{ objInfo.isHotName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否为促销:</label>
                    <div class="form-item-content">{{ objInfo.isDiscountName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否为推荐:</label>
                    <div class="form-item-content">{{ objInfo.isRecommandName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否包邮:</label>
                    <div class="form-item-content">{{ objInfo.isSendFreeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">运费:</label>
                    <div class="form-item-content">{{ objInfo.freight }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">分享标题:</label>
                    <div class="form-item-content">{{ objInfo.shareTitle }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">分享图标:</label>
                    <div class="form-item-content" ><img v-if="objInfo.shareIcon" :src="objInfo.shareIcon" /></div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否参与会员折扣:</label>
                    <div class="form-item-content">{{ objInfo.isNodisCountName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">已售数量:</label>
                    <div class="form-item-content">{{ objInfo.sales }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否搜索隐藏:</label>
                    <div class="form-item-content">{{ objInfo.isSearchShowName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否自动上下架:</label>
                    <div class="form-item-content">{{ objInfo.isAutoShelvesName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">自动上架时间:</label>
                    <div class="form-item-content">{{ objInfo.upShelvesDate }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">自动下架时间:</label>
                    <div class="form-item-content">{{ objInfo.offShelvesDate }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否开发票:</label>
                    <div class="form-item-content">{{ objInfo.isInvoiceName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">标签:</label>
                    <div class="form-item-content">{{ objInfo.tags }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">备注:</label>
                    <div class="form-item-content">{{ objInfo.remark }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">状态:</label>
                    <div class="form-item-content" v-if="objInfo.status == 1">启用</div>
                    <div class="form-item-content" v-else>禁用</div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { goodsInfo } from "@/api/goods/goods.js";
    import { enumList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                GoodsType_goodsList:[],
                GoodsTotalCnf_goodsList:[],
                GoodsDispatchType_goodsList:[],
              YesNoList:[
                {
                  code:"1",
                  name:"是"
                },{
                  code:"2",
                  name:"否"
                },
              ],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.GoodsType_goodsList = [];
              enumList("GoodsType","goods").then((resp) => {
                if (resp.code == 0) {
                  this.GoodsType_goodsList = resp.data;
                }
              });
              this.GoodsTotalCnf_goodsList = [];
              enumList("GoodsTotalCnf","goods").then((resp) => {
                if (resp.code == 0) {
                  this.GoodsTotalCnf_goodsList = resp.data;
                }
              });
              this.GoodsDispatchType_goodsList = [];
              enumList("GoodsDispatchType","goods").then((resp) => {
                if (resp.code == 0) {
                  this.GoodsDispatchType_goodsList = resp.data;
                }
              });
          },
              getvideoDesc(data) {
                var video = {
                  video: {
                    type: "gallery",
                    default: data.video,
                    attrs: {
                      type: "video",
                      size: 100,
                    },
                  },
                };
                return video;
              },

            init(id) {
                this.queryEnumList();
                if (id) {
                   goodsInfo(id).then(res => {
                        this.objInfo = res.data
                              let typeObj = this.GoodsType_goodsList.find((item) => {
                                return item.code == this.objInfo.type;
                              });
                              this.objInfo.typeName = typeObj ? typeObj.name : "";
                              let totalCnfObj = this.GoodsTotalCnf_goodsList.find((item) => {
                                return item.code == this.objInfo.totalCnf;
                              });
                              this.objInfo.totalCnfName = totalCnfObj ? totalCnfObj.name : "";
                              let dispatchTypeObj = this.GoodsDispatchType_goodsList.find((item) => {
                                return item.code == this.objInfo.dispatchType;
                              });
                              this.objInfo.dispatchTypeName = dispatchTypeObj ? dispatchTypeObj.name : "";
                              let isNewObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isNew;
                              });
                              this.objInfo.isNewName = isNewObj ? isNewObj.name : "";
                              let isHotObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isHot;
                              });
                              this.objInfo.isHotName = isHotObj ? isHotObj.name : "";
                              let isDiscountObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isDiscount;
                              });
                              this.objInfo.isDiscountName = isDiscountObj ? isDiscountObj.name : "";
                              let isRecommandObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isRecommand;
                              });
                              this.objInfo.isRecommandName = isRecommandObj ? isRecommandObj.name : "";
                              let isSendFreeObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isSendFree;
                              });
                              this.objInfo.isSendFreeName = isSendFreeObj ? isSendFreeObj.name : "";
                              let isNodisCountObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isNodisCount;
                              });
                              this.objInfo.isNodisCountName = isNodisCountObj ? isNodisCountObj.name : "";
                              let isSearchShowObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isSearchShow;
                              });
                              this.objInfo.isSearchShowName = isSearchShowObj ? isSearchShowObj.name : "";
                              let isAutoShelvesObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isAutoShelves;
                              });
                              this.objInfo.isAutoShelvesName = isAutoShelvesObj ? isAutoShelvesObj.name : "";
                              let isInvoiceObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isInvoice;
                              });
                              this.objInfo.isInvoiceName = isInvoiceObj ? isInvoiceObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
