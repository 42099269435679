import request from "@/common/request"

//会员商家表分页展示
export const pdmemberPage = (queryForm) => {
    return request("get", "/pdworld/pdMember/page", queryForm);
}
//会员商家表列表展示
export const pdmemberList = (queryForm) => {
    return request("get", "/pdworld/pdMember/list", queryForm);
}
//会员商家表详情
export const pdmemberInfo = (id) => {
    return request('get', '/pdworld/pdMember/info', {
        id: id
    })
}
//会员商家表信息保存
export const pdmemberSave = (data) => {
    return request('post', '/pdworld/pdMember/save', data)
}
//充值付费会员
export const memberRecharge = (data) => {
    return request('post', '/pdworld/pdMember/recharge', data)
}
//会员商家表信息保存
export const pdmemberShopSave = (data) => {
    return request('post', '/pdworld/pdMember/shopSave', data)
}
//会员商家表信息修改
export const pdmemberEdit = (data) => {
    return request('post', '/pdworld/pdMember/edit', data)
}
//会员商家表信息删除
export const pdmemberDel = (id) => {
    return request('post', '/pdworld/pdMember/delete?id=' + id)
}
//会员商家表批量删除
export const pdmemberDelBatch = (ids) => {
    return request('post', '/pdworld/pdMember/deleteBatch', ids)
}
//会员商家表动态表头导出
export const pdmemberDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdMember/exportDynamic', data)
}
