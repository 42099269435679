<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="会员ID" :label-width="formLabelWidth" prop="memberId">
                <fortress-select
                        v-model="form.memberId"
                        :dictList="memberIdList"
                        placeholder="请选择会员ID"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="商家头像" :label-width="formLabelWidth" prop="shopAvatar">
                <fortress-upload
                  type="image"
                  name="shopAvatar"
                  :formData="form"
                ></fortress-upload>
            </el-form-item>
            <el-form-item label="公司名称" :label-width="formLabelWidth" prop="enterpriceName">
                <el-input
                        v-model="form.enterpriceName"
                        autocomplete="off"
                        placeholder="请输入公司名称"
                ></el-input>
            </el-form-item>
            <el-form-item label="公司简介" :label-width="formLabelWidth" prop="shopInfo">
                <el-input
                        v-model="form.shopInfo"
                        autocomplete="off"
                        placeholder="请输入公司简介"
                ></el-input>
            </el-form-item>
            <el-form-item label="诚信档案(营业执照)" :label-width="formLabelWidth" prop="shopNo">
                <fortress-upload
                  type="image"
                  name="shopNo"
                  :formData="form"
                ></fortress-upload>
            </el-form-item>
            <el-form-item label="公司负责人" :label-width="formLabelWidth" prop="corpn">
                <el-input
                        v-model="form.corpn"
                        autocomplete="off"
                        placeholder="请输入公司负责人"
                ></el-input>
            </el-form-item>
            <el-form-item label="公司地址" :label-width="formLabelWidth" prop="address">
                <el-input
                        v-model="form.address"
                        autocomplete="off"
                        placeholder="请输入公司地址"
                ></el-input>
            </el-form-item>
            <el-form-item label="服务监督电话" :label-width="formLabelWidth" prop="tel">
                <el-input
                        v-model="form.tel"
                        autocomplete="off"
                        placeholder="请输入公司联系电话"
                ></el-input>
            </el-form-item>
            <el-form-item label="公司网址" :label-width="formLabelWidth" prop="wesite">
                <el-input
                        v-model="form.wesite"
                        autocomplete="off"
                        placeholder="请输入公司网址"
                ></el-input>
            </el-form-item>
<!--            <el-form-item label="服务监督电话" :label-width="formLabelWidth" prop="superviseTel">-->
<!--                <el-input-->
<!--                        v-model="form.superviseTel"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="请输入服务监督电话"-->
<!--                ></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="域名后缀(访问商家首页的唯一标识)" :label-width="formLabelWidth" prop="shopurl">
                <el-input
                        v-model="form.shopurl"
                        autocomplete="off"
                        placeholder="请输入域名后缀(访问商家首页的唯一标识)"
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { pdshopinfoInfo, pdshopinfoSave, pdshopinfoEdit } from "@/api/pdworld/pdshopinfo.js";
    import { urlList, } from "@/api/public.api.js";

    export default {
        data() {
            return {
                visible: false,
                form: {
                    memberId:"",
                    shopAvatar:"",
                    enterpriceName:"",
                    shopNo:"",
                    shopInfo:"",
                    corpn:"",
                    address:"",
                    tel:"",
                    wesite:"",
                    superviseTel:"",
                    shopurl:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
                memberIdList:[],
            };
        },
        created() {
        },
        methods: {
            queryUrlList(){
                this.memberIdList = [];
                urlList("/pdworld/pdMember/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.memberIdList.push({ code: item.id, name: item.nickName });
                      });
                    }
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryUrlList();
                //修改
                if (id) {
                    pdshopinfoInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        pdshopinfoEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        pdshopinfoSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    memberId:"",
                    shopAvatar:"",
                    enterpriceName:"",
                    shopInfo:"",
                    shopNo:"",
                    corpn:"",
                    address:"",
                    tel:"",
                    wesite:"",
                    superviseTel:"",
                    shopurl:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
