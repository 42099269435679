import request from "@/common/request"

//会员收藏商家分页展示
export const membercollectPage = (queryForm) => {
    return request("get", "/pdworld/memberCollect/page", queryForm);
}
//会员收藏商家列表展示
export const membercollectList = (queryForm) => {
    return request("get", "/pdworld/memberCollect/list", queryForm);
}
//会员收藏商家详情
export const membercollectInfo = (id) => {
    return request('get', '/pdworld/memberCollect/info', {
        id: id
    })
}
//会员收藏商家信息保存
export const membercollectSave = (data) => {
    return request('post', '/pdworld/memberCollect/save', data)
}
//会员收藏商家信息修改
export const membercollectEdit = (data) => {
    return request('post', '/pdworld/memberCollect/edit', data)
}
//会员收藏商家信息删除
export const membercollectDel = (id) => {
    return request('post', '/pdworld/memberCollect/delete?id=' + id)
}
//会员收藏商家批量删除
export const membercollectDelBatch = (ids) => {
    return request('post', '/pdworld/memberCollect/deleteBatch', ids)
}
//会员收藏商家动态表头导出
export const membercollectDynamicExport = (data) => {
    return request("eptpost", '/pdworld/memberCollect/exportDynamic', data)
}
