<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    custom-class="dialog-edit"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="是否超管" :label-width="formLabelWidth" prop="isAdmin">
        <el-radio-group v-model="form.isAdmin">
          <el-radio :label="2">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否启用" :label-width="formLabelWidth" prop="status">
        <el-switch
          v-model="form.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
        <span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66">启用</span>
        <span v-if="form.status == 2" style="margin-left: 17px; color: #ff4949">禁用</span>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth">
        <el-input v-model="form.remark" autocomplete="off" type="textarea" :rows="2"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')" size="medium">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { roleInfo, roleSave, roleEdit } from "@/api/auth/sysrole.js"
export default {
  data () {
    return {
      visible: false,
      form: {
        id: "",
        name: "",
        isAdmin: "",
        status: 1,
        remark: "",
      },
      formLabelWidth: "120px",
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        isAdmin: [{ required: true, message: "请选择是否超管", trigger: "blur" }],
        status: [{ required: true, message: "请选择启用状态", trigger: "blur" }],
      },
    }
  },
  created () { },
  methods: {
    init (id) {
      this.resetForm()
      //修改
      if (id) {
        roleInfo(id).then(resp => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              }
              if (this.form.parentId) {
                this.findParentCode(this.form.parentId, this.allDept)
              }
            })
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            })
          }
        })
        //新增
      }
      this.visible = true
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.visible = false
          //修改
          if (this.form.id) {
            roleEdit(this.form).then(resp => {
              if (resp.code == 0) {
                this.$notify({
                  title: "修改成功",
                  type: "success",
                  message: "您已成功修改一个角色!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
            //新增
          } else {
            roleSave(this.form).then(resp => {
              if (resp.code == 0) {
                this.$notify({
                  title: "添加成功",
                  type: "success",
                  message: "您已成功添加一个角色!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.form = {
        id: "",
        name: "",
        isAdmin: "",
        status: 1,
        remark: "",
      }
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields()
        }
      })
    },
  },
}
</script>
<style scoped></style>
