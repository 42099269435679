<template>
	<el-dialog :visible.sync="visible" title="分站推荐商家" width="60vw">
		<div class="padding20">
			<el-row style="margin-bottom: 7px">
				<el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
					<el-form-item>
						<fortress-select
							v-model="queryForm.position"
							:dictList="SiteRecommendList"
							placeholder="请选择推荐位置"
							clearable
							filterable
						></fortress-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({ current: 1 })">查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
					</el-form-item>
				</el-form>
				<el-button
					type="primary"
					size="medium"
					icon="el-icon-plus"
					v-if="$tools.hasPermission('pdworld:subSiteRecommend:save')"
					@click="handleEdit()"
				>
					添加
				</el-button>
				<div style="float: right; padding-right: 17px">
					<i class="el-icon-download" style="margin-right: 10px; cursor: pointer" @click="handleExport"></i>
					<i class="el-icon-refresh" style="margin-right: 10px; cursor: pointer" @click="refresh"></i>

					<el-popover placement="bottom" width="180" trigger="click" title="列展示" popper-class="columnShows">
						<el-checkbox-group v-model="tableShowList">
							<el-checkbox v-for="item in tableList" :label="item.propName" :key="item.propName" :value="item.propName">
								{{ item.propDesc }}
							</el-checkbox>
						</el-checkbox-group>
						<i slot="reference" class="el-icon-s-operation" style="cursor: pointer"></i>
					</el-popover>
				</div>
			</el-row>
			<el-table ref="multipleTable" :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border>
				<el-table-column
					v-if="$tools.isShow('memberId', tableShowList)"
					prop="enterpriseName"
					label="商家"
					:show-overflow-tooltip="true"
				></el-table-column>
				<el-table-column
					v-if="$tools.isShow('position', tableShowList)"
					prop="position"
					label="推荐位置"
					:show-overflow-tooltip="true"
					:formatter="positionFmt"
				></el-table-column>
				<el-table-column
					v-if="$tools.isShow('sort', tableShowList)"
					prop="sort"
					label="排序权重/倒序"
					:show-overflow-tooltip="true"
				></el-table-column>
				<el-table-column label="操作" fixed="right" min-width="80">
					<template slot-scope="scope">
            <!--						<el-button-->
            <!--							v-if="$tools.hasPermission('pdworld:subSiteRecommend:info')"-->
            <!--							size="mini"-->
            <!--							plain-->
            <!--							@click="handleInfo(scope.row.id)"-->
            <!--						>-->
            <!--							详情-->
            <!--						</el-button>-->
            <el-button
                v-if="$tools.hasPermission('pdworld:subSiteRecommend:edit')"
                size="mini"
                plain
                @click="handleEdit(scope.row.id)"
            >
              编辑
            </el-button>
            <el-button
                v-if="$tools.hasPermission('pdworld:subSiteRecommend:delete')"
                size="mini"
                plain
                type="danger"
                @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
				</el-table-column>
			</el-table>
			<pagination :total="page.total" :current-page="page.current" :page-size="page.size" @refreshData="queryList"></pagination>
			<!-- 新增、编辑 -->
			<form-edit v-if="formEditVisible" ref="formEdit" @refreshData="queryList" :row="row"></form-edit>
			<!-- 详情 -->
			<form-info v-if="formInfoVisible" ref="formInfo"></form-info>
		</div>
	</el-dialog>
</template>
<script>
import {
	subsiterecommendPage,
	subsiterecommendDel,
	subsiterecommendDelBatch,
	subsiterecommendDynamicExport,
} from "@/api/pdworld/subsiterecommend.js"
import { viewTableList } from "@/api/public.api.js"
import FormEdit from "./subsiterecommend-edit"
import FormInfo from "./subsiterecommend-info"
import FortressPagination from "@/components/fortress-pagination.vue"
import { enumList } from "@/api/public.api.js"

// const url = ref('')
// const f  = new FileReader()
// f.readAsDataURL(blob)
// f.onload = function(e) {
// 	url.value = e.target.result
// }

export default {
	data() {
		return {
			tableData: [],
			formEditVisible: false,
			formInfoVisible: false,
			queryForm: {
				siteId: "",
				siteCityCode: "",
				position: "",
			},
			page: {
				current: 1,
				size: 10,
				total: 0,
			},
			collapse: {
				icon: "el-icon-arrow-down el-icon--right",
				label: "展开",
				flag: false,
			},
			tableList: [],
			tableShowList: [],
			SiteRecommendList: [],

			visible: false,
			row: {},
		}
	},
	components: {
		"form-edit": FormEdit,
		"form-info": FormInfo,
		pagination: FortressPagination,
	},
	created() {
		this.queryEnumList()
		this.initTableList()
		this.queryList()
	},
	methods: {
		show(row) {
			this.resetQueryForm()
			this.row = row
			this.queryForm.siteId = row.id
			this.queryForm.siteCityCode = row.siteCityCode
			this.visible = true
			this.refresh()
		},

		queryEnumList() {
			this.SiteRecommendList = []
			enumList("SiteRecommend", "").then(resp => {
				if (resp.code == 0) {
					this.SiteRecommendList = resp.data
				}
			})
		},
		refresh() {
			this.tableData = []
			this.queryList({ current: 1 })
		},
		//查询表格数据
		queryList(page) {
			if (page) {
				this.page.current = page.current ? page.current : this.page.current
				this.page.size = page.size ? page.size : this.page.size
			}
			this.queryForm.current = this.page.current
			this.queryForm.size = this.page.size
			subsiterecommendPage(this.queryForm).then(resp => {
				if (resp.code == 0) {
					this.tableData = resp.data.records
					this.page.total = resp.data.total
					//查询页大于总页数，重新查询
					let cu =
						this.page.total % this.page.size == 0
							? parseInt(this.page.total / this.page.size)
							: parseInt(this.page.total / this.page.size + 1)
					if (cu > 0 && this.page.current > cu) {
						this.queryList({ current: cu })
					}
				} else {
					this.$msg({
						message: resp.msg,
						type: "error",
					})
				}
			})
		},
		//打开编辑窗口
		handleEdit(id) {
			this.formEditVisible = true
			this.$nextTick(() => {
				this.$refs.formEdit.init(id)
			})
		},
		//打开详情窗口
		handleInfo(id) {
			this.formInfoVisible = true
			this.$nextTick(() => {
				this.$refs.formInfo.init(id)
			})
		},
		//删除
		handleDelete(row) {
			this.$confirm("确认删除该记录, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					subsiterecommendDel(row.id).then(resp => {
						if (resp.code == 0) {
							this.$message({
								type: "success",
								message: "操作成功!",
							})
							//判断是否最后一页的最后一条记录
							let totalPages =
								(this.page.total - 1) % this.page.size == 0
									? (this.page.total - 1) / this.page.size
									: (this.page.total - 1) / this.page.size + 1
							if (this.page.current > totalPages && this.page.current != 1) {
								this.page.current = this.page.current - 1
							}
							this.queryList()
						} else {
							this.$msg({
								message: resp.msg,
								type: "error",
							})
						}
					})
				})
				.catch(() => {})
		},
		//批量删除
		handleDeleteBatch() {
			let checkedRow = this.$refs.multipleTable.selection
			if (checkedRow.length > 0) {
				let ids = []
				checkedRow.forEach(item => {
					ids.push(item.id)
				})
				this.$confirm("确认删除该记录, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						subsiterecommendDelBatch(ids).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								//判断是否最后一页的最后一条记录
								let totalPages =
									(this.page.total - checkedRow.length) % this.page.size == 0
										? (this.page.total - checkedRow.length) / this.page.size
										: (this.page.total - checkedRow.length) / this.page.size + 1
								if (this.page.current > totalPages && this.page.current != 1) {
									this.page.current = this.page.current - 1
								}
								this.queryList()
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					})
					.catch(() => {})
			} else {
				this.$msg({
					message: "请选择数据进行删除",
					type: "error",
				})
			}
		},
		//重置查询框
		resetQueryForm() {
			this.queryForm = {
				siteId: "",
				siteCityCode: "",
				position: "",
			}
		},
		//切换查询条件收缩框
		handleCollapseState() {
			this.collapse.flag = !this.collapse.flag
			if (this.collapse.flag) {
				this.collapse.icon = "el-icon-arrow-up el-icon--right"
				this.collapse.label = "收起"
			} else {
				this.collapse.icon = "el-icon-arrow-down el-icon--right"
				this.collapse.label = "展开"
			}
		},
		//视图表格列表初始化
		initTableList() {
			viewTableList("pd_sub_site_recommend").then(resp => {
				if (resp.code == 0) {
					this.tableList = resp.data
					this.tableList.forEach(item => {
						this.tableShowList.push(item.propName)
					})
				}
			})
		},
		//推荐位置(首页推荐/搜索页推荐)格式化返回
		positionFmt(row) {
			let name = ""
			if (this.SiteRecommendList) {
				this.SiteRecommendList.forEach(item => {
					if (item.code == row.position) {
						name = item.name
					}
				})
			}
			return name
		},
		//可见字段excel导出
		handleExport() {
			if (!this.tableShowList || this.tableShowList.length < 1) {
				this.$msg({
					message: "请选择要导出的列",
					type: "error",
				})
				return
			}
			let colNum = 1
			let headerList = []
			this.tableShowList.forEach(item => {
				let nameData = this.tableList.find(item2 => {
					return item2.propName == item
				})
				let name = ""
				if (nameData && nameData.propDesc) {
					name = nameData.propDesc
				} else {
					name = "未定义字段" + colNum++
				}
				headerList.push({ name: name, code: item })
			})
			subsiterecommendDynamicExport({ headerList: headerList, dto: this.queryForm })
		},
	},
}
</script>
<style scoped></style>
