<template>
	<div class="padding20">
		<el-row style="margin-bottom: 7px">
			<el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
				<el-form-item>
					<el-input
						prefix-icon="el-icon-search"
						v-model="queryForm.code"
						autocomplete="off"
						placeholder="请输入场景编码"
						clearable
					></el-input>
				</el-form-item>

				<el-form-item>
					<el-input
						prefix-icon="el-icon-search"
						v-model="queryForm.name"
						autocomplete="off"
						placeholder="请输入场景名称"
						clearable
					></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({ current: 1 })">查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
				</el-form-item>
			</el-form>
			<el-button
				type="primary"
				size="medium"
				icon="el-icon-plus"
				v-if="$tools.hasPermission('pay:payScene:save')"
				@click="handleEdit()"
			>
				添加
			</el-button>
			<div style="float: right; padding-right: 17px">
				<i class="el-icon-download" style="margin-right: 10px; cursor: pointer" @click="handleExport"></i>
				<i class="el-icon-refresh" style="margin-right: 10px; cursor: pointer" @click="refresh"></i>

				<el-popover placement="bottom" width="180" trigger="click" title="列展示" popper-class="columnShows">
					<el-checkbox-group v-model="tableShowList">
						<el-checkbox v-for="item in tableList" :label="item.propName" :key="item.propName" :value="item.propName">
							{{ item.propDesc }}
						</el-checkbox>
					</el-checkbox-group>
					<i slot="reference" class="el-icon-s-operation" style="cursor: pointer"></i>
				</el-popover>
			</div>
		</el-row>
		<el-table ref="multipleTable" :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border>
			<el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>

			<el-table-column
				v-if="$tools.isShow('code', tableShowList)"
				prop="code"
				label="场景编码"
				:show-overflow-tooltip="true"
			></el-table-column>
			<el-table-column
				v-if="$tools.isShow('name', tableShowList)"
				prop="name"
				label="场景名称"
				:show-overflow-tooltip="true"
			></el-table-column>
			<el-table-column
				v-if="$tools.isShow('remark', tableShowList)"
				prop="remark"
				label="备注"
				:show-overflow-tooltip="true"
			></el-table-column>
			<el-table-column label="支付配置" prop="pay" v-if="$tools.hasPermission('pay:payScene:configEdit')">
				<template #default="{row}">
					<el-button type="text" size="mini" @click="$router.push('/pay/payscene-setting?type=ali&code=' + row.code)">
						支付宝支付配置
						<i class="el-icon-edit-outline"></i>
					</el-button>
					<el-button type="text" size="mini" @click="$router.push('/pay/payscene-setting?type=wx&code=' + row.code)">
						微信支付配置
						<i class="el-icon-edit-outline"></i>
					</el-button>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" min-width="180">
				<template slot-scope="scope">
					<el-button
						v-if="$tools.hasPermission('pay:payScene:configEdit')"
						type="primary"
						icon="el-icon-s-operation"
						size="mini"
						@click="openConfigSetting(scope.row)"
					>
						启用配置
					</el-button>
					<el-button v-if="$tools.hasPermission('pay:payScene:edit')" size="mini" plain @click="handleEdit(scope.row.id)">
						编辑
					</el-button>
					<el-button
						v-if="$tools.hasPermission('pay:payScene:delete')"
						size="mini"
						plain
						type="danger"
						@click="handleDelete(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<pagination :total="page.total" :current-page="page.current" :page-size="page.size" @refreshData="queryList"></pagination>
		<!-- 新增、编辑 -->
		<form-edit v-if="formEditVisible" ref="formEdit" @refreshData="queryList"></form-edit>
		<!-- 详情 -->
		<form-info v-if="formInfoVisible" ref="formInfo"></form-info>

		<on-setting ref="onSetting"></on-setting>
	</div>
</template>
<script>
import { payscenePage, paysceneDel, paysceneDynamicExport } from "@/api/pay/payscene.js"
import { viewTableList } from "@/api/public.api.js"
import FormEdit from "./payscene-edit"
import FormInfo from "./payscene-info"
import FortressPagination from "@/components/fortress-pagination.vue"

import onSetting from "./components/on-setting.vue"

export default {
	data() {
		return {
			tableData: [],
			formEditVisible: false,
			formInfoVisible: false,
			queryForm: {
				code: "",
				name: "",
			},
			page: {
				current: 1,
				size: 10,
				total: 0,
			},
			collapse: {
				icon: "el-icon-arrow-down el-icon--right",
				label: "展开",
				flag: false,
			},
			tableList: [],
			tableShowList: [],
		}
	},
	components: {
		"form-edit": FormEdit,
		"form-info": FormInfo,
		pagination: FortressPagination,
		onSetting,
	},
	created() {
		this.initTableList()
		this.queryList()
	},
	methods: {
		openConfigSetting(row) {
			this.$refs.onSetting.show(row)
		},

		refresh() {
			this.tableData = []
			this.queryList({ current: 1 })
		},
		//查询表格数据
		queryList(page) {
			if (page) {
				this.page.current = page.current ? page.current : this.page.current
				this.page.size = page.size ? page.size : this.page.size
			}
			this.queryForm.current = this.page.current
			this.queryForm.size = this.page.size
			payscenePage(this.queryForm).then(resp => {
				if (resp.code == 0) {
					this.tableData = resp.data.records
					this.page.total = resp.data.total
					//查询页大于总页数，重新查询
					let cu =
						this.page.total % this.page.size == 0
							? parseInt(this.page.total / this.page.size)
							: parseInt(this.page.total / this.page.size + 1)
					if (cu > 0 && this.page.current > cu) {
						this.queryList({ current: cu })
					}
				} else {
					this.$msg({
						message: resp.msg,
						type: "error",
					})
				}
			})
		},
		//打开编辑窗口
		handleEdit(id) {
			this.formEditVisible = true
			this.$nextTick(() => {
				this.$refs.formEdit.init(id)
			})
		},
		//打开详情窗口
		handleInfo(id) {
			this.formInfoVisible = true
			this.$nextTick(() => {
				this.$refs.formInfo.init(id)
			})
		},
		//删除
		handleDelete(row) {
			this.$confirm("确认删除该记录, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					paysceneDel(row.id).then(resp => {
						if (resp.code == 0) {
							this.$message({
								type: "success",
								message: "操作成功!",
							})
							//判断是否最后一页的最后一条记录
							let totalPages =
								(this.page.total - 1) % this.page.size == 0
									? (this.page.total - 1) / this.page.size
									: (this.page.total - 1) / this.page.size + 1
							if (this.page.current > totalPages && this.page.current != 1) {
								this.page.current = this.page.current - 1
							}
							this.queryList()
						} else {
							this.$msg({
								message: resp.msg,
								type: "error",
							})
						}
					})
				})
				.catch(() => {})
		},

		//重置查询框
		resetQueryForm() {
			this.queryForm = {
				code: "",
				name: "",
			}
		},
		//切换查询条件收缩框
		handleCollapseState() {
			this.collapse.flag = !this.collapse.flag
			if (this.collapse.flag) {
				this.collapse.icon = "el-icon-arrow-up el-icon--right"
				this.collapse.label = "收起"
			} else {
				this.collapse.icon = "el-icon-arrow-down el-icon--right"
				this.collapse.label = "展开"
			}
		},
		//视图表格列表初始化
		initTableList() {
			viewTableList("pay_scene").then(resp => {
				if (resp.code == 0) {
					this.tableList = resp.data
					this.tableList.forEach(item => {
						this.tableShowList.push(item.propName)
					})
				}
			})
		},
		//可见字段excel导出
		handleExport() {
			if (!this.tableShowList || this.tableShowList.length < 1) {
				this.$msg({
					message: "请选择要导出的列",
					type: "error",
				})
				return
			}
			let colNum = 1
			let headerList = []
			this.tableShowList.forEach(item => {
				let nameData = this.tableList.find(item2 => {
					return item2.propName == item
				})
				let name = ""
				if (nameData && nameData.propDesc) {
					name = nameData.propDesc
				} else {
					name = "未定义字段" + colNum++
				}
				headerList.push({ name: name, code: item })
			})
			paysceneDynamicExport({ headerList: headerList, dto: this.queryForm })
		},
	},
}
</script>
<style scoped></style>
