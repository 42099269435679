/* eslint-disable vue/return-in-computed-property */
<!--  -->
<template>
  <div>
    <div class="inner">
      <p class="tit">包含字符</p>
      <div class="checkboxs margin10">
        <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
          <el-checkbox label="0-9"></el-checkbox>
          <el-checkbox label="a-z"></el-checkbox>
          <el-checkbox label="A-Z"></el-checkbox>
          <el-checkbox label="~!@#$%^&*?_-">
            <el-input v-model="other" placeholder @input="addOther" :disabled="disabledHandle"></el-input>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <p class="tit">密码长度</p>
      <div class="pwd-progress">
        <div class="line margin10">
          <!-- :percent="percent"-->
          <c-progress class="c-progress" @percentChange="onPercentChange" />
        </div>
      </div>
      <p class="tit">生成的随机密码</p>
      <div class="code margin10">
        <el-input v-model="randomPasswordData" placeholder>
          <i
            class="el-icon-refresh-left el-input__icon"
            slot="suffix"
            style="color:#0cafcc"
            @click="randomPassword(length)"
          ></i>
        </el-input>
      </div>
      <div class="bottom">
        <div class="btn" @click="randomPassword(length)">
          生成密码
          <i class="el-icon-s-promotion"></i>
        </div>
        <div
          class="btn copyBtn"
          :data-clipboard-text="randomPasswordData"
          @click="copy(randomPasswordData)"
        >
          复制密码
          <i class="el-icon-copy-document"></i>
        </div>
        <div
          class="btn copyBtn"
          :data-clipboard-text="randomPasswordData"
          @click="sure(randomPasswordData)"
        >
          确定
          <i class="el-icon-s-check"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard"
import cProgress from "./progress/index"
export default {
  props: {
    theme: {
      typeof: String,
      color: "#0cafcc"
    }
  },
  name: "random",
  data () {
    return {
      other: "~!@#$%^&*?_-",
      percent: 0,
      input: "",
      checkList: ['0-9', 'a-z', 'A-Z', '~!@#$%^&*?_-'],
      randomPasswordData: "",
      obj: {
        '0-9': "1234567890",
        'a-z': 'abcdefghijklmnopqrstuvwxyz',
        'A-Z': "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        '~!@#$%^&*?_-': '~!@#$%^&*?_-'
      },
      passwordArray: [],
      checkedArry: [],
      length: 0
    };
  },
  created () {
    this.passwordArray = this.checkList
  },
  computed: {
    // cssVars () {
    //   return {
    //     "--color": this.color
    //   }
    // },
    // eslint-disable-next-line vue/return-in-computed-property
    disabledHandle () {
      if (this.checkList.length > 0) {
        if (this.checkList.indexOf('~!@#$%^&*?_-') != -1) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  },
  mounted () {
    this.clipBoard = new Clipboard(".copyBtn");
  },
  components: {
    cProgress
  },
  methods: {
    //生成随机密码
    randomPassword (length) {
      length = Number(length)
      // Limit length
      // if (length < 6) {
      //   length = 6
      // } else if (length > 16) {
      //   length = 16
      // }
      // this.passwordArray = [];
      var password = [];
      let n = 0;
      for (let i = 0; i < length; i++) {
        if (password.length < length) {
          let arrayRandom = Math.floor(Math.random() * this.passwordArray.length);
          let passwordItem = this.passwordArray[arrayRandom];
          if (passwordItem) {
            let item = passwordItem[Math.floor(Math.random() * passwordItem.length)];
            password.push(item);
          }

        } else {
          let newItem = this.passwordArray[n];
          if (newItem) {
            let lastItem = newItem[Math.floor(Math.random() * newItem.length)];
            let spliceIndex = Math.floor(Math.random() * password.length);
            password.splice(spliceIndex, 0, lastItem);
            n++
          }

        }
      }
      this.randomPasswordData = password.join("");
      console.log(this.randomPasswordData.length);
    },
    handleCheckedCitiesChange (value) {
      if (value) {
        this.checkedArry = value
      }
      this.passwordArray = []
      this.checkedArry.forEach(item => {
        Object.keys(this.obj).forEach(items => {
          if (item == items) {
            this.passwordArray.push(this.obj[item])
          }
        })
      })
    },
    onPercentChange (per) {
      this.length = per

    },
    copy () {
      this.$message({
        type: "success",
        message: "复制成功!",
      });
    },
    sure (data) {
      this.$emit('click', data)
    },
    addOther (e) {
      this.other = e
      this.$nextTick(() => {
        this.obj['~!@#$%^&*?_-'] = this.other || ''
        this.handleCheckedCitiesChange()
      })
    }
  }
}

</script>
<style lang='scss' scoped >
.inner {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
  .tit {
    margin-bottom: 10px;
  }
  .margin10 {
    margin-bottom: 10px;
  }
  .checkboxs {
    /deep/ .el-checkbox__input {
      height: 14px;
      &:hover {
        box-shadow: 0px 0px 2px 5px rgba(169, 232, 248, 0.7);
      }
    }
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #0cafcc !important;
      border-color: #0cafcc !important;
    }
    /deep/ .el-input {
      width: 202px;
    }
    /deep/ .el-input__inner {
      background: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #0cafcc;
    }
    /deep/ .el-input.is-disabled .el-input__inner {
      // border: none;
      // border-radius: 0;
      border-bottom: 1px dotted #999;
    }
  }
}
/deep/ .el-checkbox {
  // height: 30px;
}
/deep/ .el-checkbox__label span {
  border-bottom: 1px solid #999;
}
.pwd-progress {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  .line {
    width: 100%;
  }
}
.code {
  /deep/ .el-input__inner {
    height: 40px;
    &:focus {
      border-color: #0cafcc;
    }
  }
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    margin: 0 15px;
    font-size: 14px;
    color: #fff;
    background: #0cafcc;
  }
}
</style>