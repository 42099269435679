<template>
  <div>
    <el-row :gutter="20">
      <el-col :lg="8" :xl="8" style="margin-bottom: 17px">
        <div class="padding10 tree" :style="{ height: treeHeight + 'px' }">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText"
            clearable
            style="margin-bottom: 10px"
          ></el-input>
          <el-tree
            class="filter-tree"
            :data="deptData"
            :props="defaultProps"
            default-expand-all
            :filter-node-method="filterNode"
            highlight-current
            ref="tree"
            @node-click="chooseDept"
          ></el-tree>
        </div>
      </el-col>
      <el-col :lg="16" :xl="16">
        <div class="padding10">
          <el-form :inline="true" :model="queryForm" class="el-common-from">
            <el-form-item>
              <el-input
                v-model="queryForm.loginName"
                autocomplete="off"
                placeholder="账号"
              ></el-input>
            </el-form-item>
            <br class="min" />
            <el-form-item>
              <el-input
                v-model="queryForm.realName"
                autocomplete="off"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <br class="min" />
            <el-form-item v-show="collapse.flag">
              <el-input
                v-model="queryForm.phone"
                autocomplete="off"
                placeholder="手机号"
              ></el-input>
            </el-form-item>
            <br class="min" />
            <el-button
              type="primary"
              @click="queryList({ current: 1 })"
              size="medium"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button
              type="primary"
              @click="resetQueryForm"
              size="medium"
              icon="el-icon-refresh-left"
              >重置</el-button
            >
            <el-button type="text" @click="handleCollapseState">
              {{ collapse.label }}
              <i :class="collapse.icon"></i>
            </el-button>
          </el-form>
          <el-button
            type="primary"
            v-if="$tools.hasPermission('system:sysUser:save')"
            @click="handleEdit()"
            style="margin: 10px 0 17px"
            size="medium"
            icon="el-icon-plus"
            >添加</el-button
          >
          <div style="float: right; padding-right: 17px">
            <i
              class="el-icon-refresh"
              style="margin-right: 10px; cursor: pointer"
              @click="refresh"
            ></i>

            <el-popover
              placement="bottom"
              trigger="click"
              title="列展示"
              popper-class="columnShows"
            >
              <el-checkbox-group v-model="tableShowList">
                <el-checkbox
                  v-for="item in tableList"
                  :label="item.propName"
                  :key="item.propName"
                  :value="item.propName"
                  >{{ item.propDesc }}</el-checkbox
                >
              </el-checkbox-group>
              <i
                slot="reference"
                class="el-icon-s-operation"
                style="cursor: pointer"
              ></i>
            </el-popover>
          </div>
          <!-- loading -->
          <loading :show="parentShow">
            <el-table
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              border
            >
              <el-table-column
                type="selection"
                width="55"
                show-overflow-tooltip
              ></el-table-column>
              <!-- <el-table-column
              v-if="$tools.isShow('id', tableShowList)"
              prop="id"
              label="主键ID"
              :show-overflow-tooltip="true"
              ></el-table-column>-->
              <el-table-column
                v-if="$tools.isShow('loginName', tableShowList)"
                prop="loginName"
                label="账号"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
              width="100px"
                v-if="$tools.isShow('avatar', tableShowList)"
                label="头像"
                :show-overflow-tooltip="true"
              >
              <template slot-scope="scope">
                 <el-image
                v-if="scope.row.avatar"
                :src="scope.row.avatar"
                style="width: 100px;height:100px"
                :preview-src-list="[scope.row.avatar]"
                fit="cover"
              />
              </template>

              </el-table-column>
              <el-table-column
                v-if="$tools.isShow('nickname', tableShowList)"
                prop="nickname"
                label="昵称"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                v-if="$tools.isShow('realName', tableShowList)"
                prop="realName"
                label="实名"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <!-- <el-table-column
              v-if="$tools.isShow('password', tableShowList)"
              prop="password"
              label="密码"
              :show-overflow-tooltip="true"
              ></el-table-column>-->
              <el-table-column
                v-if="$tools.isShow('email', tableShowList)"
                prop="email"
                label="邮件"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                v-if="$tools.isShow('phone', tableShowList)"
                prop="phone"
                label="电话"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
              width="50"
                v-if="$tools.isShow('gender', tableShowList)"
                label="性别"
                :show-overflow-tooltip="true"
              >
                <template slot-scope='scope'>
                  <span v-if='scope.row.gender=="m"'>男</span>
                  <span v-if='scope.row.gender=="f"'>女</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="$tools.isShow('birthday', tableShowList)"
                prop="birthday"
                label="生日"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <!-- <el-table-column
              v-if="$tools.isShow('avatar', tableShowList)"
              prop="avatar"
              label="头像"
              :show-overflow-tooltip="true"
              ></el-table-column>-->
              <el-table-column
                v-if="$tools.isShow('idcardType', tableShowList)"
                prop="idcardType"
                label="证件类型"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                v-if="$tools.isShow('idcard', tableShowList)"
                prop="idcard"
                label="证件号码"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                v-if="$tools.isShow('remark', tableShowList)"
                prop="remark"
                label="备注"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                v-if="$tools.isShow('deptName', tableShowList)"
                prop="deptName"
                label="部门"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                v-if="$tools.isShow('postName', tableShowList)"
                prop="postName"
                label="职位"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                v-if="$tools.isShow('status', tableShowList)"
                prop="status"
                label="状态"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <el-tag
                    type="success"
                    size="mini"
                    v-if="scope.row.status == 1"
                    >已启用</el-tag
                  >
                  <el-tag type="danger" size="mini" v-else>未启用</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                v-if="$tools.isShow('createTime', tableShowList)"
                prop="createTime"
                label="创建时间"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column label="操作" fixed="right" min-width="180">
                <template slot-scope="scope">
                  <el-button
                    v-if="$tools.hasPermission('system:sysUser:info')"
                    size="mini"
                    @click="handleInfo(scope.row.id)"
                    plain
                    >详情</el-button
                  >
                  <el-button
                    v-if="
                      $tools.hasPermission('system:sysUser:edit') &&
                      scope.row.deptId != '-1'
                    "
                    size="mini"
                    @click="handleEdit(scope.row.id, scope.row)"
                    plain
                    >编辑</el-button
                  >
                  <el-button
                    v-if="
                      $tools.hasPermission('system:sysUser:edit') &&
                      scope.row.deptId != '-1'
                    "
                    size="mini"
                    @click="handleDetailEdit(scope.row.id, scope.row)"
                    plain
                    >编辑详情</el-button
                  >
                  <el-button
                    v-if="
                      $tools.hasPermission('system:sysUser:delete') &&
                      scope.row.deptId != '-1' &&
                      scope.row.id != JSON.parse($store.state.userInfo).id
                    "
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.row)"
                    plain
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </loading>
          <pagination
            :total="page.total"
            :current-page="page.current"
            :page-size="page.size"
            @refreshData="queryList"
          ></pagination>
          <!-- 新增、编辑 -->
          <form-edit
            v-if="formEditVisible"
            ref="formEdit"
            @refreshData="queryList"
          ></form-edit>
          <!-- 编辑详情 -->
          <form-detail-edit
            v-if="formDetailEditVisible"
            ref="formDetailEdit"
            @refreshData="queryList"
          ></form-detail-edit>
          <!-- 详情 -->
          <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { deptList } from "@/api/auth/sysdept.js";
import { userPage, userDel } from "@/api/auth/sysuser.js";
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./sysuser-edit";
import FormDetailEdit from "./sysuser-detail-edit";
import FormInfo from "./sysuser-info";
import FortressPagination from "@/components/fortress-pagination.vue";

export default {
  data() {
    return {
      treeHeight: 0,
      parentShow: "",
      filterText: "",
      tableData: [],
      deptData: [],
      formEditVisible: false,
      formDetailEditVisible: false,
      formInfoVisible: false,
      formPwdVisible: false,
      queryForm: {
        loginName: "",
        realName: "",
        phone: "",
        deptId: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    "form-detail-edit": FormDetailEdit,
    pagination: FortressPagination,
  },
  created() {
    this.tableData = [];
    this.queryList();
    this.treeHeight = window.innerHeight * 0.8;
    this.initTableList();
    this.queryDeptList();
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    //刷新
    refresh() {
      this.tableData = [];
      this.queryList({ current: 1 });
    },
    //查询部门数据
    queryDeptList() {
      deptList().then((resp) => {
        if (resp.code == 0) {
          this.deptData = resp.data;
          // console.log("deptData", this.deptData)
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //点选部门树
    chooseDept(data) {
      this.sleDeptId = data.id;
      this.queryLists(data.id);
    },
    //查询表格数据
    queryLists(deptId) {
      this.queryForm.deptId = deptId;
      this.queryList();
    },
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      userPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false;
            this.tableData = resp.data.records;
            this.page.total = Number(resp.data.total);
            //查询页大于总页数，重新查询
            let cu =
              this.page.total % this.page.size == 0
                ? parseInt(this.page.total / this.page.size)
                : parseInt(this.page.total / this.page.size + 1);
            if (cu > 0 && this.page.current > cu) {
              this.queryList({ current: cu });
            }
          }, 600);
        } else {
          this.parentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id, data) {
      if (data) {
        localStorage.setItem("userForm", data);
      }
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    handleDetailEdit(id, data) {
      if (data) {
        localStorage.setItem("userForm", data);
      }
      this.formDetailEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formDetailEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        userDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$notify({
              title: "删除成功",
              type: "warning",
              message: "您已成功删除一个用户!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
              (this.page.total - 1) % this.page.size == 0
                ? (this.page.total - 1) / this.page.size
                : (this.page.total - 1) / this.page.size + 1;
            if (this.page.current > totalPages && this.page.current != 1) {
              this.page.current = this.page.current - 1;
            }
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    //状态格式化返回
    statusFmt(row) {
      let typeName = "未启用";
      if (row.status == 1) {
        typeName = "已启用";
      }
      return typeName;
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        loginName: "",
        realName: "",
      };
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList() {
      viewTableList("castle_sys_user").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.padding10 {
  padding: 10px 20px;
  background-color: #fff;
  /* max-height: 600px; */
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}
.tree {
  height: 600px;
  overflow: auto;
}
</style>
