import request from "@/common/request"

//系统参数表分页展示
export const configparamsPage = (queryForm) => {
    return request("get", "/system/configParams/page", queryForm);
}
//系统参数表列表展示
export const configparamsList = (queryForm) => {
    return request("get", "/system/configParams/page", queryForm);
}
//系统参数表详情
export const configparamsInfo = (id) => {
    return request('get', '/system/configParams/info', {
        id: id
    })
}
//系统参数表信息保存
export const configparamsSave = (data) => {
    return request('post', '/system/configParams/save', data)
}
//系统参数表信息修改
export const configparamsEdit = (data) => {
    return request('post', '/system/configParams/edit', data)
}
//系统参数表信息删除
export const configparamsDel = (id) => {
    return request('post', '/system/configParams/delete?id=' + id)
}
