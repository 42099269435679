import request from "@/common/request"

//组件示例表分页展示
export const tmpdemoPage = (queryForm) => {
    return request("get", "/system/tmpDemo/page", queryForm);
}
//组件示例表列表展示
export const tmpdemoList = (queryForm) => {
    return request("get", "/system/tmpDemo/list", queryForm);
}
//组件示例表详情
export const tmpdemoInfo = (id) => {
    return request('get', '/system/tmpDemo/info', {
        id: id
    })
}
//组件示例表信息保存
export const tmpdemoSave = (data) => {
    return request('post', '/system/tmpDemo/save', data)
}
//组件示例表信息修改
export const tmpdemoEdit = (data) => {
    return request('post', '/system/tmpDemo/edit', data)
}
//组件示例表信息删除
export const tmpdemoDel = (id) => {
    return request('post', '/system/tmpDemo/delete?id=' + id)
}
//组件示例表批量删除
export const tmpdemoDelBatch = (ids) => {
    return request('post', '/system/tmpDemo/deleteBatch', ids)
}
//组件示例表动态表头导出
export const tmpdemoDynamicExport = (data) => {
    return request("eptpost", '/system/tmpDemo/exportDynamic', data)
}