<template>
  <div>
    <el-row class="el-row">
      <el-col :span="12" class="el-col">
        <el-row style="margin-bottom: 17px">
          <el-form :inline="true" :model="queryForm">
            <el-form-item>
              <el-input
                v-model="queryForm.dictValue"
                autocomplete="off"
                placeholder="字典名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="queryForm.code"
                autocomplete="off"
                placeholder="字典编码"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              size="medium"
              @click="queryList({ current: 1 })"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button
              type="primary"
              size="medium"
              @click="resetQueryForm"
              icon="el-icon-refresh-left"
              >重置</el-button
            >
          </el-form>

          <el-button
            type="primary"
            size="medium"
            v-if="$tools.hasPermission('system:sysDict:save')"
            @click="handleEdit()"
            style="margin-top: 10px"
            icon="el-icon-plus"
            >添加</el-button
          >
        </el-row>
        <loading :show="pparentShow">
          <div class="table">
            <el-table
              ref="singleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              border
              class="el-table"
            >
              <el-table-column
                type="selection"
                width="55"
                height="23px"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="code"
                label="编码"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="dictValue"
                label="名称"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column prop="status" label="状态" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tag
                    type="success"
                    size="mini"
                    v-if="scope.row.status == 1"
                    >已启用</el-tag
                  >
                  <el-tag type="danger" size="mini" v-else>未启用</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="300">
                <template slot-scope="scope">
                  <el-button
                    v-if="$tools.hasPermission('system:sysDict:info')"
                    size="mini"
                    @click="handleInfo(scope.row.id)"
                    >详情</el-button
                  >
                  <el-button
                    v-if="$tools.hasPermission('system:sysDict:edit')"
                    size="mini"
                    @click="handleEdit(scope.row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="$tools.hasPermission('system:sysDict:edit')"
                    size="mini"
                    @click="handleSubAdd(scope.row.id, scope.row.code)"
                    >添加子项</el-button
                  >
                  <el-button
                    v-if="$tools.hasPermission('system:sysDict:delete')"
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.row)"
                    plain
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </loading>
        <pagination
          :total="page.total"
          :current-page="page.current"
          :page-size="page.size"
          @refreshData="queryList"
        ></pagination>
      </el-col>

      <!-- 数据字典子集 -->
      <el-col :span="12" class="el-col">
        <div style="margin-left: 15px">
          <el-row style="margin-bottom: 17px">
            <el-form :inline="true" :model="subQueryForm">
              <el-form-item>
                <el-input
                  v-model="subQueryForm.dictValue"
                  autocomplete="off"
                  placeholder="字典名称"
                ></el-input>
              </el-form-item>
              <el-button
                type="primary"
                size="medium"
                @click="querySubList({ current: 1 })"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="medium"
                @click="resetSubQueryForm"
                icon="el-icon-refresh-left"
                >重置</el-button
              >
            </el-form>
            <el-button
              type="primary"
              size="medium"
              v-if="$tools.hasPermission('system:sysDict:save')"
              @click="handleSubEdit()"
              :disabled="!subPageVisible"
              title="选中字典后可添加子项"
              style="margin-top: 10px"
              icon="el-icon-plus"
              >添加</el-button
            >
          </el-row>
          <loading :show="sparentShow">
            <div class="table">
              <el-table
                :data="subTableData"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
              >
                <el-table-column
                  type="selection"
                  width="55"
                  height="23px"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="code"
                  label="编码"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="dictKey"
                  label="字典值"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="dictValue"
                  label="字典名称"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="sort"
                  label="排序"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="status"
                  label="状态"
                  show-overflow-tooltip
                  :formatter="statusFmt"
                ></el-table-column>
                <el-table-column label="操作" fixed="right" width="250">
                  <template slot-scope="scope">
                    <el-button
                      v-if="$tools.hasPermission('system:sysDict:info')"
                      size="mini"
                      @click="handleSubInfo(scope.row.id)"
                      >详情</el-button
                    >
                    <el-button
                      v-if="$tools.hasPermission('system:sysDict:edit')"
                      size="mini"
                      @click="handleSubEdit(scope.row.id)"
                      >编辑</el-button
                    >
                    <el-button
                      v-if="$tools.hasPermission('system:sysDict:delete')"
                      size="mini"
                      type="danger"
                      @click="handleSubDelete(scope.row)"
                      plain
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </loading>
          <pagination
            :total="subPage.total"
            :current-page="subPage.current"
            :page-size="subPage.size"
            @refreshData="querySubList"
          ></pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 新增、编辑 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>
    <form-info
      v-if="formInfoVisible"
      ref="formInfo"
      @refreshData="queryList"
    ></form-info>
    <!-- 字典子集 新增、编辑 -->
    <sub-form-edit
      v-if="subFormEditVisible"
      ref="subFormEdit"
      :parentId="subQueryForm.parentId"
      :code="subQueryForm.code"
      @refreshData="querySubList"
    ></sub-form-edit>
    <sub-form-info
      v-if="subFormInfoVisible"
      ref="subFormInfo"
      :parentId="subQueryForm.parentId"
      :code="subQueryForm.code"
      @refreshData="querySubList"
    ></sub-form-info>
  </div>
</template>
<script>
import {
  sysDictPage,
  sysDictDel,
  subSysDictPage,
} from "@/api/system/sysdict.js";
import FormEdit from "./sysdict-edit";
import FormInfo from "./sysdict-info";
import SubFormEdit from "./sysdict-sub-edit";
import SubFormInfo from "./sysdict-sub-info";
import FortressPagination from "@/components/fortress-pagination.vue";
export default {
  data() {
    return {
      pparentShow: "",
      sparentShow: "",
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      queryForm: {
        dictValue: "",
        code: "",
      },
      subPageVisible: false,
      subFormEditVisible: false,
      subFormInfoVisible: false,
      subQueryForm: {
        dictValue: "",
        code: "",
        parentId: 0,
      },
      subTableData: [],
      subPage: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  components: {
    "form-edit": FormEdit,
    FormInfo,
    "sub-form-edit": SubFormEdit,
    SubFormInfo,
    pagination: FortressPagination,
  },
  created() {
    this.tableData = [];
    this.queryList();
  },
  methods: {
    //查询表格数据
    queryList(page) {
      this.pparentShow = true;
      this.sparentShow = true;
      if (this.subPageVisible) {
        this.subPageVisible = false;
      }
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      sysDictPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.pparentShow = false;
            this.sparentShow = false;
            this.tableData = resp.data.records;
            this.page.total = resp.data.total;
            //查询页大于总页数，重新查询
            let cu =
              this.page.total % this.page.size == 0
                ? parseInt(this.page.total / this.page.size)
                : parseInt(this.page.total / this.page.size + 1);
            if (cu > 0 && this.page.current > cu) {
              this.queryList({ current: cu });
            }
          }, 600);
          this.querySubList();
        } else {
          this.pparentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //删除
    handleDelete(row) {
      this.subQueryForm.parentId = row.id;
      this.subQueryForm.code = row.code;
      let text = "确认删除该记录, 是否继续?";
      subSysDictPage(this.subQueryForm).then((resp) => {
        console.log(resp.data.total);
        if (resp.code == 0 && +resp.data.total > 0) {
          text = "该字典有子级字典，确定要删除么，确定删除后将无法恢复！";
        }
        this.$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          sysDictDel(row.id).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              //判断是否最后一页的最后一条记录
              let totalPages =
                (this.page.total - 1) % this.page.size == 0
                  ? (this.page.total - 1) / this.page.size
                  : (this.page.total - 1) / this.page.size + 1;
              if (this.page.current > totalPages && this.page.current != 1) {
                this.page.current = this.page.current - 1;
              }
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        });
      });
    },
    //状态格式化返回
    statusFmt(row) {
      let typeName = "未启用";
      if (row.status == 1) {
        typeName = "已启用";
      }
      return typeName;
    },
    //查看详情
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //添加子项
    handleSubAdd(id, code) {
      this.subPageVisible = true;
      this.subQueryForm.parentId = id;
      this.subQueryForm.code = code;
      this.querySubList();
    },
    //字典子集查询
    querySubList(page) {
      this.sparentShow = true;
      if (page) {
        this.subPage.current = page.current
          ? page.current
          : this.subPage.current;
        this.subPage.size = page.size ? page.size : this.subPage.size;
      }
      this.subQueryForm.current = this.subPage.current;
      this.subQueryForm.size = this.subPage.size;
      subSysDictPage(this.subQueryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.sparentShow = false;
            this.subTableData = resp.data.records;
            this.subPage.total = resp.data.total;
          }, 600);
        } else {
          this.sparentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },

    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        dictValue: "",
        code: "",
      };
    },
    //重置子集查询框
    resetSubQueryForm() {
      this.subQueryForm.dictValue = "";
    },

    //打开子集编辑窗口
    handleSubEdit(id) {
      if (this.subQueryForm.parentId == 0 || this.subQueryForm.code == "") {
        this.$msg({
          message: "请选择字典后再添加子项",
          type: "error",
        });
        return false;
      }
      this.subFormEditVisible = true;
      this.$nextTick(() => {
        this.$refs.subFormEdit.init(id);
      });
    },
    //打开子详情辑窗口
    handleSubInfo(id) {
      if (this.subQueryForm.parentId == 0 || this.subQueryForm.code == "") {
        this.$msg({
          message: "请选择字典后再添加子项",
          type: "error",
        });
        return false;
      }
      this.subFormInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.subFormInfo.init(id);
      });
    },
    //删除
    handleSubDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sysDictDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
              (this.subPage.total - 1) % this.subPage.size == 0
                ? (this.subPage.total - 1) / this.subPage.size
                : (this.subPage.total - 1) / this.subPage.size + 1;
            if (
              this.subPage.current > totalPages &&
              this.subPage.current != 1
            ) {
              this.subPage.current = this.subPage.current - 1;
            }
            this.querySubList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    //checkedBox选中
    // handleSelectionChange(val) {
    // 	this.multipleSelection = val
    // 	console.log(val)
    // },
  },
};
</script>
<style scoped>
.el-row {
  width: 100%;
}
.el-col {
  padding: 20px;
  width: 49%;
  /* min-width: 800px; */
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 4px;
}
.el-col:first-child {
  margin-right: 2%;
}
@media screen and (max-width: 1745px) {
  .el-col {
    margin-bottom: 40px;
    width: 100%;
  }
}
.table {
  width: 100%;
  /* max-height: 340px; */
}
.el-table {
  height: 100%;
}
</style>
