<template>
  <div class="padding20">
    <el-row style="margin-bottom: 17px">
      <el-button
        type="primary"
        v-if="$tools.hasPermission('system:sysRole:save')"
        @click="handleEdit()"
        size="medium"
        icon="el-icon-plus"
        >添加</el-button
      >
    </el-row>
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          type="selection"
          width="55"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="isAdmin"
          label="是否超管"
          :show-overflow-tooltip="true"
          :formatter="isAdmFmt"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag type="success" size="mini" v-if="scope.row.status == 1"
              >已启用</el-tag
            >
            <el-tag type="danger" size="mini" v-else>未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="180">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('system:sysRole:info')"
              size="mini"
              @click="handleInfo(scope.row.id)"
              plain
              >详情</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:sysRole:edit')"
              size="mini"
              @click="handleEdit(scope.row.id)"
              plain
              >编辑</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:sysRole:menuAuth')"
              size="mini"
              @click="handleMenuAuthEdit(scope.row.id)"
              plain
              >菜单权限</el-button
            >
            <el-button
              v-if="
                $tools.hasPermission('system:sysRole:dataAuth') &&
                scope.row.isAdmin != 1
              "
              size="mini"
              @click="handleDataAuthEdit(scope.row.id)"
              plain
              >数据权限</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:sysRole:delete')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <!-- 新增、编辑 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
    <!-- 菜单权限编辑 -->
    <menuauth-edit v-if="menuAuthVisible" ref="menuauthEdit"></menuauth-edit>
    <!-- 数据权限编辑 -->
    <dataauth-edit
      v-if="dataAuthVisible"
      ref="dataauthEdit"
      @refreshData="queryList"
    ></dataauth-edit>
  </div>
</template>
<script>
import { rolePage, roleDel } from "@/api/auth/sysrole.js";
import FormEdit from "./sysrole-edit";
import FormInfo from "./sysrole-info";
import MenuAuthEdit from "./sysrole-menuauth-edit";
import DataAuthEdit from "./sysrole-dataauth-edit";
import FortressPagination from "@/components/fortress-pagination.vue";
export default {
  data() {
    return {
      parentShow: "",
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      menuAuthVisible: false,
      dataAuthVisible: false,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  components: {
    "form-edit": FormEdit,
    FormInfo,
    "menuauth-edit": MenuAuthEdit,
    "dataauth-edit": DataAuthEdit,
    pagination: FortressPagination,
  },
  created() {
    this.tableData = [];
    this.queryList();
  },
  methods: {
    //查询表格数据
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      rolePage(this.page).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false;
            this.tableData = resp.data.records;
            this.page.total = resp.data.total;
            //查询页大于总页数，重新查询
            let cu =
              this.page.total % this.page.size == 0
                ? parseInt(this.page.total / this.page.size)
                : parseInt(this.page.total / this.page.size + 1);
            if (cu > 0 && this.page.current > cu) {
              this.queryList({ current: cu });
            }
          }, 600);
        } else {
          this.parentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },

    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        roleDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$notify({
              title: "删除成功",
              type: "warning",
              message: "您已成功删除一个角色!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
              (this.page.total - 1) % this.page.size == 0
                ? (this.page.total - 1) / this.page.size
                : (this.page.total - 1) / this.page.size + 1;
            if (this.page.current > totalPages && this.page.current != 1) {
              this.page.current = this.page.current - 1;
            }
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    //是否超管格式化返回
    isAdmFmt(row) {
      let typeName = "否";
      if (row.isAdmin == 1) {
        typeName = "是";
      }
      return typeName;
    },
    //状态格式化返回
    statusFmt(row) {
      let typeName = "未启用";
      if (row.status == 1) {
        typeName = "已启用";
      }
      return typeName;
    },
    //菜单权限
    handleMenuAuthEdit(id) {
      this.menuAuthVisible = true;
      this.$nextTick(() => {
        this.$refs.menuauthEdit.init(id);
      });
    },
    //数据权限
    handleDataAuthEdit(id) {
      this.dataAuthVisible = true;
      this.$nextTick(() => {
        this.$refs.dataauthEdit.init(id);
      });
    },
    //查看详情
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id, true);
      });
    },
  },
};
</script>
<style scoped></style>
