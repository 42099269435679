<template>
  <div>
    <el-drawer
      title
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">主键:</label>
          <div class="form-item-content">{{ infoList.id || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">参数编码:</label>
          <div class="form-item-content">{{ infoList.paramCode || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">参数值:</label>
          <div class="form-item-content">{{ infoList.paramValue || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">类型:</label>
          <div class="form-item-content">{{ infoList.paramType || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">描述:</label>
          <div class="form-item-content">{{ infoList.paramDescription || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">状态:</label>
          <div class="form-item-content">{{ infoList.status || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建人:</label>
          <div class="form-item-content">{{ infoList.createUserName || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建部门:</label>
          <div class="form-item-content">{{ infoList.createPostName || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建职位:</label>
          <div class="form-item-content">{{ infoList.createDeptName || "无" }}</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { configparamsInfo } from "@/api/system/configparams.js"
export default {
  data () {
    return {
      drawer: true,
      direction: "rtl",
      infoList: {},
    }
  },
  created () { },
  methods: {
    init (id) {
      if (id) {
        configparamsInfo(id).then(res => {
          this.infoList = res.data
        })
      }
      this.drawer = true
    },
    // 关闭抽屉
    handleClose (done) {
      done()
    },
  },
}
</script>
<style scoped></style>
