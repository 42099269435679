<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    custom-class="dialog-edit"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="menuRule">
      <el-form-item label="菜单类型" :label-width="formLabelWidth" prop="type">
        <el-radio-group v-model="form.type" @change="menuTypeChange">
          <el-radio
            v-for="item in menuTypeList"
            :key="item.id"
            :label="item.code"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="上级菜单"
        :label-width="formLabelWidth"
        prop="parentId"
      >
        <el-select v-model="form.parentId" clearable placeholder="请选择">
          <el-option :label="selParent.label" :value="selParent.id">
            <el-tree
              :data="treeSelectMenuData"
              :props="defaultProps"
              accordion
              highlight-current
              @node-click="handleNodeClick"
            ></el-tree>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="视图路径"
        :label-width="formLabelWidth"
        v-if="form.type == 1 || form.type == 2"
        prop="viewPath"
      >
        <el-input v-model="form.viewPath" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="排序" :label-width="formLabelWidth">
        <el-input-number
          v-model="form.sort"
          controls-position="right"
          :min="1"
          :max="99999"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="授权标识" :label-width="formLabelWidth">
        <el-input
          v-model="form.permissions"
          autocomplete="off"
          placeholder="多个用；隔开"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="图标"
        :label-width="formLabelWidth"
        v-if="form.type == 1"
        prop="icon"
      >
        <el-popover
          ref="iconPopover"
          placement="bottom-start"
          trigger="click"
          visible-arrow
          popper-class="iconsGroup"
        >
          <!-- <ul class="icons_ul"> -->
          <span
            v-for="(icon, index) in iconList"
            :key="icon + index"
            @click="handleIconClick(icon)"
          >
            <i :class="icon"></i>
          </span>
          <!-- </ul> -->
          <el-input
            placeholder="请选择图标"
            slot="reference"
            v-model="form.icon"
          ></el-input>
        </el-popover>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth">
        <el-input
          v-model="form.remark"
          autocomplete="off"
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger"
        >取 消</el-button
      >
      <el-button type="primary" size="medium" @click="submitForm('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { menuInfo, menuSave, menuEdit } from "@/api/system/menu.js";
import iconList from "@/common/iconList.js";
export default {
  props: ["menuTypeList", "allMenu"],
  data() {
    return {
      visible: false,

      treeSelectMenuData: [],
      selParent: {
        id: "",
        label: "",
      },
      form: {
        id: "",
        name: "",
        parentId: "",
        permissions: "",
        icon: "",
        sort: "",
        type: "1",
        viewPath: "",
        remark: "",
      },
      formLabelWidth: "120px",
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      iconList: iconList,
      rule1: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        icon: [{ required: true, message: "请选择图标", trigger: "change" }],
      },
      rule2: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        parentId: [
          {
            required: true,
            message: "请选择上级菜单",
            trigger: "blur",
          },
        ],
        viewPath: [
          {
            required: true,
            message: "请填写视图路径",
            trigger: "change",
          },
        ],
      },
      rule3: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        parentId: [
          {
            required: true,
            message: "请选择上级菜单",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    menuRule() {
      if (this.form.type == "2") {
        return this.rule2;
      } else if (this.form.type == "3") {
        return this.rule3;
      } else {
        return this.rule1;
      }
    },
  },
  created() {},
  methods: {
    init(id) {
      this.resetForm();
      //修改
      if (id) {
        this.selParent.id = "";
        this.selParent.label = "";
        menuInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              this.form.type = this.form.type + "";

              this.treeSelectMenuData = this.filterParentMenuData(
                this.form.type,
                this.allMenu
              );
              if (this.form.parentId) {
                this.findParentCode(
                  this.form.parentId,
                  this.treeSelectMenuData
                );
              }
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
        //新增
      } else {
        this.treeSelectMenuData = this.filterParentMenuData(
          this.form.type,
          this.allMenu
        );
      }
      this.visible = true;
    },
    //树形菜单展示
    handleNodeClick(data) {
      this.selParent.id = data.id;
      this.selParent.label = data.name;
      this.form.parentId = data.id;
    },
    //查找父节点
    findParentCode(parentId, menuScope) {
      menuScope.forEach((m) => {
        if (parentId == m.id) {
          this.selParent.id = m.id;
          this.selParent.label = m.name;
          return;
        } else if (m.children) {
          this.findParentCode(parentId, m.children);
        }
      });
    },
    //过滤树形下拉框的数据 上级菜单
    filterParentMenuData(type, data) {
      let menuScope = [];
      //菜单、内页 支持所有的菜单
      if (type == "1" || type == "2") {
        data.forEach((item) => {
          if (item.type == "1") {
            let children = [];
            if (item.children) {
              children = this.filterParentMenuData(type, item.children);
            }
            menuScope.push({
              id: item.id,
              name: item.name,
              children: children,
            });
          }
        });
        //按钮 支持菜单和内页
      } else if (type == "3") {
        data.forEach((item) => {
          if (item.type == "1" || item.type == "2") {
            let children = [];
            if (item.children) {
              children = this.filterParentMenuData(type, item.children);
            }
            menuScope.push({
              id: item.id,
              name: item.name,
              children: children,
            });
          }
        });
      }
      return menuScope;
    },
    //菜单类型切换
    menuTypeChange(data) {
      if (this.$refs["form"]) {
        this.$nextTick(() => {
          this.$refs["form"].clearValidate(); // 只清除清除验证
        });
      }
      this.form.type = data;
      this.form.parentId = "";
      this.treeSelectMenuData = this.filterParentMenuData(data, this.allMenu);
    },
    //初始化图标列表
    queryIcons(queryString, cb) {
      var iconList = this.iconList;
      // 调用 callback 返回建议列表的数据
      cb(iconList);
    },
    //选择图标
    handleIconClick(data) {
      this.form.icon = data;
      this.$refs.iconPopover.doClose();
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.visible = false;
          //修改
          if (this.form.id) {
            menuEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            menuSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        parentId: "",
        permissions: "",
        icon: "",
        sort: "",
        type: "1",
        viewPath: "",
        remark: "",
      };

      if (this.$refs["form"]) {
        this.$nextTick(() => {
          this.$refs["form"].resetFields();
        });
      }
    },
  },
};
</script>
<style scoped></style>
