import request from "@/common/request"

//代码生成示例表分页展示
export const tmpdemogeneratePage = (queryForm) => {
    return request("get", "/demo/tmpDemoGenerate/page", queryForm);
}
//代码生成示例表列表展示
export const tmpdemogenerateList = (queryForm) => {
    return request("get", "/demo/tmpDemoGenerate/list", queryForm);
}
//代码生成示例表详情
export const tmpdemogenerateInfo = (id) => {
    return request('get', '/demo/tmpDemoGenerate/info', {
        id: id
    })
}
//代码生成示例表信息保存
export const tmpdemogenerateSave = (data) => {
    return request('post', '/demo/tmpDemoGenerate/save', data)
}
//代码生成示例表信息修改
export const tmpdemogenerateEdit = (data) => {
    return request('post', '/demo/tmpDemoGenerate/edit', data)
}
//代码生成示例表信息删除
export const tmpdemogenerateDel = (id) => {
    return request('post', '/demo/tmpDemoGenerate/delete?id=' + id)
}
//代码生成示例表批量删除
export const tmpdemogenerateDelBatch = (ids) => {
    return request('post', '/demo/tmpDemoGenerate/deleteBatch', ids)
}
//代码生成示例表动态表头导出
export const tmpdemogenerateDynamicExport = (data) => {
    return request("eptpost", '/demo/tmpDemoGenerate/exportDynamic', data)
}
