import request from "@/common/coderequest"

//表字段列表展示
export const colconfigList = (id) => {
    return request("get", "/colconfig/list", { tbId: id });
}
//批量修改表字段信息
export const colconfigEdit = (data) => {
    return request('post', '/colconfig/editBatch', data)
}

