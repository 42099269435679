<template>
  <div>
    <el-drawer
      title
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">任务名:</label>
          <div class="form-item-content">{{ objInfo.taskName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">任务ID:</label>
          <div class="form-item-content">{{ objInfo.taskId }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">调用参数:</label>
          <div class="form-item-content">{{ objInfo.invokeParams }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">调用状态:</label>
          <div class="form-item-content">{{ objInfo.invokeStatus=="00"?"成功":"失败" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">调用时间:</label>
          <div class="form-item-content">{{ objInfo.invokeTime }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">耗时(毫秒):</label>
          <div class="form-item-content">{{ objInfo.elapsedTime }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建时间:</label>
          <div class="form-item-content">{{ objInfo.createTime }}</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { logjobInfo } from "@/api/log/logjob.js";

export default {
  data () {
    return {
      drawer: true,
      direction: "rtl",
      objInfo: {},
    }
  },
  created () {
  },
  methods: {
    init (id) {
      if (id) {
        logjobInfo(id).then(res => {
          this.objInfo = res.data
        })

      }
      this.drawer = true
    },
    // 关闭抽屉
    handleClose (done) {
      done()
    },
  },
}
</script>
<style scoped>
.from-title {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  color: #17233d;
  border-bottom: 1px solid #eee;
}
.info-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 20px;
  font-size: 14px;
}
.form-item-label {
  text-align: right;
  float: left;
  width: 25%;
}
.form-item-content {
  margin-left: 30%;
  width: 70%;
}
</style>
