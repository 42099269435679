import request from "@/common/request"

//物流圈分类分页展示
export const logisticsdynamicstypePage = (queryForm) => {
    return request("get", "/pdworld/logisticsDynamicsType/page", queryForm);
}
//物流圈分类列表展示
export const logisticsdynamicstypeList = (queryForm) => {
    return request("get", "/pdworld/logisticsDynamicsType/list", queryForm);
}
//物流圈分类详情
export const logisticsdynamicstypeInfo = (id) => {
    return request('get', '/pdworld/logisticsDynamicsType/info', {
        id: id
    })
}
//物流圈分类信息保存
export const logisticsdynamicstypeSave = (data) => {
    return request('post', '/pdworld/logisticsDynamicsType/save', data)
}
//物流圈分类信息修改
export const logisticsdynamicstypeEdit = (data) => {
    return request('post', '/pdworld/logisticsDynamicsType/edit', data)
}
//物流圈分类信息删除
export const logisticsdynamicstypeDel = (id) => {
    return request('post', '/pdworld/logisticsDynamicsType/delete?id=' + id)
}
//物流圈分类批量删除
export const logisticsdynamicstypeDelBatch = (ids) => {
    return request('post', '/pdworld/logisticsDynamicsType/deleteBatch', ids)
}
//物流圈分类动态表头导出
export const logisticsdynamicstypeDynamicExport = (data) => {
    return request("eptpost", '/pdworld/logisticsDynamicsType/exportDynamic', data)
}
