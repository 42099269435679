<template>
  <div>
    <el-drawer
      title
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">客户名称:</label>
          <div class="form-item-content">{{ objInfo.custName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">秘钥id:</label>
          <div class="form-item-content">
            {{ objInfo.secretId.substring(0, 10) + "******" + objInfo.secretId.substring(-1, 10) }}
            <fortress-copy :copyData="objInfo.secretId"></fortress-copy>
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">秘钥key:</label>
          <div class="form-item-content">
            {{ objInfo.secretKey.substring(0, 10) + "******" + objInfo.secretKey.substring(-1, 10) }}
            <fortress-copy :copyData="objInfo.secretKey"></fortress-copy>
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">过期日期:</label>
          <div class="form-item-content">{{ objInfo.expiredDate }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">启用状态:</label>
          <div class="form-item-content" v-if="objInfo.status == 1">启用</div>
          <div class="form-item-content" v-if="objInfo.status == 2">禁用</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { apisecretInfo } from "@/api/system/apisecret.js"

export default {
  data () {
    return {
      drawer: true,
      direction: "rtl",
      objInfo: {},
    }
  },
  created () { },
  methods: {
    init (id) {
      if (id) {
        apisecretInfo(id).then(res => {
          this.objInfo = res.data
        })
      }
      this.drawer = true
    },
    // 关闭抽屉
    handleClose (done) {
      done()
    },
  },
}
</script>
<style scoped>
.from-title {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  color: #17233d;
  border-bottom: 1px solid #eee;
}
.info-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 20px;
  font-size: 14px;
}
.form-item-label {
  text-align: right;
  float: left;
  width: 25%;
}
.form-item-content {
  margin-left: 30%;
  width: 70%;
}
</style>
