<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false" append-to-body modal-append-to-body>
		<el-form ref="form" :model="form" :rules="rules">
			<!-- <el-form-item label="分站ID" :label-width="formLabelWidth" prop="siteId">
				<el-input v-model="form.siteId" autocomplete="off" placeholder="请输入分站ID"></el-input>
			</el-form-item>
			<el-form-item label="分站所属市编码subsite" :label-width="formLabelWidth" prop="siteCityCode">
				<el-input v-model="form.siteCityCode" autocomplete="off" placeholder="请输入分站所属市编码subsite"></el-input>
			</el-form-item> -->
			<el-form-item v-if="!form.id" label="要推荐的商家" :label-width="formLabelWidth" prop="memberId">
        <el-input
            v-model="member.nickName"
            readonly
            autocomplete="off"
            placeholder="请选择要推荐的商家"
            @click.native="onSelectvSelectPdmemberRef"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="!form.id" label="推荐位置" :label-width="formLabelWidth" prop="position">
        <fortress-radio-group v-model="form.position" :dictList="SiteRecommendList"></fortress-radio-group>
      </el-form-item>
			<el-form-item label="排序权重" :label-width="formLabelWidth" prop="sort">
				<el-input-number v-model="form.sort"></el-input-number>
			</el-form-item>
			<el-form-item label="图片" prop="picture" :label-width="formLabelWidth" v-if="form.position == 1">
				<fortress-upload type="image" name="picture" :formData="form" :max="1"></fortress-upload>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
			<el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
		</div>

		<vSelectPdmember ref="vSelectPdmemberRef"></vSelectPdmember>
	</el-dialog>
</template>
<script>
import { subsiterecommendInfo, subsiterecommendSave, subsiterecommendEdit } from "@/api/pdworld/subsiterecommend.js"
import { enumList } from "@/api/public.api.js"
import vSelectPdmember from "./v-select-pdmember.vue"

export default {
	props: {
		row: {},
	},
	data() {
		return {
			visible: false,
			form: {
				siteId: "",
				siteCityCode: "",
				memberId: "",
				position: "1",
				sort: "",
				picture: "",
			},
			formLabelWidth: "120px",
			rules: {},
			SiteRecommendList: [],

			member: {},
		}
	},
	created() {},
	methods: {
		onSelectvSelectPdmemberRef() {
			this.$refs.vSelectPdmemberRef.show(member => {
				this.member = member
				this.form.memberId = member.id
			})
		},
		queryEnumList() {
			this.SiteRecommendList = []
			enumList("SiteRecommend", "").then(resp => {
				if (resp.code == 0) {
					this.SiteRecommendList = resp.data
				}
			})
		},
		init(id) {
			this.member = {}
			this.resetForm()
			this.queryEnumList()
			//修改
			if (id) {
				subsiterecommendInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
			}
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			if (!this.form.memberId) {
				this.$message({
					type: "error",
					message: "请选择要推荐的商家!",
				})
				return
			}
			if (this.form.position == 1 && !this.form.picture) {
				this.$message({
					type: "error",
					message: "请上传图片!",
				})
				return
			}

			this.form.siteId = this.row.id
			this.form.siteCityCode = this.row.siteCityCode

			this.$refs["form"].validate(valid => {
				if (valid) {
					//修改
					if (this.form.id) {
						subsiterecommendEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						subsiterecommendSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				siteId: "",
				siteCityCode: "",
				memberId: "",
				position: "1",
				sort: "",
				picture: "",
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
	components: {
		vSelectPdmember,
	},
}
</script>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
