import { render, staticRenderFns } from "./goodsspecitem.vue?vue&type=template&id=511fb8f2&scoped=true&"
import script from "./goodsspecitem.vue?vue&type=script&lang=js&"
export * from "./goodsspecitem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511fb8f2",
  null
  
)

export default component.exports