import request from "@/common/request"

//商品规格表分页展示
export const goodsspecPage = (queryForm) => {
    return request("get", "/goods/goodsSpec/page", queryForm);
}
//商品规格表列表展示
export const goodsspecList = (queryForm) => {
    return request("get", "/goods/goodsSpec/list", queryForm);
}
//商品规格表详情
export const goodsspecInfo = (id) => {
    return request('get', '/goods/goodsSpec/info', {
        id: id
    })
}
//商品规格表信息保存
export const goodsspecSave = (data) => {
    return request('post', '/goods/goodsSpec/save', data)
}
//商品规格表信息修改
export const goodsspecEdit = (data) => {
    return request('post', '/goods/goodsSpec/edit', data)
}
//商品规格表信息删除
export const goodsspecDel = (id) => {
    return request('post', '/goods/goodsSpec/delete?id=' + id)
}
//商品规格表批量删除
export const goodsspecDelBatch = (ids) => {
    return request('post', '/goods/goodsSpec/deleteBatch', ids)
}
//商品规格表动态表头导出
export const goodsspecDynamicExport = (data) => {
    return request("eptpost", '/goods/goodsSpec/exportDynamic', data)
}
