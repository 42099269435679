<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" custom-class="dialog-edit" :close-on-click-modal="false">
		<el-form ref="form" :model="form" :rules="rules">
			<el-form-item label="编码" :label-width="formLabelWidth" prop="code">
				<el-input v-model="form.code" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="名称" :label-width="formLabelWidth" prop="dictValue">
				<el-input v-model="form.dictValue" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="是否启用" :label-width="formLabelWidth" prop="status">
				<el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2"></el-switch>
				<span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66">启用</span>
				<span v-if="form.status == 2" style="margin-left: 17px; color: #ff4949">禁用</span>
			</el-form-item>
			<el-form-item label="备注" :label-width="formLabelWidth">
				<el-input v-model="form.remark" autocomplete="off" type="textarea" :rows="2"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
			<el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { sysDictInfo, sysDictSave, sysDictEdit } from "@/api/system/sysdict.js"
export default {
	data() {
		return {
			visible: false,

			form: {
				id: "",
				code: "",
				parentId: 0,
				dictKey: "-1",
				dictValue: "",
				status: 1,
				remark: "",
			},
			formLabelWidth: "120px",
			rules: {
				code: [{ required: true, message: "请输入字典编码", trigger: "blur" }],
				dictValue: [{ required: true, message: "请输入字典名称", trigger: "blur" }],
			},
		}
	},
	created() {},
	methods: {
		init(id) {
			this.resetForm()
			//修改
			if (id) {
				sysDictInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
				//新增
			}
			this.visible = true
		},

		//提交表单数据
		submitForm() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					this.visible = false
					//修改
					if (this.form.id) {
						sysDictEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						sysDictSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				id: "",
				code: "",
				parentId: 0,
				dictKey: "-1",
				dictValue: "",
				status: 1,
				remark: "",
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
}
</script>
<style scoped></style>
