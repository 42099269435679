<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">所属快递公司:</label>
                    <div class="form-item-content">{{ objInfo.companyIdName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">网点名称:</label>
                    <div class="form-item-content">{{ objInfo.branchName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">网点地址:</label>
                    <div class="form-item-content">{{ objInfo.address }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">联系方式:</label>
                    <div class="form-item-content">{{ objInfo.phone }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">网点图片:</label>
                    <div class="form-item-content" ><img :src="objInfo.branchImg" /></div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">备注:</label>
                    <div class="form-item-content">{{ objInfo.remark }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">省:</label>
                    <div class="form-item-content">{{ objInfo.province }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { pdexpressagebranchInfo } from "@/api/pdworld/pdexpressagebranch.js";
    import { urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                companyIdList:[],
            }
        },
        created() {
        },
        methods: {
          queryUrlList(){
              this.companyIdList = [];
              urlList("/pdworld/pdExpressageCompany/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.companyIdList.push({ code: item.id, name: item.expressageCompany });
                    });
                  }
                }
              });
          },
            init(id) {
                this.queryUrlList();
                if (id) {
                   pdexpressagebranchInfo(id).then(res => {
                        this.objInfo = res.data
                              let companyIdObj = this.companyIdList.find((item) => {
                                return item.code == this.objInfo.companyId;
                              });
                              this.objInfo.companyIdName = companyIdObj ? companyIdObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
     .form-item-content img{
        width: 20%;
        object-fit: cover;
        border-radius: 10px;
    }
</style>
