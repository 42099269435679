import request from "@/common/request"

//发布车源分页展示
export const pdcarPage = (queryForm) => {
    return request("get", "/pdworld/pdCar/page", queryForm);
}
//发布车源列表展示
export const pdcarList = (queryForm) => {
    return request("get", "/pdworld/pdCar/list", queryForm);
}
//发布车源详情
export const pdcarInfo = (id) => {
    return request('get', '/pdworld/pdCar/info', {
        id: id
    })
}
//发布车源信息保存
export const pdcarSave = (data) => {
    return request('post', '/pdworld/pdCar/save', data)
}
//发布车源信息修改
export const pdcarEdit = (data) => {
    return request('post', '/pdworld/pdCar/edit', data)
}
//发布车源信息删除
export const pdcarDel = (id) => {
    return request('post', '/pdworld/pdCar/delete?id=' + id)
}
//发布车源批量删除
export const pdcarDelBatch = (ids) => {
    return request('post', '/pdworld/pdCar/deleteBatch', ids)
}
//发布车源动态表头导出
export const pdcarDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdCar/exportDynamic', data)
}
