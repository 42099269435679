<template>
    <div class="padding20">
      <el-form ref="form" :model="form" :rules="rules">
        <h3>访问提醒配置</h3>
        <br/>
        <el-form-item label="短信访问提醒" :label-width="formLabelWidth" prop="isRemind">
          <el-switch
              v-model="form.isRemind"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
          ></el-switch>
          <span v-if="form.isRemind == 1" style="margin-left: 17px; color: #13ce66"
          >启用</span
          >
          <span v-if="form.isRemind == 2" style="margin-left: 17px; color: #ff4949"
          >禁用</span
          >
        </el-form-item>
        <hr/>

        <h3>线路广告设置</h3>
        <br/>
        <el-form-item label="是否开启线路广告" :label-width="formLabelWidth" prop="isRouteAdv">
          <el-switch
              v-model="form.isRouteAdv"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
          ></el-switch>
          <span v-if="form.isRouteAdv == 1" style="margin-left: 17px; color: #13ce66"
          >启用</span
          >
          <span v-if="form.isRouteAdv == 2" style="margin-left: 17px; color: #ff4949"
          >禁用</span
          >
        </el-form-item>
        <hr/>

        <h3>小程序提醒设置</h3>
        <br/>
        <el-form-item label="小程序温馨提醒" :label-width="formLabelWidth" prop="miniRemind">
          <el-input
              v-model="form.miniRemind"
              autocomplete="off"
              placeholder="请输入小程序温馨提醒"
          ></el-input>
        </el-form-item>
        <hr/>

        <h3>基础配置</h3>
        <br/>
        <el-form-item label="平台热线" :label-width="formLabelWidth" prop="phone">
          <el-input
              v-model="form.phone"
              autocomplete="off"
              placeholder="请输入平台热线"
          ></el-input>
        </el-form-item>
        <el-form-item label="客服电话" :label-width="formLabelWidth" prop="customerServiceContact">
          <el-input
              v-model="form.customerServiceContact"
              autocomplete="off"
              placeholder="请输入客服电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="平台邮箱" :label-width="formLabelWidth" prop="mail">
          <el-input
              v-model="form.mail"
              autocomplete="off"
              placeholder="请输入平台邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系地址" :label-width="formLabelWidth" prop="address">
          <el-input
              v-model="form.address"
              autocomplete="off"
              placeholder="请输入联系地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="小程序二维码图片" :label-width="formLabelWidth" prop="qrcode">
          <fortress-upload
              type="image"
              name="qrcode"
              :formData="form"
          ></fortress-upload>
        </el-form-item>
        <el-form-item label="关于我们" :label-width="formLabelWidth" prop="about">
          <fortress-editor v-model="form.about"></fortress-editor>
        </el-form-item>
        <el-form-item label="物流入驻说明" :label-width="formLabelWidth" prop="enterAbout">
          <fortress-editor v-model="form.enterAbout"></fortress-editor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" >
        <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
        <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
      </div>
    </div>
</template>
<script>
    import { pdsettingDetail, pdsettingSave, pdsettingEdit } from "@/api/pdworld/pdsetting.js";
    export default {
      data() {
        return {
          visible: false,
          form: {
            phone: "",
            mail: "",
            address: "",
            qrcode: "",
            about: "",
            isRemind: "",
            enterAbout: "",
            customerServiceContact: "",
            isRouteAdv: "",
            miniRemind: "",
          },
          formLabelWidth: "150px",
          myconfig: window.$conf.commonConf.ueConfig,
          rules: {},
        };
      },
      created() {
        this.init();
      },
      methods: {
        init() {
          this.resetForm();
          //修改
          pdsettingDetail().then((resp) => {
              if (resp.code == 0) {
                this.$nextTick(() => {
                  this.form = {
                    ...this.form,
                    ...resp.data,
                  };
                });
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          this.visible = true;
        },
        //提交表单数据
        submitForm() {
          this.$refs["form"].validate((valid) => {
            if (valid) {
              //修改
              if (this.form.id) {
                pdsettingEdit(this.form).then((resp) => {
                  if (resp.code == 0) {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                    });
                    this.$emit("refreshData");
                    this.visible = false;
                  } else {
                    this.$msg({
                      message: resp.msg,
                      type: "error",
                    });
                  }
                });
                //新增
              } else {
                pdsettingSave(this.form).then((resp) => {
                  if (resp.code == 0) {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                    });
                    this.$emit("refreshData");
                    this.visible = false;
                  } else {
                    this.$msg({
                      message: resp.msg,
                      type: "error",
                    });
                  }
                });
              }
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        },
        resetForm() {
          this.form = {
            phone: "",
            mail: "",
            address: "",
            qrcode: "",
            about: "",
            isRemind: "",
            enterAbout: "",
            customerServiceContact: "",
            isRouteAdv: "",
            miniRemind: "",
          };
          this.$nextTick(() => {
            if (this.$refs["form"]) {
              this.$refs["form"].resetFields();
            }
          });
        },
      },
    };
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
