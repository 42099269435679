<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
		<el-form ref="form" :model="form" :rules="rules">
			<!-- <el-form-item label="会员ID" :label-width="formLabelWidth" prop="memberId">
                <el-input
                        v-model="form.memberId"
                        autocomplete="off"
                        placeholder="请输入会员ID"
                ></el-input>
            </el-form-item>
            <el-form-item label="商家基础信息ID" :label-width="formLabelWidth" prop="shopId">
                <el-input
                        v-model="form.shopId"
                        autocomplete="off"
                        placeholder="请输入商家基础信息ID"
                ></el-input>
            </el-form-item> -->
			<el-form-item label="类型（banner图/公司场景/公司荣誉）" :label-width="formLabelWidth" prop="type">
				<fortress-select
            v-model="form.type"
            :dictList="ShopImgTypeList"
            :disabled="!!form.type"
            placeholder="请选择类型（banner图/公司场景/公司荣誉）"
            clearable
				></fortress-select>
			</el-form-item>
      <div v-if="form.type">
        <el-form-item label="商家图片" :label-width="formLabelWidth" prop="url">
          <div class="img" style="float: left">
            <fortress-upload type="image" name="url" :formData="form" :nowater="form.type == 1"></fortress-upload>
          </div>
        </el-form-item>
      </div>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
			<el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { pdshopinfoimgInfo, pdshopinfoimgSave, pdshopinfoimgEdit } from "@/api/pdworld/pdshopinfoimg.js"
import { enumList } from "@/api/public.api.js"

export default {
	data() {
		return {
			visible: false,
			form: {
				memberId: this.memberId,
				shopId: "",
				type: "",
				url: "",
			},
			formLabelWidth: "120px",
			rules: {},
			ShopImgTypeList: [],
			memberId: "",
		}
	},
	created() {},
	methods: {
		queryEnumList() {
			this.ShopImgTypeList = []
			enumList("ShopImgType", "").then(resp => {
				if (resp.code == 0) {
					this.ShopImgTypeList = resp.data
				}
			})
		},
		init(memberId, id) {
			this.memberId = memberId
			this.resetForm()
			this.queryEnumList()
			//修改
			if (id) {
				pdshopinfoimgInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
			}
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					//修改
					if (this.form.id) {
						pdshopinfoimgEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						pdshopinfoimgSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				memberId: this.memberId,
				shopId: "",
				type: "",
				url: "",
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
}
</script>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
