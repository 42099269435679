<template>
	<div>
		<slot>
			<el-input readonly :placeholder="placeholder" :value="address" @click.native="onOpenDialog"></el-input>
		</slot>

		<el-dialog
			:visible.sync="dialogVisible"
			title="地图选点"
			width="1000px"
			:close-on-click-modal="false"
			append-to-body
			modal-append-to-body
		>
			<div v-if="visible" class="fortress-map-container">
				<div class="fortress-map-search-box">
					<input v-model="searchKey" type="search" id="search" />
					<el-button @click="searchByHand" size="mini" type="primary">搜索</el-button>
					<div class="fortress-map-box" id="searchTip"></div>
				</div>
				<el-amap
					class="fortress-map-amap-box"
					:amap-manager="amapManager"
					:vid="'amap-vue'"
					:zoom="zoom"
					:plugin="plugin"
					:center="center"
					:events="events"
				>
					<!-- 标记 -->
					<el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
				</el-amap>
			</div>

			<template #footer>
				<el-button @click="submitAddress" size="medium" type="success">确认</el-button>
				<el-button @click="onCancel" size="medium">取消</el-button>
			</template>
		</el-dialog>
	</div>
</template>
<script>
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap"
let amapManager = new AMapManager()

const initCenter = [121.45, 37.46]
const initMarkers = []
let initAddress = ""
export default {
	name: "FortressMap",
	props: {
		placeholder: {
			type: String,
			default: "请选择地点",
		},
	},
	data() {
		let self = this
		return {
			dialogVisible: false,

			visible: true,
			address: null,
			searchKey: "",
			amapManager,
			markers: [],
			searchOption: {
				city: "全国",
				citylimit: true,
			},
			center: [121.45, 37.46],
			zoom: 17,
			lng: 0,
			lat: 0,
			loaded: false,
			markerEvent: {
				click(e) {},
			},
			events: {
				init() {
					lazyAMapApiLoaderInstance.load().then(() => {
						self.initSearch()
					})
				},
				// 点击获取地址的数据
				// lng精度
				// lat维度
				click(e) {
					self.markers = []
					let { lng, lat } = e.lnglat
					self.lng = lng
					self.lat = lat
					console.log(e)
					self.center = [lng, lat]
					self.markers.push([lng, lat])
					// 这里通过高德 SDK 完成。
					let geocoder = new AMap.Geocoder({
						radius: 1000,
						extensions: "all",
					})
					geocoder.getAddress([lng, lat], function(status, result) {
						if (status === "complete" && result.info === "OK") {
							if (result && result.regeocode) {
								console.log(result.regeocode.formattedAddress)
								self.address = result.regeocode.formattedAddress
								self.searchKey = result.regeocode.formattedAddress
								self.$nextTick()
							}
						}
					})
				},
			},
			// 一些工具插件
			plugin: [
				{
					// 定位
					pName: "Geolocation",
					events: {
						init(o) {
							// o是高德地图定位插件实例
							o.getCurrentPosition((status, result) => {
								if (result && result.position) {
									// 设置经度
									self.lng = result.position.lng
									// 设置维度
									self.lat = result.position.lat
									// 设置坐标
									self.center = [self.lng, self.lat]
									self.markers.push([self.lng, self.lat])
									// load
									self.loaded = true
									// 页面渲染好后
									self.$nextTick()
								}
							})
						},
						click(e) {
							console.log(e)
						},
					},
				},
				{
					// 工具栏
					pName: "ToolBar",
					events: {
						init(instance) {
							console.log(instance)
						},
					},
				},
				{
					// 鹰眼
					pName: "OverView",
					events: {
						init(instance) {
							console.log(instance)
						},
					},
				},
				{
					// 地图类型
					pName: "MapType",
					defaultType: 0,
					events: {
						init(instance) {
							console.log(instance)
						},
					},
				},
				{
					// 搜索
					pName: "PlaceSearch",
					events: {
						init(instance) {
							console.log(instance)
						},
					},
				},
			],
		}
	},
	methods: {
		setAddress([lng, lat], address) {
			this.center = [lng, lat]
			this.address = address
			this.markers = [[lng, lat]]

			initCenter[0] = lng
			initCenter[1] = lat
			initAddress = address
		},
		submitAddress() {
			this.$emit("ftmap", this.center, this.address)
			this.dialogVisible = false

			initCenter[0] = this.center[0]
			initCenter[1] = this.center[1]
			initAddress = this.address
			initMarkers[0] = this.markers[0]
			initMarkers[1] = this.markers[1]
		},
		initSearch() {
			let vm = this
			let map = this.amapManager.getMap()
			AMapUI.loadUI(["misc/PoiPicker"], function(PoiPicker) {
				var poiPicker = new PoiPicker({
					input: "search",
					placeSearchOptions: {
						map: map,
						pageSize: 10,
					},
					suggestContainer: "searchTip",
					searchResultsContainer: "searchTip",
				})
				vm.poiPicker = poiPicker
				// 监听poi选中信息
				poiPicker.on("poiPicked", function(poiResult) {
					// console.log(poiResult)
					let source = poiResult.source
					let poi = poiResult.item
					if (source !== "search") {
						poiPicker.searchByKeyword(poi.name)
					} else {
						poiPicker.clearSearchResults()
						vm.markers = []
						let lng = poi.location.lng
						let lat = poi.location.lat
						let address = poi.cityname + poi.adname + poi.name
						vm.center = [lng, lat]
						vm.markers.push([lng, lat])
						vm.lng = lng
						vm.lat = lat
						vm.address = address
						vm.searchKey = address
					}
				})
			})
		},
		searchByHand() {
			if (this.searchKey !== "") {
				this.poiPicker.searchByKeyword(this.searchKey)
			}
		},

		onOpenDialog() {
			this.dialogVisible = true
		},
		onCancel() {
			this.dialogVisible = false
			this.center = [121.45, 37.46]
			this.markers = []
			this.address = ""
			this.searchKey = ""
		},
	},
}
</script>

<style>
.fortress-map-container {
	width: 100%;
	height: 500px;
	position: relative;
	border: 1px solid #999;
}

.fortress-map-search-box {
	position: absolute;
	z-index: 5;
	width: 70%;
	left: 13%;
	top: 10px;
	height: 30px;
}

.fortress-map-search-box input {
	float: left;
	width: 59%;
	height: 100%;
	font-size: 13px;
	border: 2px solid #30ccc1;
	padding: 0 8px;
	outline: none;
}

.fortress-map-search-box button {
	float: left;
	margin-left: 5px;
	/* width: 20%;
        height: 100%;
        background: #30ccc1;
        border: 1px solid #30ccc1;
        color: #fff;
        outline: none; */
}

.fortress-map--box {
	width: 100%;
	max-height: 260px;
	position: absolute;
	top: 30px;
	overflow-y: auto;
	background-color: #fff;
}
</style>
