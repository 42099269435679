<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">图片url:</label>
                    <div class="form-item-content" ><img :src="objInfo.imgUrl" /></div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">跳转链接:</label>
                    <div class="form-item-content">{{ objInfo.href }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">是否显示:</label>
                    <div class="form-item-content">{{ objInfo.isShowName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">顺序:</label>
                    <div class="form-item-content">{{ objInfo.sort }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">广告位置(首页左/右/居中/查询列表页):</label>
                    <div class="form-item-content">{{ objInfo.positionName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">标题:</label>
                    <div class="form-item-content">{{ objInfo.title }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">备注:</label>
                    <div class="form-item-content">{{ objInfo.remake }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { pdadvertisingInfo } from "@/api/pdworld/pdadvertising.js";
    import { enumList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                YesNoList:[],
                AdvPositionList:[],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.YesNoList = [];
              enumList("YesNo","").then((resp) => {
                if (resp.code == 0) {
                  this.YesNoList = resp.data;
                }
              });
              this.AdvPositionList = [];
              enumList("AdvPosition","").then((resp) => {
                if (resp.code == 0) {
                  this.AdvPositionList = resp.data;
                }
              });
          },
            init(id) {
                this.queryEnumList();
                if (id) {
                   pdadvertisingInfo(id).then(res => {
                        this.objInfo = res.data
                              let isShowObj = this.YesNoList.find((item) => {
                                return item.code == this.objInfo.isShow;
                              });
                              this.objInfo.isShowName = isShowObj ? isShowObj.name : "";
                              let positionObj = this.AdvPositionList.find((item) => {
                                return item.code == this.objInfo.position;
                              });
                              this.objInfo.positionName = positionObj ? positionObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
     .form-item-content img{
        width: 20%;
        object-fit: cover;
        border-radius: 10px;
    }
</style>
