import request from "@/common/request"

//商品参数表分页展示
export const goodsparamsPage = (queryForm) => {
    return request("get", "/goods/goodsParams/page", queryForm);
}
//商品参数表列表展示
export const goodsparamsList = (queryForm) => {
    return request("get", "/goods/goodsParams/list", queryForm);
}
//商品参数表详情
export const goodsparamsInfo = (id) => {
    return request('get', '/goods/goodsParams/info', {
        id: id
    })
}
//商品参数表信息保存
export const goodsparamsSave = (data) => {
    return request('post', '/goods/goodsParams/save', data)
}
//商品参数表信息修改
export const goodsparamsEdit = (data) => {
    return request('post', '/goods/goodsParams/edit', data)
}
//商品参数表信息删除
export const goodsparamsDel = (id) => {
    return request('post', '/goods/goodsParams/delete?id=' + id)
}
//商品参数表批量删除
export const goodsparamsDelBatch = (ids) => {
    return request('post', '/goods/goodsParams/deleteBatch', ids)
}
//商品参数表动态表头导出
export const goodsparamsDynamicExport = (data) => {
    return request("eptpost", '/goods/goodsParams/exportDynamic', data)
}
