<template>
  <div>
    <el-drawer
      title
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">输入框:</label>
          <div class="form-item-content">{{ objInfo.name }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">富文本:</label>
          <div class="form-item-content" v-html="objInfo.content"></div>
        </div>
        <div class="form-item">
          <label class="form-item-label">作者:</label>
          <div class="form-item-content">{{ objInfo.auth }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">手机号:</label>
          <div class="form-item-content">{{ objInfo.phone }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">地址:</label>
          <div class="form-item-content">
            {{ objInfo.provinceName }}&nbsp;{{ objInfo.cityName }}&nbsp;{{
              objInfo.areaName
            }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">邮箱:</label>
          <div class="form-item-content">{{ objInfo.email }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">图片:</label>
          <div class="form-item-content">
            <img :src="objInfo.images" />
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">文件:</label>
          <div class="form-item-content">
            <template v-for="(item, indexs) in objInfo.files">
              <a :key="indexs" :href="item.url" target="_blank">{{
                item.name
              }}</a>
              <br />
            </template>
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">视频:</label>
          <div class="form-item-content">
            <fortress-upload
              v-if="objInfo.video"
              type="videoShow"
              name="video"
              :formData="objInfo"
              :videoSrc="objInfo.video"
            ></fortress-upload>
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">地图-经度:</label>
          <div class="form-item-content">{{ objInfo.longitude }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">地图-纬度:</label>
          <div class="form-item-content">{{ objInfo.latitude }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">数字:</label>
          <div class="form-item-content">
            {{ objInfo.vueNumber }}
            <fortress-copy :copyData="objInfo.vueNumber"></fortress-copy>
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">单选:</label>
          <div class="form-item-content">{{ objInfo.vueRadioName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">多行文本:</label>
          <div class="form-item-content">{{ objInfo.vueTextarea }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">复选:</label>
          <div class="form-item-content">{{ objInfo.vueCheckboxName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">下拉:</label>
          <div class="form-item-content">{{ objInfo.vueSelectName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">枚举下拉:</label>
          <div class="form-item-content">{{ objInfo.selectEnumName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">URL下拉:</label>
          <div class="form-item-content">{{ objInfo.selectUrlName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">json下拉:</label>
          <div class="form-item-content">{{ objInfo.selectJsonName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">日期:</label>
          <div class="form-item-content">{{ objInfo.vueDate }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">日期时间:</label>
          <div class="form-item-content">{{ objInfo.vueDatetime }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">状态:</label>
          <div class="form-item-content" v-if="objInfo.status == 1">启用</div>
          <div class="form-item-content" v-else>禁用</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建人:</label>
          <div class="form-item-content">{{ objInfo.createUserName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建部门:</label>
          <div class="form-item-content">{{ objInfo.createDeptName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建职位:</label>
          <div class="form-item-content">{{ objInfo.createPostName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建时间:</label>
          <div class="form-item-content">{{ objInfo.createTime }}</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { tmpdemoInfo } from "@/api/system/tmpdemo.js";
import { dictList, enumList, urlList } from "@/api/public.api.js";
export default {
  data() {
    return {
      drawer: true,
      direction: "rtl",
      objInfo: {},
      dictDemoList: [],
      selectEnumList: [],
      selectUrlList: [],
      selectJsonList: [],
    };
  },
  created() {
    this.queryDictList();
    this.queryEnumList();
    this.queryUrlList();
    this.queryJsonList();
  },
  methods: {
    //字典
    queryDictList() {
      this.dictDemoList = [];
      dictList("dictDemo").then((resp) => {
        if (resp.code == 0) {
          this.dictDemoList = resp.data;
        }
      });
    },
    //枚举
    queryEnumList() {
      this.selectEnumList = [];
      enumList("DataPermissionPost").then((resp) => {
        if (resp.code == 0) {
          this.selectEnumList = resp.data;
        }
      });
    },
    //url
    queryUrlList() {
      this.selectUrlList = [];
      urlList("/system/sysRole/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.selectUrlList.push({ code: item.id, name: item.name });
            });
          }
        }
      });
    },
    //json
    queryJsonList() {
      this.selectJsonList = [];
      let data =
        '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
      this.selectJsonList = JSON.parse(data);
    },
    init(id) {
      if (id) {
        tmpdemoInfo(id).then((res) => {
          this.objInfo = res.data;
          this.objInfo.files = JSON.parse(res.data.files);
          this.dictDemoList.forEach((item) => {
            if (item.code == this.objInfo.vueRadio) {
              this.objInfo.vueRadioName = item.name;
            }
          });
          this.objInfo.vueCheckboxName = "";
          if (this.objInfo.vueCheckbox) {
            let vueCheckboxArray = this.objInfo.vueCheckbox.split(";");
            this.dictDemoList.forEach((item) => {
              vueCheckboxArray.forEach((o) => {
                if (o == item.code) {
                  this.objInfo.vueCheckboxName += item.name + " ";
                }
              });
            });
          }
          this.dictDemoList.forEach((item) => {
            if (item.code == this.objInfo.vueSelect) {
              this.objInfo.vueSelectName = item.name;
            }
          });

          let selectEnumObj = this.selectEnumList.find((item) => {
            return item.code == this.objInfo.selectEnum;
          });
          this.objInfo.selectEnumName = selectEnumObj ? selectEnumObj.name : "";

          let selectUrlObj = this.selectUrlList.find((item) => {
            return item.code == this.objInfo.selectUrl;
          });
          this.objInfo.selectUrlName = selectUrlObj ? selectUrlObj.name : "";

          let selectJsonObj = this.selectJsonList.find((item) => {
            return item.code == this.objInfo.selectJson;
          });
          this.objInfo.selectJsonName = selectJsonObj ? selectJsonObj.name : "";
        });
      }
      this.drawer = true;
    },
    // 关闭抽屉
    handleClose(done) {
      done();
    },
  },
};
</script>
<style scoped>
.from-title {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  color: #17233d;
  border-bottom: 1px solid #eee;
}
.info-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 20px;
  font-size: 14px;
}
.form-item-label {
  text-align: right;
  float: left;
  width: 25%;
}
.form-item-content {
  margin-left: 30%;
  width: 70%;
}
</style>
