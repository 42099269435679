<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="分类名称" :label-width="formLabelWidth" prop="name">
        <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入分类名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="分类图片" :label-width="formLabelWidth" prop="thumb">
        <fortress-upload
            type="image"
            name="thumb"
            :formData="form"
        ></fortress-upload>
      </el-form-item>
      <el-form-item label="上级分类" :label-width="formLabelWidth" prop="parentId">
        <!--                <el-input-->
        <!--                        v-model="form.parentId"-->
        <!--                        autocomplete="off"-->
        <!--                        placeholder="请输入父id"-->
        <!--                ></el-input>-->
        <el-select v-model="form.parentId" clearable placeholder="请选择">
          <el-option :label="selParent.label" :value="selParent.id">
            <el-tree
                :data="allCategory"
                :props="defaultProps"
                accordion
                highlight-current
                @node-click="handleNodeClick"
            ></el-tree>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否推荐" :label-width="formLabelWidth" prop="isRecommand">
<!--        <el-switch-->
<!--            v-model="form.isRecommand"-->
<!--            active-color="#13ce66"-->
<!--            inactive-color="#ff4949"-->
<!--            :active-value="1"-->
<!--            active-text="是"-->
<!--            :inactive-value="2"-->
<!--            inactive-text="否"-->
<!--        ></el-switch>-->
        <fortress-radio-group
            v-model="form.isRecommand"
            :dictList="YesNoList"
        >
        </fortress-radio-group>
      </el-form-item>
      <el-form-item label="是否首页显示" :label-width="formLabelWidth" prop="isHome">
<!--        <el-switch-->
<!--            v-model="form.isHome"-->
<!--            active-color="#13ce66"-->
<!--            inactive-color="#ff4949"-->
<!--            :active-value="1"-->
<!--            active-text="是"-->
<!--            :inactive-value="2"-->
<!--            inactive-text="否"-->
<!--        ></el-switch>-->
        <fortress-radio-group
            v-model="form.isHome"
            :dictList="YesNoList"
        >
        </fortress-radio-group>
      </el-form-item>
      <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
        <el-input-number
            step-strictly
            :min="0"
            v-model="form.sort"
            autocomplete="off"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
            v-model="form.remark"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {goodscategoryInfo, goodscategorySave, goodscategoryEdit, treeList} from "@/api/goods/goodscategory.js";


export default {
  props: ["allCategory"],
  data() {
    return {
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      categoryList: [],
      visible: false,
      selParent: {
        id: "",
        label: "",
      },
      form: {
        name: "",
        thumb: "",
        parentId: "",
        isRecommand: "",
        isHome: "",
        sort: "",
        remark: "",
      },
      formLabelWidth: "120px",
      rules: {
        name: [
          {required: true, message: "请输入分类名称", trigger: "blur"},
        ],
        isRecommand: [
          {required: true, message: "请输入是否推荐", trigger: "blur"},
        ],
        isHome: [
          {required: true, message: "请输入是否首页显示", trigger: "blur"},
        ],
      },
      YesNoList:[
        {
          code:"1",
          name:"是"
        },{
          code:"2",
          name:"否"
        },
      ],
    };
  },
  created() {
  },
  methods: {
    init(id) {
      this.resetForm();
      //修改
      if (id) {
        goodscategoryInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              if (this.form.parentId) {
                this.findParentCode(this.form.parentId, this.allCategory)
              }
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //树形菜单展示
    handleNodeClick(data) {
      this.selParent.id = data.id
      this.selParent.label = data.name
      this.form.parentId = data.id
    },
    //查找父节点
    findParentCode(parentId, dataScope) {
      dataScope.forEach(m => {
        if (parentId == m.id) {
          this.selParent.id = m.id
          this.selParent.label = m.name
          return
        } else if (m.children) {
          this.findParentCode(parentId, m.children)
        }
      })
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            goodscategoryEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            goodscategorySave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        name: "",
        thumb: "",
        parentId: "",
        isRecommand: 2,
        isHome: 2,
        sort: "",
        remark: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
