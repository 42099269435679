<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="线路id" :label-width="formLabelWidth" prop="routeId">
                <el-input
                        v-model="form.routeId"
                        autocomplete="off"
                        placeholder="请输入线路id"
                ></el-input>
            </el-form-item>
            <el-form-item label="所属商家ID" :label-width="formLabelWidth" prop="memberId">
                <el-input
                        v-model="form.memberId"
                        autocomplete="off"
                        placeholder="请输入所属商家ID"
                ></el-input>
            </el-form-item>
            <el-form-item label="开始时间" :label-width="formLabelWidth" prop="startTime">
<!--                <el-input-->
<!--                        v-model="form.startTime"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="请输入开始时间"-->
<!--                ></el-input>-->
              <el-time-select
                  placeholder="起始时间"
                  v-model="form.startTime"
                  :picker-options="{
                  start: '08:00',
                  step: '00:30',
                  end: '23:00',
                }">
              </el-time-select>
            </el-form-item>
            <el-form-item label="结束时间" :label-width="formLabelWidth" prop="endTime">

              <el-time-select
                  placeholder="结束时间"
                  v-model="form.endTime"
                  :picker-options="{
                  start: '08:00',
                  step: '00:30',
                  end: '23:00',
                  minTime: form.startTime
                }">
              </el-time-select>
<!--                <el-input-->
<!--                        v-model="form.endTime"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="请输入结束时间"-->
<!--                ></el-input>-->
            </el-form-item>
            <el-form-item label="间隔时间(分钟)" :label-width="formLabelWidth" prop="interval">
<!--                <el-input-->
<!--                        v-model="form.interval"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="请输入刷新间隔时间"-->
<!--                ></el-input>-->
              <el-input-number v-model="form.interval" :min="1" :max="60" label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item label="是否每天刷新" :label-width="formLabelWidth" prop="isEveryday">
                <el-switch
                      v-model="form.isEveryday"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="2"
                ></el-switch>
            </el-form-item>
            <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
                <el-switch
                      v-model="form.status"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="2"
                ></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { pdrouterefreshInfo, pdrouterefreshSave, pdrouterefreshEdit } from "@/api/pdworld/pdrouterefresh.js";
    import { enumList, } from "@/api/public.api.js";
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    routeId:"",
                    memberId:"",
                    startTime:"",
                    endTime:"",
                    interval:"",
                    isEveryday:"2",
                    status:"2",
                },
                formLabelWidth: "120px",
                rules: {
                },
                YesNoList:[],
            };
        },
        created() {
        },
        methods: {
            queryEnumList(){
                this.YesNoList = [];
                enumList("YesNo","").then((resp) => {
                  if (resp.code == 0) {
                    this.YesNoList = resp.data;
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryEnumList();
                //修改
                if (id) {
                    pdrouterefreshInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        pdrouterefreshEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        pdrouterefreshSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    routeId:"",
                    memberId:"",
                    startTime:"",
                    endTime:"",
                    interval:"",
                    isEveryday:"2",
                    status:"2",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
