import request from "@/common/request"

//首页轮播图分页展示
export const bannerPage = (queryForm) => {
    return request("get", "/pdworld/banner/banner/page", queryForm);
}
//首页轮播图列表展示
export const bannerList = (queryForm) => {
    return request("get", "/pdworld/banner/banner/list", queryForm);
}
//首页轮播图详情
export const bannerInfo = (id) => {
    return request('get', '/pdworld/banner/banner/info', {
        id: id
    })
}
//首页轮播图信息保存
export const bannerSave = (data) => {
    return request('post', '/pdworld/banner/banner/save', data)
}
//首页轮播图信息修改
export const bannerEdit = (data) => {
    return request('post', '/pdworld/banner/banner/edit', data)
}
//首页轮播图信息删除
export const bannerDel = (id) => {
    return request('post', '/pdworld/banner/banner/delete?id=' + id)
}
//首页轮播图批量删除
export const bannerDelBatch = (ids) => {
    return request('post', '/pdworld/banner/banner/deleteBatch', ids)
}
//首页轮播图动态表头导出
export const bannerDynamicExport = (data) => {
    return request("eptpost", '/pdworld/banner/banner/exportDynamic', data)
}
