import request from "@/common/request"

//分站推荐线路信息分页展示
export const subsiteroutePage = (queryForm) => {
    return request("get", "/pdworld/subSiteRoute/page", queryForm);
}
//分站推荐线路信息列表展示
export const subsiterouteList = (queryForm) => {
    return request("get", "/pdworld/subSiteRoute/list", queryForm);
}
//分站推荐线路信息详情
export const subsiterouteInfo = (id) => {
    return request('get', '/pdworld/subSiteRoute/info', {
        id: id
    })
}
//分站推荐线路信息信息保存
export const subsiterouteSave = (data) => {
    return request('post', '/pdworld/subSiteRoute/save', data)
}
//分站推荐线路信息信息修改
export const subsiterouteEdit = (data) => {
    return request('post', '/pdworld/subSiteRoute/edit', data)
}
//分站推荐线路信息信息删除
export const subsiterouteDel = (id) => {
    return request('post', '/pdworld/subSiteRoute/delete?id=' + id)
}
//分站推荐线路信息批量删除
export const subsiterouteDelBatch = (ids) => {
    return request('post', '/pdworld/subSiteRoute/deleteBatch', ids)
}
//分站推荐线路信息动态表头导出
export const subsiterouteDynamicExport = (data) => {
    return request("eptpost", '/pdworld/subSiteRoute/exportDynamic', data)
}
