import request from "@/common/request"

//优化信息表分页展示
export const seoPage = (queryForm) => {
    return request("get", "/pdworld/seo/page", queryForm);
}
//优化信息表列表展示
export const seoList = (queryForm) => {
    return request("get", "/pdworld/seo/list", queryForm);
}
//优化信息表详情
export const seoInfo = (id) => {
    return request('get', '/pdworld/seo/info', {
        id: id
    })
}
//优化信息表信息保存
export const seoSave = (data) => {
    return request('post', '/pdworld/seo/save', data)
}
//优化信息表信息修改
export const seoEdit = (data) => {
    return request('post', '/pdworld/seo/edit', data)
}
//优化信息表信息删除
export const seoDel = (id) => {
    return request('post', '/pdworld/seo/delete?id=' + id)
}
//优化信息表批量删除
export const seoDelBatch = (ids) => {
    return request('post', '/pdworld/seo/deleteBatch', ids)
}
//优化信息表动态表头导出
export const seoDynamicExport = (data) => {
    return request("eptpost", '/pdworld/seo/exportDynamic', data)
}
