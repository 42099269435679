import request from "@/common/request"

//定时任务调用日志分页展示
export const logjobPage = (queryForm) => {
    return request("get", "/log/logJob/page", queryForm);
}
//定时任务调用日志列表展示
export const logjobList = (queryForm) => {
    return request("get", "/log/logJob/list", queryForm);
}
//定时任务调用日志详情
export const logjobInfo = (id) => {
    return request('get', '/log/logJob/info', {
        id: id
    })
}
//定时任务调用日志信息保存
export const logjobSave = (data) => {
    return request('post', '/log/logJob/save', data)
}
//定时任务调用日志信息修改
export const logjobEdit = (data) => {
    return request('post', '/log/logJob/edit', data)
}
//定时任务调用日志信息删除
export const logjobDel = (id) => {
    return request('post', '/log/logJob/delete?id=' + id)
}
//定时任务调用日志批量删除
export const logjobDelBatch = (ids) => {
    return request('post', '/log/logJob/deleteBatch', ids)
}
