<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-form :inline="true" :model="queryForm">
        <el-form-item>
          <el-input
            v-model="queryForm.smsCode"
            autocomplete="off"
            placeholder="请输入短信编码"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <fortress-select
            v-model="queryForm.platform"
            :dictList="SmsPlatFormList"
            autocomplete="off"
            placeholder="请输入平台类型"
            clearable
          ></fortress-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.status"
            placeholder="请选择状态"
            clearable
          >
            <el-option label="启用" value="1"></el-option>
            <el-option label="未启用" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-search"
            @click="queryList({ current: 1 })"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-refresh-left"
            @click="resetQueryForm"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        v-if="$tools.hasPermission('message:sms:configSms:save')"
        @click="handleEdit()"
        >添加</el-button
      >
      <el-button
        v-if="$tools.hasPermission('message:sms:configSms:deleteBatch')"
        size="medium"
        type="danger"
        icon="el-icon-delete"
        @click="handleDeleteBatch()"
        >删除</el-button
      >
      <div style="float: right; padding-right: 17px">
        <i
          class="el-icon-refresh"
          style="margin-right: 10px; cursor: pointer"
          @click="refresh"
        ></i>

        <el-popover
          placement="bottom"
          width="180"
          trigger="click"
          title="列展示"
          popper-class="columnShows"
        >
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
              v-for="item in tableList"
              :label="item.propName"
              :key="item.propName"
              :value="item.propName"
              >{{ item.propDesc }}</el-checkbox
            >
          </el-checkbox-group>
          <i
            slot="reference"
            class="el-icon-s-operation"
            style="cursor: pointer"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <!-- loading -->
    <loading :show="parentShow">
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          v-if="$tools.isShow('smsCode', tableShowList)"
          prop="smsCode"
          label="短信编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('platform', tableShowList)"
          prop="platform"
          label="平台类型"
          :show-overflow-tooltip="true"
          :formatter="platformFmt"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('remark', tableShowList)"
          prop="remark"
          label="备注"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('status', tableShowList)"
          prop="status"
          label="状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag type="success" size="mini" v-if="scope.row.status == 1"
              >已启用</el-tag
            >
            <el-tag type="danger" size="mini" v-else>未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="$tools.isShow('createUser', tableShowList)"
          prop="createUserName"
          label="创建人"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('createDept', tableShowList)"
          prop="createDeptName"
          label="创建部门"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('createTime', tableShowList)"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="180">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('message:sms:configSms:info')"
              size="mini"
              plain
              @click="handleInfo(scope.row.id)"
              >详情</el-button
            >
            <el-button
              v-if="$tools.hasPermission('message:sms:configSms:edit')"
              size="mini"
              plain
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-if="
                $tools.hasPermission('message:sms:configSms:debug') &&
                scope.row.status == 1
              "
              size="mini"
              plain
              @click="handleDebug(scope.row)"
              >调试</el-button
            >
            <el-button
              v-if="$tools.hasPermission('message:sms:configSms:delete')"
              size="mini"
              plain
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <!-- 新增、编辑 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      :smsPlatFormList="SmsPlatFormList"
      @refreshData="queryList"
    ></form-edit>
    <!-- 详情 -->
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
    <!-- 短信调试窗口 -->
    <el-dialog title="短信调试" :visible.sync="dialogDebugFormVisible">
      <el-form :model="debugForm">
        <el-form-item label="手机号" :label-width="formLabelWidth">
          <el-input v-model="debugForm.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="参数" :label-width="formLabelWidth">
          <el-input
            v-model="debugForm.params"
            autocomplete="off"
            :placeholder="sendSmsParamHolder"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDebugFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendSms">发送</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  configsmsPage,
  configsmsDel,
  configsmsDelBatch,
  smsSend,
} from "@/api/message/sms/configsms.js";
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./configsms-edit";
import FormInfo from "./configsms-info";
import FortressPagination from "@/components/fortress-pagination.vue";
import { enumList } from "@/api/public.api.js";
export default {
  data() {
    return {
      parentShow: "",

      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      formLabelWidth: "120px",
      queryForm: {
        smsCode: "",
        platform: "",
        status: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      tableList: [],
      tableShowList: [],
      SmsPlatFormList: [],
      dialogDebugFormVisible: false,
      debugForm: {
        smsCode: "",
        mobile: "",
        params: "",
      },
      sendSmsParamHolder: '参数格式:{"code":"1111"}',
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    pagination: FortressPagination,
  },
  created() {
    this.querySmsPlatFormList();
    this.tableData = [];
    this.queryList();
    this.initTableList();
  },
  methods: {
    refresh() {
      this.tableData = [];
      this.queryList({ current: 1 });
    },
    //查询表格数据
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      configsmsPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false;
            this.tableData = resp.data.records;
            this.page.total = resp.data.total;
            //查询页大于总页数，重新查询
            let cu =
              this.page.total % this.page.size == 0
                ? parseInt(this.page.total / this.page.size)
                : parseInt(this.page.total / this.page.size + 1);
            if (cu > 0 && this.page.current > cu) {
              this.queryList({ current: cu });
            }
          }, 600);
        } else {
          this.parentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          configsmsDel(row.id).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              //判断是否最后一页的最后一条记录
              let totalPages =
                (this.page.total - 1) % this.page.size == 0
                  ? (this.page.total - 1) / this.page.size
                  : (this.page.total - 1) / this.page.size + 1;
              if (this.page.current > totalPages && this.page.current != 1) {
                this.page.current = this.page.current - 1;
              }
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    },
    //批量删除
    handleDeleteBatch() {
      let checkedRow = this.$refs.multipleTable.selection;
      if (checkedRow.length > 0) {
        let ids = [];
        checkedRow.forEach((item) => {
          ids.push(item.id);
        });
        this.$confirm("确认删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            configsmsDelBatch(ids).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                //判断是否最后一页的最后一条记录
                let totalPages =
                  (this.page.total - checkedRow.length) % this.page.size == 0
                    ? (this.page.total - checkedRow.length) / this.page.size
                    : (this.page.total - checkedRow.length) / this.page.size +
                      1;
                if (this.page.current > totalPages && this.page.current != 1) {
                  this.page.current = this.page.current - 1;
                }
                this.queryList();
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          })
          .catch(() => {});
      } else {
        this.$msg({
          message: "请选择数据进行删除",
          type: "error",
        });
      }
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        smsCode: "",
        platform: "",
        status: "",
      };
    },

    //视图表格列表初始化
    initTableList() {
      viewTableList("castle_config_sms").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
    //查询sms平台类型
    querySmsPlatFormList() {
      enumList("SmsPlatForm").then((resp) => {
        if (resp.code == 0) {
          this.SmsPlatFormList = resp.data;
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //状态格式化返回
    statusFmt(row) {
      let typeName = "未启用";
      if (row.status == 1) {
        typeName = "已启用";
      }
      return typeName;
    },
    //平台类型格式化返回
    platformFmt(row) {
      let name = "";
      var platForm = this.SmsPlatFormList.find((item) => {
        return item.code == row.platform;
      }); //查找第一个满足的
      if (platForm) {
        name = platForm.name;
      }
      return name;
    },
    //调试短信发送
    handleDebug(row) {
      this.sendSmsParamHolder = '参数格式:{"code":"1111"}';
      if (row.platform == "2") {
        this.sendSmsParamHolder = "多个参数用逗号隔开";
      }
      this.dialogDebugFormVisible = true;
      this.debugForm = {
        smsCode: row.smsCode,
        mobile: "",
        params: "",
      };
    },
    //发送短信
    sendSms() {
      this.dialogDebugFormVisible = false;
      smsSend(this.debugForm).then((resp) => {
        if (resp.code == 0) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style scoped></style>
