<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item
        label="用户名"
        :label-width="formLabelWidth"
        prop="userName"
      >
        <el-input
          v-model="form.userName"
          autocomplete="off"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <!--            <el-form-item label="密码" :label-width="formLabelWidth" prop="password">-->
      <!--                <el-input-->
      <!--                        v-model="form.password"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入密码"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
        <el-input
          v-model="form.phone"
          autocomplete="off"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickName">
        <el-input
          v-model="form.nickName"
          autocomplete="off"
          placeholder="请输入昵称"
        ></el-input>
      </el-form-item>
      <el-form-item label="头像" :label-width="formLabelWidth" prop="avatar">
        <fortress-upload
          type="image"
          name="avatar"
          :formData="form"
        ></fortress-upload>
      </el-form-item>
      <el-form-item label="性别" :label-width="formLabelWidth" prop="gender">
        <fortress-radio-group v-model="form.gender" :dictList="sexList">
        </fortress-radio-group>
      </el-form-item>
      <el-form-item label="生日" :label-width="formLabelWidth" prop="birthday">
        <el-date-picker
          v-model="form.birthday"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="省" :label-width="formLabelWidth" prop="province">
                <el-date-picker
                        v-model="form.province"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </el-form-item> -->
      <!--省市区-->
      <el-form-item label="地区" :label-width="formLabelWidth">
        <fortress-region
          ref="fortressRegion"
          @ftregion="regionHandler"
        ></fortress-region>
      </el-form-item>
      <!--            <el-form-item label="省名称" :label-width="formLabelWidth" prop="provinceName">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入省名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="市" :label-width="formLabelWidth" prop="city">-->
      <!--                <el-input-->
      <!--                        v-model="form.city"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入市"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="市名称" :label-width="formLabelWidth" prop="cityName">-->
      <!--                <el-input-->
      <!--                        v-model="form.cityName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入市名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="区" :label-width="formLabelWidth" prop="area">-->
      <!--                <el-input-->
      <!--                        v-model="form.area"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入区"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="区名称" :label-width="formLabelWidth" prop="areaName">-->
      <!--                <el-input-->
      <!--                        v-model="form.areaName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入区名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->

      <el-form-item
        label="详细地址"
        :label-width="formLabelWidth"
        prop="address"
      >
        <el-input
          v-model="form.address"
          autocomplete="off"
          placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>

      <el-form-item label="权重" :label-width="formLabelWidth" prop="sort">
        <el-input
          v-model="form.sort"
          autocomplete="off"
          placeholder="权重"
          type="number"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="会员类型[会员/商家]"
        :label-width="formLabelWidth"
        prop="type"
      >
        <fortress-radio-group
          v-model="form.type"
          :dictList="MemberTypeList"
          disabled
        >
        </fortress-radio-group>
      </el-form-item>
      <el-form-item
        label="审核状态（是否实名）"
        :label-width="formLabelWidth"
        prop="auditStatus"
      >
        <fortress-radio-group v-model="form.auditStatus" :dictList="YesNoList">
        </fortress-radio-group>
      </el-form-item>
      <!-- <el-form-item label="所属网点" :label-width="formLabelWidth" prop="branch">
                <fortress-select
                        v-model="form.branch"
                        :dictList="branchList"
                        placeholder="请选择所属网点"
                        clearable
                ></fortress-select>
            </el-form-item> -->

      <!-- <el-form-item label="货运类型（专线运输/货运代理）" :label-width="formLabelWidth" prop="type">
                <fortress-select
                        v-model="form.transportType"
                        :dictList="PDTypeList"
                        placeholder="请选择类型（专线运输/货运代理）"
                        clearable
                ></fortress-select>
            </el-form-item> -->

      <el-form-item
        label="公司名称"
        :label-width="formLabelWidth"
        prop="enterpriseName"
      >
        <el-input
          v-model="form.enterpriseName"
          autocomplete="off"
          placeholder="请输入公司名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="营业执照"
        :label-width="formLabelWidth"
        prop="cardType"
      >
        <fortress-upload
          type="image"
          name="cardType"
          :formData="form"
        ></fortress-upload>
        <!-- <el-input
                        v-model="form.cardType"
                        autocomplete="off"
                        placeholder="请输入营业执照"
                ></el-input> -->
      </el-form-item>
      <el-form-item
        label="法定代表人"
        :label-width="formLabelWidth"
        prop="person"
      >
        <el-input
          v-model="form.person"
          autocomplete="off"
          placeholder="请输入法定代表人"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="法人身份证号"
        :label-width="formLabelWidth"
        prop="personCode"
      >
        <el-input
          v-model="form.personCode"
          autocomplete="off"
          placeholder="请输入法人身份证号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="法人身份证正面"
        :label-width="formLabelWidth"
        prop="cardFront"
      >
        <fortress-upload
          type="image"
          name="cardFront"
          :formData="form"
        ></fortress-upload>
      </el-form-item>
      <el-form-item
        label="法人身份证反面"
        :label-width="formLabelWidth"
        prop="cardBack"
      >
        <fortress-upload
          type="image"
          name="cardBack"
          :formData="form"
        ></fortress-upload>
      </el-form-item>
      <!--            <el-form-item label="注册资本" :label-width="formLabelWidth" prop="capital">-->
      <!--                <el-input-->
      <!--                        v-model="form.capital"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入注册资本"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="经营范围" :label-width="formLabelWidth" prop="business">-->
      <!--                <el-input-->
      <!--                        type="textarea"-->
      <!--                        :rows="2"-->
      <!--                        placeholder="请输入经营范围"-->
      <!--                        v-model="form.business"-->
      <!--                >-->
      <!--                </el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="统一社会信用代码" :label-width="formLabelWidth" prop="creditCode">-->
      <!--                <el-input-->
      <!--                        v-model="form.creditCode"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入统一社会信用代码"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="成立日期" :label-width="formLabelWidth" prop="regDate">-->
      <!--                <el-date-picker-->
      <!--                        v-model="form.regDate"-->
      <!--                        type="date"-->
      <!--                        placeholder="选择日期"-->
      <!--                        value-format="yyyy-MM-dd"-->
      <!--                >-->
      <!--                </el-date-picker>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="营业期限" :label-width="formLabelWidth" prop="businessTerm">-->
      <!--                <el-input-->
      <!--                        v-model="form.businessTerm"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入营业期限"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="住所" :label-width="formLabelWidth" prop="residence">-->
      <!--                <el-input-->
      <!--                        v-model="form.residence"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入住所"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="登记机关" :label-width="formLabelWidth" prop="regAuthority">-->
      <!--                <el-input-->
      <!--                        v-model="form.regAuthority"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入登记机关"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="发证日期" :label-width="formLabelWidth" prop="awardDate">-->
      <!--                <el-date-picker-->
      <!--                        v-model="form.awardDate"-->
      <!--                        type="date"-->
      <!--                        placeholder="选择日期"-->
      <!--                        value-format="yyyy-MM-dd"-->
      <!--                >-->
      <!--                </el-date-picker>-->
      <!--            </el-form-item>-->
      <el-form-item
        label="道路运输许可证"
        :label-width="formLabelWidth"
        prop="permit"
      >
        <fortress-upload
          type="image"
          name="permit"
          :formData="form"
        ></fortress-upload>
        <!-- <el-input
          v-model="form.permit"
          autocomplete="off"
          placeholder="请输入道路运输许可证"
        ></el-input> -->
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
        <el-input
          v-model="form.remark"
          autocomplete="off"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <!--      <el-form-item-->
      <!--        label="是否推荐商家(搜索页右侧)"-->
      <!--        :label-width="formLabelWidth"-->
      <!--        prop="isRec"-->
      <!--      >-->
      <!--        <fortress-radio-group v-model="form.isRec" :dictList="YesNoList">-->
      <!--        </fortress-radio-group>-->
      <!--      </el-form-item>-->

      <!-- <el-form-item label="是否货运代理推荐(首页)" :label-width="formLabelWidth" prop="isAgentrec">
                <fortress-radio-group
                        v-model="form.isAgentrec"
                        :dictList="YesNoList"
                >
                </fortress-radio-group>
            </el-form-item> -->

      <!--      <el-form-item-->
      <!--        label="是否首页推荐"-->
      <!--        :label-width="formLabelWidth"-->
      <!--        prop="isIndex"-->
      <!--      >-->
      <!--        <fortress-radio-group v-model="form.isIndex" :dictList="YesNoList">-->
      <!--        </fortress-radio-group>-->
      <!--      </el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger"
        >取 消</el-button
      >
      <el-button type="primary" size="medium" @click="submitForm('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  pdmemberInfo,
  pdmemberShopSave,
  pdmemberEdit,
} from "@/api/pdworld/pdmember.js";
import { dictList, enumList, urlList } from "@/api/public.api.js";

export default {
  data() {
    return {
      visible: false,
      region: [],
      form: {
        sort: "",
        userName: "",
        password: "",
        phone: "",
        nickName: "",
        avatar: "",
        gender: "",
        birthday: "",
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
        address: "",
        type: "0",
        auditStatus: "1",
        branch: "",
        enterpriseName: "",
        cardType: "",
        person: "",
        personCode: "",
        cardFront: "",
        cardBack: "",
        capital: "",
        business: "",
        creditCode: "",
        regDate: "",
        businessTerm: "",
        residence: "",
        regAuthority: "",
        awardDate: "",
        permit: "",
        remark: "",
        isRec: "2",
        isAgentrec: "2",
        isIndex: "2",
        transportType: "1",
      },
      formLabelWidth: "120px",
      rules: {},
      sexList: [],
      MemberTypeList: [],
      YesNoList: [],
      branchList: [],
      PDTypeList: [],
    };
  },
  created() {},
  methods: {
    queryDictList() {
      this.sexList = [];
      dictList("sex").then((resp) => {
        if (resp.code == 0) {
          this.sexList = resp.data;
        }
      });
    },
    queryEnumList() {
      this.PDTypeList = [];
      enumList("PDType", "").then((resp) => {
        if (resp.code == 0) {
          this.PDTypeList = resp.data;
        }
      });
      this.MemberTypeList = [];
      enumList("MemberType", "").then((resp) => {
        if (resp.code == 0) {
          this.MemberTypeList = resp.data;
        }
      });
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    queryUrlList() {
      this.branchList = [];
      urlList("/pdworld/pdPark/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.branchList.push({ code: item.id, name: item.storeName });
            });
          }
        }
      });
    },
    //省市区三级联动
    regionHandler(regions) {
      console.log("regionHandler", regions);
      this.form.province = regions[0].id;
      this.form.city = regions[1].id;
      this.form.area = regions[2].id;

      this.form.provinceName = regions[0].name;
      this.form.cityName = regions[1].name;
      this.form.areaName = regions[2].name;
    },
    init(id) {
      this.resetForm();
      this.queryDictList();
      this.queryEnumList();
      this.queryUrlList();
      //修改
      if (id) {
        pdmemberInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              this.region.push(this.form.province);
              this.region.push(this.form.city);
              this.region.push(this.form.area);
              console.log(this.$refs.fortressRegion);
              this.$refs.fortressRegion.init(this.region);
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      } else {
        this.$nextTick(() => {
          this.$refs.fortressRegion.init([]);
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            pdmemberEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            pdmemberShopSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.region = [];
      this.form = {
        sort: "",
        userName: "",
        password: "",
        phone: "",
        nickName: "",
        avatar: "",
        gender: "",
        birthday: "",
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
        address: "",
        type: "0",
        auditStatus: "1",
        branch: "",
        enterpriseName: "",
        cardType: "",
        person: "",
        personCode: "",
        cardFront: "",
        cardBack: "",
        capital: "",
        business: "",
        creditCode: "",
        regDate: "",
        businessTerm: "",
        residence: "",
        regAuthority: "",
        awardDate: "",
        permit: "",
        remark: "",
        isRec: "2",
        isAgentrec: "2",
        isIndex: "2",
        transportType: "1",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
