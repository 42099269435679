import request from "@/common/request"

//快递公司表分页展示
export const pdexpressagecompanyPage = (queryForm) => {
    return request("get", "/pdworld/pdExpressageCompany/page", queryForm);
}
//快递公司表列表展示
export const pdexpressagecompanyList = (queryForm) => {
    return request("get", "/pdworld/pdExpressageCompany/list", queryForm);
}
//快递公司表详情
export const pdexpressagecompanyInfo = (id) => {
    return request('get', '/pdworld/pdExpressageCompany/info', {
        id: id
    })
}
//快递公司表信息保存
export const pdexpressagecompanySave = (data) => {
    return request('post', '/pdworld/pdExpressageCompany/save', data)
}
//快递公司表信息修改
export const pdexpressagecompanyEdit = (data) => {
    return request('post', '/pdworld/pdExpressageCompany/edit', data)
}
//快递公司表信息删除
export const pdexpressagecompanyDel = (id) => {
    return request('post', '/pdworld/pdExpressageCompany/delete?id=' + id)
}
//快递公司表批量删除
export const pdexpressagecompanyDelBatch = (ids) => {
    return request('post', '/pdworld/pdExpressageCompany/deleteBatch', ids)
}
//快递公司表动态表头导出
export const pdexpressagecompanyDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdExpressageCompany/exportDynamic', data)
}
