import request from "@/common/request"

//商家的线路分页展示
export const pdroutePage = (queryForm) => {
    return request("get", "/pdworld/pdRoute/page", queryForm);
}
//商家的线路列表展示
export const pdrouteList = (queryForm) => {
    return request("get", "/pdworld/pdRoute/list", queryForm);
}
//商家的线路详情
export const pdrouteInfo = (id) => {
    return request('get', '/pdworld/pdRoute/info', {
        id: id
    })
}
//商家的线路信息保存
export const pdrouteSave = (data) => {
    return request('post', '/pdworld/pdRoute/save', data)
}
//商家的线路信息修改
export const pdrouteEdit = (data) => {
    return request('post', '/pdworld/pdRoute/edit', data)
}
//商家的线路信息删除
export const pdrouteDel = (id) => {
    return request('post', '/pdworld/pdRoute/delete?id=' + id)
}
//商家的线路批量删除
export const pdrouteDelBatch = (ids) => {
    return request('post', '/pdworld/pdRoute/deleteBatch', ids)
}
//商家的线路动态表头导出
export const pdrouteDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdRoute/exportDynamic', data)
}
