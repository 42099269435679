import request from "@/common/request"

//消息记录表分页展示
export const noticerecordPage = (queryForm) => {
    return request("get", "/pdworld/noticeRecord/page", queryForm);
}
//消息记录表列表展示
export const noticerecordList = (queryForm) => {
    return request("get", "/pdworld/noticeRecord/list", queryForm);
}
//消息记录表详情
export const noticerecordInfo = (id) => {
    return request('get', '/pdworld/noticeRecord/info', {
        id: id
    })
}
//消息记录表信息保存
export const noticerecordSave = (data) => {
    return request('post', '/pdworld/noticeRecord/save', data)
}
//消息记录表信息修改
export const noticerecordEdit = (data) => {
    return request('post', '/pdworld/noticeRecord/edit', data)
}
//消息记录表信息删除
export const noticerecordDel = (id) => {
    return request('post', '/pdworld/noticeRecord/delete?id=' + id)
}
//消息记录表批量删除
export const noticerecordDelBatch = (ids) => {
    return request('post', '/pdworld/noticeRecord/deleteBatch', ids)
}
//消息记录表动态表头导出
export const noticerecordDynamicExport = (data) => {
    return request("eptpost", '/pdworld/noticeRecord/exportDynamic', data)
}
