import request from "@/common/request"

//分站推荐商家信息分页展示
export const subsiterecommendPage = (queryForm) => {
    return request("get", "/pdworld/subSiteRecommend/page", queryForm);
}
//分站推荐商家信息列表展示
export const subsiterecommendList = (queryForm) => {
    return request("get", "/pdworld/subSiteRecommend/list", queryForm);
}
//分站推荐商家信息详情
export const subsiterecommendInfo = (id) => {
    return request('get', '/pdworld/subSiteRecommend/info', {
        id: id
    })
}
//分站推荐商家信息信息保存
export const subsiterecommendSave = (data) => {
    return request('post', '/pdworld/subSiteRecommend/save', data)
}
//分站推荐商家信息信息修改
export const subsiterecommendEdit = (data) => {
    return request('post', '/pdworld/subSiteRecommend/edit', data)
}
//分站推荐商家信息信息删除
export const subsiterecommendDel = (id) => {
    return request('post', '/pdworld/subSiteRecommend/delete?id=' + id)
}
//分站推荐商家信息批量删除
export const subsiterecommendDelBatch = (ids) => {
    return request('post', '/pdworld/subSiteRecommend/deleteBatch', ids)
}
//分站推荐商家信息动态表头导出
export const subsiterecommendDynamicExport = (data) => {
    return request("eptpost", '/pdworld/subSiteRecommend/exportDynamic', data)
}
