import request from "@/common/request"

//商品图片表分页展示
export const goodsimgPage = (queryForm) => {
    return request("get", "/goods/goodsImg/page", queryForm);
}
//商品图片表列表展示
export const goodsimgList = (queryForm) => {
    return request("get", "/goods/goodsImg/list", queryForm);
}
//商品图片表详情
export const goodsimgInfo = (id) => {
    return request('get', '/goods/goodsImg/info', {
        id: id
    })
}
//商品图片表信息保存
export const goodsimgSave = (data) => {
    return request('post', '/goods/goodsImg/save', data)
}
//商品图片表信息修改
export const goodsimgEdit = (data) => {
    return request('post', '/goods/goodsImg/edit', data)
}
//商品图片表信息删除
export const goodsimgDel = (id) => {
    return request('post', '/goods/goodsImg/delete?id=' + id)
}
//商品图片表批量删除
export const goodsimgDelBatch = (ids) => {
    return request('post', '/goods/goodsImg/deleteBatch', ids)
}
//商品图片表动态表头导出
export const goodsimgDynamicExport = (data) => {
    return request("eptpost", '/goods/goodsImg/exportDynamic', data)
}
