import request from "@/common/request"

//物流圈分页展示
export const logisticsdynamicsPage = (queryForm) => {
    return request("get", "/pdworld/logisticsDynamics/page", queryForm);
}
//物流圈列表展示
export const logisticsdynamicsList = (queryForm) => {
    return request("get", "/pdworld/logisticsDynamics/list", queryForm);
}
//物流圈详情
export const logisticsdynamicsInfo = (id) => {
    return request('get', '/pdworld/logisticsDynamics/info', {
        id: id
    })
}
//物流圈信息保存
export const logisticsdynamicsSave = (data) => {
    return request('post', '/pdworld/logisticsDynamics/save', data)
}
//物流圈信息修改
export const logisticsdynamicsEdit = (data) => {
    return request('post', '/pdworld/logisticsDynamics/edit', data)
}
//物流圈信息删除
export const logisticsdynamicsDel = (id) => {
    return request('post', '/pdworld/logisticsDynamics/delete?id=' + id)
}
//物流圈批量删除
export const logisticsdynamicsDelBatch = (ids) => {
    return request('post', '/pdworld/logisticsDynamics/deleteBatch', ids)
}
//物流圈动态表头导出
export const logisticsdynamicsDynamicExport = (data) => {
    return request("eptpost", '/pdworld/logisticsDynamics/exportDynamic', data)
}
