<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">分类名称:</label>
                    <div class="form-item-content">{{ objInfo.name }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">排序字段:</label>
                    <div class="form-item-content">{{ objInfo.sort }}
                    </div>
                </div>
<!--                <div class="form-item">-->
<!--                    <label class="form-item-label">是否删除:</label>-->
<!--                    <div class="form-item-content" v-if="objInfo.isDeleted == 1">启用</div>-->
<!--                    <div class="form-item-content" v-else>禁用</div>-->
<!--                </div>-->
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建人:</label>
                    <div class="form-item-content">{{ objInfo.createUserName }}</div>
                </div>
<!--                <div class="form-item">-->
<!--                    <label class="form-item-label">状态:</label>-->
<!--                    <div class="form-item-content" v-if="objInfo.status == 1">启用</div>-->
<!--                    <div class="form-item-content" v-else>禁用</div>-->
<!--                </div>-->
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { logisticsdynamicstypeInfo } from "@/api/pdworld/logisticsdynamicstype.js";
    import { enumList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                YesNoList:[],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.YesNoList = [];
              enumList("YesNo","").then((resp) => {
                if (resp.code == 0) {
                  this.YesNoList = resp.data;
                }
              });
          },
            init(id) {
                this.queryEnumList();
                if (id) {
                   logisticsdynamicstypeInfo(id).then(res => {
                        this.objInfo = res.data
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
