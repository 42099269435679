<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">发货省:</label>
                    <div class="form-item-content">{{ objInfo.consigner }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发货省名称:</label>
                    <div class="form-item-content">{{ objInfo.consignerName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发货市:</label>
                    <div class="form-item-content">{{ objInfo.consignerCity }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发货市名称:</label>
                    <div class="form-item-content">{{ objInfo.consignerCityName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发货区:</label>
                    <div class="form-item-content">{{ objInfo.consignerArea }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发货区名称:</label>
                    <div class="form-item-content">{{ objInfo.consignerAreaName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">收货省:</label>
                    <div class="form-item-content">{{ objInfo.province }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">收货省名称:</label>
                    <div class="form-item-content">{{ objInfo.provinceName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">收货市:</label>
                    <div class="form-item-content">{{ objInfo.provinceCity }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">收货市名称:</label>
                    <div class="form-item-content">{{ objInfo.provinceCityName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">收货区:</label>
                    <div class="form-item-content">{{ objInfo.provinceArea }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">收货区名称:</label>
                    <div class="form-item-content">{{ objInfo.areaName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">竞价日期:</label>
                    <div class="form-item-content">{{ objInfo.biddingDate }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">最高出价:</label>
                    <div class="form-item-content">{{ objInfo.maxPrice }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { pdroutebiddingrecordInfo } from "@/api/pdworld/pdroutebiddingrecord.js";
    
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
            }
        },
        created() {
        },
        methods: {
            init(id) {
                if (id) {
                   pdroutebiddingrecordInfo(id).then(res => {
                        this.objInfo = res.data
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
