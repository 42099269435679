import request from "@/common/request"

//会员订单/购买线路/购买VIP分页展示
export const pdmemberorderPage = (queryForm) => {
    return request("get", "/pdworld/pdMemberOrder/page", queryForm);
}
//会员订单/购买线路/购买VIP列表展示
export const pdmemberorderList = (queryForm) => {
    return request("get", "/pdworld/pdMemberOrder/list", queryForm);
}
//会员订单/购买线路/购买VIP详情
export const pdmemberorderInfo = (id) => {
    return request('get', '/pdworld/pdMemberOrder/info', {
        id: id
    })
}
//会员订单/购买线路/购买VIP信息保存
export const pdmemberorderSave = (data) => {
    return request('post', '/pdworld/pdMemberOrder/save', data)
}
//会员订单/购买线路/购买VIP信息修改
export const pdmemberorderEdit = (data) => {
    return request('post', '/pdworld/pdMemberOrder/edit', data)
}
//会员订单/购买线路/购买VIP信息删除
export const pdmemberorderDel = (id) => {
    return request('post', '/pdworld/pdMemberOrder/delete?id=' + id)
}
//会员订单/购买线路/购买VIP批量删除
export const pdmemberorderDelBatch = (ids) => {
    return request('post', '/pdworld/pdMemberOrder/deleteBatch', ids)
}
//会员订单/购买线路/购买VIP动态表头导出
export const pdmemberorderDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdMemberOrder/exportDynamic', data)
}
