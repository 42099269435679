import request from "@/common/request"

//线路广告分页展示
export const routeadvertisingPage = (queryForm) => {
    return request("get", "/pdworld/routeAdvertising/page", queryForm);
}
//线路广告列表展示
export const routeadvertisingList = (queryForm) => {
    return request("get", "/pdworld/routeAdvertising/list", queryForm);
}
//线路广告详情
export const routeadvertisingInfo = (id) => {
    return request('get', '/pdworld/routeAdvertising/info', {
        id: id
    })
}
//线路广告信息保存
export const routeadvertisingSave = (data) => {
    return request('post', '/pdworld/routeAdvertising/save', data)
}
//线路广告信息修改
export const routeadvertisingEdit = (data) => {
    return request('post', '/pdworld/routeAdvertising/edit', data)
}
//线路广告信息删除
export const routeadvertisingDel = (id) => {
    return request('post', '/pdworld/routeAdvertising/delete?id=' + id)
}
//线路广告批量删除
export const routeadvertisingDelBatch = (ids) => {
    return request('post', '/pdworld/routeAdvertising/deleteBatch', ids)
}
//线路广告动态表头导出
export const routeadvertisingDynamicExport = (data) => {
    return request("eptpost", '/pdworld/routeAdvertising/exportDynamic', data)
}
