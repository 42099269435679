import request from "@/common/request"

//商家线路竞价记录分页展示
export const pdroutebiddingrecordPage = (queryForm) => {
    return request("get", "/pdworld/pdRouteBiddingRecord/page", queryForm);
}
//商家线路竞价记录列表展示
export const pdroutebiddingrecordList = (queryForm) => {
    return request("get", "/pdworld/pdRouteBiddingRecord/list", queryForm);
}
//商家线路竞价记录详情
export const pdroutebiddingrecordInfo = (id) => {
    return request('get', '/pdworld/pdRouteBiddingRecord/info', {
        id: id
    })
}
//商家线路竞价记录信息保存
export const pdroutebiddingrecordSave = (data) => {
    return request('post', '/pdworld/pdRouteBiddingRecord/save', data)
}
//商家线路竞价记录信息修改
export const pdroutebiddingrecordEdit = (data) => {
    return request('post', '/pdworld/pdRouteBiddingRecord/edit', data)
}
//商家线路竞价记录信息删除
export const pdroutebiddingrecordDel = (id) => {
    return request('post', '/pdworld/pdRouteBiddingRecord/delete?id=' + id)
}
//商家线路竞价记录批量删除
export const pdroutebiddingrecordDelBatch = (ids) => {
    return request('post', '/pdworld/pdRouteBiddingRecord/deleteBatch', ids)
}
//商家线路竞价记录动态表头导出
export const pdroutebiddingrecordDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdRouteBiddingRecord/exportDynamic', data)
}
