<template>
    <div class="padding20">
        <el-row style="margin-bottom: 7px">
            <el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
                <el-form-item  >
                    <el-input v-model="queryForm.name" autocomplete="off" placeholder="请输入中文" clearable></el-input>
                </el-form-item>

                <el-form-item  >
                    <el-input v-model="queryForm.auth" autocomplete="off" placeholder="请输入字母" clearable></el-input>
                </el-form-item>

                <el-form-item  v-show="collapse.flag" >
                    <el-input v-model="queryForm.phone" autocomplete="off" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>

                <el-form-item  v-show="collapse.flag" >
                    <el-input v-model="queryForm.email" autocomplete="off" placeholder="请输入邮箱" clearable></el-input>
                </el-form-item>

                <el-form-item  v-show="collapse.flag" >
                    <el-input v-model="queryForm.vueNumber" autocomplete="off" placeholder="请输入数字" clearable></el-input>
                </el-form-item>

                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.vueRadio"
                      :dictList="dictDemoList"
                      placeholder="请选择单选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.vueCheckbox"
                      :dictList="dictDemoList"
                      placeholder="请选择复选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.vueSelect"
                      :dictList="dictDemoList"
                      placeholder="请选择下拉"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.selectEnum"
                      :dictList="DataPermissionPostList"
                      placeholder="请选择枚举下拉"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.selectUrl"
                      :dictList="selectUrlList"
                      placeholder="请选择url下拉"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.selectJson"
                      :dictList="selectJsonList"
                      placeholder="请选择json下拉"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                <el-date-picker
                        v-model="queryForm.vueDate"
                        type="date"
                        placeholder="请选择日期"
                        value-format="yyyy-MM-dd"
                        clearable
                >
                </el-date-picker>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                <el-date-picker
                        v-model="queryForm.vueDatetime"
                        type="datetime"
                        placeholder="请选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        clearable
                >
                </el-date-picker>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                  <el-select
                      v-model="queryForm.status"
                      placeholder="请选择状态"
                      clearable
                  >
                    <el-option label="启用" value="1"></el-option>
                    <el-option label="禁用" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.redioEnum"
                      :dictList="DataPermissionPostList"
                      placeholder="请选择枚举单选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.radioUrl"
                      :dictList="radioUrlList"
                      placeholder="请选择url单选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.radioJson"
                      :dictList="radioJsonList"
                      placeholder="请选择json单选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.checkboxEnum"
                      :dictList="DataPermissionPostList"
                      placeholder="请选择枚举复选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.checkboxUrl"
                      :dictList="checkboxUrlList"
                      placeholder="请选择url复选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item  v-show="collapse.flag" >
                    <fortress-select
                      v-model="queryForm.checkboxJson"
                      :dictList="checkboxJsonList"
                      placeholder="请选择json复选"
                      clearable
                      filterable
                    ></fortress-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({current:1})">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="text" @click="handleCollapseState"
                    >{{ collapse.label }}<i :class="collapse.icon"></i
                    ></el-button>
                </el-form-item>
            </el-form>
            <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    v-if="$tools.hasPermission('demo:tmpDemoGenerate:save')"
                    @click="handleEdit()"
            >添加</el-button
            >
            <el-button
                    v-if="$tools.hasPermission('demo:tmpDemoGenerate:deleteBatch')"
                    size="medium"
                    type="danger"
                    icon="el-icon-delete"
                    @click="handleDeleteBatch()"
            >删除</el-button
            >
            <div style="float: right; padding-right: 17px">
                <i
                  class="el-icon-download"
                  style="margin-right: 10px; cursor: pointer"
                  @click="handleExport"
                ></i>
                <i
                        class="el-icon-refresh"
                        style="margin-right: 10px; cursor: pointer"
                        @click="refresh"
                ></i>

                <el-popover placement="bottom" width="180" trigger="click" title="列展示" popper-class="columnShows">
                    <el-checkbox-group v-model="tableShowList">
                        <el-checkbox
                                v-for="item in tableList"
                                :label="item.propName"
                                :key="item.propName"
                                :value="item.propName"
                        >{{ item.propDesc }}</el-checkbox
                        >
                    </el-checkbox-group>
                    <i
                            slot="reference"
                            class="el-icon-s-operation"
                            style="cursor: pointer"
                    ></i>
                </el-popover>
            </div>
        </el-row>
        <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
        >
            <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>

            <el-table-column
              v-if="$tools.isShow('name', tableShowList)"
              prop="name"
              label="中文"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                 v-if="$tools.isShow('content', tableShowList)"
                 prop="content"
                 label="富文本"
                 :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <p v-html="scope.row.content"></p>
              </template>
            </el-table-column>
            <el-table-column
              v-if="$tools.isShow('auth', tableShowList)"
              prop="auth"
              label="字母"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              v-if="$tools.isShow('phone', tableShowList)"
              prop="phone"
              label="手机号"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              v-if="$tools.isShow('email', tableShowList)"
              prop="email"
              label="邮箱"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
          <el-table-column
              v-if="$tools.isShow('images', tableShowList)"
              prop="images"
              label="图片"
              :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-image
                  v-if="scope.row.images"
                  :src="scope.row.images"
                  style="width: 100px; height: 100px"
                  :preview-src-list="[scope.row.images]"
              />
            </template>
          </el-table-column>
          <el-table-column
              v-if="$tools.isShow('files', tableShowList)"
              prop="files"
              label="文件"
              :show-overflow-tooltip="true"
              :formatter="filesFmt"
          >
          </el-table-column>
          <el-table-column
              v-if="$tools.isShow('video', tableShowList)"
              prop="video"
              label="视频"
              :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <fortress-upload
                  style="width: 100px; height: 100px"
                  v-if="scope.row.video"
                  type="videoShow"
                  name="video"
                  :formData="tableData[scope.$index]"
                  :videoSrc="scope.row.video"
              ></fortress-upload>
            </template>
          </el-table-column>
            <el-table-column
              v-if="$tools.isShow('vueNumber', tableShowList)"
              prop="vueNumber"
              label="数字"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('vueRadio', tableShowList)"
                    prop="vueRadio"
                    label="单选"
                    :show-overflow-tooltip="true"
                    :formatter="vueRadioFmt"
            >
            </el-table-column>
            <el-table-column
              v-if="$tools.isShow('vueTextarea', tableShowList)"
              prop="vueTextarea"
              label="多行文本"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('vueCheckbox', tableShowList)"
                    prop="vueCheckbox"
                    label="复选"
                    :show-overflow-tooltip="true"
                    :formatter="vueCheckboxFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('vueSelect', tableShowList)"
                    prop="vueSelect"
                    label="下拉"
                    :show-overflow-tooltip="true"
                    :formatter="vueSelectFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('selectEnum', tableShowList)"
                    prop="selectEnum"
                    label="枚举下拉"
                    :show-overflow-tooltip="true"
                    :formatter="selectEnumFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('selectUrl', tableShowList)"
                    prop="selectUrl"
                    label="url下拉"
                    :show-overflow-tooltip="true"
                    :formatter="selectUrlFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('selectJson', tableShowList)"
                    prop="selectJson"
                    label="json下拉"
                    :show-overflow-tooltip="true"
                    :formatter="selectJsonFmt"
            >
            </el-table-column>
            <el-table-column
              v-if="$tools.isShow('vueDate', tableShowList)"
              prop="vueDate"
              label="日期"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              v-if="$tools.isShow('vueDatetime', tableShowList)"
              prop="vueDatetime"
              label="日期时间"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                  v-if="$tools.isShow('status', tableShowList)"
                  prop="status"
                  label="状态"
                  :show-overflow-tooltip="true"
                  :formatter="statusFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('createUser', tableShowList)"
                    prop="createUserName"
                    label="创建人"
                    :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              v-if="$tools.isShow('createTime', tableShowList)"
              prop="createTime"
              label="创建时间"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('redioEnum', tableShowList)"
                    prop="redioEnum"
                    label="枚举单选"
                    :show-overflow-tooltip="true"
                    :formatter="redioEnumFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('radioUrl', tableShowList)"
                    prop="radioUrl"
                    label="url单选"
                    :show-overflow-tooltip="true"
                    :formatter="radioUrlFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('radioJson', tableShowList)"
                    prop="radioJson"
                    label="json单选"
                    :show-overflow-tooltip="true"
                    :formatter="radioJsonFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('checkboxEnum', tableShowList)"
                    prop="checkboxEnum"
                    label="枚举复选"
                    :show-overflow-tooltip="true"
                    :formatter="checkboxEnumFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('checkboxUrl', tableShowList)"
                    prop="checkboxUrl"
                    label="url复选"
                    :show-overflow-tooltip="true"
                    :formatter="checkboxUrlFmt"
            >
            </el-table-column>
            <el-table-column
                    v-if="$tools.isShow('checkboxJson', tableShowList)"
                    prop="checkboxJson"
                    label="json复选"
                    :show-overflow-tooltip="true"
                    :formatter="checkboxJsonFmt"
            >
            </el-table-column>
            <el-table-column label="操作" fixed="right" min-width="180">
                <template slot-scope="scope">
                    <el-button
                            v-if="$tools.hasPermission('demo:tmpDemoGenerate:info')"
                            size="mini"
                            plain
                            @click="handleInfo(scope.row.id)"
                    >详情</el-button
                    >
                    <el-button
                            v-if="$tools.hasPermission('demo:tmpDemoGenerate:edit')"
                            size="mini"
                            plain
                            @click="handleEdit(scope.row.id)"
                    >编辑</el-button
                    >
                    <el-button
                            v-if="$tools.hasPermission('demo:tmpDemoGenerate:delete')"
                            size="mini"
                            plain
                            type="danger"
                            @click="handleDelete(scope.row)"
                    >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="page.total" :current-page="page.current" :page-size="page.size" @refreshData="queryList"></pagination>
        <!-- 新增、编辑 -->
        <form-edit
                v-if="formEditVisible"
                ref="formEdit"
                @refreshData="queryList"
        ></form-edit>
        <!-- 详情 -->
        <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
    </div>
</template>
<script>
    import { tmpdemogeneratePage, tmpdemogenerateDel, tmpdemogenerateDelBatch, tmpdemogenerateDynamicExport, } from "@/api/demo/tmpdemogenerate.js";
    import { viewTableList } from "@/api/public.api.js";
    import FormEdit from "./tmpdemogenerate-edit";
    import FormInfo from "./tmpdemogenerate-info";
    import FortressPagination from "@/components/fortress-pagination.vue";
    import { dictList,enumList,urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                tableData: [],
                formEditVisible: false,
                formInfoVisible: false,
                queryForm: {
                        name:"",
                        auth:"",
                        phone:"",
                        email:"",
                        vueNumber:"",
                        vueRadio:"",
                        vueCheckbox:"",
                        vueSelect:"",
                        selectEnum:"",
                        selectUrl:"",
                        selectJson:"",
                        vueDate:"",
                        vueDatetime:"",
                        status:"",
                        redioEnum:"",
                        radioUrl:"",
                        radioJson:"",
                        checkboxEnum:"",
                        checkboxUrl:"",
                        checkboxJson:"",
                },
                page: {
                    current: 1,
                    size: 10,
                    total: 0,
                },
                collapse: {
                    icon: "el-icon-arrow-down el-icon--right",
                    label: "展开",
                    flag: false,
                },
                tableList: [],
                tableShowList: [],
                dictDemoList:[],
                DataPermissionPostList:[],
                selectUrlList:[],
                radioUrlList:[],
                checkboxUrlList:[],
                selectJsonList:[],
                radioJsonList:[],
                checkboxJsonList:[],
            };
        },
        components: {
            "form-edit": FormEdit,
            "form-info": FormInfo,
            pagination: FortressPagination,
        },
        created() {
            this.queryDictList();
            this.queryEnumList();
            this.queryUrlList();
            this.queryJsonList();
            this.initTableList();
            this.queryList();
        },
        methods: {
            queryDictList(){
              this.dictDemoList = [];
              dictList("dictDemo").then((resp) => {
                if (resp.code == 0) {
                  this.dictDemoList = resp.data;
                }
              });
            },
            queryEnumList(){
              this.DataPermissionPostList = [];
                enumList("DataPermissionPost","").then((resp) => {
                if (resp.code == 0) {
                  this.DataPermissionPostList = resp.data;
                }
              });
            },
            queryUrlList(){
              this.selectUrlList = [];
              urlList("/system/sysRole/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.selectUrlList.push({ code: item.id, name: item.name });
                    });
                  }
                }
              });
              this.radioUrlList = [];
              urlList("/system/sysRole/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.radioUrlList.push({ code: item.id, name: item.name });
                    });
                  }
                }
              });
              this.checkboxUrlList = [];
              urlList("/system/sysRole/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.checkboxUrlList.push({ code: item.id, name: item.name });
                    });
                  }
                }
              });
            },
            queryJsonList(){
                this.selectJsonList = [];
                let selectJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
                this.selectJsonList = JSON.parse(selectJsonListData);
                this.radioJsonList = [];
                let radioJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
                this.radioJsonList = JSON.parse(radioJsonListData);
                this.checkboxJsonList = [];
                let checkboxJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
                this.checkboxJsonList = JSON.parse(checkboxJsonListData);
            },
            refresh() {
              this.tableData = [];
              this.queryList({ current: 1 });
            },
            //查询表格数据
            queryList(page) {
                if (page) {
                    this.page.current = page.current?page.current:this.page.current;
                    this.page.size = page.size?page.size:this.page.size;
                }
                this.queryForm.current = this.page.current;
                this.queryForm.size = this.page.size;
                tmpdemogeneratePage(this.queryForm).then((resp) => {
                    if (resp.code == 0) {
                        this.tableData = resp.data.records;
                        this.page.total = resp.data.total;
                        //查询页大于总页数，重新查询
                        let cu = this.page.total%this.page.size==0?parseInt(this.page.total/this.page.size):parseInt((this.page.total/this.page.size)+1);
                        if(cu>0 && this.page.current>cu){
                          this.queryList({current:cu});
                        }
                    } else {
                        this.$msg({
                            message: resp.msg,
                            type: "error",
                        });
                    }
                });
            },
            //打开编辑窗口
            handleEdit(id) {
                this.formEditVisible = true;
                this.$nextTick(() => {
                    this.$refs.formEdit.init(id);
                });
            },
            //打开详情窗口
            handleInfo(id) {
                this.formInfoVisible = true;
                this.$nextTick(() => {
                    this.$refs.formInfo.init(id);
                });
            },
            //删除
            handleDelete(row) {
                this.$confirm('确认删除该记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                        tmpdemogenerateDel(row.id).then((resp) => {
                        if (resp.code == 0) {
                            this.$message({
                                type: "success",
                                message: "操作成功!",
                            });
                           //判断是否最后一页的最后一条记录
                           let totalPages =
                              (this.page.total - 1) % this.page.size == 0
                                  ? (this.page.total - 1) / this.page.size
                                  : (this.page.total - 1) / this.page.size + 1;
                           if (this.page.current > totalPages && this.page.current != 1) {
                            this.page.current = this.page.current - 1;
                           }
                            this.queryList();
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }).catch(() => {
                });
            },
            //批量删除
            handleDeleteBatch() {
                let checkedRow = this.$refs.multipleTable.selection;
                if (checkedRow.length > 0) {
                    let ids = [];
                    checkedRow.forEach((item) => {
                        ids.push(item.id);
                    });
                    this.$confirm("确认删除该记录, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                            .then(() => {
                                tmpdemogenerateDelBatch(ids).then((resp) => {
                                    if (resp.code == 0) {
                                        this.$message({
                                            type: "success",
                                            message: "操作成功!",
                                        });
                                      //判断是否最后一页的最后一条记录
                                      let totalPages =
                                          (this.page.total - checkedRow.length) % this.page.size == 0
                                              ? (this.page.total - checkedRow.length) / this.page.size
                                              : (this.page.total - checkedRow.length) / this.page.size +
                                              1;
                                      if (this.page.current > totalPages && this.page.current != 1) {
                                        this.page.current = this.page.current - 1;
                                      }
                                        this.queryList();
                                    } else {
                                        this.$msg({
                                            message: resp.msg,
                                            type: "error",
                                        });
                                    }
                                });
                            })
                            .catch(() => {});
                } else {
                    this.$msg({
                        message: "请选择数据进行删除",
                        type: "error",
                    });
                }
            },
            //重置查询框
            resetQueryForm() {
                this.queryForm = {
                        name:"",
                        auth:"",
                        phone:"",
                        email:"",
                        vueNumber:"",
                        vueRadio:"",
                        vueCheckbox:"",
                        vueSelect:"",
                        selectEnum:"",
                        selectUrl:"",
                        selectJson:"",
                        vueDate:"",
                        vueDatetime:"",
                        status:"",
                        redioEnum:"",
                        radioUrl:"",
                        radioJson:"",
                        checkboxEnum:"",
                        checkboxUrl:"",
                        checkboxJson:"",
                };
            },
            //切换查询条件收缩框
            handleCollapseState() {
                this.collapse.flag = !this.collapse.flag;
                if (this.collapse.flag) {
                    this.collapse.icon = "el-icon-arrow-up el-icon--right";
                    this.collapse.label = "收起";
                } else {
                    this.collapse.icon = "el-icon-arrow-down el-icon--right";
                    this.collapse.label = "展开";
                }
            },
            //视图表格列表初始化
            initTableList() {
                viewTableList("tmp_demo_generate").then((resp) => {
                    if (resp.code == 0) {
                        this.tableList = resp.data;
                        this.tableList.forEach((item) => {
                            this.tableShowList.push(item.propName);
                        });
                    }
                });
            },
            filesFmt(row) {
                let name = "";
                if (row.files) {
                  let filesArray = JSON.parse(row.files);
                  filesArray.forEach((item) => {
                    name = name + item.name + ",";
                  });
                }
                if (name != "") {
                  name = name.substring(0, name.length - 1);
                }
                return name;
            },
            //单选格式化返回
            vueRadioFmt(row) {
                let name = "";
                if (this.dictDemoList) {
                    this.dictDemoList.forEach(item =>{
                        if(item.code == row.vueRadio){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            vueCheckboxFmt(row) {
                let name = "";
                if (this.dictDemoList && row.vueCheckbox) {
                    let vueCheckboxArray = row.vueCheckbox.split(";");
                    this.dictDemoList.forEach(item =>{
                        vueCheckboxArray.forEach((o) => {
                            if (o == item.code) {
                                name += item.name + " ";
                            }
                        });
                    })
                }
                return name;
            },
            //下拉格式化返回
            vueSelectFmt(row) {
                let name = "";
                if (this.dictDemoList) {
                    this.dictDemoList.forEach(item =>{
                        if(item.code == row.vueSelect){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            //枚举下拉格式化返回
            selectEnumFmt(row) {
                let name = "";
                if (this.DataPermissionPostList) {
                    this.DataPermissionPostList.forEach(item =>{
                        if(item.code == row.selectEnum){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            //url下拉格式化返回
            selectUrlFmt(row) {
                let name = "";
                if (this.selectUrlList) {
                    this.selectUrlList.forEach(item =>{
                        if(item.code == row.selectUrl){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            //json下拉格式化返回
            selectJsonFmt(row) {
                let name = "";
                if (this.selectJsonList) {
                    this.selectJsonList.forEach(item =>{
                        if(item.code == row.selectJson){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            statusFmt(row) {
                let name = "禁用";
                if (row.status == 1) {
                  name = "启用";
                }
                return name;
            },
            //枚举单选格式化返回
            redioEnumFmt(row) {
                let name = "";
                if (this.DataPermissionPostList) {
                    this.DataPermissionPostList.forEach(item =>{
                        if(item.code == row.redioEnum){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            //url单选格式化返回
            radioUrlFmt(row) {
                let name = "";
                if (this.radioUrlList) {
                    this.radioUrlList.forEach(item =>{
                        if(item.code == row.radioUrl){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            //json单选格式化返回
            radioJsonFmt(row) {
                let name = "";
                if (this.radioJsonList) {
                    this.radioJsonList.forEach(item =>{
                        if(item.code == row.radioJson){
                            name = item.name;
                        }
                    })
                }
                return name;
            },
            checkboxEnumFmt(row) {
                let name = "";
                if (this.DataPermissionPostList && row.checkboxEnum) {
                    let checkboxEnumArray = row.checkboxEnum.split(";");
                    this.DataPermissionPostList.forEach(item =>{
                        checkboxEnumArray.forEach((o) => {
                            if (o == item.code) {
                                name += item.name + " ";
                            }
                        });
                    })
                }
                return name;
            },
            checkboxUrlFmt(row) {
                let name = "";
                if (this.checkboxUrlList && row.checkboxUrl) {
                    let checkboxUrlArray = row.checkboxUrl.split(";");
                    this.checkboxUrlList.forEach(item =>{
                        checkboxUrlArray.forEach((o) => {
                            if (o == item.code) {
                                name += item.name + " ";
                            }
                        });
                    })
                }
                return name;
            },
            checkboxJsonFmt(row) {
                let name = "";
                if (this.checkboxJsonList && row.checkboxJson) {
                    let checkboxJsonArray = row.checkboxJson.split(";");
                    this.checkboxJsonList.forEach(item =>{
                        checkboxJsonArray.forEach((o) => {
                            if (o == item.code) {
                                name += item.name + " ";
                            }
                        });
                    })
                }
                return name;
            },
            //可见字段excel导出
            handleExport() {
              if (!this.tableShowList || this.tableShowList.length < 1) {
                this.$msg({
                  message: "请选择要导出的列",
                  type: "error",
                });
                return;
              }
              let colNum = 1;
              let headerList = [];
              this.tableShowList.forEach((item) => {
                let nameData = this.tableList.find((item2) => {
                  return item2.propName == item;
                });
                let name = "";
                if (nameData && nameData.propDesc) {
                  name = nameData.propDesc;
                } else {
                  name = "未定义字段" + colNum++;
                }
                headerList.push({ name: name, code: item });
              });
            tmpdemogenerateDynamicExport({ headerList: headerList, dto: this.queryForm });
            },
        },
    };
</script>
<style scoped>
</style>
