<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">所属类型:</label>
                    <div class="form-item-content">{{ objInfo.typeIdName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">文章标题:</label>
                    <div class="form-item-content">{{ objInfo.articleName }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">文章内容:</label>
                    <div class="form-item-content" v-html="objInfo.articleContent"></div>
                </div>
<!--                <div class="form-item">-->
<!--                    <label class="form-item-label">阅读次数:</label>-->
<!--                    <div class="form-item-content">{{ objInfo.viewsNumber }}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="form-item">-->
<!--                    <label class="form-item-label">发布人:</label>-->
<!--                    <div class="form-item-content">{{ objInfo.visitorName }}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="form-item">-->
<!--                    <label class="form-item-label">发布时间:</label>-->
<!--                    <div class="form-item-content">{{ objInfo.visitorDate }}-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { helparticleInfo } from "@/api/helparticle/helparticle.js";
    import { enumList,urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                YesNoList:[],
                typeIdList:[],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.YesNoList = [];
              enumList("YesNo","").then((resp) => {
                if (resp.code == 0) {
                  this.YesNoList = resp.data;
                }
              });
          },
          queryUrlList(){
              this.typeIdList = [];
              urlList("/helparticle/helpArticleType/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.typeIdList.push({ code: item.id, name: item.typeName });
                    });
                  }
                }
              });
          },
            init(id) {
                this.queryEnumList();
                this.queryUrlList();
                if (id) {
                   helparticleInfo(id).then(res => {
                        this.objInfo = res.data
                              let typeIdObj = this.typeIdList.find((item) => {
                                return item.code == this.objInfo.typeId;
                              });
                              this.objInfo.typeIdName = typeIdObj ? typeIdObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
