import request from "@/common/request"

//获取指定部门下的职位列表
export const postList = (params) => {
    return request("get", "/system/sysPost/list", params);
}
//职位详情
export const postInfo = (id) => {
    return request('get', '/system/sysPost/info', {
        id: id
    })
}
//保存职位信息
export const postSave = (data) => {
    return request('post', '/system/sysPost/save', data)
}
//修改职位信息
export const postEdit = (data) => {
    return request('post', '/system/sysPost/edit', data)
}
//删除职位信息
export const postDel = (id) => {
    return request('post', '/system/sysPost/delete?id=' + id)
}