import request from "@/common/request"

//全局设置(底部平台信息，关于我们等。)分页展示
export const pdsettingPage = (queryForm) => {
    return request("get", "/pdworld/pdSetting/page", queryForm);
}
//全局设置(底部平台信息，关于我们等。)列表展示
export const pdsettingList = (queryForm) => {
    return request("get", "/pdworld/pdSetting/list", queryForm);
}
//全局设置(底部平台信息，关于我们等。)详情
export const pdsettingInfo = (id) => {
    return request('get', '/pdworld/pdSetting/info', {
        id: id
    })
}
//获取设置详情。取库里第一条。
export const pdsettingDetail = () => {
    return request('get', '/pdworld/pdSetting/detail')
}

//全局设置(底部平台信息，关于我们等。)信息保存
export const pdsettingSave = (data) => {
    return request('post', '/pdworld/pdSetting/save', data)
}
//全局设置(底部平台信息，关于我们等。)信息修改
export const pdsettingEdit = (data) => {
    return request('post', '/pdworld/pdSetting/edit', data)
}
//全局设置(底部平台信息，关于我们等。)信息删除
export const pdsettingDel = (id) => {
    return request('post', '/pdworld/pdSetting/delete?id=' + id)
}
//全局设置(底部平台信息，关于我们等。)批量删除
export const pdsettingDelBatch = (ids) => {
    return request('post', '/pdworld/pdSetting/deleteBatch', ids)
}
//全局设置(底部平台信息，关于我们等。)动态表头导出
export const pdsettingDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdSetting/exportDynamic', data)
}
