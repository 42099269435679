<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <!-- <el-form-item label="发布人" :label-width="formLabelWidth" prop="publisherId"> -->
        <!--        <fortress-select-->
        <!--            filterable-->
        <!--            v-model="form.publisherId"-->
        <!--            :dictList="publisherIdList"-->
        <!--            placeholder="请选择发布人"-->
        <!--            clearable-->
        <!--        ></fortress-select>-->
        <!-- <el-input v-model="displayMemberName" autocomplete="off" placeholder="请选择发布人" @focus="dialogHandle">
        </el-input>
      </el-form-item> -->
      <el-form-item label="分类" :label-width="formLabelWidth" prop="typeId">
        <fortress-select
            filterable
            v-model="form.typeId"
            :dictList="typeIdList"
            placeholder="请选择分类"
            clearable
			disabled
        ></fortress-select>
      </el-form-item>
      <el-form-item label="有效期" :label-width="formLabelWidth" prop="timeChose">
        <fortress-select
            filterable
            v-model="form.timeChose"
            :dictList="timeList"
            placeholder="请选择有效期"
            clearable
			disabled
        ></fortress-select>
      </el-form-item>
      <el-form-item label="联系人名称" :label-width="formLabelWidth" prop="contactName">
        <el-input
            v-model="form.contactName"
            autocomplete="off"
            placeholder="请输入联系人名称"
			disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人电话" :label-width="formLabelWidth" prop="contactPhone">
        <el-input
            v-model="form.contactPhone"
            autocomplete="off"
            placeholder="请输入联系人电话"
			disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
        <!-- <fortress-editor v-model="form.content"disabled></fortress-editor> -->
		<div class="editor__logisticsdynamics" v-html="form.content"></div>
      </el-form-item>

      <el-form-item label="位置" :label-width="formLabelWidth" prop="location">
        <el-input
            v-model="form.location"
            autocomplete="off"
            placeholder="请输入位置"
			disabled
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="审核状态" v-if="form.id!=null" :label-width="formLabelWidth" prop="auditStatus">
        <fortress-select
            v-model="form.auditStatus"
            :dictList="LogisticsAuditStatusList"
            placeholder="请选择审核状态"
            clearable
			disabled
        ></fortress-select>
      </el-form-item> -->
      <el-form-item label="图片" :label-width="formLabelWidth"
                    v-show="form.imgs!=null && form.imgs.length>0 && form.id!=null">
        <div class="form-item-content">
          <img v-for="(item,index) in form.imgs" :key="index" :src="item.img"
               style="width: 100px; height: 100px"/>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="warning" size="medium" @click="submitForm(2)">拒绝
      </el-button>
      <el-button type="success" size="medium" @click="submitForm(3)">通过
      </el-button>
    </div>
    <el-dialog append-to-body :close-on-click-modal="false" title="选择关联商家" :visible.sync="dialogVisible" width="80%"
               height="600px;">

      <div class="padding20">
        <el-row style="margin-bottom: 7px">
          <el-form
              :inline="true"
              :model="queryForm"
              @submit.native.prevent
              @keyup.enter.native="queryList"
          >
            <el-form-item>
              <el-input
                  v-model="queryForm.userName"
                  autocomplete="off"
                  placeholder="请输入用户名"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="queryForm.phone"
                  autocomplete="off"
                  placeholder="请输入手机号"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="queryForm.nickName"
                  autocomplete="off"
                  placeholder="请输入昵称"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  size="medium"
                  icon="el-icon-search"
                  @click="queryList({ current: 1 })"
              >查询
              </el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  size="medium"
                  icon="el-icon-refresh-left"
                  @click="resetQueryForm"
              >重置
              </el-button
              >
            </el-form-item>
          </el-form>
        </el-row>
        <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            highlight-current-row
            @current-change="handleCurrentChange"
        >

          <el-table-column
              prop="userName"
              label="用户名"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              prop="phone"
              label="手机号"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
        </el-table>
        <pagination
            :total="page.total"
            :current-page="page.current"
            :page-size="page.size"
            @refreshData="queryList"
        ></pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="devSubmit">确定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import {logisticsdynamicsInfo, logisticsdynamicsSave, logisticsdynamicsEdit} from "@/api/pdworld/logisticsdynamics.js";
import {enumList, urlList,} from "@/api/public.api.js";
import * as ftVal from "@/common/fortressValidator.js";
import FortressPagination from "@/components/fortress-pagination.vue";
import {
  pdmemberPage
} from "@/api/pdworld/pdmember.js";

export default {
  data() {
    return {
      visible: false,
      form: {
        typeId: "",
        timeChose: "",
        contactName: "",
        contactPhone: "",
        content: "",
        location: "",
        auditStatus: "",
        publisherId: "",
      },
      formLabelWidth: "120px",
      myconfig: window.$conf.commonConf.ueConfig,
      rules: {
        // publisherId: [
        //   {required: true, message: "请选择发布人", trigger: "blur"},
        // ],
        // typeId: [
        //   {required: true, message: "请输入分类", trigger: "blur"},
        // ],
        // timeChose: [
        //   {required: true, message: "请选择有效期", trigger: "blur"},
        // ],
        // contactName: [
        //   {required: true, message: "请输入联系人名称", trigger: "blur"},
        // ],
        // contactPhone: [
        //   {required: true, message: "请输入联系人电话", trigger: "blur"},
        //   {validator: ftVal.validatePhoneTwo, trigger: "blur"},
        // ],
        // content: [
        //   {required: true, message: "请输入内容", trigger: "blur"},
        // ],
        // location: [
        //   {required: true, message: "请输入位置", trigger: "blur"},
        // ],
      },
      YesNoList: [],
      LogisticsAuditStatusList: [],
      typeIdList: [],
      timeList: [],
      publisherIdList: [],
      auditUserList: [],


      checkedRow: null,
      displayMemberName: '',
      dialogVisible: false,
      tableData: [],
      queryForm: {
        userName: "",
        phone: "",
        nickName: "",
        type: 0,
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      tableList: [],
    };
  },
  components: {
    pagination: FortressPagination,
  },
  created() {
    this.queryList();
  },
  methods: {
    handleCurrentChange(row) {
      console.log(row);
      this.checkedRow = row;
    },
    devSubmit() {
      this.dialogVisible = false
      this.displayMemberName = this.checkedRow.userName;
      this.form.publisherId = this.checkedRow.id;
    },
    dialogHandle() {
      //重置列表
      this.tableData = []
      //重置列表查询条件
      this.resetQueryForm();
      this.checkedRow = null;
      //查询列表
      this.queryList()
      this.dialogVisible = true
      this.displayMemberName = ''
    },
    refresh() {
      this.tableData = [];
      this.queryList({current: 1});
    },
    //查询表格数据
    queryList(page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      pdmemberPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu =
              this.page.total % this.page.size == 0
                  ? parseInt(this.page.total / this.page.size)
                  : parseInt(this.page.total / this.page.size + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({current: cu});
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    resetQueryForm() {
      this.queryForm = {
        userName: "",
        phone: "",
        nickName: "",
        type: 0,
      };
    },
    queryEnumList() {
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
      this.timeList = [];
      enumList("TimeChose", "").then((resp) => {
        if (resp.code == 0) {
          this.timeList = resp.data;
        }
      });
      this.LogisticsAuditStatusList = [];
      enumList("LogisticsAuditStatus", "").then((resp) => {
        if (resp.code == 0) {
          this.LogisticsAuditStatusList = resp.data;
        }
      });
    },
    queryUrlList() {
      this.typeIdList = [];
      urlList("/pdworld/logisticsDynamicsType/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.typeIdList.push({code: item.id, name: item.name});
            });
          }
        }
      });
      this.publisherIdList = [];
      urlList("/pdworld/pdMember/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.publisherIdList.push({code: item.id, name: item.nickName});
            });
          }
        }
      });
      this.auditUserList = [];
      urlList("/system/sysUser/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.auditUserList.push({code: item.id, name: item.realName});
            });
          }
        }
      });
    },
    init(id) {
      this.resetForm();
      this.queryEnumList();
      this.queryUrlList();
      //修改
      if (id) {
        logisticsdynamicsInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm(type) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
			this.form.auditStatus = type
          //修改
          if (this.form.id) {
            logisticsdynamicsEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            logisticsdynamicsSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        typeId: "",
        timeChose: "",
        contactName: "",
        contactPhone: "",
        content: "",
        location: "",
        auditStatus: "",
        publisherId: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>

<style lang='scss'>
.editor__logisticsdynamics{
	img{
		max-width: 100%;
	}
}
</style>
