import request from "@/common/request"

//获取当前用户可访问的部门
export const deptList = () => {
    return request("get", "/system/sysDept/list");
}
//部门详情
export const deptInfo = (id) => {
    return request('get', '/system/sysDept/info', {
        id: id
    })
}
//保存部门信息
export const deptSave = (data) => {
    return request('post', '/system/sysDept/save', data)
}
//修改部门信息
export const deptEdit = (data) => {
    return request('post', '/system/sysDept/edit', data)
}
//删除部门信息
export const deptDel = (id) => {
    return request('post', '/system/sysDept/delete?id=' + id)
}