import request from "@/common/request"

//商家竞价线路分页展示
export const pdroutebiddingPage = (queryForm) => {
    return request("get", "/pdworld/pdRouteBidding/page", queryForm);
}
//商家竞价线路列表展示
export const pdroutebiddingList = (queryForm) => {
    return request("get", "/pdworld/pdRouteBidding/list", queryForm);
}
//商家竞价线路详情
export const pdroutebiddingInfo = (id) => {
    return request('get', '/pdworld/pdRouteBidding/info', {
        id: id
    })
}
//商家竞价线路信息保存
export const pdroutebiddingSave = (data) => {
    return request('post', '/pdworld/pdRouteBidding/save', data)
}
//商家竞价线路信息修改
export const pdroutebiddingEdit = (data) => {
    return request('post', '/pdworld/pdRouteBidding/edit', data)
}
//商家竞价线路信息删除
export const pdroutebiddingDel = (id) => {
    return request('post', '/pdworld/pdRouteBidding/delete?id=' + id)
}
//商家竞价线路批量删除
export const pdroutebiddingDelBatch = (ids) => {
    return request('post', '/pdworld/pdRouteBidding/deleteBatch', ids)
}
//商家竞价线路动态表头导出
export const pdroutebiddingDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdRouteBidding/exportDynamic', data)
}
