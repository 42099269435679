import request from "@/common/request"

//商品规格项目详情分页展示
export const goodsoptionPage = (queryForm) => {
    return request("get", "/goods/goodsOption/page", queryForm);
}
//商品规格项目详情列表展示
export const goodsoptionList = (queryForm) => {
    return request("get", "/goods/goodsOption/list", queryForm);
}
//商品规格项目详情详情
export const goodsoptionInfo = (id) => {
    return request('get', '/goods/goodsOption/info', {
        id: id
    })
}
//商品规格项目详情信息保存
export const goodsoptionSave = (data) => {
    return request('post', '/goods/goodsOption/save', data)
}
//商品规格项目详情信息修改
export const goodsoptionEdit = (data) => {
    return request('post', '/goods/goodsOption/edit', data)
}
//商品规格项目详情信息删除
export const goodsoptionDel = (id) => {
    return request('post', '/goods/goodsOption/delete?id=' + id)
}
//商品规格项目详情批量删除
export const goodsoptionDelBatch = (ids) => {
    return request('post', '/goods/goodsOption/deleteBatch', ids)
}
//商品规格项目详情动态表头导出
export const goodsoptionDynamicExport = (data) => {
    return request("eptpost", '/goods/goodsOption/exportDynamic', data)
}
