<template>
    <el-dialog @close="close" :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
                <el-input
                        v-model="form.name"
                        autocomplete="off"
                        placeholder="请输入名称"
                ></el-input>
            </el-form-item>
            <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
                <el-input-number
                    :min="0"
                    step-strictly
                        v-model="form.sort"
                        autocomplete="off"
                        placeholder="请输入排序"
                ></el-input-number>
            </el-form-item>
            <el-form-item label="标签" :label-width="formLabelWidth" prop="sort">
              <div>
                <el-tag
                    class="tag1"
                    v-for="(tag,index) in tagsList"
                    :key="index" closable
                    :disable-transitions="true"
                    @click="editTag(tag,index)"
                    @close="handleClose(tag)">
                  <span v-if="index!=num">{{tag}}</span>
                  <input
                      maxlength="10"
                      class="custom_input"
                      type="text" v-model="words"
                      v-if="index==num"
                      ref="editInput"
                      @keyup.enter="$event.target.blur(tag,index)"
                      @blur="handleInput(tag,index)">
                </el-tag>
                <el-input
                    maxlength="10"
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                >
                </el-input>
                <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                      @click="showInput">{{theme}}</el-button>
              </div>
            </el-form-item>
            <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
              <el-switch
                  v-model="form.status"
                  :active-value="1"
                  active-text="启用"
                  :inactive-value="2"
                  inactive-text="禁用"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { goodstaggroupInfo, goodstaggroupSave, goodstaggroupEdit } from "@/api/goods/goodstaggroup.js";


    export default {
      props: {
        theme: {
          type: String,
          default: '+ 新标签'
        }
      },
        data() {
            return {
              inputVisible: false,
              inputValue: '',
              num: -1,
              words: '',
                visible: false,
                form: {
                    name:"",
                    sort:"",
                    status:"",
                },
                formLabelWidth: "120px",
                rules: {
                    name:[
                          { required: true, message: "请输入名称", trigger: "blur" },
                      ],
                },
              tagsList:[]
            };
        },
        created() {
        },
        methods: {
        close(){
          this.form  = {
            name:"",
            sort:"",
            status:"",
          }
          this.tagsList = []
        },
          handleClose (tag) {
            this.$confirm('确定要删除此标签?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // 点击确定按钮的操作
              this.tagsList.splice(this.tagsList.indexOf(tag), 1)
              this.submit()
            }).catch(() => {
              // 点击取消按钮的操作
            })
          },
          showInput () {
            this.inputVisible = true
            this.$nextTick(_ => {
              this.$refs.saveTagInput.$refs.input.focus()
            })
          },
          // 新增标签
          handleInputConfirm () {
            let inputValue = this.inputValue
            if (inputValue) {
              if (this.tagsList.indexOf(inputValue) > -1) {
                this.$message.error('添加失败,标签重复')
              } else {
                this.tagsList.push(inputValue)
                // this.submit()
              }
            }
            this.inputVisible = false
            this.inputValue = ''
          },
          editTag (tag, index) {
            this.num = index
            this.$nextTick(_ => {
              this.$refs.editInput[0].focus()
            })
            this.words = tag
          },
          // 修改标签的失去焦点事件
          handleInput (tag, index) {
            if (this.words === tag) {
              this.words = ''
              this.num = -1
              return
            }
            if (this.tagsList.indexOf(this.words) > -1) {
              this.$message.error('添加失败,标签重复')
              this.words = ''
              this.num = -1
              return
            }

            let words = this.words
            if (words) {
              this.tagsList[index] = words
            }
            this.words = ''
            this.num = -1
          },
            init(id) {
                this.resetForm();
                //修改
                if (id) {
                    goodstaggroupInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                                this.tagsList = this.form.tagsList
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.tagsList = this.tagsList
                    //修改
                    if (this.form.id) {
                        goodstaggroupEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        goodstaggroupSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    name:"",
                    sort:"",
                    status:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
    .tag1{
      margin-left: 10px;
    }
    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
    .custom_input {
      width: 80px;
      height: 16px;
      outline: none;
      border: transparent;
      background-color: transparent;
      font-size: 12px;
      color: #B59059;
    }
</style>
