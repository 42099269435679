<template>
    <div>
            <div class="info-item">
                <h5 class="from-title">账户信息</h5>
                <div class="form-item">
                    <label class="form-item-label">余额:</label>
                    <div class="form-item-content">{{ objInfo.balanceMoney }}
                    </div>
                </div>
                <!-- <div class="form-item">
                    <label class="form-item-label">冻结金额:</label>
                    <div class="form-item-content">{{ objInfo.frozenMoney }}
                    </div>
                </div> -->
                <div class="form-item">
                    <label class="form-item-label">总金额:</label>
                    <div class="form-item-content">{{ objInfo.amountMoney }}
						<el-button type="primary" size="mini" @click="open(1)">充值余额</el-button>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">剩余积分:</label>
                    <div class="form-item-content">{{ objInfo.balanceScore }}
                    </div>
                </div>
                <!-- <div class="form-item">
                    <label class="form-item-label">冻结积分:</label>
                    <div class="form-item-content">{{ objInfo.frozenScore }}
                    </div>
                </div> -->
                <div class="form-item">
                    <label class="form-item-label">总积分:</label>
                    <div class="form-item-content">{{ objInfo.amountScore }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">剩余诚保资金:</label>
                    <div class="form-item-content">{{ objInfo.balanceIntegrityMoney }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">冻结诚保资金:</label>
                    <div class="form-item-content">{{ objInfo.frozenIntegrityMoney }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">总诚保资金:</label>
                    <div class="form-item-content">{{ objInfo.amountIntegrityMoney }}
						<el-button type="primary" size="mini" @click="open(2)">充值保资金</el-button>
                    </div>
                </div>
            </div>
			<el-dialog :visible.sync="visible" title="充值金额">
				<el-input-number  :step="1000" step-strictly v-model="money"></el-input-number>
				<template #footer>
					<el-button type="danger" @click="tocancel">取消</el-button>
					<el-button type="primary" @click="toPay">确定</el-button>
				</template>
			</el-dialog>
    </div>
</template>
<script>
import request from "@/common/request"
    
    export default {
		props: {
			memberId: {
				type: String,
				default: ""
			}
		},
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
				visible: false,
				money: 1000,
				type: ''
            }
        },
        created() {
			this.getData()
        },
        methods: {
			getData() {
				request('get', '/account/memberAccount/myAccount', {
				memberId: this.memberId
			}).then(res => {
				this.objInfo = res.data
			})
			},
            // 关闭抽屉
            handleClose(done) {
                done()
            },
			open(type) {
				this.type = type
				this.visible = true
				this.money = 1000
			},
			toPay() {
				if(!this.money) return ;
				request('post', '/account/memberAccount/topUp', {
					memberId: this.memberId,
					topUpMoney: this.money,
					topUpType: this.type
				}).then(() => {
					this.$message.success('充值成功')
					this.visible = false
					this.money = 1000
			this.getData()
				}).catch(() => {
					this.$message.error('充值失败')
				})
			},
			tocancel() {
				this.visible = false
				this.money = 1000
			},
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
