import request from "@/common/request"

//邮件配置表分页展示
export const castleconfigmailPage = (queryForm) => {
    return request("get", "/message/mail/castleConfigMail/page", queryForm);
}
//邮件配置表列表展示
export const castleconfigmailList = (queryForm) => {
    return request("get", "/message/mail/castleConfigMail/list", queryForm);
}
//邮件配置表详情
export const castleconfigmailInfo = (id) => {
    return request('get', '/message/mail/castleConfigMail/info', {
        id: id
    })
}
//邮件配置表信息保存
export const castleconfigmailSave = (data) => {
    return request('post', '/message/mail/castleConfigMail/save', data)
}
//邮件配置表信息修改
export const castleconfigmailEdit = (data) => {
    return request('post', '/message/mail/castleConfigMail/edit', data)
}
//邮件配置表信息删除
export const castleconfigmailDel = (id) => {
    return request('post', '/message/mail/castleConfigMail/delete?id=' + id)
}
//邮件配置表批量删除
export const castleconfigmailDelBatch = (ids) => {
    return request('post', '/message/mail/castleConfigMail/deleteBatch', ids)
}
//邮件配置表动态表头导出
export const castleconfigmailDynamicExport = (data) => {
    return request("eptpost", '/message/mail/castleConfigMail/exportDynamic', data)
}
