import request from "@/common/request"

//全局广告分页展示
export const pdadvertisingPage = (queryForm) => {
    return request("get", "/pdworld/pdAdvertising/page", queryForm);
}
//全局广告列表展示
export const pdadvertisingList = (queryForm) => {
    return request("get", "/pdworld/pdAdvertising/list", queryForm);
}
//全局广告详情
export const pdadvertisingInfo = (id) => {
    return request('get', '/pdworld/pdAdvertising/info', {
        id: id
    })
}
//全局广告信息保存
export const pdadvertisingSave = (data) => {
    return request('post', '/pdworld/pdAdvertising/save', data)
}
//全局广告信息修改
export const pdadvertisingEdit = (data) => {
    return request('post', '/pdworld/pdAdvertising/edit', data)
}
//全局广告信息删除
export const pdadvertisingDel = (id) => {
    return request('post', '/pdworld/pdAdvertising/delete?id=' + id)
}
//全局广告批量删除
export const pdadvertisingDelBatch = (ids) => {
    return request('post', '/pdworld/pdAdvertising/deleteBatch', ids)
}
//全局广告动态表头导出
export const pdadvertisingDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdAdvertising/exportDynamic', data)
}
