<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item
        label="短信编码"
        :label-width="formLabelWidth"
        prop="smsCode"
      >
        <el-input
          v-model="form.smsCode"
          autocomplete="off"
          placeholder="请输入短信编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
        <el-input
          v-model="form.remark"
          autocomplete="off"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="是否启用"
        :label-width="formLabelWidth"
        prop="status"
      >
        <el-switch
          v-model="form.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
        <span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66"
          >启用</span
        >
        <span v-if="form.status == 2" style="margin-left: 17px; color: #ff4949"
          >禁用</span
        >
      </el-form-item>
      <el-divider content-position="left">平台配置</el-divider>
      <el-form-item
        label="平台类型"
        :label-width="formLabelWidth"
        prop="platform"
      >
        <fortress-radio-group
          v-model="form.platform"
          :dictList="smsPlatFormList"
          placeholder="请选择"
          clearable
        ></fortress-radio-group>
      </el-form-item>
      <!-- 阿里云 -->
      <div v-if="form.platform == '1'">
        <el-form-item
          label="AccessKeyId"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.aliyunAccessKeyId"
        >
          <el-input
            :rows="2"
            placeholder="请输入AccessKeyId"
            v-model="form.smsPlatFormDto.aliyunAccessKeyId"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="AccessKeySecret"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.aliyunAccessKeySecret"
        >
          <el-input
            :rows="2"
            placeholder="请输入AccessKeySecret"
            v-model="form.smsPlatFormDto.aliyunAccessKeySecret"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="短信签名"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.aliyunSignName"
        >
          <el-input
            :rows="2"
            placeholder="请输入短信签名"
            v-model="form.smsPlatFormDto.aliyunSignName"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="模板编码"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.aliyunTemplateCode"
        >
          <el-input
            :rows="2"
            placeholder="请输入短信编码"
            v-model="form.smsPlatFormDto.aliyunTemplateCode"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="区域ID"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.aliyunRegionId"
        >
          <el-input
            :rows="2"
            placeholder="请输入区域ID"
            v-model="form.smsPlatFormDto.aliyunRegionId"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="访问域名"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.aliyunEndpoint"
        >
          <el-input
            :rows="2"
            placeholder="请输入访问域名"
            v-model="form.smsPlatFormDto.aliyunEndpoint"
          >
          </el-input>
        </el-form-item>
      </div>
      <!-- 腾讯云 -->
      <div v-if="form.platform == '2'">
        <el-form-item
          label="SdkAppId"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.tencentcloudSdkAppId"
        >
          <el-input
            :rows="2"
            placeholder="请输入SdkAppId"
            v-model="form.smsPlatFormDto.tencentcloudSdkAppId"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="SecretId"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.tencentcloudSecretId"
        >
          <el-input
            :rows="2"
            placeholder="请输入SecretId"
            v-model="form.smsPlatFormDto.tencentcloudSecretId"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="SecretKey"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.tencentcloudSecretKey"
        >
          <el-input
            :rows="2"
            placeholder="请输入SecretKey"
            v-model="form.smsPlatFormDto.tencentcloudSecretKey"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="签名"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.tencentcloudSign"
        >
          <el-input
            :rows="2"
            placeholder="请输入签名"
            v-model="form.smsPlatFormDto.tencentcloudSign"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="模板ID"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.tencentcloudTemplateId"
        >
          <el-input
            :rows="2"
            placeholder="请输入模板ID"
            v-model="form.smsPlatFormDto.tencentcloudTemplateId"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="区域"
          :label-width="formLabelWidth"
          prop="smsPlatFormDto.tencentcloudRegion"
        >
          <el-input
            :rows="2"
            placeholder="请输入区域"
            v-model="form.smsPlatFormDto.tencentcloudRegion"
          >
          </el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger"
        >取 消</el-button
      >
      <el-button type="primary" size="medium" @click="submitForm('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  configsmsInfo,
  configsmsSave,
  configsmsEdit,
} from "@/api/message/sms/configsms.js";

export default {
  props: ["smsPlatFormList"],
  data() {
    return {
      visible: false,
      form: {
        smsCode: "",
        platform: "1",
        smsConfig: "",
        remark: "",
        status: 1,
        smsPlatFormDto: {
          aliyunAccessKeyId: "",
          aliyunAccessKeySecret: "",
          aliyunEndpoint: "",
          aliyunSignName: "",
          aliyunTemplateCode: "",
        },
      },
      formLabelWidth: "140px",
      rules: {
        smsCode: [
          { required: true, message: "请输入短信编码", trigger: "blur" },
        ],
        platform: [
          { required: true, message: "请输入平台类型", trigger: "blur" },
        ],
        smsConfig: [
          { required: true, message: "请输入短信配置", trigger: "blur" },
        ],
        status: [{ required: true, message: "请输入状态", trigger: "blur" }],
        "smsPlatFormDto.aliyunAccessKeyId": [
          { required: true, message: "请输入AccessKeyId", trigger: "blur" },
        ],
        "smsPlatFormDto.aliyunAccessKeySecret": [
          { required: true, message: "请输入AccessKeySecret", trigger: "blur" },
        ],
        "smsPlatFormDto.aliyunSignName": [
          { required: true, message: "请输入短信签名", trigger: "blur" },
        ],
        "smsPlatFormDto.aliyunTemplateCode": [
          { required: true, message: "请输入模板ID", trigger: "blur" },
        ],
        "smsPlatFormDto.tencentcloudSdkAppId": [
          { required: true, message: "请输入SdkAppId", trigger: "blur" },
        ],
        "smsPlatFormDto.tencentcloudSecretId": [
          { required: true, message: "请输入SecretId", trigger: "blur" },
        ],
        "smsPlatFormDto.tencentcloudSecretKey": [
          { required: true, message: "请输入SecretKey", trigger: "blur" },
        ],
        "smsPlatFormDto.tencentcloudSign": [
          { required: true, message: "请输入签名", trigger: "blur" },
        ],
        "smsPlatFormDto.tencentcloudTemplateId": [
          { required: true, message: "请输入模板ID", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(id) {
      this.resetForm();
      //修改
      if (id) {
        configsmsInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.visible = false;
          //修改
          if (this.form.id) {
            configsmsEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            configsmsSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        smsCode: "",
        platform: "1",
        smsConfig: "",
        remark: "",
        status: 1,
        smsPlatFormDto: {
          aliyunAccessKeyId: "",
          aliyunAccessKeySecret: "",
          aliyunEndpoint: "",
          aliyunSignName: "",
          aliyunTemplateCode: "",
        },
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>