import request from "@/common/request"

//系统任务调度表分页展示
export const configtaskPage = (queryForm) => {
    return request("get", "/job/configTask/page", queryForm);
}
//系统任务调度表列表展示
export const configtaskList = (queryForm) => {
    return request("get", "/job/configTask/list", queryForm);
}
//系统任务调度表详情
export const configtaskInfo = (id) => {
    return request('get', '/job/configTask/info', {
        id: id
    })
}
//系统任务调度表执行一次
export const configtaskRun = (id) => {
    return request('get', '/job/configTask/run', {
        id: id
    })
}
//系统任务调度表信息保存
export const configtaskSave = (data) => {
    return request('post', '/job/configTask/save', data)
}
//系统任务调度表信息修改
export const configtaskEdit = (data) => {
    return request('post', '/job/configTask/edit', data)
}
//系统任务启动
export const configtaskTrigger = (data) => {
    return request('post', '/job/configTask/trigger', data)
}
//系统任务暂停
export const configtaskPause = (data) => {
    return request('post', '/job/configTask/pause', data)
}
//系统任务恢复
export const configtaskResume = (data) => {
    return request('post', '/job/configTask/resume', data)
}
//系统任务调度表信息删除
export const configtaskDel = (id) => {
    return request('post', '/job/configTask/delete?id=' + id)
}
//系统任务调度表批量删除
export const configtaskDelBatch = (ids) => {
    return request('post', '/job/configTask/deleteBatch', ids)
}
