import request from "@/common/request"

//会员账户余额流水分页展示
export const memberaccountserialPage = (queryForm) => {
    return request("get", "/account/memberAccountSerial/page", queryForm);
}
//会员账户余额流水列表展示
export const memberaccountserialList = (queryForm) => {
    return request("get", "/account/memberAccountSerial/list", queryForm);
}
//会员账户余额流水详情
export const memberaccountserialInfo = (id) => {
    return request('get', '/account/memberAccountSerial/info', {
        id: id
    })
}
//会员账户余额流水信息保存
export const memberaccountserialSave = (data) => {
    return request('post', '/account/memberAccountSerial/save', data)
}
//会员账户余额流水信息修改
export const memberaccountserialEdit = (data) => {
    return request('post', '/account/memberAccountSerial/edit', data)
}
//会员账户余额流水信息删除
export const memberaccountserialDel = (id) => {
    return request('post', '/account/memberAccountSerial/delete?id=' + id)
}
//会员账户余额流水批量删除
export const memberaccountserialDelBatch = (ids) => {
    return request('post', '/account/memberAccountSerial/deleteBatch', ids)
}
//会员账户余额流水动态表头导出
export const memberaccountserialDynamicExport = (data) => {
    return request("eptpost", '/account/memberAccountSerial/exportDynamic', data)
}
