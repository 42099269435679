<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-form
        :inline="true"
        :model="queryForm"
        @submit.native.prevent
        @keyup.enter.native="queryList"
      >
        <el-form-item>
          <el-input
            v-model="queryForm.phone"
            autocomplete="off"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-search"
            @click="queryList({ current: 1 })"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-refresh-left"
            @click="resetQueryForm"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        v-if="$tools.hasPermission('system:sysCaptcha:save')"
        @click="handleEdit()"
        >添加</el-button
      >
      <el-button
        v-if="$tools.hasPermission('system:sysCaptcha:deleteBatch')"
        size="medium"
        type="danger"
        icon="el-icon-delete"
        @click="handleDeleteBatch()"
        >删除</el-button
      >
      <div style="float: right; padding-right: 17px">
        <i
          class="el-icon-refresh"
          style="margin-right: 10px; cursor: pointer"
          @click="queryList"
        ></i>

        <el-popover
          placement="bottom"
          width="180"
          trigger="click"
          title="列展示"
          popper-class="columnShows"
        >
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
              v-for="item in tableList"
              :label="item.propName"
              :key="item.propName"
              :value="item.propName"
              >{{ item.propDesc }}</el-checkbox
            >
          </el-checkbox-group>
          <i
            slot="reference"
            class="el-icon-s-operation"
            style="cursor: pointer"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <loading :show="parentShow">
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('phone', tableShowList)"
          prop="phone"
          label="手机号"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('captcha', tableShowList)"
          prop="captcha"
          label="验证码"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('createTime', tableShowList)"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="180">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('system:sysCaptcha:info')"
              size="mini"
              plain
              @click="handleInfo(scope.row.id)"
              >详情</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:sysCaptcha:edit')"
              size="mini"
              plain
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:sysCaptcha:delete')"
              size="mini"
              plain
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <!-- 新增、编辑 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>
    <!-- 详情 -->
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
  </div>
</template>
<script>
import {
  syscaptchaPage,
  syscaptchaDel,
  syscaptchaDelBatch,
} from "@/api/system/syscaptcha.js";
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./syscaptcha-edit";
import FormInfo from "./syscaptcha-info";
import FortressPagination from "@/components/fortress-pagination.vue";

export default {
  data() {
    return {
      parentShow: "",
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      queryForm: {
        phone: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    pagination: FortressPagination,
  },
  created() {
    this.initTableList();
    this.tableData = [];
    this.queryList();
  },
  methods: {
    //查询表格数据
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      syscaptchaPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false;
            this.tableData = resp.data.records;
            this.page.total = resp.data.total;
            //查询页大于总页数，重新查询
            let cu =
              this.page.total % this.page.size == 0
                ? parseInt(this.page.total / this.page.size)
                : parseInt(this.page.total / this.page.size + 1);
            if (cu > 0 && this.page.current > cu) {
              this.queryList({ current: cu });
            }
          }, 600);
        } else {
          this.parentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          syscaptchaDel(row.id).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              //判断是否最后一页的最后一条记录
              let totalPages =
                (this.page.total - 1) % this.page.size == 0
                  ? (this.page.total - 1) / this.page.size
                  : (this.page.total - 1) / this.page.size + 1;
              if (this.page.current > totalPages && this.page.current != 1) {
                this.page.current = this.page.current - 1;
              }
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    },
    //批量删除
    handleDeleteBatch() {
      let checkedRow = this.$refs.multipleTable.selection;
      if (checkedRow.length > 0) {
        let ids = [];
        checkedRow.forEach((item) => {
          ids.push(item.id);
        });
        this.$confirm("确认删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            syscaptchaDelBatch(ids).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                //判断是否最后一页的最后一条记录
                let totalPages =
                  (this.page.total - checkedRow.length) % this.page.size == 0
                    ? (this.page.total - checkedRow.length) / this.page.size
                    : (this.page.total - checkedRow.length) / this.page.size +
                      1;
                if (this.page.current > totalPages && this.page.current != 1) {
                  this.page.current = this.page.current - 1;
                }
                this.queryList();
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          })
          .catch(() => {});
      } else {
        this.$msg({
          message: "请选择数据进行删除",
          type: "error",
        });
      }
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        phone: "",
      };
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList() {
      viewTableList("castle_sys_captcha").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>
