<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-form
        :inline="true"
        :model="queryForm"
        @submit.native.prevent
        @keyup.enter.native="queryList"
      >
        <el-form-item>
          <el-input v-model="queryForm.name" autocomplete="off" placeholder="请输入商品名称" clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="queryForm.categoryId"
            autocomplete="off"
            placeholder="请输入所属分类"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.type"
            :dictList="GoodsType_goodsList"
            placeholder="请选择类型"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <el-input v-model="queryForm.goodsSn" autocomplete="off" placeholder="请输入编码" clearable></el-input>
        </el-form-item>

        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.totalCnf"
            :dictList="GoodsTotalCnf_goodsList"
            placeholder="请选择减库存方式"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.dispatchType"
            :dictList="GoodsDispatchType_goodsList"
            placeholder="请选择配送方式"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.isNew"
            :dictList="YesNoList"
            placeholder="请选择是否为新上"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.isHot"
            :dictList="YesNoList"
            placeholder="请选择是否为热卖"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.isDiscount"
            :dictList="YesNoList"
            placeholder="请选择是否为促销"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.isRecommand"
            :dictList="YesNoList"
            placeholder="请选择是否为推荐"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.isSendFree"
            :dictList="YesNoList"
            placeholder="请选择是否包邮"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.isAutoShelves"
            :dictList="YesNoList"
            placeholder="请选择是否自动上下架"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
            v-model="queryForm.isInvoice"
            :dictList="YesNoList"
            placeholder="请选择是否开发票"
            clearable
            filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <el-select v-model="queryForm.status" placeholder="请选择状态" clearable>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-search"
            @click="queryList({current:1})"
          >查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-refresh-left"
            @click="resetQueryForm"
          >重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="handleCollapseState">
            {{ collapse.label }}
            <i :class="collapse.icon"></i>
          </el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        v-if="$tools.hasPermission('goods:goods:save')"
        @click="handleEdit()"
      >添加</el-button>
      <el-button
        v-if="$tools.hasPermission('goods:goods:deleteBatch')"
        size="medium"
        type="danger"
        icon="el-icon-delete"
        @click="handleDeleteBatch()"
      >删除</el-button>
      <div style="float: right; padding-right: 17px">
        <i
          class="el-icon-download"
          style="margin-right: 10px; cursor: pointer"
          @click="handleExport"
        ></i>
        <i class="el-icon-refresh" style="margin-right: 10px; cursor: pointer" @click="refresh"></i>

        <el-popover
          placement="bottom"
          width="180"
          trigger="click"
          title="列展示"
          popper-class="columnShows"
        >
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
              v-for="item in tableList"
              :label="item.propName"
              :key="item.propName"
              :value="item.propName"
            >{{ item.propDesc }}</el-checkbox>
          </el-checkbox-group>
          <i slot="reference" class="el-icon-s-operation" style="cursor: pointer"></i>
        </el-popover>
      </div>
    </el-row>
    <el-menu
      style="margin-bottom:15px"
      :default-active="activeIndex"
      class="menu"
      mode="horizontal"
    >
      <el-menu-item index="1">出售中</el-menu-item>
      <el-menu-item index="2">已售罄</el-menu-item>
      <el-menu-item index="3">已下架</el-menu-item>
    </el-menu>

    <el-table
      class="table"
      :highlight-current-row="true"
      :header-cell-style="{background:'#eef5ff'}"
      ref="multipleTable"
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
    >
      <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
      <el-table-column label="商品" min-width="300">
        <template slot-scope="scope">
          <div class="shop">
            <div class="shop-img">
              <el-image
                v-if="scope.row.thumb"
                :src="scope.row.thumb"
                style="width: 100px"
                :preview-src-list="[scope.row.thumb]"
                fit="cover"
              />
            </div>
            <div class="shop-text">
              <h6
                class="overLineNum2"
                v-if="$tools.isShow('name', tableShowList)"
              >{{scope.row.name}}</h6>
              <p v-if="$tools.isShow('categoryName', tableShowList)">[{{scope.row.categoryName}}]</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        v-if="$tools.isShow('productPrice', tableShowList)"
        prop="productPrice"
        label="原价"
        :show-overflow-tooltip="true"
      ></el-table-column>-->
      <el-table-column
        v-if="$tools.isShow('marketPrice', tableShowList)"
        label="价格"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <p class="price">
            <span>￥{{scope.row.marketPrice}}</span>
            <span v-if="$tools.isShow('productPrice', tableShowList)">￥{{scope.row.productPrice}}</span>
          </p>
        </template>
      </el-table-column>
      <!-- <el-table-column
        v-if="$tools.isShow('name', tableShowList)"
        prop="name"
        label="商品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('categoryName', tableShowList)"
        prop="categoryName"
        label="所属分类"
        :show-overflow-tooltip="true"
      ></el-table-column>-->
      <el-table-column
        v-if="$tools.isShow('type', tableShowList)"
        prop="type"
        label="类型"
        :show-overflow-tooltip="true"
        :formatter="typeFmt"
        width="120"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('total', tableShowList)"
        prop="total"
        label="库存"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('sales', tableShowList)"
        prop="sales"
        label="已售数量"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('dispatchType', tableShowList)"
        prop="dispatchType"
        label="配送方式"
        :show-overflow-tooltip="true"
        :formatter="dispatchTypeFmt"
        width="120"
      ></el-table-column>

      <el-table-column label="属性" :show-overflow-tooltip="true" width="260">
        <template slot-scope="scope">
          <div class="attribute">
            <span
              :class="scope.row.isNew == 1?'active':''"
              v-if="$tools.isShow('isNew', tableShowList)"
            >新上</span>
            <span
              :class="scope.row.isHot == 1?'active':''"
              v-if="$tools.isShow('isHot', tableShowList)"
            >热卖</span>
            <span
              :class="scope.row.isDiscount==1?'active':''"
              v-if="$tools.isShow('isDiscount', tableShowList)"
            >促销</span>
            <span
              :class="scope.row.isRecommand==1?'active':''"
              v-if="$tools.isShow('isRecommand', tableShowList)"
            >推荐</span>
            <span
              :class="scope.row.isSendFree == 1?'active':''"
              v-if="$tools.isShow('isSendFree', tableShowList)"
            >包邮</span>
            <span
              :class="scope.row.isNodisCount == 1?'active':''"
              v-if="$tools.isShow('isNodisCount', tableShowList)"
            >参与会员折扣</span>
            <span
              :class="scope.row.isSearchShow == 1?'active':''"
              v-if="$tools.isShow('isSearchShow', tableShowList)"
            >搜索隐藏</span>
            <span
              :class="scope.row.isAutoShelves == 1?'active':''"
              v-if="$tools.isShow('isAutoShelves', tableShowList)"
            >自动上下架</span>
            <span
              :class="scope.row.isInvoice == 1?'active':''"
              v-if="$tools.isShow('isInvoice', tableShowList)"
            >开发票</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('tags', tableShowList)"
        label="标签"
        min-width="160"
        :formatter="tagFmt"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div class="tag">
            <el-tag v-for="item in tagFmt(scope.row)" style="margin-right:4px">{{item}}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('status', tableShowList)"
        prop="status"
        label="状态"
        :show-overflow-tooltip="true"
        :formatter="statusFmt"
      >
        <template slot-scope="scope">
          <el-switch v-model="trueText" disabled v-if="statusFmt(scope.row)"></el-switch>
          <el-switch v-model="falseText" disabled v-else></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('remark', tableShowList)"
        prop="remark"
        label="备注"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('video', tableShowList)"
        prop="video"
        label="主视频"
        width="120"
      >
        <template slot-scope="scope">
          <fortress-upload
            style="width: 100px; height: 100px"
            v-if="scope.row.video"
            type="videoShow"
            name="video"
            :formData="tableData[scope.$index]"
            :videoSrc="scope.row.video"
          ></fortress-upload>
        </template>
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('imgList', tableShowList)"
        prop="imgList"
        label="其他图片"
        width="120"
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.imgList"
            :src="scope.row.imgList[0]"
            style="width: 100px; height: 100px;border-radius:10px"
            fit="cover"
            :preview-src-list="scope.row.imgList"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('content', tableShowList)"
        prop="content"
        label="详情信息"
        min-width="120"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <p v-html="scope.row.content"></p>
        </template>
      </el-table-column>

      <el-table-column
        v-if="$tools.isShow('totalCnf', tableShowList)"
        prop="totalCnf"
        label="减库存方式"
        :show-overflow-tooltip="true"
        :formatter="totalCnfFmt"
      ></el-table-column>

      <el-table-column
        v-if="$tools.isShow('sort', tableShowList)"
        prop="sort"
        label="排序"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        v-if="$tools.isShow('productSn', tableShowList)"
        prop="productSn"
        label="条码"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.productSn"
            :src="scope.row.productSn"
            style="width: 100px; height: 100px"
            :preview-src-list="[scope.row.productSn]"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="$tools.isShow('goodsSn', tableShowList)"
        prop="goodsSn"
        label="编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('credit', tableShowList)"
        prop="credit"
        label="购买赠送积分"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('maxBuy', tableShowList)"
        prop="maxBuy"
        label="单次最多购买量"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('userMaxBuy', tableShowList)"
        prop="userMaxBuy"
        label="用户最多购买量"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('weight', tableShowList)"
        prop="weight"
        label="重量"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        v-if="$tools.isShow('freight', tableShowList)"
        prop="freight"
        label="运费"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('shareTitle', tableShowList)"
        prop="shareTitle"
        label="分享标题"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('shareIcon', tableShowList)"
        prop="shareIcon"
        label="分享图标"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.shareIcon"
            :src="scope.row.shareIcon"
            style="width: 100px; height: 100px"
            :preview-src-list="[scope.row.shareIcon]"
          />
        </template>
      </el-table-column>

      <el-table-column
        v-if="$tools.isShow('upShelvesDate', tableShowList)"
        prop="upShelvesDate"
        label="自动上架时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('offShelvesDate', tableShowList)"
        prop="offShelvesDate"
        label="自动下架时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-if="$tools.isShow('createTime', tableShowList)"
        prop="createTime"
        label="创建时间"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column label="操作" fixed="right" min-width="180">
        <template slot-scope="scope">
          <div class="btns">
            <el-button
              icon="iconfont kaiyanjing"
              plain
              type="primary"
              v-if="$tools.hasPermission('goods:goods:info')"
              @click="handleInfo(scope.row.id)"
            ></el-button>
            <el-button
              icon="el-icon-edit"
              plain
              type="warning"
              v-if="$tools.hasPermission('goods:goods:edit')"
              @click="handleEdit(scope.row.id)"
            ></el-button>
            <el-button
              icon="el-icon-delete"
              plain
              type="danger"
              v-if="$tools.hasPermission('goods:goods:delete')"
              @click="handleDelete(scope.row)"
            ></el-button>
          </div>
          <!-- <el-button
            v-if="$tools.hasPermission('goods:goods:info')"
            size="mini"
            plain
            @click="handleInfo(scope.row.id)"
          >详情</el-button>-->
          <!-- <el-button
            v-if="$tools.hasPermission('goods:goods:edit')"
            size="mini"
            plain
            @click="handleEdit(scope.row.id)"
          >编辑</el-button>
          <el-button
            v-if="$tools.hasPermission('goods:goods:delete')"
            size="mini"
            plain
            type="danger"
            @click="handleDelete(scope.row)"
          >删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <!-- 新增、编辑 -->
    <form-edit v-if="formEditVisible" ref="formEdit" @refreshData="queryList"></form-edit>
    <!-- 详情 -->
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
  </div>
</template>
<script>
import { goodsPage, goodsDel, goodsDelBatch, goodsDynamicExport, } from "@/api/goods/goods.js";
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./goods-edit";
import FormInfo from "./goods-info";
import FortressPagination from "@/components/fortress-pagination.vue";
import { enumList, } from "@/api/public.api.js";
export default {
  data () {
    return {
      trueText: true,
      falseText: false,
      activeIndex: '1',
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      queryForm: {
        name: "",
        categoryId: "",
        type: "",
        goodsSn: "",
        totalCnf: "",
        dispatchType: "",
        isNew: "",
        isHot: "",
        isDiscount: "",
        isRecommand: "",
        isSendFree: "",
        isAutoShelves: "",
        isInvoice: "",
        status: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
      GoodsType_goodsList: [],
      GoodsTotalCnf_goodsList: [],
      GoodsDispatchType_goodsList: [],
      YesNoList: [
        {
          code: "1",
          name: "是"
        }, {
          code: "2",
          name: "否"
        },
      ],
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    pagination: FortressPagination,
  },
  created () {
    this.queryEnumList();
    this.initTableList();
    this.queryList();
  },
  methods: {
    queryEnumList () {
      this.GoodsType_goodsList = [];
      enumList("GoodsType", "goods").then((resp) => {
        if (resp.code == 0) {
          this.GoodsType_goodsList = resp.data;
        }
      });
      this.GoodsTotalCnf_goodsList = [];
      enumList("GoodsTotalCnf", "goods").then((resp) => {
        if (resp.code == 0) {
          this.GoodsTotalCnf_goodsList = resp.data;
        }
      });
      this.GoodsDispatchType_goodsList = [];
      enumList("GoodsDispatchType", "goods").then((resp) => {
        if (resp.code == 0) {
          this.GoodsDispatchType_goodsList = resp.data;
        }
      });
    },
    refresh () {
      this.tableData = [];
      this.queryList({ current: 1 });
    },
    //查询表格数据
    queryList (page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      goodsPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu = this.page.total % this.page.size == 0 ? parseInt(this.page.total / this.page.size) : parseInt((this.page.total / this.page.size) + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({ current: cu });
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit (id) {
      // this.formEditVisible = true;
      // this.$nextTick(() => {
      //     this.$refs.formEdit.init(id);
      // });
      if (id) {
        this.$router.push("/goods/goods-edit?id=" + id)
      } else {
        this.$router.push("/goods/goods-edit")
      }

    },
    //打开详情窗口
    handleInfo (id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //删除
    handleDelete (row) {
      this.$confirm('确认删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        goodsDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
              (this.page.total - 1) % this.page.size == 0
                ? (this.page.total - 1) / this.page.size
                : (this.page.total - 1) / this.page.size + 1;
            if (this.page.current > totalPages && this.page.current != 1) {
              this.page.current = this.page.current - 1;
            }
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }).catch(() => {
      });
    },
    //批量删除
    handleDeleteBatch () {
      let checkedRow = this.$refs.multipleTable.selection;
      if (checkedRow.length > 0) {
        let ids = [];
        checkedRow.forEach((item) => {
          ids.push(item.id);
        });
        this.$confirm("确认删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            goodsDelBatch(ids).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                //判断是否最后一页的最后一条记录
                let totalPages =
                  (this.page.total - checkedRow.length) % this.page.size == 0
                    ? (this.page.total - checkedRow.length) / this.page.size
                    : (this.page.total - checkedRow.length) / this.page.size +
                    1;
                if (this.page.current > totalPages && this.page.current != 1) {
                  this.page.current = this.page.current - 1;
                }
                this.queryList();
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          })
          .catch(() => { });
      } else {
        this.$msg({
          message: "请选择数据进行删除",
          type: "error",
        });
      }
    },
    //重置查询框
    resetQueryForm () {
      this.queryForm = {
        name: "",
        categoryId: "",
        type: "",
        goodsSn: "",
        totalCnf: "",
        dispatchType: "",
        isNew: "",
        isHot: "",
        isDiscount: "",
        isRecommand: "",
        isSendFree: "",
        isAutoShelves: "",
        isInvoice: "",
        status: "",
      };
    },
    //切换查询条件收缩框
    handleCollapseState () {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList () {
      viewTableList("goods").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
    //tag将字符串转换为数组
    tagFmt (row) {
      let arry = []
      if (row.tags) {
        arry = row.tags.split(',')
      }
      return arry
    },
    //类型格式化返回
    typeFmt (row) {
      let name = "";
      if (this.GoodsType_goodsList) {
        this.GoodsType_goodsList.forEach(item => {
          if (item.code == row.type) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //减库存方式格式化返回
    totalCnfFmt (row) {
      let name = "";
      if (this.GoodsTotalCnf_goodsList) {
        this.GoodsTotalCnf_goodsList.forEach(item => {
          if (item.code == row.totalCnf) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //配送方式格式化返回
    dispatchTypeFmt (row) {
      let name = "";
      if (this.GoodsDispatchType_goodsList) {
        this.GoodsDispatchType_goodsList.forEach(item => {
          if (item.code == row.dispatchType) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否为新上格式化返回
    isNewFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isNew) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否为热卖格式化返回
    isHotFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isHot) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否为促销格式化返回
    isDiscountFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isDiscount) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否为推荐格式化返回
    isRecommandFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isRecommand) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否包邮格式化返回
    isSendFreeFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isSendFree) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否参与会员折扣格式化返回
    isNodisCountFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isNodisCount) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否搜索隐藏格式化返回
    isSearchShowFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isSearchShow) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否自动上下架格式化返回
    isAutoShelvesFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isAutoShelves) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //是否开发票格式化返回
    isInvoiceFmt (row) {
      let name = "";
      if (this.YesNoList) {
        this.YesNoList.forEach(item => {
          if (item.code == row.isInvoice) {
            name = item.name;
          }
        })
      }
      return name;
    },
    statusFmt (row) {
      let B = false;
      if (row.status == 1) {
        B = true;
      }
      return B;
    },
    //可见字段excel导出
    handleExport () {
      if (!this.tableShowList || this.tableShowList.length < 1) {
        this.$msg({
          message: "请选择要导出的列",
          type: "error",
        });
        return;
      }
      let colNum = 1;
      let headerList = [];
      this.tableShowList.forEach((item) => {
        let nameData = this.tableList.find((item2) => {
          return item2.propName == item;
        });
        let name = "";
        if (nameData && nameData.propDesc) {
          name = nameData.propDesc;
        } else {
          name = "未定义字段" + colNum++;
        }
        headerList.push({ name: name, code: item });
      });
      goodsDynamicExport({ headerList: headerList, dto: this.queryForm });
    },
  },
};
</script>
<style scoped lang="scss">
.tabs {
  &::v-deep .el-tabs__active-bar {
    display: block !important;
  }
  &::v-deep .el-tabs__item {
    margin-right: 0;
  }
}
.table {
  &::v-deep .hover-row > td {
    background: #eef5ff !important;
    box-sizing: border-box;
  }
  &::v-deep .hover-row > td:first-child {
    border-left: 6px solid #0d6efd;
  }
  &::v-deep .current-row > td {
    background: #eef5ff !important;
  }
  &::v-deep .current-row > td:first-child {
    border-left: 6px solid #0d6efd;
  }
  &::v-deep .cell.el-tooltip {
    color: #333;
    font-size: 15px;
  }
  &::v-deep .el-checkbox__inner {
    border-color: #e0e0e0 !important;
  }
  &::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #1362fc !important;
    background-color: #1362fc !important;
  }
  &::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #1362fc !important;
    background-color: #1362fc !important;
  }
  &::v-deep td {
    border: none;
    border-bottom: 1px solid #efefef;
  }
  &::v-deep thead tr th {
    border-top: 1px solid #efefef;
  }
  &::v-deep th > .cell {
    color: #333;
    font-weight: normal;
  }
}
.menu {
  &::v-deep .el-menu-item.is-active {
    color: #1362fc !important;
    border-bottom: 4px solid #1362fc;
  }
}
.shop {
  width: 100%;
  display: flex;
  align-items: center;
  .shop-img {
    margin-right: 15px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
  }
  .shop-text {
    width: 0;
    flex: 1;
    h6 {
      margin-bottom: 5px;
      font-size: 16px;
      color: #333;
      font-weight: normal;
    }
    p {
      font-size: 14px;
      color: #1362fc;
    }
  }
}
.price {
  span {
    font-size: 18px;
    color: #333;
    &:last-child {
      margin-left: 10px;
      font-size: 14px;
      color: #999;
      text-decoration: line-through;
    }
  }
}
.btns {
  display: flex;
  &::v-deep .el-button {
    padding: 0 !important;
    width: 35px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.attribute {
  white-space: pre-wrap;
  span {
    margin-right: 10px;
  }
  .active {
    color: #1362fc;
  }
}
</style>
