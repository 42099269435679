<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" append-to-body="true" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">物流圈:</label>
                    <div class="form-item-content">{{ objInfo.logisticsDynamicsIdName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发布人:</label>
                    <div class="form-item-content">{{ objInfo.publisherIdName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">评论内容
:</label>
                    <div class="form-item-content">{{ objInfo.content }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { logisticsdynamicscommentInfo } from "@/api/pdworld/logisticsdynamicscomment.js";
    import { urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                logisticsDynamicsIdList:[],
                publisherIdList:[],
            }
        },
        created() {
        },
        methods: {
          queryUrlList(){
              this.logisticsDynamicsIdList = [];
              urlList("/pdworld/logisticsDynamics/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.logisticsDynamicsIdList.push({ code: item.id, name: item.id });
                    });
                  }
                }
              });
              this.publisherIdList = [];
              urlList("/pdworld/pdMember/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.publisherIdList.push({ code: item.id, name: item.userName });
                    });
                  }
                }
              });
          },
            init(id) {
                this.queryUrlList();
                if (id) {
                   logisticsdynamicscommentInfo(id).then(res => {
                        this.objInfo = res.data
                              let logisticsDynamicsIdObj = this.logisticsDynamicsIdList.find((item) => {
                                return item.code == this.objInfo.logisticsDynamicsId;
                              });
                              this.objInfo.logisticsDynamicsIdName = logisticsDynamicsIdObj ? logisticsDynamicsIdObj.name : "";
                              let publisherIdObj = this.publisherIdList.find((item) => {
                                return item.code == this.objInfo.publisherId;
                              });
                              this.objInfo.publisherIdName = publisherIdObj ? publisherIdObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
