import { render, staticRenderFns } from "./ALIPAY_MINI.vue?vue&type=template&id=d5ce6e14&scoped=true&"
import script from "./ALIPAY_MINI.vue?vue&type=script&lang=js&"
export * from "./ALIPAY_MINI.vue?vue&type=script&lang=js&"
import style0 from "./ALIPAY_MINI.vue?vue&type=style&index=0&id=d5ce6e14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5ce6e14",
  null
  
)

export default component.exports