<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible">
    <el-form ref="form" :model="form" :rules="rule">
      <el-form-item label="连接名" :label-width="formLabelWidth" prop="dbName">
        <el-input v-model="form.dbName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="数据库类型"
        :label-width="formLabelWidth"
        prop="dbDriverName"
      >
        <el-select v-model="form.dbDriverName" placeholder="数据库类型">
          <el-option value="com.mysql.jdbc.Driver" label="MYSQL"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="数据库URL"
        :label-width="formLabelWidth"
        prop="dbUrl"
      >
        <el-input
          v-model="form.dbUrl"
          autocomplete="off"
          style="width: 93%"
        ></el-input>
        <el-tooltip
          effect="dark"
          content="请复制后修改为自己的数据：jdbc:mysql://数据库连接/数据库名称?useUnicode=true&characterEncoding=UTF-8&serverTimezone=Asia/Shanghai"
          placement="top"
          style="
            display: inline-block;
            font-size: 20px;
            margin-left: 20px;
            line-height: 35px;
          "
        >
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item
        label="用户名"
        :label-width="formLabelWidth"
        prop="dbUsername"
      >
        <el-input v-model="form.dbUsername" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="密码"
        :label-width="formLabelWidth"
        prop="dbPassword"
      >
        <el-input v-model="form.dbPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="是否启用"
        :label-width="formLabelWidth"
        prop="status"
      >
        <el-switch
          v-model="form.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
        <span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66"
          >启用</span
        >
        <span v-if="form.status == 2" style="margin-left: 17px; color: #ff4949"
          >禁用</span
        >
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="testConnection('form')" size="medium"
        >测试连接</el-button
      >
      <el-button @click="visible = false" type="danger" size="medium"
        >取 消</el-button
      >
      <el-button type="primary" @click="submitForm('form')" size="medium"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  dbconfigInfo,
  dbconfigSave,
  dbconfigEdit,
  testDbConnection,
} from "@/api/devtools/devdbconfig.js";
export default {
  data() {
    return {
      visible: false,
      form: {
        id: "",
        dbName: "",
        dbDriverName: "com.mysql.jdbc.Driver",
        dbUrl: "",
        dbUsername: "",
        dbPassword: "",
        status: 1,
      },
      formLabelWidth: "120px",
      rule: {
        dbName: [{ required: true, message: "请输入连接名", trigger: "blur" }],
        dbDriverName: [
          { required: true, message: "请选择数据库类型", trigger: "blur" },
        ],
        dbUrl: [
          { required: true, message: "请输入数据库URL", trigger: "blur" },
        ],
        dbUsername: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        dbPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {},
  methods: {
    init(id) {
      this.resetForm();
      //修改
      if (id) {
        dbconfigInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
        //新增
      }
      this.visible = true;
    },
    //菜单类型切换
    typeChange(data) {
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].clearValidate(); // 只清除清除验证
        }
      });
      this.form.type = data;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            dbconfigEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            dbconfigSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //测试连接
    testConnection() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          testDbConnection(this.form).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: resp.data,
              });
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        id: "",
        dbName: "",
        dbDriverName: "com.mysql.jdbc.Driver",
        dbUrl: "",
        dbUsername: "",
        dbPassword: "",
        status: 1,
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
.icons_ul li i {
  display: block;
  font-size: 27px;
  float: left;
  padding: 2px;
}
</style>
