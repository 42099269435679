import request from "@/common/request"

//全局资讯分页展示
export const pdarticlePage = (queryForm) => {
    return request("get", "/pdworld/pdArticle/page", queryForm);
}
//全局资讯列表展示
export const pdarticleList = (queryForm) => {
    return request("get", "/pdworld/pdArticle/list", queryForm);
}
//全局资讯详情
export const pdarticleInfo = (id) => {
    return request('get', '/pdworld/pdArticle/info', {
        id: id
    })
}
//全局资讯信息保存
export const pdarticleSave = (data) => {
    return request('post', '/pdworld/pdArticle/save', data)
}
//全局资讯信息修改
export const pdarticleEdit = (data) => {
    return request('post', '/pdworld/pdArticle/edit', data)
}
//全局资讯信息删除
export const pdarticleDel = (id) => {
    return request('post', '/pdworld/pdArticle/delete?id=' + id)
}
//全局资讯批量删除
export const pdarticleDelBatch = (ids) => {
    return request('post', '/pdworld/pdArticle/deleteBatch', ids)
}
//全局资讯动态表头导出
export const pdarticleDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdArticle/exportDynamic', data)
}
