<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">


      <el-form-item v-if="!form.id" label="始发地" :label-width="formLabelWidth" prop="consigner">
        <el-cascader
            v-model="consignerName"
            :options="regionList"
            :props="{
                        label: 'name',
                        value: 'id',
                        leaf: 'leaf',
                      }"
            @change="onChangeConsigner"
            :disabled="!!form.id"
        ></el-cascader>
      </el-form-item>

      <el-form-item v-if="!form.id" label="到达地" :label-width="formLabelWidth" prop="province">
        <el-cascader
            v-model="provinceName"
            :options="regionList"
            :props="{
                      label: 'name',
                      value: 'id',
                      leaf: 'leaf',
                    }"
            @change="onChangeProvince"
            :disabled="!!form.id"
        ></el-cascader>
      </el-form-item>

      <!--            <el-form-item label="发货省名称" :label-width="formLabelWidth" prop="consignerName">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货省名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            -->
      <!--          <el-form-item label="发货市" :label-width="formLabelWidth" prop="consignerCity">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerCity"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货市"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="发货市名称" :label-width="formLabelWidth" prop="consignerCityName">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerCityName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货市名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->


      <!--            <el-form-item label="收货省" :label-width="formLabelWidth" prop="province">-->
      <!--                <el-input-->
      <!--                        v-model="form.province"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货省"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货省名称" :label-width="formLabelWidth" prop="provinceName">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货省名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货市" :label-width="formLabelWidth" prop="provinceCity">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceCity"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货市"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货市名称" :label-width="formLabelWidth" prop="provinceCityName">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceCityName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货市名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->

      <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
        <el-input
            v-model="form.remark"
            autocomplete="off"
            placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item label="跳转url" :label-width="formLabelWidth" prop="path">
        <el-input
            v-model="form.path"
            autocomplete="off"
            placeholder="请输入跳转url"
        ></el-input>
      </el-form-item>
      <el-form-item label="图片url" :label-width="formLabelWidth" prop="imgUrl">
        <fortress-upload
            type="image"
            name="imgUrl"
            :formData="form"
        ></fortress-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {routeadvertisingInfo, routeadvertisingSave, routeadvertisingEdit} from "@/api/pdworld/routeadvertising.js";
import {sysregionTree} from "@/api/system/sysregion";


export default {
  data() {
    return {
      visible: false,
      form: {
        consigner: "",
        consignerName: "",
        consignerCity: "",
        consignerCityName: "",
        province: "",
        provinceName: "",
        provinceCity: "",
        provinceCityName: "",
        remark: "",
        path: "",
        imgUrl: "",
      },
      formLabelWidth: "120px",
      rules: {},
      regionList: [],
      consignerName: "",
      provinceName: "",
    };
  },
  async created() {
    const {data} = await sysregionTree()

    data.map(item => {
      if (item.children) {
        item.children.map(item => {
          item.children = null
        })
      }
    })

    this.regionList = data
  },
  methods: {
    onChangeConsigner([provider, city]) {
      const p = this.regionList.find(item => item.id == provider)
      const c = p.children.find(item => item.id == city)
      console.log('p', p)
      console.log('c', c)
      this.form.consigner = p.id
      this.form.consignerName = p.name
      this.form.consignerCity = c.id
      this.form.consignerCityName = c.name
    },
    onChangeProvince([provider, city]) {
      const p = this.regionList.find(item => item.id == provider)
      const c = p.children.find(item => item.id == city)
      this.form.province = p.id
      this.form.provinceName = p.name
      this.form.provinceCity = c.id
      this.form.provinceCityName = c.name
    },
    init(id) {
      this.resetForm();
      //修改
      if (id) {
        routeadvertisingInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            routeadvertisingEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            routeadvertisingSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        consigner: "",
        consignerName: "",
        consignerCity: "",
        consignerCityName: "",
        province: "",
        provinceName: "",
        provinceCity: "",
        provinceCityName: "",
        remark: "",
        path: "",
        imgUrl: "",
      };
      this.consignerName = ""
      this.provinceName = ""
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
