import request from "@/common/request"

//线路的设置(线路上限/购买价格)分页展示
export const routesettingPage = (queryForm) => {
    return request("get", "/pdworld/routeSetting/page", queryForm);
}
//线路的设置(线路上限/购买价格)列表展示
export const routesettingList = (queryForm) => {
    return request("get", "/pdworld/routeSetting/list", queryForm);
}
//线路的设置(线路上限/购买价格)详情
export const routesettingInfo = (id) => {
    return request('get', '/pdworld/routeSetting/info', {
        id: id
    })
}
//线路的设置(线路上限/购买价格)信息保存
export const routesettingSave = (data) => {
    return request('post', '/pdworld/routeSetting/save', data)
}
//线路的设置(线路上限/购买价格)信息修改
export const routesettingEdit = (data) => {
    return request('post', '/pdworld/routeSetting/edit', data)
}
//线路的设置(线路上限/购买价格)信息删除
export const routesettingDel = (id) => {
    return request('post', '/pdworld/routeSetting/delete?id=' + id)
}
//线路的设置(线路上限/购买价格)批量删除
export const routesettingDelBatch = (ids) => {
    return request('post', '/pdworld/routeSetting/deleteBatch', ids)
}
//线路的设置(线路上限/购买价格)动态表头导出
export const routesettingDynamicExport = (data) => {
    return request("eptpost", '/pdworld/routeSetting/exportDynamic', data)
}
