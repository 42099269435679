import request from "@/common/request"

//商家的网点分页展示
export const pdstorePage = (queryForm) => {
    return request("get", "/pdworld/pdStore/page", queryForm);
}
//商家的网点列表展示
export const pdstoreList = (queryForm) => {
    return request("get", "/pdworld/pdStore/list", queryForm);
}
//商家的网点详情
export const pdstoreInfo = (id) => {
    return request('get', '/pdworld/pdStore/info', {
        id: id
    })
}
//商家的网点信息保存
export const pdstoreSave = (data) => {
    return request('post', '/pdworld/pdStore/save', data)
}
//商家的网点信息修改
export const pdstoreEdit = (data) => {
    return request('post', '/pdworld/pdStore/edit', data)
}
//商家的网点信息删除
export const pdstoreDel = (id) => {
    return request('post', '/pdworld/pdStore/delete?id=' + id)
}
//商家的网点批量删除
export const pdstoreDelBatch = (ids) => {
    return request('post', '/pdworld/pdStore/deleteBatch', ids)
}
//商家的网点动态表头导出
export const pdstoreDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdStore/exportDynamic', data)
}
