import request from "@/common/request"

//分站信息分页展示
export const subsitePage = (queryForm) => {
    return request("get", "/pdworld/subSite/page", queryForm);
}
//分站信息列表展示
export const subsiteList = (queryForm) => {
    return request("get", "/pdworld/subSite/list", queryForm);
}
//分站信息详情
export const subsiteInfo = (id) => {
    return request('get', '/pdworld/subSite/info', {
        id: id
    })
}
//分站信息信息保存
export const subsiteSave = (data) => {
    return request('post', '/pdworld/subSite/save', data)
}
//分站信息信息修改
export const subsiteEdit = (data) => {
    return request('post', '/pdworld/subSite/edit', data)
}
//分站信息信息删除
export const subsiteDel = (id) => {
    return request('post', '/pdworld/subSite/delete?id=' + id)
}
//分站信息批量删除
export const subsiteDelBatch = (ids) => {
    return request('post', '/pdworld/subSite/deleteBatch', ids)
}
//分站信息动态表头导出
export const subsiteDynamicExport = (data) => {
    return request("eptpost", '/pdworld/subSite/exportDynamic', data)
}
