import request from "@/common/request"

//获取当前用户可访问的存储配置分页展示
export const configOssPage = (queryParam) => {
    return request("get", "/system/configOss/page", queryParam);
}
//存储配置详情
export const configOssInfo = (id) => {
    return request('get', '/system/configOss/info', {
        id: id
    })
}
//保存存储配置信息
export const configOssSave = (data) => {
    return request('post', '/system/configOss/save', data)
}
//修改存储配置信息
export const configOssEdit = (data) => {
    return request('post', '/system/configOss/edit', data)
}
//删除存储配置信息
export const configOssDel = (id) => {
    return request('post', '/system/configOss/delete?id=' + id)
}

