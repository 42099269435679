import { render, staticRenderFns } from "./pdexpressagecompany-edit.vue?vue&type=template&id=1df32668&scoped=true&"
import script from "./pdexpressagecompany-edit.vue?vue&type=script&lang=js&"
export * from "./pdexpressagecompany-edit.vue?vue&type=script&lang=js&"
import style0 from "./pdexpressagecompany-edit.vue?vue&type=style&index=0&id=1df32668&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df32668",
  null
  
)

export default component.exports