<template>
	<div class="contentItem">
		<div class="contentItem-title">
			<slot name="contentItem-title"></slot>
		</div>
		<div class="contentItem-body">
			<slot name="contentItem-body"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "contentItem",
	props: {},
	data() {
		return {}
	},
	created() {},
	methods: {},
	components: {},
}
</script>

<style lang="scss" scoped>
.contentItem {
	background: #fff;
	border-radius: 4px;
	font-size: 14px;
	.contentItem-title {
		border-bottom: 1px solid #e8eaec;
		padding: 14px 16px;
		line-height: 1;
	}
	.contentItem-body {
		padding: 16px;
	}
}
</style>
