import request from "@/common/request"

//会员等级设置分页展示
export const memberlevelrelationPage = (queryForm) => {
    return request("get", "/level/memberLevelRelation/page", queryForm);
}
//会员等级设置列表展示
export const memberlevelrelationList = (queryForm) => {
    return request("get", "/level/memberLevelRelation/list", queryForm);
}
//会员等级设置详情
export const memberlevelrelationInfo = (id) => {
    return request('get', '/level/memberLevelRelation/info', {
        id: id
    })
}
//会员等级设置信息保存
export const memberlevelrelationSave = (data) => {
    return request('post', '/level/memberLevelRelation/save', data)
}
//会员等级设置信息修改
export const memberlevelrelationEdit = (data) => {
    return request('post', '/level/memberLevelRelation/edit', data)
}
//会员等级设置信息删除
export const memberlevelrelationDel = (id) => {
    return request('post', '/level/memberLevelRelation/delete?id=' + id)
}
//会员等级设置批量删除
export const memberlevelrelationDelBatch = (ids) => {
    return request('post', '/level/memberLevelRelation/deleteBatch', ids)
}
//会员等级设置动态表头导出
export const memberlevelrelationDynamicExport = (data) => {
    return request("eptpost", '/level/memberLevelRelation/exportDynamic', data)
}
