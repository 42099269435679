import request from "@/common/request"

//商家基础信息的图片表分页展示
export const pdshopinfoimgPage = (queryForm) => {
    return request("get", "/pdworld/pdShopinfoImg/page", queryForm);
}
//商家基础信息的图片表列表展示
export const pdshopinfoimgList = (queryForm) => {
    return request("get", "/pdworld/pdShopinfoImg/list", queryForm);
}
//商家基础信息的图片表详情
export const pdshopinfoimgInfo = (id) => {
    return request('get', '/pdworld/pdShopinfoImg/info', {
        id: id
    })
}
//商家基础信息的图片表信息保存
export const pdshopinfoimgSave = (data) => {
    return request('post', '/pdworld/pdShopinfoImg/save', data)
}
//商家基础信息的图片表信息修改
export const pdshopinfoimgEdit = (data) => {
    return request('post', '/pdworld/pdShopinfoImg/edit', data)
}
//商家基础信息的图片表信息删除
export const pdshopinfoimgDel = (id) => {
    return request('post', '/pdworld/pdShopinfoImg/delete?id=' + id)
}
//商家基础信息的图片表批量删除
export const pdshopinfoimgDelBatch = (ids) => {
    return request('post', '/pdworld/pdShopinfoImg/deleteBatch', ids)
}
//商家基础信息的图片表动态表头导出
export const pdshopinfoimgDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdShopinfoImg/exportDynamic', data)
}
