<template>
  <div>
    <el-dialog
      :title="form.id ? '编辑' : '新增'"
      :visible.sync="visible"
      custom-class="dialog-edit"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="账号" :label-width="formLabelWidth" prop="loginName">
          <el-input v-model="form.loginName" auto-complete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="realName">
          <el-input v-model="form.realName" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 新增 -->
        <el-form-item label="密码" :label-width="formLabelWidth" prop="password" v-if="!form.id">
          <el-input
            v-model="form.password"
            auto-complete="new-password"
            type="password"
            :show-password="true"
          >
            <el-button
              style="padding-right:10px"
              slot="suffix"
              type="text"
              @click="randomHandle"
            >随机密码</el-button>
          </el-input>
        </el-form-item>
        <!-- 修改 -->
        <el-form-item label="密码" :label-width="formLabelWidth" prop="passwordUpdate" v-else>
          <template>
            <el-input
              v-model="form.passwordUpdate"
              auto-complete="new-password"
              type="password"
              :show-password="true"
            >
              <el-button style slot="suffix" type="text" @click="randomHandle">随机密码</el-button>
            </el-input>
          </template>
        </el-form-item>
        <el-form-item label="联系方式" :label-width="formLabelWidth" prop="phone">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth" prop="roleIds">
          <el-select v-model="form.roleIds" multiple placeholder="请选择" @change="roleChange">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" :label-width="formLabelWidth" prop="deptId">
          <el-select v-model="form.deptId" clearable placeholder="请选择">
            <el-option :label="selDept.label" :value="selDept.id">
              <el-tree
                :data="deptList"
                :props="defaultProps"
                accordion
                highlight-current
                @node-click="handleNodeClickDept"
              ></el-tree>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位" :label-width="formLabelWidth" prop="postId">
          <el-select v-model="form.postId" clearable placeholder="请选择">
            <el-option :label="selPost.label" :value="selPost.id">
              <el-tree
                :data="postList"
                :props="defaultProps"
                accordion
                highlight-current
                @node-click="handleNodeClickPost"
              ></el-tree>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号状态" :label-width="formLabelWidth" prop="status">
          <el-switch
            v-model="form.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
          <span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66">启用</span>
          <span v-if="form.status != 1" style="margin-left: 17px; color: #ff4949">禁用</span>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="form.remark" autocomplete="off" type="textarea" :rows="2"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')" size="medium">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="生成随机密码" :visible.sync="randomVisible" custom-class="dialog-edit">
      <random-pwd @click="getPwd"></random-pwd>
      <!-- <span>这是一段信息</span> -->
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>-->
    </el-dialog>
  </div>
</template>
<script>
import randomPwd from "@/components/random/index"
import { userInfo, userSave, userEdit } from "@/api/auth/sysuser.js"
import { deptList } from "@/api/auth/sysdept.js"
import { postList } from "@/api/auth/syspost.js"
import { roleList } from "@/api/auth/sysrole.js"
import { dictList } from "@/api/public.api.js"
import md5 from "crypto-js/md5"
import * as ftVal from "@/common/fortressValidator.js";

export default {
  data () {
    return {
      ftVal:ftVal,
      randomVisible: false,
      formPwdVisible: false,
      fileUploadUrl: window.$conf.commonConf.baseUrl + "/system/oss/upload",
      fileUrl: "",
      visible: false,
      form: {
        id: "",
        loginName: "",
        realName: "",
        password: "",
        phone: "",
        deptId: "",
        postId: "",
        status: 1,
        remark: "",
        roleIds: [],
        roles: [],
        passwordUpdate: "",
      },
      idcardTypeList: [],
      formLabelWidth: "80px",
      rules: {
        loginName: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入电话", trigger: "blur" },{ validator: ftVal.validatePhone, trigger: "blur" }],
        deptId: [{ required: true, message: "请选择部门", trigger: "blur" }],
        postId: [{ required: true, message: "请选择职位", trigger: "blur" }],
        roleIds: [{ required: true, message: "请选择角色", trigger: "blur" }],
        status: [{ required: true, message: "请确定状态", trigger: "blur" }],
      },
      deptList: [],
      postList: [],
      roleList: [],
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      selDept: {},
      selPost: {},
      roleNames: [],
      isAdmin: false,
      currentRoles: [],
      imagesDesc: {
        images: {
          type: "image-uploader",
          attrs: {
            fileSize: 3,
            action: window.$conf.commonConf.baseUrl + "/system/oss/upload",
            // 上传的文件字段名 (同官网)
            name: "upfile",
            responseFn (response) {
              return response.data.url
            },
          },
        },
      },
    }
  },
  computed: {},
  components: {
    randomPwd
  },
  mounted () {
    // console.log(window.$conf.commonConf.baseUrl)
    // console.log("isAdmin", this.isAdmin)
  },
  updated () {
  },
  methods: {
    // 部门改变

    // 角色改变时触发
    roleChange ($event) {
      this.currentRoles = []
      let currentSelect = $event
      currentSelect.forEach(item => {
        this.roleList.forEach(item1 => {
          if (item1.id === item) {
            this.currentRoles.push(item1)
          }
        })
      })
      this.isAdmin = this.currentRoles.some(item => {
        return item.isAdmin == 1
      })
      this.rules.postId[0].required = !this.isAdmin
      this.rules.deptId[0].required = !this.isAdmin
      this.form.deptId = ""
      this.form.postId = ""
    },
    //查询部门列表数据
    queryDeptList () {
      this.deptList = []
      deptList().then(resp => {
        if (resp.code == 0) {
          this.deptList = resp.data
        }
      })
    },
    //查询职位列表
    queryPostList (deptId) {
      this.postList = []
      postList({ deptId: deptId }).then(resp => {
        if (resp.code == 0) {
          this.postList = resp.data
        }
      })
    },
    //查询角色列表
    queryRoleList () {
      this.roleList = []
      roleList().then(resp => {
        if (resp.code == 0) {
          this.roleList = resp.data
        }
      })
    },
    //字典
    queryDictList () {
      this.idcardTypeList = []
      dictList("idcardType").then(resp => {
        if (resp.code == 0) {
          this.idcardTypeList = resp.data
        }
      })
    },
    init (id) {
      this.resetForm()
      this.queryDeptList()
      this.queryRoleList()
      this.queryDictList()
      //修改
      if (id) {
        userInfo(id).then(resp => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              let admin = resp.data.roles.some(item => {
                return item.isAdmin === 1
              })
              if (admin) {
                this.rules.postId[0].required = !admin
                this.rules.deptId[0].required = !admin
              }
              if (resp.data.postId == "-1" && resp.data.deptId != "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                  postId: "",
                  createPostName: "",
                }
              }
              if (resp.data.postId != "-1" && resp.data.deptId == "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                  deptId: "",
                  createDeptName: "",
                }
              }
              if (resp.data.postId == "-1" && resp.data.deptId == "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                  postId: "",
                  createPostName: "",
                  deptId: "",
                  createDeptName: "",
                }
              }
              if (resp.data.deptId != "-1" && resp.data.postId != "-1") {
                this.form = {
                  ...this.form,
                  ...resp.data,
                }
              }

              if (this.form.avatar) {
                this.fileUrl = this.$store.state.prefixUrl + this.form.avatar
              }
              this.selDept = {
                id: resp.data.deptId,
                label: resp.data.createDeptName,
              }
              this.selPost = {
                id: resp.data.postId,
                label: resp.data.createPostName,
              }
              if (resp.data.roles) {
                resp.data.roles.forEach(item => {
                  this.form.roleIds.push(item.id)
                })
                this.isAdmin = this.form.roles.some(item => {
                  return item.isAdmin == 1
                })
              }
            })
            if (resp.data.deptId) {
              this.queryPostList(resp.data.deptId)
            }
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            })
          }
        })
      }
      this.visible = true
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          //用户角色
          let selRoles = this.form.roleIds
          this.form.roles = []
          selRoles.forEach(item => {
            this.form.roles.push({ id: item })
          })
          //修改
          if (this.form.id) {
            //校验密码是否修改
            if (this.form.passwordUpdate) {
              this.form.password = md5(this.form.passwordUpdate).toString();
            } else {
              this.form.password = ""
            }
            userEdit({ ...this.form, deptId: this.form.deptId || -1, postId: this.form.postId || -1 }).then(resp => {
              if (resp.code == 0) {
                this.visible = false
                this.$notify({
                  title: "修改成功",
                  type: "success",
                  message: "您已成功修改用户信息!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
            //新增
          } else {
            let tempPwd = this.form.password;
            this.form.password = md5(this.form.password).toString();
            userSave({
              ...this.form,
              deptId: this.form.deptId || -1,
              postId: this.form.postId || -1,
            }).then((resp) => {
              if (resp.code == 0) {
                this.visible = false
                this.$notify({
                  title: "添加成功",
                  type: "success",
                  message: "您已成功添加一个用户!",
                })
                this.$emit("refreshData")
              } else {
                this.form.password = tempPwd;
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.form = {
        id: "",
        loginName: "",
        nickname: "",
        realName: "",
        password: "",
        email: "",
        phone: "",
        gender: "",
        birthday: "",
        avatar: "",
        idcardType: "",
        idcard: "",
        deptId: "",
        postId: "",
        remark: "",
        status: 1,
        roleIds: [],
        roles: [],
        passwordUpdate: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields()
        }
      })
    },
    //上传成功
    handleAvatarSuccess (res, file) {
      if (res && res.code == 0) {
        this.form.avatar = res.data.path
        this.fileUrl = res.data.url
      }
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === "image/jpeg"
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!")
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!")
      }
      return isJPG && isLt2M
    },
    //部门选择
    handleNodeClickDept (data) {
      this.selDept.id = data.id
      this.selDept.label = data.name
      this.form.deptId = data.id
      this.selPost.id = ""
      this.selPost.label = ""
      this.form.postId = ""
      this.queryPostList(this.form.deptId)
    },
    //职位选择
    handleNodeClickPost (data) {
      this.selPost.id = data.id
      this.selPost.label = data.name
      this.form.postId = data.id
    },
    randomHandle () {
      this.randomVisible = true
    },
    getPwd (e) {

      if (e) {
        if (this.form.id) {
          this.form.passwordUpdate = e
        } else {
          this.form.password = e
        }
      }
      this.randomVisible = false

    }
  },
}
</script>
<style scoped lang='scss'>
/deep/ .el-input .el-input__icon {
  float: left;
}
/deep/ .el-input--suffix .el-input__inner {
  padding-right: 90px;
}
</style>
