import request from "@/common/request"

//车型的车长表分页展示
export const pdcartypelongPage = (queryForm) => {
    return request("get", "/pdworld/pdCarTypeLong/page", queryForm);
}
//车型的车长表列表展示
export const pdcartypelongList = (queryForm) => {
    return request("get", "/pdworld/pdCarTypeLong/list", queryForm);
}
//车型的车长表详情
export const pdcartypelongInfo = (id) => {
    return request('get', '/pdworld/pdCarTypeLong/info', {
        id: id
    })
}
//车型的车长表信息保存
export const pdcartypelongSave = (data) => {
    return request('post', '/pdworld/pdCarTypeLong/save', data)
}
//车型的车长表信息修改
export const pdcartypelongEdit = (data) => {
    return request('post', '/pdworld/pdCarTypeLong/edit', data)
}
//车型的车长表信息删除
export const pdcartypelongDel = (id) => {
    return request('post', '/pdworld/pdCarTypeLong/delete?id=' + id)
}
//车型的车长表批量删除
export const pdcartypelongDelBatch = (ids) => {
    return request('post', '/pdworld/pdCarTypeLong/deleteBatch', ids)
}
//车型的车长表动态表头导出
export const pdcartypelongDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdCarTypeLong/exportDynamic', data)
}
