<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="中文" :label-width="formLabelWidth" prop="name">
                <el-input
                        v-model="form.name"
                        autocomplete="off"
                        placeholder="请输入中文"
                ></el-input>
            </el-form-item>
            <el-form-item label="富文本" :label-width="formLabelWidth" prop="content">
                <fortress-editor v-model="form.content"></fortress-editor>
            </el-form-item>
            <el-form-item label="字母" :label-width="formLabelWidth" prop="auth">
                <el-input
                        v-model="form.auth"
                        autocomplete="off"
                        placeholder="请输入字母"
                ></el-input>
            </el-form-item>
            <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
                <el-input
                        v-model="form.phone"
                        autocomplete="off"
                        placeholder="请输入手机号"
                ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                <el-input
                        v-model="form.email"
                        autocomplete="off"
                        placeholder="请输入邮箱"
                ></el-input>
            </el-form-item>
            <el-form-item label="图片" :label-width="formLabelWidth" prop="images">
                <fortress-upload
                  type="image"
                  name="images"
                  :formData="form"
                ></fortress-upload>
            </el-form-item>
            <el-form-item label="文件" :label-width="formLabelWidth" prop="files">
                <fortress-upload
                  type="file"
                  name="files"
                  :formData="form"
                ></fortress-upload>
            </el-form-item>
            <el-form-item label="视频" :label-width="formLabelWidth" prop="video">
                <fortress-upload
                  type="video"
                  name="video"
                  :formData="form"
                ></fortress-upload>
            </el-form-item>
            <el-form-item label="数字" :label-width="formLabelWidth" prop="vueNumber">
                <el-input
                        v-model="form.vueNumber"
                        autocomplete="off"
                        placeholder="请输入数字"
                ></el-input>
            </el-form-item>
            <el-form-item label="单选" :label-width="formLabelWidth" prop="vueRadio">
                <fortress-radio-group
                        v-model="form.vueRadio"
                        :dictList="dictDemoList"
                >
                </fortress-radio-group>
            </el-form-item>
            <el-form-item label="多行文本" :label-width="formLabelWidth" prop="vueTextarea">
                <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入多行文本"
                        v-model="form.vueTextarea"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="复选" :label-width="formLabelWidth" prop="vueCheckboxCheckedList">
                <el-checkbox-group v-model="form.vueCheckboxCheckedList">
                <el-checkbox
                        v-for="item in dictDemoList"
                        :label="item.code"
                        :key="item.code"
                >{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="下拉" :label-width="formLabelWidth" prop="vueSelect">
                <fortress-select
                        v-model="form.vueSelect"
                        :dictList="dictDemoList"
                        placeholder="请选择下拉"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="枚举下拉" :label-width="formLabelWidth" prop="selectEnum">
                <fortress-select
                        v-model="form.selectEnum"
                        :dictList="DataPermissionPostList"
                        placeholder="请选择枚举下拉"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="url下拉" :label-width="formLabelWidth" prop="selectUrl">
                <fortress-select
                        v-model="form.selectUrl"
                        :dictList="selectUrlList"
                        placeholder="请选择url下拉"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="json下拉" :label-width="formLabelWidth" prop="selectJson">
                <fortress-select
                        v-model="form.selectJson"
                        :dictList="selectJsonList"
                        placeholder="请选择json下拉"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="日期" :label-width="formLabelWidth" prop="vueDate">
                <el-date-picker
                        v-model="form.vueDate"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="日期时间" :label-width="formLabelWidth" prop="vueDatetime">
                <el-date-picker
                        v-model="form.vueDatetime"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
                <el-switch
                      v-model="form.status"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="2"
                ></el-switch>
            </el-form-item>
            <el-form-item label="枚举单选" :label-width="formLabelWidth" prop="redioEnum">
                <fortress-radio-group
                        v-model="form.redioEnum"
                        :dictList="DataPermissionPostList"
                >
                </fortress-radio-group>
            </el-form-item>
            <el-form-item label="url单选" :label-width="formLabelWidth" prop="radioUrl">
                <fortress-radio-group
                        v-model="form.radioUrl"
                        :dictList="radioUrlList"
                >
                </fortress-radio-group>
            </el-form-item>
            <el-form-item label="json单选" :label-width="formLabelWidth" prop="radioJson">
                <fortress-radio-group
                        v-model="form.radioJson"
                        :dictList="radioJsonList"
                >
                </fortress-radio-group>
            </el-form-item>
            <el-form-item label="枚举复选" :label-width="formLabelWidth" prop="checkboxEnumCheckedList">
                <el-checkbox-group v-model="form.checkboxEnumCheckedList">
                <el-checkbox
                        v-for="item in DataPermissionPostList"
                        :label="item.code"
                        :key="item.code"
                >{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="url复选" :label-width="formLabelWidth" prop="checkboxUrlCheckedList">
                <el-checkbox-group v-model="form.checkboxUrlCheckedList">
                <el-checkbox
                        v-for="item in checkboxUrlList"
                        :label="item.code"
                        :key="item.code"
                >{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="json复选" :label-width="formLabelWidth" prop="checkboxJsonCheckedList">
                <el-checkbox-group v-model="form.checkboxJsonCheckedList">
                <el-checkbox
                        v-for="item in checkboxJsonList"
                        :label="item.code"
                        :key="item.code"
                >{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { tmpdemogenerateInfo, tmpdemogenerateSave, tmpdemogenerateEdit } from "@/api/demo/tmpdemogenerate.js";
    import { dictList,enumList,urlList, } from "@/api/public.api.js";
    import * as ftVal from "@/common/fortressValidator.js";
    export default {
        data() {
            return {
                visible: false,
                form: {
                    name:"",
                    content:"",
                    auth:"",
                    phone:"",
                    email:"",
                    images:"",
                    files:"",
                    video:"",
                    vueNumber:"",
                    vueRadio:"",
                    vueTextarea:"",
                    vueCheckboxCheckedList:[],
                    vueCheckbox:"",
                    vueSelect:"",
                    selectEnum:"",
                    selectUrl:"",
                    selectJson:"",
                    vueDate:"",
                    vueDatetime:"",
                    status:"",
                    redioEnum:"",
                    radioUrl:"",
                    radioJson:"",
                    checkboxEnumCheckedList:[],
                    checkboxEnum:"",
                    checkboxUrlCheckedList:[],
                    checkboxUrl:"",
                    checkboxJsonCheckedList:[],
                    checkboxJson:"",
                },
                formLabelWidth: "120px",
                myconfig: window.$conf.commonConf.ueConfig,
                rules: {
                    name:[
                          { required: true, message: "请输入中文", trigger: "blur" },
                          { validator: ftVal.validateChinese,trigger: "blur"},
                      ],
                    content:[
                          { required: true, message: "请输入富文本", trigger: "blur" },
                      ],
                    auth:[
                          { validator: ftVal.validateAlphabets,trigger: "blur"},
                      ],
                    phone:[
                          { required: true, message: "请输入手机号", trigger: "blur" },
                          { validator: ftVal.validatePhone,trigger: "blur"},
                      ],
                    email:[
                          { required: true, message: "请输入邮箱", trigger: "blur" },
                          { validator: ftVal.validateEMail,trigger: "blur"},
                      ],
                    images:[
                          { required: true, message: "请输入图片", trigger: "blur" },
                      ],
                    files:[
                          { required: true, message: "请输入文件", trigger: "blur" },
                      ],
                    video:[
                          { required: true, message: "请输入视频", trigger: "blur" },
                      ],
                    vueNumber:[
                          { validator: ftVal.validateInteger,trigger: "blur"},
                      ],
                },
                dictDemoList:[],
                DataPermissionPostList:[],
                selectUrlList:[],
                radioUrlList:[],
                checkboxUrlList:[],
                selectJsonList:[],
                radioJsonList:[],
                checkboxJsonList:[],
            };
        },
        created() {
        },
        methods: {
            queryDictList(){
                this.dictDemoList = [];
                dictList("dictDemo").then((resp) => {
                  if (resp.code == 0) {
                    this.dictDemoList = resp.data;
                  }
                });
            },
            queryEnumList(){
                this.DataPermissionPostList = [];
                enumList("DataPermissionPost","").then((resp) => {
                  if (resp.code == 0) {
                    this.DataPermissionPostList = resp.data;
                  }
                });
            },
            queryUrlList(){
                this.selectUrlList = [];
                urlList("/system/sysRole/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.selectUrlList.push({ code: item.id, name: item.name });
                      });
                    }
                  }
                });
                this.radioUrlList = [];
                urlList("/system/sysRole/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.radioUrlList.push({ code: item.id, name: item.name });
                      });
                    }
                  }
                });
                this.checkboxUrlList = [];
                urlList("/system/sysRole/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.checkboxUrlList.push({ code: item.id, name: item.name });
                      });
                    }
                  }
                });
            },
            queryJsonList(){
                this.selectJsonList = [];
                let selectJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
                this.selectJsonList = JSON.parse(selectJsonListData);
                this.radioJsonList = [];
                let radioJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
                this.radioJsonList = JSON.parse(radioJsonListData);
                this.checkboxJsonList = [];
                let checkboxJsonListData = '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
                this.checkboxJsonList = JSON.parse(checkboxJsonListData);
            },
            init(id) {
                this.resetForm();
                this.queryDictList();
                this.queryEnumList();
                this.queryUrlList();
                this.queryJsonList();
                //修改
                if (id) {
                    tmpdemogenerateInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                                if (resp.data.files) {
                                    this.form.files = [];
                                    let filesArray = JSON.parse(resp.data.files);
                                    filesArray.forEach((item) => {
                                      this.form.files.push({ name: item.name, url: item.url });
                                    });
                                }
                                if(resp.data.vueCheckbox){
                                    this.form.vueCheckboxCheckedList = resp.data.vueCheckbox.split(';');
                                }
                                if(resp.data.checkboxEnum){
                                    this.form.checkboxEnumCheckedList = resp.data.checkboxEnum.split(';');
                                }
                                if(resp.data.checkboxUrl){
                                    this.form.checkboxUrlCheckedList = resp.data.checkboxUrl.split(';');
                                }
                                if(resp.data.checkboxJson){
                                    this.form.checkboxJsonCheckedList = resp.data.checkboxJson.split(';');
                                }
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.files) {
                        let filesJson = [];
                        this.form.files.forEach((item) => {
                            filesJson.push({ name: item.name, url: item.url });
                        });
                        this.form.files = JSON.stringify(filesJson);
                    }
                    if(this.form.vueCheckboxCheckedList){
                        this.form.vueCheckbox = this.form.vueCheckboxCheckedList.join(";");
                    }
                    if(this.form.checkboxEnumCheckedList){
                        this.form.checkboxEnum = this.form.checkboxEnumCheckedList.join(";");
                    }
                    if(this.form.checkboxUrlCheckedList){
                        this.form.checkboxUrl = this.form.checkboxUrlCheckedList.join(";");
                    }
                    if(this.form.checkboxJsonCheckedList){
                        this.form.checkboxJson = this.form.checkboxJsonCheckedList.join(";");
                    }
                    //修改
                    if (this.form.id) {
                        tmpdemogenerateEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        tmpdemogenerateSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    name:"",
                    content:"",
                    auth:"",
                    phone:"",
                    email:"",
                    images:"",
                    files:"",
                    video:"",
                    vueNumber:"",
                    vueRadio:"",
                    vueTextarea:"",
                    vueCheckboxCheckedList:[],
                    vueCheckbox:"",
                    vueSelect:"",
                    selectEnum:"",
                    selectUrl:"",
                    selectJson:"",
                    vueDate:"",
                    vueDatetime:"",
                    status:"",
                    redioEnum:"",
                    radioUrl:"",
                    radioJson:"",
                    checkboxEnumCheckedList:[],
                    checkboxEnum:"",
                    checkboxUrlCheckedList:[],
                    checkboxUrl:"",
                    checkboxJsonCheckedList:[],
                    checkboxJson:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
