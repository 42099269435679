<template>
	<div>
		<el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
			<div class="info-item">
				<h5 class="from-title">基本信息</h5>
				<div class="form-item">
					<label class="form-item-label">存储编码:</label>
					<div class="form-item-content">{{ objInfo.ftCode }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">平台类型:</label>
					<div class="form-item-content">{{ objInfo.platformName }}</div>
				</div>
				<!-- 本地存储 -->
				<div v-if="objInfo.platform == '1'">
					<div class="form-item">
						<label class="form-item-label">文件存储位置:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.localFilePosition }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">文件访问路径:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.localFileUrl }}
						</div>
					</div>
				</div>

				<!-- 阿里云存储 -->
				<div v-if="objInfo.platform == '2'">
					<div class="form-item">
						<label class="form-item-label">访问域名:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.aliyunEndpoint }}
							<fortress-copy :copyData="objInfo.ossPlatFormDto.aliyunEndpoint"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">存储空间:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.aliyunBucket }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">AccessKey:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.aliyunAccessKey }}
							<fortress-copy :copyData="objInfo.ossPlatFormDto.aliyunAccessKey"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">SecretKey:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.aliyunSecretKey }}
							<fortress-copy :copyData="objInfo.ossPlatFormDto.aliyunSecretKey"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">路径前缀:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.aliyunPathPrefix }}
						</div>
					</div>
				</div>
				<!-- 京东云 -->
				<div v-if="objInfo.platform == '3'">
					<div class="form-item">
						<label class="form-item-label">访问域名:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.jdcloudEndpoint }}
							<fortress-copy :copyData="objInfo.ossPlatFormDto.jdcloudEndpoint"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">地区编码:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.jdcloudRegion }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">AccessKey:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.jdcloudAccessKey }}
							<fortress-copy :copyData="objInfo.ossPlatFormDto.jdcloudAccessKey"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">SecretKey:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.jdcloudSecretKey }}
							<fortress-copy :copyData="objInfo.ossPlatFormDto.jdcloudSecretKey"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">存储空间:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.jdcloudBucket }}
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">路径前缀:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.jdcloudPathPrefix }}
						</div>
					</div>
				</div>

				<!-- 腾讯云 -->
				<div v-if="objInfo.platform == '4'">
					<div class="form-item">
						<label class="form-item-label">访问域名:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.cosEndpoint }}
							<fortress-copy :copyData="objInfo.ossPlatFormDto.cosEndpoint"></fortress-copy>
						</div>
					</div>
					<div class="form-item">
						<label class="form-item-label">存储空间:</label>
						<div class="form-item-content">
							{{ objInfo.ossPlatFormDto.cosBucketName }}
						</div>
					</div>

          <div class="form-item">
            <label class="form-item-label">AppId:</label>
            <div class="form-item-content">
              {{ objInfo.ossPlatFormDto.cosAppId }}
              <fortress-copy
                :copyData="objInfo.ossPlatFormDto.cosAppId"
              ></fortress-copy>
            </div>
          </div>
          <div class="form-item">
            <label class="form-item-label">SecretId:</label>
            <div class="form-item-content">
              {{ objInfo.ossPlatFormDto.cosSecretId }}
              <fortress-copy
                :copyData="objInfo.ossPlatFormDto.cosSecretId"
              ></fortress-copy>
            </div>
          </div>
          <div class="form-item">
            <label class="form-item-label">SecretKey:</label>
            <div class="form-item-content">
              {{ objInfo.ossPlatFormDto.cosSecretKey }}
              <fortress-copy
                :copyData="objInfo.ossPlatFormDto.cosSecretKey"
              ></fortress-copy>
            </div>
          </div>
          <div class="form-item">
            <label class="form-item-label">地域:</label>
            <div class="form-item-content">
              {{ objInfo.ossPlatFormDto.cosRegion }}
            </div>
          </div>
          <div class="form-item">
            <label class="form-item-label">路径前缀:</label>
            <div class="form-item-content">
              {{ objInfo.ossPlatFormDto.cosPathPrefix }}
            </div>
          </div>
        </div>

				<div class="form-item">
					<label class="form-item-label">备注:</label>
					<div class="form-item-content">{{ objInfo.remark }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">状态:</label>
					<div class="form-item-content">
						{{ objInfo.status == 1 ? "已启用" : "未启用" }}
					</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">创建人:</label>
					<div class="form-item-content">{{ objInfo.createUserName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">创建部门:</label>
					<div class="form-item-content">{{ objInfo.createDeptName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">创建时间:</label>
					<div class="form-item-content">{{ objInfo.createTime }}</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
import { configOssInfo } from "@/api/system/configoss.js"
export default {
	data() {
		return {
			drawer: true,
			direction: "rtl",
			objInfo: {
				ftCode: "",
				platform: "1",
				remark: "",
				status: 1,
				ossPlatFormDto: {},
			},
		}
	},
	created() {},
	methods: {
		init(id) {
			if (id) {
				configOssInfo(id).then(res => {
					this.objInfo = {
						...this.objInfo,
						...res.data,
					}
					if (this.objInfo.platform == "1") {
						this.objInfo.platformName = "本地存储"
					} else if (this.objInfo.platform == "2") {
						this.objInfo.platformName = "阿里云"
					}
					if (this.objInfo.platform == "3") {
						this.objInfo.platformName = "京东云"
					}
					if (this.objInfo.platform == "4") {
						this.objInfo.platformName = "腾讯云"
					}
				})
			}
			this.drawer = true
		},
		// 关闭抽屉
		handleClose(done) {
			done()
		},
	},
}
</script>
<style scoped>
.from-title {
	margin-bottom: 20px;
	padding: 0 20px 20px 20px;
	font-size: 16px;
	color: #17233d;
	border-bottom: 1px solid #eee;
}
.info-item {
	margin-bottom: 40px;
}

.form-item {
	margin-bottom: 20px;
	font-size: 14px;
}
.form-item-label {
	text-align: right;
	float: left;
	width: 25%;
}
.form-item-content {
	margin-left: 30%;
	width: 70%;
}
</style>
