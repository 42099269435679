import { render, staticRenderFns } from "./memberaccountserial-info.vue?vue&type=template&id=47d1e6b6&scoped=true&"
import script from "./memberaccountserial-info.vue?vue&type=script&lang=js&"
export * from "./memberaccountserial-info.vue?vue&type=script&lang=js&"
import style0 from "./memberaccountserial-info.vue?vue&type=style&index=0&id=47d1e6b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d1e6b6",
  null
  
)

export default component.exports