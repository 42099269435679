<!-- 公众号 -->
<template>
	<div class="APP">
		<el-form label-width="200px" ref="form" :model="formData" :rules="rules">
			<el-form-item label="APPID" prop="appId">
				<el-input placeholder="请输入内容" v-model="formData.appId" clearable>
					<template slot="append">
						<el-tooltip effect="dark">
							<div slot="content">
								<a href="https://opendocs.alipay.com/open/200/105310#%E6%9F%A5%E7%9C%8B%20APPID" target="_blank">
									点击查看APPID
								</a>
							</div>
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="应用私钥" prop="privateKey">
				<el-input placeholder="请输入内容" v-model="formData.privateKey" clearable>
					<template slot="append">
						<el-tooltip effect="dark">
							<div slot="content">
								应用私钥为使用工具自己生成的，
								<a
									href="https://opendocs.alipay.com/open/02nlga#%E7%AC%AC%E4%B8%80%E6%AD%A5%EF%BC%9A%E7%94%9F%E6%88%90%E5%AF%86%E9%92%A5"
									target="_blank"
								>
									生成教程
								</a>
							</div>
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="支付宝公钥" prop="aliPayPublicKey">
				<el-input placeholder="请输入内容" v-model="formData.aliPayPublicKey" clearable>
					<template slot="append">
						<el-tooltip effect="dark">
							<div slot="content">
								<a
									href="https://opendocs.alipay.com/open/02nlga#%E8%8E%B7%E5%8F%96%E6%94%AF%E4%BB%98%E5%AE%9D%E5%85%AC%E9%92%A5"
									target="_blank"
								>
									点击查看支付宝公钥
								</a>
							</div>
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="异步通知回调地址" prop="notifyUrl">
				<el-input placeholder="请输入内容" v-model="formData.notifyUrl" clearable>
					<template slot="append">
						<el-tooltip effect="dark" content="支付结果通知地址">
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submit">提交</el-button>
				<el-button type="info" @click="cancel">取消</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import request from "@/common/request"
export default {
	name: "APP",
	props: {
		data: {},
	},
	data() {
		return {
			formData: {
				aliPayPublicKey: null,
				appId: null,
				id: "",
				notifyUrl: null,
				payTypeCode: "",
				payTypeName: "",
				privateKey: null,
				returnUrl: null,
				sceneCode: "",
				status: 3,
			},
			rules: {
				appId: [{ required: true, message: "请输入APPID", trigger: "blur" }],
				privateKey: [{ required: true, message: "请输入应用私钥", trigger: "blur" }],
				aliPayPublicKey: [{ required: true, message: "请输入支付宝公钥", trigger: "blur" }],
				notifyUrl: [{ required: true, message: "请输入回调地址-", trigger: "blur" }],
			},
		}
	},
	computed: {},
	methods: {
		submit() {
			console.log(this.$refs.form)
			this.$refs.form.validate(async valid => {
				if (!valid) return
				const { code, msg } = await request("post", "/pay/payAliConfig/edit", this.formData)
				console.log(code)
				if (code == 0) {
					this.$message.success("提交成功")
					this.$router.back()
				} else {
					this.$message.error(msg || "提交失败")
				}
			})
		},
		cancel() {
			this.$router.back()
		},
	},
	watch: {
		data: {
			handler(val) {
				this.formData = val || {}
			},
			deep: true,
			immediate: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.APP {
}
</style>
