import request from "@/common/request"

//会员账户充值订单分页展示
export const memberaccountrechargeorderPage = (queryForm) => {
    return request("get", "/account/memberAccountRechargeOrder/page", queryForm);
}
//会员账户充值订单列表展示
export const memberaccountrechargeorderList = (queryForm) => {
    return request("get", "/account/memberAccountRechargeOrder/list", queryForm);
}
//会员账户充值订单详情
export const memberaccountrechargeorderInfo = (id) => {
    return request('get', '/account/memberAccountRechargeOrder/info', {
        id: id
    })
}
//会员账户充值订单信息保存
export const memberaccountrechargeorderSave = (data) => {
    return request('post', '/account/memberAccountRechargeOrder/save', data)
}
//会员账户充值订单信息修改
export const memberaccountrechargeorderEdit = (data) => {
    return request('post', '/account/memberAccountRechargeOrder/edit', data)
}
//会员账户充值订单信息删除
export const memberaccountrechargeorderDel = (id) => {
    return request('post', '/account/memberAccountRechargeOrder/delete?id=' + id)
}
//会员账户充值订单批量删除
export const memberaccountrechargeorderDelBatch = (ids) => {
    return request('post', '/account/memberAccountRechargeOrder/deleteBatch', ids)
}
//会员账户充值订单动态表头导出
export const memberaccountrechargeorderDynamicExport = (data) => {
    return request("eptpost", '/account/memberAccountRechargeOrder/exportDynamic', data)
}
