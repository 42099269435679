import request from "@/common/request"

//消息表分页展示
export const noticePage = (queryForm) => {
    return request("get", "/pdworld/notice/page", queryForm);
}
//消息表列表展示
export const noticeList = (queryForm) => {
    return request("get", "/pdworld/notice/list", queryForm);
}
//消息表详情
export const noticeInfo = (id, status) => {
    return request('get', '/pdworld/notice/info', {
        id: id,
        status: status,
    })
}
//消息表信息保存
export const noticeSave = (data) => {
    return request('post', '/pdworld/notice/save', data)
}
//消息表信息修改
export const noticeEdit = (data) => {
    return request('post', '/pdworld/notice/edit', data)
}
//消息表信息删除
export const noticeDel = (id) => {
    return request('post', '/pdworld/notice/delete?id=' + id)
}
//消息表批量删除
export const noticeDelBatch = (ids) => {
    return request('post', '/pdworld/notice/deleteBatch', ids)
}
//消息表动态表头导出
export const noticeDynamicExport = (data) => {
    return request("eptpost", '/pdworld/notice/exportDynamic', data)
}
