import request from "@/common/request"

//协议表分页展示
export const pdagreementPage = (queryForm) => {
    return request("get", "/pdworld/pdAgreement/page", queryForm);
}
//协议表列表展示
export const pdagreementList = (queryForm) => {
    return request("get", "/pdworld/pdAgreement/list", queryForm);
}
//协议表详情
export const pdagreementInfo = (id) => {
    return request('get', '/pdworld/pdAgreement/info', {
        id: id
    })
}
//协议表信息保存
export const pdagreementSave = (data) => {
    return request('post', '/pdworld/pdAgreement/save', data)
}
//协议表信息修改
export const pdagreementEdit = (data) => {
    return request('post', '/pdworld/pdAgreement/edit', data)
}
//协议表信息删除
export const pdagreementDel = (id) => {
    return request('post', '/pdworld/pdAgreement/delete?id=' + id)
}
//协议表批量删除
export const pdagreementDelBatch = (ids) => {
    return request('post', '/pdworld/pdAgreement/deleteBatch', ids)
}
//协议表动态表头导出
export const pdagreementDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdAgreement/exportDynamic', data)
}
