<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="职位名称
" :label-width="formLabelWidth" prop="jobName">
                <el-input
                        v-model="form.jobName"
                        autocomplete="off"
                        placeholder="请输入职位名称
"
                ></el-input>
            </el-form-item>
            <el-form-item label="邮箱(招聘人的)" :label-width="formLabelWidth" prop="mail">
                <el-input
                        v-model="form.mail"
                        autocomplete="off"
                        placeholder="请输入邮箱(招聘人的)"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系方式(招聘人的)" :label-width="formLabelWidth" prop="phone">
                <el-input
                        v-model="form.phone"
                        autocomplete="off"
                        placeholder="请输入联系方式(招聘人的)"
                ></el-input>
            </el-form-item>
            <el-form-item label="工作地点" :label-width="formLabelWidth" prop="workAddress">
                <el-input
                        v-model="form.workAddress"
                        autocomplete="off"
                        placeholder="请输入工作地点"
                ></el-input>
            </el-form-item>
            <el-form-item label="岗位职位" :label-width="formLabelWidth" prop="position">
                <el-input
                        v-model="form.position"
                        autocomplete="off"
                        placeholder="请输入岗位职位"
                ></el-input>
            </el-form-item>
            <el-form-item label="招聘人数" :label-width="formLabelWidth" prop="recruitment">
                <el-input
                        v-model="form.recruitment"
                        autocomplete="off"
                        placeholder="请输入招聘人数"
                ></el-input>
            </el-form-item>
            <el-form-item label="发布时间" :label-width="formLabelWidth" prop="releaseTime">
                <el-date-picker
                        v-model="form.releaseTime"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="职位描述" :label-width="formLabelWidth" prop="jobDescription">
                <fortress-editor v-model="form.jobDescription"></fortress-editor>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { pdrecruitInfo, pdrecruitSave, pdrecruitEdit } from "@/api/pdworld/pdrecruit.js";
    
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    id:"",
                    jobName:"",
                    mail:"",
                    phone:"",
                    workAddress:"",
                    position:"",
                    recruitment:"",
                    releaseTime:"",
                    jobDescription:"",
                },
                formLabelWidth: "120px",
                myconfig: window.$conf.commonConf.ueConfig,
                rules: {
                },
            };
        },
        created() {
        },
        methods: {
            init(id) {
                this.resetForm();
                //修改
                if (id) {
                    pdrecruitInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        pdrecruitEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        pdrecruitSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    id:"",
                    jobName:"",
                    mail:"",
                    phone:"",
                    workAddress:"",
                    position:"",
                    recruitment:"",
                    releaseTime:"",
                    jobDescription:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
