import request from "@/common/coderequest"

//表信息分页展示
export const tbconfigPage = (queryParam) => {
    return request("get", "/tbconfig/page", queryParam);
}
//指定数据源下的表列表
export const tbUnderDbList = (id) => {
    return request("get", "/tbconfig/list", { dbId: id });
}
//表信息详情
export const tbconfigInfo = (id) => {
    return request('get', '/tbconfig/info', {
        id: id
    })
}
//初始化表信息
export const tbconfigInit = (data) => {
    return request('post', '/tbconfig/init', data)
}
//保存表信息信息
export const tbconfigSave = (data) => {
    return request('post', '/tbconfig/save', data)
}
//修改表信息信息
export const tbconfigEdit = (data) => {
    return request('post', '/tbconfig/edit', data)
}
//删除表信息信息
export const tbconfigDel = (id) => {
    return request('post', '/tbconfig/delete?id=' + id)
}
//同步表结构信息
export const tbconfigSyn = (data) => {
    return request('post', '/tbconfig/syn', data)
}

