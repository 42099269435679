import request from "@/common/request"

//商家线路预约刷新任务分页展示
export const pdrouterefreshPage = (queryForm) => {
    return request("get", "/pdworld/pdRouteRefresh/page", queryForm);
}
//商家线路预约刷新任务列表展示
export const pdrouterefreshList = (queryForm) => {
    return request("get", "/pdworld/pdRouteRefresh/list", queryForm);
}
//商家线路预约刷新任务详情
export const pdrouterefreshInfo = (id) => {
    return request('get', '/pdworld/pdRouteRefresh/info', {
        id: id
    })
}
//商家线路预约刷新任务信息保存
export const pdrouterefreshSave = (data) => {
    return request('post', '/pdworld/pdRouteRefresh/save', data)
}
//商家线路预约刷新任务信息修改
export const pdrouterefreshEdit = (data) => {
    return request('post', '/pdworld/pdRouteRefresh/edit', data)
}
//商家线路预约刷新任务信息删除
export const pdrouterefreshDel = (id) => {
    return request('post', '/pdworld/pdRouteRefresh/delete?id=' + id)
}
//商家线路预约刷新任务批量删除
export const pdrouterefreshDelBatch = (ids) => {
    return request('post', '/pdworld/pdRouteRefresh/deleteBatch', ids)
}
//商家线路预约刷新任务动态表头导出
export const pdrouterefreshDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdRouteRefresh/exportDynamic', data)
}
