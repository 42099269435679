<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <!--      <el-form-item v-if="form.id!=null" label="关联商家" :label-width="formLabelWidth" prop="memberId">-->
      <!--        <fortress-select-->
      <!--            disabled-->
      <!--            v-model="form.memberId"-->
      <!--            :dictList="memberIdList"-->
      <!--            placeholder="请选择关联商家"-->
      <!--            clearable-->
      <!--        ></fortress-select>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item v-else label="关联商家" :label-width="formLabelWidth" prop="memberId">-->
      <!--        <fortress-select-->
      <!--            v-model="form.memberId"-->
      <!--            :dictList="memberIdList"-->
      <!--            placeholder="请选择关联商家"-->
      <!--            clearable-->
      <!--        ></fortress-select>-->
      <!--      </el-form-item>-->
      <!--      -->
      <el-form-item label="关联商家" :label-width="formLabelWidth" prop="memberId">
        <el-input v-model="displayMemberName" autocomplete="off" placeholder="请选择关联商家" @focus="dialogHandle">
        </el-input>
      </el-form-item>
      <!--            <el-form-item label="关联等级" :label-width="formLabelWidth" prop="levelId">-->
      <!--                <fortress-select-->
      <!--                        v-model="form.levelId"-->
      <!--                        :dictList="levelIdList"-->
      <!--                        placeholder="请选择关联等级"-->
      <!--                        clearable-->
      <!--                ></fortress-select>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="会员剩余天数" :label-width="formLabelWidth" prop="lastDay">-->
      <!--              <el-input-number-->
      <!--                  v-model="form.lastDay"-->
      <!--              ></el-input-number>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="会员累计天数" :label-width="formLabelWidth" prop="totalDay">-->
      <!--              <el-input-number-->
      <!--                  v-model="form.totalDay"-->
      <!--              ></el-input-number>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="累计充值年" :label-width="formLabelWidth" prop="totalRechargeYear">-->
      <!--              <el-input-number-->
      <!--                  v-model="form.totalRechargeYear"-->
      <!--              ></el-input-number>-->
      <!--            </el-form-item>-->
      <el-form-item label="充值时长(年)" :label-width="formLabelWidth" prop="totalRechargeYear">
        <el-input-number
            v-model="form.totalRechargeYear"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>


    <el-dialog append-to-body :close-on-click-modal="false" title="选择关联商家" :visible.sync="dialogVisible" width="80%"
               height="600px;">

      <div class="padding20">
        <el-row style="margin-bottom: 7px">
          <el-form
              :inline="true"
              :model="queryForm"
              @submit.native.prevent
              @keyup.enter.native="queryList"
          >
            <el-form-item>
              <el-input
                  v-model="queryForm.userName"
                  autocomplete="off"
                  placeholder="请输入用户名"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="queryForm.phone"
                  autocomplete="off"
                  placeholder="请输入手机号"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="queryForm.nickName"
                  autocomplete="off"
                  placeholder="请输入昵称"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  size="medium"
                  icon="el-icon-search"
                  @click="queryList({ current: 1 })"
              >查询
              </el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  size="medium"
                  icon="el-icon-refresh-left"
                  @click="resetQueryForm"
              >重置
              </el-button
              >
            </el-form-item>
          </el-form>
        </el-row>
        <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            highlight-current-row
            @current-change="handleCurrentChange"
        >

          <el-table-column
              prop="userName"
              label="用户名"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              prop="phone"
              label="手机号"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
        </el-table>
        <pagination
            :total="page.total"
            :current-page="page.current"
            :page-size="page.size"
            @refreshData="queryList"
        ></pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="devSubmit">确定</el-button>
      </span>

    </el-dialog>
  </el-dialog>
</template>
<script>
import {
  memberlevelrelationInfo,
  memberlevelrelationSave,
  memberlevelrelationEdit
} from "@/api/level/memberlevelrelation.js";
import {enumList, urlList,} from "@/api/public.api.js";
import * as ftVal from "@/common/fortressValidator.js";
import FortressPagination from "@/components/fortress-pagination.vue";
import {
  pdmemberPage
} from "@/api/pdworld/pdmember.js";

export default {
  data() {
    return {
      visible: false,
      form: {
        memberId: "",
        // levelId:"",
        // lastDay:"",
        // totalDay:"",
        totalRechargeYear: "",
      },
      checkedRow: null,
      displayMemberName: '',
      formLabelWidth: "120px",
      rules: {
        memberId: [
          {required: true, message: "请输入关联用户", trigger: "blur"},
        ],
        // levelId:[
        //       { required: true, message: "请输入关联等级", trigger: "blur" },
        //   ],
        // lastDay:[
        //       { required: true, message: "请输入会员剩余天数", trigger: "blur" },
        //       { validator: ftVal.validatePInteger,trigger: "blur"},
        //   ],
        // totalDay:[
        //       { required: true, message: "请输入会员累计天数", trigger: "blur" },
        //       { validator: ftVal.validatePInteger,trigger: "blur"},
        //   ],
        totalRechargeYear: [
          {required: true, message: "请输入充值时长", trigger: "blur"},
          {validator: ftVal.validatePInteger, trigger: "blur"},
        ],
      },
      YesNoList: [],
      // memberIdList: [],
      levelIdList: [],


      dialogVisible: false,
      tableData: [],
      queryForm: {
        userName: "",
        phone: "",
        nickName: "",
        type: 0,
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      tableList: [],
    };
  },
  components: {
    pagination: FortressPagination,
  },
  created() {
    this.queryList();
  },
  methods: {
    handleCurrentChange(row) {
      console.log(row);
      this.checkedRow = row;
    },
    devSubmit() {
      this.dialogVisible = false
      this.displayMemberName = this.checkedRow.userName;
      this.form.memberId = this.checkedRow.id;
    },
    dialogHandle() {
      //重置列表
      this.tableData = []
      //重置列表查询条件
      this.resetQueryForm();
      this.checkedRow = null;
      //查询列表
      this.queryList()
      this.dialogVisible = true
    },
    refresh() {
      this.tableData = [];
      this.queryList({current: 1});
    },
    //查询表格数据
    queryList(page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      pdmemberPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu =
              this.page.total % this.page.size == 0
                  ? parseInt(this.page.total / this.page.size)
                  : parseInt(this.page.total / this.page.size + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({current: cu});
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    resetQueryForm() {
      this.queryForm = {
        userName: "",
        phone: "",
        nickName: "",
        type: 0,
      };
    },
    queryEnumList() {
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    queryUrlList() {
      // this.memberIdList = [];
      // urlList("/pdworld/pdMember/list").then((resp) => {
      //   if (resp.code == 0) {
      //     if (resp.data) {
      //       resp.data.forEach((item) => {
      //         this.memberIdList.push({code: item.id, name: item.nickName});
      //       });
      //     }
      //   }
      // });
      this.levelIdList = [];
      urlList("/level/memberLevel/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.levelIdList.push({code: item.id, name: item.levelName});
            });
          }
        }
      });
    },
    init(id) {
      this.displayMemberName = ""
      this.resetForm();
      this.queryEnumList();
      this.queryUrlList();
      //修改
      if (id) {
        memberlevelrelationInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            memberlevelrelationEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            memberlevelrelationSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        memberId: "",
        // levelId:"",
        // lastDay:"",
        // totalDay:"",
        totalRechargeYear: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
