import request from "@/common/request"

//商品标签分组表分页展示
export const goodstaggroupPage = (queryForm) => {
    return request("get", "/goods/goodsTagGroup/page", queryForm);
}
//商品标签分组表列表展示
export const goodstaggroupList = (queryForm) => {
    return request("get", "/goods/goodsTagGroup/list", queryForm);
}
//商品标签分组表详情
export const goodstaggroupInfo = (id) => {
    return request('get', '/goods/goodsTagGroup/info', {
        id: id
    })
}
//商品标签分组表信息保存
export const goodstaggroupSave = (data) => {
    return request('post', '/goods/goodsTagGroup/save', data)
}
//商品标签分组表信息修改
export const goodstaggroupEdit = (data) => {
    return request('post', '/goods/goodsTagGroup/edit', data)
}
//商品标签分组表信息删除
export const goodstaggroupDel = (id) => {
    return request('post', '/goods/goodsTagGroup/delete?id=' + id)
}
//商品标签分组表批量删除
export const goodstaggroupDelBatch = (ids) => {
    return request('post', '/goods/goodsTagGroup/deleteBatch', ids)
}
//商品标签分组表动态表头导出
export const goodstaggroupDynamicExport = (data) => {
    return request("eptpost", '/goods/goodsTagGroup/exportDynamic', data)
}
