<template>
  <div>
    <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose"
               custom-class="drawer-info">
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">分站ID:</label>
          <div class="form-item-content">{{ objInfo.siteId }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">分站所属市编码subsite:</label>
          <div class="form-item-content">{{ objInfo.siteCityCode }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">要推荐的商家memberID:</label>
          <div class="form-item-content">{{ objInfo.memberId }}
          </div>
        </div>
        <div class="form-item">
          <label class="form-item-label">推荐位置(首页推荐/搜索页推荐):</label>
          <div class="form-item-content">{{ objInfo.positionName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">排序权重/倒序:</label>
          <div class="form-item-content">{{ objInfo.sort }}
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {subsiterecommendInfo} from "@/api/pdworld/subsiterecommend.js";
import {enumList,} from "@/api/public.api.js";

export default {
  data() {
    return {
      drawer: true,
      direction: "rtl",
      objInfo: {},
      SiteRecommendList: [],
    }
  },
  created() {
  },
  methods: {
    queryEnumList() {
      this.SiteRecommendList = [];
      enumList("SiteRecommend", "").then((resp) => {
        if (resp.code == 0) {
          this.SiteRecommendList = resp.data;
        }
      });
    },
    init(id) {
      this.queryEnumList();
      if (id) {
        subsiterecommendInfo(id).then(res => {
          this.objInfo = res.data
          let positionObj = this.SiteRecommendList.find((item) => {
            return item.code == this.objInfo.position;
          });
          this.objInfo.positionName = positionObj ? positionObj.name : "";
        })

      }
      this.drawer = true
    },
    // 关闭抽屉
    handleClose(done) {
      done()
    },
  },
}
</script>
<style scoped>
.from-title {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  color: #17233d;
  border-bottom: 1px solid #eee;
}

.info-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 20px;
  font-size: 14px;
}

.form-item-label {
  text-align: right;
  float: left;
  width: 25%;
}

.form-item-content {
  margin-left: 30%;
  width: 70%;
}
</style>
