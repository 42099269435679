<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
        <el-form-item>
          <fortress-select
              v-model="queryForm.typeId"
              :dictList="typeIdList"
              placeholder="请选择分类"
              clearable
              filterable
          ></fortress-select>
        </el-form-item>
        <el-form-item v-show="collapse.flag">
          <fortress-select
              v-model="queryForm.auditStatus"
              :dictList="LogisticsAuditStatusList"
              placeholder="请选择审核状态"
              clearable
              filterable
          ></fortress-select>
        </el-form-item>
<!--        <el-form-item>-->
<!--          <el-input prefix-icon="el-icon-search" v-model="queryForm.contactName" autocomplete="off"-->
<!--                    placeholder="请输入联系人名称" clearable></el-input>-->
<!--        </el-form-item>-->

        <el-form-item v-show="collapse.flag">
          <el-input prefix-icon="el-icon-search" v-model="queryForm.contactPhone" autocomplete="off"
                    placeholder="请输入联系人电话" clearable></el-input>
        </el-form-item>

<!--        <el-form-item v-show="collapse.flag">-->
<!--          <el-date-picker-->
<!--              v-model="queryForm.auditTimeStart"-->
<!--              type="date"-->
<!--              placeholder="筛选审核时间开始"-->
<!--              value-format="yyyy-MM-dd HH:mm:ss"-->
<!--              clearable-->
<!--          >-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-show="collapse.flag">-->
<!--          <el-date-picker-->
<!--              v-model="queryForm.auditTimeEnd"-->
<!--              type="date"-->
<!--              placeholder="筛选审核时间结束"-->
<!--              value-format="yyyy-MM-dd HH:mm:ss"-->
<!--              clearable-->
<!--          >-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-show="collapse.flag">-->
<!--          <fortress-select-->
<!--              v-model="queryForm.publisherId"-->
<!--              :dictList="publisherIdList"-->
<!--              placeholder="请选择发布人"-->
<!--              clearable-->
<!--              filterable-->
<!--          ></fortress-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-show="collapse.flag">-->
<!--          <fortress-select-->
<!--              v-model="queryForm.auditUser"-->
<!--              :dictList="auditUserList"-->
<!--              placeholder="请选择审核人"-->
<!--              clearable-->
<!--              filterable-->
<!--          ></fortress-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({current:1})">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="handleCollapseState"
          >{{ collapse.label }}<i :class="collapse.icon"></i
          ></el-button>
        </el-form-item>
      </el-form>
      <!-- <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          v-if="$tools.hasPermission('pdworld:logisticsDynamics:save')"
          @click="handleEdit()"
      >添加
      </el-button
      > -->
      <el-button
          v-if="$tools.hasPermission('pdworld:logisticsDynamics:deleteBatch')"
          size="medium"
          type="danger"
          icon="el-icon-delete"
          @click="handleDeleteBatch()"
      >删除
      </el-button
      >
      <div style="float: right; padding-right: 17px">
        <i
            class="el-icon-download"
            style="margin-right: 10px; cursor: pointer"
            @click="handleExport"
        ></i>
        <i
            class="el-icon-refresh"
            style="margin-right: 10px; cursor: pointer"
            @click="refresh"
        ></i>

        <el-popover placement="bottom" width="180" trigger="click" title="列展示" popper-class="columnShows">
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
                v-for="item in tableList"
                :label="item.propName"
                :key="item.propName"
                :value="item.propName"
            >{{ item.propDesc }}
            </el-checkbox
            >
          </el-checkbox-group>
          <i
              slot="reference"
              class="el-icon-s-operation"
              style="cursor: pointer"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
    >
      <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>

      <el-table-column
          header-align="center"
          v-if="$tools.isShow('typeId', tableShowList)"
          prop="typeId"
          label="分类"
          :show-overflow-tooltip="true"
          :formatter="typeIdFmt"
          width="100"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('contactName', tableShowList)"
          prop="contactName"
          label="联系人名称"
          :show-overflow-tooltip="true"
          width="100"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('contactPhone', tableShowList)"
          prop="contactPhone"
          label="联系人电话"
          :show-overflow-tooltip="true"
          width="100"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          prop="content"
          label="内容"
          :show-overflow-tooltip="true"
          width="100"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('contactName', tableShowList)"
          label="图片"
          :show-overflow-tooltip="true"
          width="100"
      >
        <template #default = {row}>
          <el-image v-for="(item,index) in row.imgs.slice(0,2)" :src="item.img">

          </el-image>
        </template>
      </el-table-column>
      <el-table-column
          header-align="center"
          prop="timeChose"
          label="有效期"
          :show-overflow-tooltip="true"
          width="150"
          :formatter="validityPeriodFmt"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('createTime', tableShowList)"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
          width="150"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('createUser', tableShowList)"
          prop="createUserName"
          label="创建人"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          v-if="$tools.isShow('auditTime', tableShowList)"-->
<!--          prop="auditTime"-->
<!--          label="审核时间"-->
<!--          :show-overflow-tooltip="true"-->
<!--          width="150"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('auditStatus', tableShowList)"
          prop="auditStatus"
          label="审核状态"
          :show-overflow-tooltip="true"
          :formatter="auditStatusFmt"
          width="80"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('publisherId', tableShowList)"
          prop="publisherName"
          label="发布人"
          :show-overflow-tooltip="true"
          width="160"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          v-if="$tools.isShow('auditUser', tableShowList)"
          prop="auditUserName"
          label="审核人"
          :show-overflow-tooltip="true"
          :formatter="auditUserNameFmt"
          width="100"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" min-width="180">
        <template slot-scope="scope">
          <el-button
              v-if="$tools.hasPermission('pdworld:logisticsDynamics:audit')&& scope.row.auditStatus==1"
              size="mini"
              plain
              @click="handleEdit(scope.row.id)"
          >审核
          </el-button>
          <el-button
              v-if="$tools.hasPermission('pdworld:logisticsDynamics:info')&& scope.row.auditStatus!=1"
              size="mini"
              plain
              @click="handleInfo(scope.row.id)"
          >详情
          </el-button>
          <el-button
              v-if="$tools.hasPermission('pdworld:logisticsDynamicsComment:page')"
              size="mini"
              plain
              @click="handleComment(scope.row.id)"
          >相关评论
          </el-button>
          <el-button
              v-if="$tools.hasPermission('pdworld:logisticsDynamics:delete')"
              size="mini"
              plain
              type="danger"
              @click="handleDelete(scope.row)"
          >删除
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="page.total" :current-page="page.current" :page-size="page.size"
                @refreshData="queryList"></pagination>
    <!-- 新增、编辑 -->
    <form-edit
        v-if="formEditVisible"
        ref="formEdit"
        @refreshData="queryList"
    ></form-edit>
    <form-comment
        v-if="formCommentVisible"
        ref="formComment"
        @refreshData="queryList"
    ></form-comment>
    <!-- 详情 -->
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
  </div>
</template>
<script>
import {
  logisticsdynamicsPage,
  logisticsdynamicsDel,
  logisticsdynamicsDelBatch,
  logisticsdynamicsDynamicExport,
} from "@/api/pdworld/logisticsdynamics.js";
import {viewTableList} from "@/api/public.api.js";
import FormEdit from "./logisticsdynamics-edit";
import FormComment from "./logisticsdynamicscomment";
import FormInfo from "./logisticsdynamics-info";
import FortressPagination from "@/components/fortress-pagination.vue";
import {enumList, urlList,} from "@/api/public.api.js";

export default {
  data() {
    return {
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      formCommentVisible: false,
      queryForm: {
        typeId: "",
        contactName: "",
        contactPhone: "",
        auditTimeStart: "",
        auditTimeEnd: "",
        publisherId: "",
        auditUser: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
      YesNoList: [],
      LogisticsAuditStatusList: [],
      typeIdList: [],
      publisherIdList: [],
      auditUserList: [],
      validityPeriodList: [],
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    "form-comment": FormComment,
    pagination: FortressPagination,
  },
  created() {
    this.queryEnumList();
    this.queryUrlList();
    this.initTableList();
    this.queryList();
  },
  methods: {
    queryEnumList() {
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
      this.LogisticsAuditStatusList = [];
      enumList("LogisticsAuditStatus", "").then((resp) => {
        if (resp.code == 0) {
          this.LogisticsAuditStatusList = resp.data;
        }
      });
      this.validityPeriodList = [];
      enumList("TimeChose", "").then((resp) => {
        if (resp.code == 0) {
          this.validityPeriodList = resp.data;
        }
      });
    },
    queryUrlList() {
      this.typeIdList = [];
      urlList("/pdworld/logisticsDynamicsType/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.typeIdList.push({code: item.id, name: item.name});
            });
          }
        }
      });
      this.publisherIdList = [];
      urlList("/pdworld/pdMember/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.publisherIdList.push({code: item.id, name: item.nickName});
            });
          }
        }
      });
      this.auditUserList = [];
      urlList("/system/sysUser/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.auditUserList.push({code: item.id, name: item.realName});
            });
          }
        }
      });
    },
    refresh() {
      this.tableData = [];
      this.queryList({current: 1});
    },
    //查询表格数据
    queryList(page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      logisticsdynamicsPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu = this.page.total % this.page.size == 0 ? parseInt(this.page.total / this.page.size) : parseInt((this.page.total / this.page.size) + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({current: cu});
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //打开评论窗口
    handleComment(id) {
      this.formCommentVisible = true;
      this.$nextTick(() => {
        this.$refs.formComment.init(id);
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm('确认删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        logisticsdynamicsDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
                (this.page.total - 1) % this.page.size == 0
                    ? (this.page.total - 1) / this.page.size
                    : (this.page.total - 1) / this.page.size + 1;
            if (this.page.current > totalPages && this.page.current != 1) {
              this.page.current = this.page.current - 1;
            }
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }).catch(() => {
      });
    },
    //批量删除
    handleDeleteBatch() {
      let checkedRow = this.$refs.multipleTable.selection;
      if (checkedRow.length > 0) {
        let ids = [];
        checkedRow.forEach((item) => {
          ids.push(item.id);
        });
        this.$confirm("确认删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              logisticsdynamicsDelBatch(ids).then((resp) => {
                if (resp.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  //判断是否最后一页的最后一条记录
                  let totalPages =
                      (this.page.total - checkedRow.length) % this.page.size == 0
                          ? (this.page.total - checkedRow.length) / this.page.size
                          : (this.page.total - checkedRow.length) / this.page.size +
                          1;
                  if (this.page.current > totalPages && this.page.current != 1) {
                    this.page.current = this.page.current - 1;
                  }
                  this.queryList();
                } else {
                  this.$msg({
                    message: resp.msg,
                    type: "error",
                  });
                }
              });
            })
            .catch(() => {
            });
      } else {
        this.$msg({
          message: "请选择数据进行删除",
          type: "error",
        });
      }
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        typeId: "",
        contactName: "",
        contactPhone: "",
        auditTime: "",
        publisherId: "",
        auditUser: "",
      };
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList() {
      viewTableList("logistics_dynamics").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
    //分类格式化返回
    typeIdFmt(row) {
      let name = "";
      if (this.typeIdList) {
        this.typeIdList.forEach(item => {
          if (item.code == row.typeId) {
            name = item.name;
          }
        })
      }
      return name;
    },
    validityPeriodFmt(row) {
      let name = "";
      if (this.validityPeriodList) {
        this.validityPeriodList.forEach(item => {
          if (item.code == row.timeChose) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //审核状态格式化返回
    auditStatusFmt(row) {
      let name = "";
      if (this.LogisticsAuditStatusList) {
        this.LogisticsAuditStatusList.forEach(item => {
          if (item.code == row.auditStatus) {
            name = item.name;
          }
        })
      }
      return name;
    },
    auditUserNameFmt(row) {
      let name = "";
      if(row.auditUser == -1){
        return "超级管理员";
      }
      if (row.auditUserName != null) {
        return row.auditUserName;
      }
      return name;
    },
    //发布人格式化返回
    publisherIdFmt(row) {
      let name = "";
      if (this.publisherIdList) {
        this.publisherIdList.forEach(item => {
          if (item.code == row.publisherId) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //审核人格式化返回
    auditUserFmt(row) {
      let name = "";
      if (row.auditUser != null && row.auditUser == -1) {
        return '超级管理员';
      }
      if (this.auditUserList) {
        this.auditUserList.forEach(item => {
          if (item.code == row.auditUser) {
            name = item.name;
          }
        })
      }
      return name;
    },
    //可见字段excel导出
    handleExport() {
      if (!this.tableShowList || this.tableShowList.length < 1) {
        this.$msg({
          message: "请选择要导出的列",
          type: "error",
        });
        return;
      }
      let colNum = 1;
      let headerList = [];
      this.tableShowList.forEach((item) => {
        let nameData = this.tableList.find((item2) => {
          return item2.propName == item;
        });
        let name = "";
        if (nameData && nameData.propDesc) {
          name = nameData.propDesc;
        } else {
          name = "未定义字段" + colNum++;
        }
        headerList.push({name: name, code: item});
      });
      logisticsdynamicsDynamicExport({headerList: headerList, dto: this.queryForm});
    },
  },
};
</script>
<style scoped>
</style>
