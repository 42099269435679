<template>
	<div class="cockpit">
		<content-item class="accessBox">
			<div slot="contentItem-title">
				<el-row class="accessBox-title-row">
					<el-col :span="8" class="col-8">
						<i class="fa  fa-bar-chart" style="color:#0cafcc ;margin-right:10px"></i>
						<span>访问量</span>
					</el-col>
					<el-col :span="16" class="col-16">
						<div class="date">
							<el-date-picker type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" v-model="date1"></el-date-picker>
						</div>
						<ul class="radioGroups">
							<li @click="todayHandle(1)" :class="{ active: current === 1 }">今日</li>
							<li @click="monthHandle(2)" :class="{ active: current == 2 }">本月</li>
							<li @click="yearHandle(3)" :class="{ active: current == 3 }">本年</li>
						</ul>
						<!-- <el-radio-group class="radioGroups" v-model="radioCurrent">
							<el-radio-button label="今日" @change="today()"></el-radio-button>
							<el-radio-button label="本月" @change="month()"></el-radio-button>
							<el-radio-button label="全年" @change="year()"></el-radio-button>
						</el-radio-group> -->
					</el-col>
				</el-row>
			</div>
			<div slot="contentItem-body">
				<el-row class="accessBox-body-row">
					<el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16" ref="canvasBox">
						<h4>访问量趋势</h4>
						<div ref="chart" style="width:100%;height:300px"></div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
						<h4>访问量排名</h4>
						<div class="ranking">
							<ul>
								<li v-for="(item, indexs) of demo" :key="indexs">
									<div class="ranking-item">
										<el-row>
											<el-col :span="14" class="col-14">
												<span>1、</span>
												<span>东直门 1 号</span>
											</el-col>
											<el-col :span="10" class="col-10">
												<span>456,789</span>
											</el-col>
										</el-row>
									</div>
								</li>
							</ul>
						</div>
					</el-col>
				</el-row>
			</div>
		</content-item>

		<el-row class="fortressRow">
			<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="fortressRow-row-12">
				<contentItem>
					<div slot="contentItem-title">
						<div class="title-text">
							<i class="fa  fa-search" style="color:#0cafcc ;margin-right:10px"></i>
							<span>热门搜索</span>
						</div>
						<div class="title-icon"></div>
					</div>
					<div slot="contentItem-body">
						<el-row>
							<el-col :span="12">
								<div>
									<span>搜索用户数</span>
									<i></i>
								</div>
								<div>
									<div><span>23,378</span></div>
									<div>
										<span>16.8</span>
										<i></i>
									</div>
								</div>
								<div ref="chart02" style="width:100%;height:50px"></div>
							</el-col>
							<el-col :span="12">
								<div>
									<span>搜索用户数</span>
									<i></i>
								</div>
								<div>
									<div><span>23,378</span></div>
									<div>
										<span>16.8</span>
										<i></i>
									</div>
								</div>
								<div ref="chart03" style="width:100%;height:50px"></div>
							</el-col>
						</el-row>
						<el-row>
							<el-table :data="tableData" style="width: 100%;height:100%">
								<el-table-column prop="ranking" label="排名" width="60"></el-table-column>
								<el-table-column prop="text" label="搜索关键词" width="100"></el-table-column>
								<el-table-column prop="userNum" label="用户数" sortable></el-table-column>
								<el-table-column prop="weekUp" label="周涨幅" sortable></el-table-column>
							</el-table>
							<el-pagination :page-size="5" :pager-count="7" :total="50" layout="prev, pager, next" style="text-align:right"></el-pagination>
						</el-row>
					</div>
				</contentItem>
			</el-col>
			<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="fortressRow-row-12">
				<contentItem>
					<div slot="contentItem-title">
						<div class="title-text">
							<i class="fa  fa-user-circle" style="color:#0cafcc ;margin-right:10px"></i>
							<span>用户画像</span>
						</div>
					</div>
					<div slot="contentItem-body">
						<div class="fortress-user-gender-data">
							<el-row class="user-gender-data">
								<el-col :span="8">
									<span class="people-icon" style="color: rgb(51, 153, 255); background-color: rgb(214, 240, 255);"><i class="iconfont icon-dad"></i></span>
									<div>男性 65%</div>
								</el-col>
								<el-col :span="8">
									<span class="people-icon" style="color: rgb(190, 107, 224); background-color: rgb(253, 240, 255);"><i class="iconfont icon-nvren"></i></span>
									<div>女性 25%</div>
								</el-col>
								<el-col :span="8">
									<span class="people-icon" style="color: rgb(186, 189, 195); background-color: rgb(230, 237, 245);"><i class="iconfont  icon-nanren"></i></span>
									<div>未知 10%</div>
								</el-col>
							</el-row>
							<div class="fortress-user-gender-grid">
								<div class="gender-grid-item" v-for="index1 of man" :key="index1">
									<div class="grid-item-main">
										<span style="background-color: rgb(51, 153, 255);"></span>
									</div>
								</div>
								<div class="gender-grid-item" v-for="index2 of woman">
									<div class="grid-item-main">
										<span style="background-color: rgb(190, 107, 224);"></span>
									</div>
								</div>
								<div class="gender-grid-item" v-for="index3 of nothing">
									<div class="grid-item-main">
										<span style="background-color: rgb(186, 189, 195);"></span>
									</div>
								</div>
							</div>
						</div>
						<div style="height:250px;position:relative">
							<div class="user-category">
								<svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
									<circle cx="5%" cy="20%" r="10%" fill="#eaf4ff" stroke="none" fill-opacity="0.9"></circle>
									<circle cx="80%" cy="80%" r="8%" fill="#eaf4ff" stroke="none" fill-opacity="0.9"></circle>
									<circle cx="40%" cy="10%" r="6%" fill="#eaf4ff" stroke="none" fill-opacity="0.9"></circle>
									<circle cx="70%" cy="14%" r="6%" fill="#eaf4ff" stroke="none" fill-opacity="0.9"></circle>
									<circle cx="50%" cy="80%" r="12%" fill="#eaf4ff" stroke="none" fill-opacity="0.9"></circle>
									<circle cx="10%" cy="50%" r="10%" fill="#eacb5d" stroke="none" fill-opacity="0.9"></circle>
									<text x="10%" y="50%">老人机</text>
									<text x="10%" y="55%">6%</text>
									<circle cx="24%" cy="60%" r="15%" fill="#30c479" fill-opacity="0.9"></circle>
									<text x="24%" y="60%">美颜</text>
									<text x="24%" y="65%">15%</text>

									<circle cx="42%" cy="50%" r="9%" fill="#7e7ae5" fill-opacity="0.9"></circle>
									<text x="42%" y="50%">高端商务</text>
									<text x="42%" y="55%">9%</text>

									<circle cx="60%" cy="40%" r="10%" fill="#9bc84d" fill-opacity="0.9"></circle>
									<text x="60%" y="40%">摄影手机</text>
									<text x="60%" y="45%">30%</text>

									<circle cx="80%" cy="50%" r="10%" fill="#ffa319" fill-opacity="0.9"></circle>
									<text x="80%" y="50%">高性价比</text>
									<text x="80%" y="55%">20%</text>

									<circle cx="80%" cy="50%" r="10%" fill="#ffa319" fill-opacity="0.9"></circle>
									<text x="80%" y="50%">音乐</text>
									<text x="80%" y="55%">10%</text>
								</svg>
							</div>
						</div>
					</div>
				</contentItem>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import contentItem from "../../components/dashboard/fortress-contentItem.vue"
const echarts = require("echarts")
export default {
	props: {},
	data() {
		return {
			current: 0,
			date1: [],
			man: 65,
			woman: 25,
			nothing: 10,
			demo: [{}, {}, {}, {}, {}, {}, {}],
			myChart: "",
			myChart02: "",
			myChart03: "",
			// 表格
			tableData: [
				{
					ranking: 1,
					text: "关键词111",
					userNum: 50,
					weekUp: "50%",
				},
				{
					ranking: 2,
					text: "关键词222",
					userNum: 50,
					weekUp: "50%",
				},
				{
					ranking: 3,
					text: "关键词333",
					userNum: 50,
					weekUp: "50%",
				},
				{
					ranking: 4,
					text: "关键词444",
					userNum: 50,
					weekUp: "50%",
				},
				{
					ranking: 5,
					text: "关键词555",
					userNum: 50,
					weekUp: "50%",
				},
			],
			// num: {
			// 	va1: 250 * 0.06,
			// 	va2: 250 * 0.15,
			// 	va3: 250 * 0.09,
			// 	va4: 250 * 0.3,
			// 	va5: 250 * 0.2,
			// 	va6: 250 * 0.1,
			// },
		}
	},
	computed: {
		year() {
			return new Date().getFullYear()
		},
		month() {
			return new Date().getMonth()
		},
	},
	created() {},
	methods: {
		todayHandle(num) {
			this.current = num
			const date = new Date()
			this.date1 = [date, date]
		},
		monthHandle(num) {
			this.current = num
			var fristMonth = new Date()
			fristMonth.setDate(1)
			var lastMonth = new Date()
			lastMonth.setDate(1)
			lastMonth.setMonth(lastMonth.getMonth() + 1) //这时候day已经变成下个月第一天
			lastMonth.setDate(lastMonth.getDate() - 1)
			this.date1 = [fristMonth, lastMonth]
		},
		yearHandle(num) {
			this.current = num
			var fristYear = new Date(this.year, 0, 1)
			var lastYear = new Date(this.year, 12, 0)
			this.date1 = [fristYear, lastYear]
			// console.log(fristYear)
		},
		// echarts柱状图
		initCharts() {
			this.myChart = echarts.init(this.$refs.chart)
			// 绘制图表
			this.myChart.setOption({
				title: {
					// text: "访问量趋势",
					// textStyle: {
					// 	fontSize: 14,
					// 	fontWeight: "nomal",
					// },
				},
				tooltip: {},
				xAxis: {
					type: "category",
					data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
					axisTick: {
						alignWithLabel: true,
					},
				},
				yAxis: {},
				series: [
					{
						name: "访问量",
						type: "bar",
						// 颜色渐变
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: "#87cefa",
									},
									{
										offset: 1,
										color: "#0cafdf",
									},
								]),
								barBorderRadius: [3, 3, 0, 0],
							},
						},
						data: [5, 20, 36, 10, 10, 20],
					},
				],
				grid: {
					top: 40, //距离容器上边界40像素
					bottom: 30, //距离容器下边界30像素
					left: 40,
					right: 40,
				},
			})
		},
		// 波浪图
		initWaves1() {
			this.myChart02 = echarts.init(this.$refs.chart02)
			// 绘制图表
			this.myChart02.setOption({
				xAxis: {
					show: false,
					type: "category",
					boundaryGap: false,
				},
				yAxis: {
					show: false,
					type: "value",
					boundaryGap: [0, "30%"],
				},
				visualMap: {
					type: "piecewise",
					show: false,
					dimension: 0,
					seriesIndex: 0,
					pieces: [
						{
							gt: 0,
							lt: 8,
							color: "#CAFFFF",
						},
					],
				},
				series: [
					{
						name: "搜索量",
						type: "line",
						smooth: 0.6,
						symbol: "none",
						lineStyle: {
							color: "#0cafdf",
							width: 2,
						},
						markLine: {},
						areaStyle: {},
						data: [
							["2019-10-10", 200],
							["2019-10-11", 560],
							["2019-10-12", 750],
							["2019-10-13", 580],
							["2019-10-14", 250],
							["2019-10-15", 300],
							["2019-10-16", 450],
							["2019-10-17", 300],
							["2019-10-18", 100],
						],
					},
				],
				tooltip: {
					trigger: "axis",
				},
				axisLine: {
					//axisLine决定是否显示坐标刻度
					show: false,
				},
				axisLabel: {
					//决定是否显示数据
					show: false,
				},
			})
		},
		initWaves2() {
			this.myChart03 = echarts.init(this.$refs.chart03)
			// 绘制图表
			this.myChart03.setOption({
				xAxis: {
					show: false,
					type: "category",
					boundaryGap: false,
				},
				yAxis: {
					show: false,
					type: "value",
					boundaryGap: [0, "30%"],
				},
				visualMap: {
					type: "piecewise",
					show: false,
					dimension: 0,
					seriesIndex: 0,
					pieces: [
						{
							gt: 0,
							lt: 8,
							color: "#CAFFFF",
						},
					],
				},
				series: [
					{
						type: "line",
						smooth: 0.6,
						symbol: "none",
						lineStyle: {
							color: "#0cafdf",
							width: 2,
						},
						markLine: {},
						areaStyle: {},
						data: [
							["2019-10-10", 200],
							["2019-10-11", 560],
							["2019-10-12", 750],
							["2019-10-13", 580],
							["2019-10-14", 250],
							["2019-10-15", 300],
							["2019-10-16", 450],
							["2019-10-17", 300],
							["2019-10-18", 100],
						],
					},
				],
				tooltip: {
					trigger: "axis",
				},
			})
		},
	},
	components: { contentItem },
	mounted() {
		this.initCharts()
		this.initWaves1()
		this.initWaves2()
		var that = this
		window.onresize = function() {
			that.myChart.resize() // 如果有多个图标变动，可写多个
			that.myChart02.resize()
			that.myChart03.resize()
		}
	},
}
</script>
<style lang="scss">
.date {
	.el-date-editor--daterange.el-input__inner {
		width: 230px !important;
	}
	.el-date-editor .el-range-separator {
		width: 24px !important;
	}
}
</style>
<style lang="scss" scoped>
.user-category text {
	fill: white;
	text-anchor: middle;
	font-size: 12px;
}
.active {
	color: #0cafcc;
}
.accessBox {
	width: 100%;
	background: #fff;
	border-radius: 5px;
	margin-bottom: 16px;
	.accessBox-title-row {
		.col-8 {
			padding-left: 0px;
			line-height: 38px;
			display: flex;
			align-items: center;

			span {
				font-size: 14px;
			}
		}
		.col-16 {
			display: inline-block;
			.radioGroups {
				float: right;
				display: flex;
				line-height: 20px;
				li {
					padding: 10px;
					cursor: pointer;
				}
			}
			.date {
				float: right;
			}
		}
	}
	.accessBox-body-row {
		h4 {
			font-size: 14px;
		}
		.ranking {
			margin-top: 10px;
		}
		.ranking-item {
			padding: 10px 5px;
			font-size: 14px;
			.col-14 {
				display: flex;
				align-items: center;
				span {
					&:nth-child(1) {
						margin-right: 6px;
						text-align: center;
						// width: 20px;
						// height: 20px;
						// border: 1px solid #128bf1;
						// border-radius: 50%;
						color: #0cafcc;
					}
				}
			}
			.col-10 {
				text-align: right;
				padding: 0;
			}
		}
	}
}
.fortressRow {
	.fortressRow-row-12 {
		&:nth-child(1) {
			padding-right: 12px;
		}
		&:nth-child(2) {
			padding-left: 12px;
		}
	}
	.fortress-user-gender-data {
		.user-gender-data {
			margin-bottom: 8px;
			& > div {
				text-align: center;
				.people-icon {
					margin-bottom: 4px;
					width: 40px;
					height: 40px;
					line-height: 40px;
					border-radius: 50%;
					display: inline-block;
					text-align: center;
					i {
						font-size: 24px;
					}
				}
			}
		}
		.fortress-user-gender-grid {
			width: 100%;
			height: 80px;
			position: relative;
			.gender-grid-item {
				float: left;
				width: 4%;
				height: 19.875px;
				position: relative;
				.grid-item-main {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					text-align: center;
					padding: 2px;
					span {
						display: block;
						height: 100%;
						border-radius: 2px;
						opacity: 0.75;
					}
				}
			}
		}
	}
	.user-category {
		height: 100%;
		width: 100%;
		max-width: 700px;
		margin: 0 auto;
		// background-image: url(../../assets/img/user-category.png);
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: 50%;
	}
}
</style>
