<template>
  <div>
    <el-drawer
      title
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">姓名:</label>
          <div class="form-item-content">{{ infoList.realName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">部门:</label>
          <div class="form-item-content">{{ infoList.createDeptName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">职位:</label>
          <div class="form-item-content">{{ infoList.createPostName }}</div>
        </div>
      </div>
      <div class="info-item">
        <h5 class="from-title">其他信息</h5>
        <div class="form-item">
          <label class="form-item-label">账号:</label>
          <div class="form-item-content">{{ infoList.loginName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">昵称:</label>
          <div class="form-item-content">{{ infoList.nickname }}</div>
        </div>
        <!-- <div class="form-item">
					<label class="form-item-label">性别:</label>
					<div class="form-item-content">{{ infoList.gender || "无" }}</div>
        </div>-->
        <div class="form-item">
          <label class="form-item-label">邮箱:</label>
          <div class="form-item-content">{{ infoList.email || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">电话:</label>
          <div class="form-item-content">{{ infoList.phone || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">性别:</label>
          <div class="form-item-content" v-if="infoList.gender=='m'">男</div>
          <div class="form-item-content" v-if="infoList.gender=='m'">男</div>
          <div class="form-item-content" v-else>暂无</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">生日:</label>
          <div class="form-item-content">{{ infoList.birthday || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">证件类型:</label>
          <div class="form-item-content">{{ infoList.idcardType || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">证件号码:</label>
          <div class="form-item-content">{{ infoList.idcard || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">备注:</label>
          <div class="form-item-content">{{ infoList.remark || "无" }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">创建时间:</label>
          <div class="form-item-content">{{ infoList.createTime || "无" }}</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { userInfo } from "@/api/auth/sysuser.js"
export default {
  data () {
    return {
      drawer: true,
      direction: "rtl",
      infoList: {},
      infoRemask: "无",
    }
  },
  created () { },
  methods: {
    init (id) {
      if (id) {
        userInfo(id).then(res => {
          this.infoList = res.data

        })
      }
      this.drawer = true
    },
    // 关闭抽屉
    handleClose (done) {
      done()
    },
  },
}
</script>
<style scoped></style>
