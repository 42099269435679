<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" custom-class="dialog-edit">
		<el-form ref="form" :model="form" :rules="rules">
			<el-form-item label="参数编码" :label-width="formLabelWidth" prop="paramCode">
				<el-input v-model="form.paramCode" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="参数值" :label-width="formLabelWidth" prop="paramValue">
				<el-input v-model="form.paramValue" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="类型" :label-width="formLabelWidth" prop="paramType">
				<el-input v-model="form.paramType" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="描述" :label-width="formLabelWidth" prop="paramDescription">
				<el-input v-model="form.paramDescription" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="状态" :label-width="formLabelWidth" prop="status">
				<el-input v-model="form.status" autocomplete="off"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="primary" @click="submitForm('form')">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { configparamsInfo, configparamsSave, configparamsEdit } from "@/api/system/configparams.js"
import { dictList } from "@/api/public.api.js"
export default {
	data() {
		return {
			visible: false,

			form: {
				paramCode: "",
				paramValue: "",
				paramType: "",
				paramDescription: "",
				status: "",
			},
			formLabelWidth: "120px",
			rules: {
				paramCode: [{ required: true, message: "请输入参数编码", trigger: "blur" }],
				paramValue: [{ required: true, message: "请输入参数值", trigger: "blur" }],
				paramType: [{ required: true, message: "请输入类型", trigger: "blur" }],
			},
			idcardTypeList: [],
		}
	},
	created() {},
	methods: {
		//字典
		queryDictList() {
			this.idcardTypeList = []
			dictList("idcardType").then(resp => {
				if (resp.code == 0) {
					this.idcardTypeList = resp.data
				}
			})
		},

		init(id) {
			this.resetForm()
			this.queryDictList()
			//修改
			if (id) {
				configparamsInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
			}
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					this.visible = false
					//修改
					if (this.form.id) {
						configparamsEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						configparamsSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				paramCode: "",
				paramValue: "",
				paramType: "",
				paramDescription: "",
				status: "",
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
}
</script>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
