<template>
	<el-dialog title="表字段编辑" :visible.sync="visible" fullscreen>
		<el-table :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border fit :header-cell-style="{ 'text-align': 'center' }">
			<el-table-column label="字段">
				<el-table-column prop="colName" label="字段名" :show-overflow-tooltip="true" width="170px"></el-table-column>
				<el-table-column prop="propName" label="属性名" :show-overflow-tooltip="true" width="170px">
					<template slot-scope="scope">
						<el-input v-model="scope.row.propName" autocomplete="off"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="colType" label="字段类型" :show-overflow-tooltip="true" width="100px"></el-table-column>
				<el-table-column prop="propType" label="属性类型" :show-overflow-tooltip="true" width="100px"></el-table-column>
				<el-table-column prop="propDesc" label="属性说明" :show-overflow-tooltip="true" width="300px">
					<template slot-scope="scope">
						<el-input v-model="scope.row.propDesc" autocomplete="off"></el-input>
					</template>
				</el-table-column>
			</el-table-column>

			<el-table-column label="列表">
				<el-table-column prop="isList" label="列表" :show-overflow-tooltip="true" width="60px">
					<template slot="header">
						<span @click="checkAllIsList">列表(点击全选)</span>
					</template>
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.isList" :true-label="1" :false-label="2"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="isQuery" label="查询" :show-overflow-tooltip="true" width="60px">
					<template slot="header">
						<span @click="checkAllIsQuery">查询(点击全选)</span>
					</template>
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.isQuery" :true-label="1" :false-label="2"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="queryType" label="查询方式" :show-overflow-tooltip="true" width="100px">
					<template slot-scope="scope">
						<el-select v-model="scope.row.queryType" placeholder="请选择">
							<el-option v-for="item in DbQueryConditionList" :key="item.code" :label="item.name" :value="item.code"></el-option>
						</el-select>
					</template>
				</el-table-column>
			</el-table-column>
			<el-table-column label="详情">
				<el-table-column prop="isCopy" label="复制" :show-overflow-tooltip="true" width="60px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.isCopy" :true-label="1" :false-label="2"></el-checkbox>
					</template>
				</el-table-column>
			</el-table-column>

			<el-table-column label="表单">
				<el-table-column prop="isForm" label="表单" :show-overflow-tooltip="true" width="60px">
					<template slot="header">
						<span @click="checkAllIsForm">表单(点击全选)</span>
					</template>
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.isForm" :true-label="1" :false-label="2"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="isFormRequire" label="必填" :show-overflow-tooltip="true" width="60px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.isFormRequire" :true-label="1" :false-label="2"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="validateType" label="自定义校验" :show-overflow-tooltip="true" width="150px">
					<template slot-scope="scope">
						<fortress-select v-model="scope.row.validateType" :dictList="validateTypeList" placeholder="请选择" clearable></fortress-select>
					</template>
				</el-table-column>
				<el-table-column prop="formType" label="表单类型" :show-overflow-tooltip="true" width="170px">
					<template slot-scope="scope">
						<fortress-select v-model="scope.row.formType" :dictList="ViewFormTypeList" placeholder="请选择"></fortress-select>
					</template>
				</el-table-column>
				<el-table-column prop="listdataType" label="数据集类型" :show-overflow-tooltip="true" width="150px">
					<template slot-scope="scope">
						<el-select v-model="scope.row.listdataType" placeholder="请选择" clearable @clear="typeClear(scope.row)">
							<el-option v-for="item in listdataTypeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
						</el-select>
						<!-- <fortress-select
              v-model="scope.row.listdataType"
              :dictList="listdataTypeList"
              placeholder="请选择"
              clearable
              :clearHanlder="typeClear(scope.row)"
            ></fortress-select> -->
					</template>
				</el-table-column>
				<el-table-column label="数据集配置" :show-overflow-tooltip="true" width="800px">
					<template slot-scope="scope">
						<!-- 字典 -->
						<el-select v-model="scope.row.dictName" placeholder="请选择" clearable filterable v-if="scope.row.listdataType == 1">
							<el-option v-for="item in dictList" :key="item.code" :label="item.code + ' | ' + item.dictValue" :value="item.code"></el-option>
						</el-select>
						<div v-if="scope.row.listdataType == 2">
							<!-- 枚举 -->
							<el-row>
								<el-col :span="4"><span>枚举名称</span></el-col>
								<el-col :span="19"><el-input size="medium" v-model="scope.row.enumObj.enumName"></el-input></el-col>
							</el-row>
							<el-row>
								<el-col :span="4"><span>模块名称</span></el-col>
								<el-col :span="19"><el-input size="medium" v-model="scope.row.enumObj.moduleName"></el-input></el-col>
							</el-row>
						</div>
						<div v-if="scope.row.listdataType == 3">
							<!-- 接口 -->
							<el-row>
								<el-col :span="4"><span>接口地址</span></el-col>
								<el-col :span="19"><el-input size="medium" v-model="scope.row.urlObj.urlName"></el-input></el-col>
							</el-row>
							<el-row>
								<el-col :span="4"><span>CODE字段</span></el-col>
								<el-col :span="19"><el-input size="medium" v-model="scope.row.urlObj.code"></el-input></el-col>
							</el-row>
							<el-row>
								<el-col :span="4"><span>NAME字段</span></el-col>
								<el-col :span="19"><el-input size="medium" v-model="scope.row.urlObj.name"></el-input></el-col>
							</el-row>
							<span style="color: red">* 数据过多时不建议使用</span>
						</div>
						<div v-if="scope.row.listdataType == 4">
							<!-- JSON常量 -->
							<el-input type="textarea" :rows="2" placeholder="JSON格式：[{code:'code1',name:'name1'},{code:'code2',name:'name2'}]" v-model="scope.row.jsonObj"></el-input>
						</div>
					</template>
				</el-table-column>
			</el-table-column>
		</el-table>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="primary" @click="submitForm()">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { colconfigList, colconfigEdit } from "@/api/devtools/devcolconfig.js"
import { sysDictList } from "@/api/system/sysdict.js"
import { enumList } from "@/api/devtools/devcode.js"
import fortressSelect from "../../components/fortress-select/fortress-select.vue"
export default {
	components: { fortressSelect },
	data() {
		return {
			visible: false,
			tableData: [],
			isQueryStatus: 1, //查询全选状态
			isListStatus: 1, //列表全选状态
			isFormStatus: 1, //表单全选状态
			DbQueryConditionList: [],
			ViewFormTypeList: [],
			dictList: [],
			listdataTypeList: [],
			validateTypeList: [],
		}
	},
	computed: {},
	created() {
		enumList("DbQueryCondition").then(resp => {
			if (resp.code == 0) {
				this.DbQueryConditionList = resp.data
			}
		})
		enumList("ViewFormType").then(resp => {
			if (resp.code == 0) {
				this.ViewFormTypeList = resp.data
			}
		})
		enumList("ValidateType").then(resp => {
			if (resp.code == 0) {
				this.validateTypeList = resp.data
			}
		})
		enumList("ListDataType").then(resp => {
			if (resp.code == 0) {
				this.listdataTypeList = resp.data
			}
		})
		sysDictList().then(resp => {
			if (resp.code == 0) {
				this.dictList = resp.data
			}
		})
	},
	methods: {
		checkAllIsForm() {
			if (this.isFormStatus == 1) {
				this.isFormStatus = 2
			} else {
				this.isFormStatus = 1
			}
			this.tableData.forEach(item => {
				item.isForm = this.isFormStatus
			})
		},
		checkAllIsList() {
			if (this.isListStatus == 1) {
				this.isListStatus = 2
			} else {
				this.isListStatus = 1
			}
			this.tableData.forEach(item => {
				item.isList = this.isListStatus
			})
		},
		checkAllIsQuery() {
			if (this.isQueryStatus == 1) {
				this.isQueryStatus = 2
			} else {
				this.isQueryStatus = 1
			}
			this.tableData.forEach(item => {
				item.isQuery = this.isQueryStatus
			})
		},
		init(id) {
			this.tableData = []
			//查询列表
			colconfigList(id).then(resp => {
				if (resp.code == 0) {
					this.tableData = resp.data
					this.tableData.forEach(item => {
						if (item.listdataType) {
							item.listdataType = item.listdataType + ""
						}
					})
				} else {
					this.$msg({
						message: resp.msg,
						type: "error",
					})
				}
			})
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			this.tableData.forEach(item => {
				item.listdataConfig = ""
				if (item.listdataType == 1 && item.dictName) {
					item.listdataConfig = item.dictName
				} else if (item.listdataType == 2 && item.enumObj) {
					item.listdataConfig = JSON.stringify(item.enumObj)
				} else if (item.listdataType == 3 && item.urlObj) {
					item.listdataConfig = JSON.stringify(item.urlObj)
				} else if (item.listdataType == 4 && item.jsonObj) {
					item.listdataConfig = item.jsonObj
				}
			})
			colconfigEdit(this.tableData).then(resp => {
				if (resp.code == 0) {
					this.$message({
						type: "success",
						message: "操作成功!",
					})
					this.visible = false
				} else {
					this.$msg({
						message: resp.msg,
						type: "error",
					})
				}
			})
		},
		//数据集类型切换
		typeClear(row) {
			row.listdataConfig = ""
			row.dictName = ""
			;(row.enumObj = { enumName: "", moduleName: "" }), (row.urlObj = { name: "", code: "", urlName: "" }), (row.jsonObj = "[]")
		},
	},
}
</script>
<style scoped></style>
