import request from "@/common/request"

//车型表分页展示
export const pdcartypePage = (queryForm) => {
    return request("get", "/pdworld/pdCarType/page", queryForm);
}
//车型表列表展示
export const pdcartypeList = (queryForm) => {
    return request("get", "/pdworld/pdCarType/list", queryForm);
}
//车型表详情
export const pdcartypeInfo = (id) => {
    return request('get', '/pdworld/pdCarType/info', {
        id: id
    })
}
//车型表信息保存
export const pdcartypeSave = (data) => {
    return request('post', '/pdworld/pdCarType/save', data)
}
//车型表信息修改
export const pdcartypeEdit = (data) => {
    return request('post', '/pdworld/pdCarType/edit', data)
}
//车型表信息删除
export const pdcartypeDel = (id) => {
    return request('post', '/pdworld/pdCarType/delete?id=' + id)
}
//车型表批量删除
export const pdcartypeDelBatch = (ids) => {
    return request('post', '/pdworld/pdCarType/deleteBatch', ids)
}
//车型表动态表头导出
export const pdcartypeDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdCarType/exportDynamic', data)
}
