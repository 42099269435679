<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">手机号:</label>
                    <div class="form-item-content">{{ objInfo.phone }}
                      <fortress-copy :copyData="objInfo.phone"></fortress-copy>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">验证码:</label>
                    <div class="form-item-content">{{ objInfo.captcha }}
                      <fortress-copy :copyData="objInfo.captcha"></fortress-copy>
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { syscaptchaInfo } from "@/api/system/syscaptcha.js";
    
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
            }
        },
        created() {
        },
        methods: {
            init(id) {
                if (id) {
                   syscaptchaInfo(id).then(res => {
                        this.objInfo = res.data
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
