<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">

      <el-form-item
          label="是否中转性质"
          :label-width="formLabelWidth"
          prop="isTransit"
      >
        <fortress-radio-group v-model="form.isTransit" :dictList="YesNoList">
        </fortress-radio-group>
      </el-form-item>

      <!--省市区-->
      <el-form-item
          v-if="!form.id"
          label="始发地"
          :label-width="formLabelWidth"
          prop="startPlace"
      >
        <fortress-region
            ref="startPlaceFortressRegion"
          @ftregion="startPlaceHandler"
        ></fortress-region>
      </el-form-item>

      <el-form-item
        v-if="!form.id"
        label="到达地"
        :label-width="formLabelWidth"
        prop="destination"
      >
        <fortress-region
          ref="destinationFortressRegion"
          @ftregion="destinationHandler"
        ></fortress-region>
      </el-form-item>


<!--      <el-form-item-->
<!--          label="是否生成所有区"-->
<!--          :label-width="formLabelWidth"-->
<!--          prop="isAllArea"-->
<!--      >-->
<!--        <fortress-radio-group v-model="form.isAllArea" :dictList="YesNoList">-->
<!--        </fortress-radio-group>-->
<!--        <p style="color: red">为是时,上面始发地到达地选中的区县将失效</p>-->
<!--        <p style="color: red">会生成始发地所有区县 至 到达地所有区县的多条路线数据</p>-->
<!--      </el-form-item>-->

      <!-- <el-form-item label="到达地" :label-width="formLabelWidth" prop="destination">-->
      <!--                <el-input-->
      <!--                        v-model="form.destination"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入到达地"-->
      <!--                ></el-input>-->
      <!--            </el-form-item> -->

      <el-form-item
        label="运输时效"
        :label-width="formLabelWidth"
        prop="transportation"
      >
        <el-input
          v-model="form.transportation"
          autocomplete="off"
          placeholder="请输入运输时效"
        ></el-input>
      </el-form-item>
      <!--            <el-form-item label="类型（专线运输/货运代理）" :label-width="formLabelWidth" prop="type">-->
      <!--                <fortress-select-->
      <!--                        v-model="form.type"-->
      <!--                        :dictList="PDTypeList"-->
      <!--                        placeholder="请选择类型（专线运输/货运代理）"-->
      <!--                        clearable-->
      <!--                ></fortress-select>-->
      <!--            </el-form-item>-->

      <el-form-item label="专线经理电话" :label-width="formLabelWidth" prop="phone">
        <el-input
            v-model="form.phone"
            autocomplete="off"
            placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="业务类型"
        :label-width="formLabelWidth"
        prop="type"
      >
        <fortress-radio-group v-model="form.type" :dictList="TypeList">
        </fortress-radio-group>
      </el-form-item>

      <!--      <el-form-item-->
      <!--          label="是否首页推荐"-->
      <!--          :label-width="formLabelWidth"-->
      <!--          prop="isIndexrec"-->
      <!--      >-->
      <!--        <fortress-radio-group v-model="form.isIndexrec" :dictList="YesNoList">-->
      <!--        </fortress-radio-group>-->
      <!--      </el-form-item>-->

      <el-form-item label="虚拟订单数" :label-width="formLabelWidth" prop="indexrecSort">
        <el-input
            v-model="form.indexrecSort"
            autocomplete="off"
            placeholder="请输入虚拟订单数"
            type="number"
        ></el-input>
      </el-form-item>


      <el-form-item
          label="价格是否公开"
          :label-width="formLabelWidth"
          prop="pricePublic"
      >
        <fortress-radio-group v-model="form.pricePublic" :dictList="YesNoList">
        </fortress-radio-group>
      </el-form-item>

      <div v-if="form.pricePublic ==1">
        <el-form-item label="重货价" :label-width="formLabelWidth" prop="heavyCost">
          <el-input
              v-model="form.heavyCost"
              autocomplete="off"
              placeholder="请输入重货价"
          >
            <template slot="append">元/KG</template>
          </el-input>
        </el-form-item>
        <el-form-item label="抛货价" :label-width="formLabelWidth" prop="castCost">
          <el-input
              v-model="form.castCost"
              autocomplete="off"
              placeholder="请输入抛货价"
          >
            <template slot="append">元/立方</template>
          </el-input>
        </el-form-item>
        <el-form-item label="起运价" :label-width="formLabelWidth" prop="startCost">
          <el-input
              v-model="form.startCost"
              autocomplete="off"
              placeholder="请输入起运价"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </div>

<!--      <el-form-item-->
<!--        label="是否搜索页推荐 搜索页置顶。"-->
<!--        :label-width="formLabelWidth"-->
<!--        prop="isSearchrec"-->
<!--      >-->
<!--        <fortress-radio-group v-model="form.isSearchrec" :dictList="YesNoList">-->
<!--        </fortress-radio-group>-->
<!--      </el-form-item>-->
      <el-form-item label="权重" :label-width="formLabelWidth" prop="sort">
        <el-input
          v-model="form.sort"
          autocomplete="off"
          placeholder="请输入权重"
          type="number"
        ></el-input>
      </el-form-item>
      <!--            <el-form-item label="发货省" :label-width="formLabelWidth" prop="consigner">-->
      <!--                <el-input-->
      <!--                        v-model="form.consigner"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货省"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="发货省名称" :label-width="formLabelWidth" prop="consignerName">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货省名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="发货市" :label-width="formLabelWidth" prop="consignerCity">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerCity"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货市"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="发货市名称" :label-width="formLabelWidth" prop="consignerCityName">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerCityName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货市名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="发货区" :label-width="formLabelWidth" prop="consignerArea">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerArea"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货区"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="发货区名称" :label-width="formLabelWidth" prop="consignerAreaName">-->
      <!--                <el-input-->
      <!--                        v-model="form.consignerAreaName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入发货区名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货省" :label-width="formLabelWidth" prop="province">-->
      <!--                <el-input-->
      <!--                        v-model="form.province"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货省"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货省名称" :label-width="formLabelWidth" prop="provinceName">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货省名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货市" :label-width="formLabelWidth" prop="provinceCity">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceCity"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货市"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货市名称" :label-width="formLabelWidth" prop="provinceCityName">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceCityName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货市名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货区" :label-width="formLabelWidth" prop="provinceArea">-->
      <!--                <el-input-->
      <!--                        v-model="form.provinceArea"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货区"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="收货区名称" :label-width="formLabelWidth" prop="areaName">-->
      <!--                <el-input-->
      <!--                        v-model="form.areaName"-->
      <!--                        autocomplete="off"-->
      <!--                        placeholder="请输入收货区名称"-->
      <!--                ></el-input>-->
      <!--            </el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger"
        >取 消</el-button
      >
      <el-button type="primary" size="medium" @click="submitForm('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  pdrouteInfo,
  pdrouteSave,
  pdrouteEdit,
} from "@/api/pdworld/pdroute.js";
import { enumList, urlList } from "@/api/public.api.js";

export default {
  data() {
    return {
      startPlaceRegion: [],
      destinationRegion: [],
      visible: false,
      form: {
        memberId: this.memberId,
        parkId: "",
        startPlace: "",
        destination: "",
        transportation: "",
        type: "",
        isIndexrec: "2",
        isSearchrec: "2",
        indexrecSort: "",
        sort: "",
        consigner: "",
        consignerName: "",
        consignerCity: "",
        consignerCityName: "",
        consignerArea: "",
        consignerAreaName: "",
        province: "",
        provinceName: "",
        provinceCity: "",
        provinceCityName: "",
        provinceArea: "",
        areaName: "",
        phone: "",
        isAllArea: "2",
        pricePublic: "2",
        heavyCost: "",
        castCost: "",
        startCost: "",
        isTransit: "2",
      },
      formLabelWidth: "120px",
      rules: {},
      PDTypeList: [],
      YesNoList: [],
      TypeList: [],
      memberIdList: [],
      parkIdList: [],
      memberId: "",
      id: "",
    };
  },
  created() {},
  methods: {
    startPlaceHandler(regions) {
      console.log("regionHandler", regions);
      this.form.consigner = regions[0].id;
      this.form.consignerName = regions[0].name;
      this.form.consignerCity = regions[1].id;
      this.form.consignerCityName = regions[1].name;
      this.form.consignerArea = regions[2].id;
      this.form.consignerAreaName = regions[2].name;
    },
    destinationHandler(regions) {
      console.log("regionHandler", regions);
      this.form.province = regions[0].id;
      this.form.provinceName = regions[0].name;
      this.form.provinceCity = regions[1].id;
      this.form.provinceCityName = regions[1].name;
      this.form.provinceArea = regions[2].id;
      this.form.areaName = regions[2].name;
    },
    queryEnumList() {
      // this.PDTypeList = [];
      // enumList("PDType", "").then((resp) => {
      //   if (resp.code == 0) {
      //     this.PDTypeList = resp.data;
      //   }
      // });
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
      this.TypeList = [];
      enumList("PDRouteType", "").then((resp) => {
        if (resp.code == 0) {
          this.TypeList = resp.data;
        }
      });
    },
    // queryUrlList() {
    //   // this.memberIdList = [];
    //   // urlList("/pdworld/pdMember/list").then((resp) => {
    //   //   if (resp.code == 0) {
    //   //     if (resp.data) {
    //   //       resp.data.forEach((item) => {
    //   //         this.memberIdList.push({ code: item.id, name: item.nickName });
    //   //       });
    //   //     }
    //   //   }
    //   // });
    //   this.parkIdList = [];
    //   urlList("/pdworld/pdPark/list").then((resp) => {
    //     if (resp.code == 0) {
    //       if (resp.data) {
    //         resp.data.forEach((item) => {
    //           this.parkIdList.push({ code: item.id, name: item.storeName });
    //         });
    //       }
    //     }
    //   });
    // },
    init(memberId, id) {
      this.id = id;
      this.memberId = memberId;
      this.resetForm();
      this.queryEnumList();
      // this.queryUrlList();
      //修改
      if (id) {
        pdrouteInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              this.startPlaceRegion.push(this.form.consigner);
              this.startPlaceRegion.push(this.form.consignerCity);
              this.startPlaceRegion.push(this.form.consignerArea);
              this.$refs.startPlaceFortressRegion.init(this.startPlaceRegion);

              this.destinationRegion.push(this.form.province);
              this.destinationRegion.push(this.form.provinceCity);
              this.destinationRegion.push(this.form.provinceArea);
              this.$refs.destinationFortressRegion.init(this.destinationRegion);
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      } else {
        this.$nextTick(() => {
          this.$refs.startPlaceFortressRegion.init([]);
          this.$refs.destinationFortressRegion.init([]);
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            pdrouteEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {

            // 新增时 如果是批量生成, 先弹窗
            if (this.form.isAllArea == 1){
              this.$message({
                type: "success",
                message: "生成中.请稍后刷新列表查看!",
              });
              this.visible = false
            }
            pdrouteSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });

          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.region = [];
      this.form = {
        memberId: this.memberId,
        parkId: "",
        startPlace: "",
        destination: "",
        transportation: "",
        type: "",
        isIndexrec: "2",
        isSearchrec: "2",
        indexrecSort: "",
        sort: "",
        consigner: "",
        consignerName: "",
        consignerCity: "",
        consignerCityName: "",
        consignerArea: "",
        consignerAreaName: "",
        province: "",
        provinceName: "",
        provinceCity: "",
        provinceCityName: "",
        provinceArea: "",
        areaName: "",
        phone: "",
        isAllArea:"2",
        pricePublic:"2",
        heavyCost:"",
        castCost:"",
        startCost:"",
        isTransit: "2"
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
