import request from "@/common/request"

//帮助中心分类分页展示
export const helparticletypePage = (queryForm) => {
    return request("get", "/helparticle/helpArticleType/page", queryForm);
}
//帮助中心分类列表展示
export const helparticletypeList = (queryForm) => {
    return request("get", "/helparticle/helpArticleType/list", queryForm);
}
//帮助中心分类详情
export const helparticletypeInfo = (id) => {
    return request('get', '/helparticle/helpArticleType/info', {
        id: id
    })
}
//帮助中心分类信息保存
export const helparticletypeSave = (data) => {
    return request('post', '/helparticle/helpArticleType/save', data)
}
//帮助中心分类信息修改
export const helparticletypeEdit = (data) => {
    return request('post', '/helparticle/helpArticleType/edit', data)
}
//帮助中心分类信息删除
export const helparticletypeDel = (id) => {
    return request('post', '/helparticle/helpArticleType/delete?id=' + id)
}
//帮助中心分类批量删除
export const helparticletypeDelBatch = (ids) => {
    return request('post', '/helparticle/helpArticleType/deleteBatch', ids)
}
//帮助中心分类动态表头导出
export const helparticletypeDynamicExport = (data) => {
    return request("eptpost", '/helparticle/helpArticleType/exportDynamic', data)
}
