<template>
  <el-dialog
    :title="form.id ? '编辑' : '新增'"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item
        label="用户名"
        :label-width="formLabelWidth"
        prop="userName"
      >
        <el-input
          disabled
          v-model="form.userName"
          autocomplete="off"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
        <el-input
          disabled
          v-model="form.phone"
          autocomplete="off"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickName">
        <el-input
          disabled
          v-model="form.nickName"
          autocomplete="off"
          placeholder="请输入昵称"
        ></el-input>
      </el-form-item>
<!--      <el-form-item-->
<!--        label="会员类型[会员/商家]"-->
<!--        :label-width="formLabelWidth"-->
<!--        prop="type"-->
<!--      >-->
<!--        <fortress-radio-group-->
<!--          v-model="form.type"-->
<!--          :dictList="MemberTypeList"-->
<!--        >-->
<!--        </fortress-radio-group>-->
<!--      </el-form-item>-->
      <el-form-item
        label="审核状态[是否通过]"
        :label-width="formLabelWidth"
        prop="auditStatus"
      >
        <fortress-radio-group v-model="form.auditStatus" :dictList="YesNoList">
        </fortress-radio-group>
      </el-form-item>

      <el-form-item
        label="公司名称"
        :label-width="formLabelWidth"
        prop="enterpriseName"
      >
        <el-input
          disabled
          v-model="form.enterpriseName"
          autocomplete="off"
          placeholder="请输入公司名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="营业执照"
        :label-width="formLabelWidth"
        prop="cardType"
      >
        <el-image
          v-if="form.cardType"
          style="width: 100px; height: 100px"
          :src="form.cardType"
          :preview-src-list="[form.cardType]"
        >
        </el-image>
        <!-- <fortress-upload
          type="image"
          name="cardType"
          :formData="form"
        ></fortress-upload> -->
        <!-- <el-input
                        v-model="form.cardType"
                        autocomplete="off"
                        placeholder="请输入营业执照"
                ></el-input> -->
      </el-form-item>
      <el-form-item
        label="法定代表人"
        :label-width="formLabelWidth"
        prop="person"
      >
        <el-input
          disabled
          v-model="form.person"
          autocomplete="off"
          placeholder="请输入法定代表人"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="法人身份证号"
        :label-width="formLabelWidth"
        prop="personCode"
      >
        <el-input
          disabled
          v-model="form.personCode"
          autocomplete="off"
          placeholder="请输入法人身份证号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="法人身份证正面"
        :label-width="formLabelWidth"
        prop="cardFront"
      >
        <el-image
          v-if="form.cardFront"
          style="width: 100px; height: 100px"
          :src="form.cardFront"
          :preview-src-list="[form.cardFront]"
        >
        </el-image>

        <!-- <fortress-upload
          type="image"
          name="cardFront"
          :formData="form"
        ></fortress-upload> -->
      </el-form-item>
      <el-form-item
        label="法人身份证反面"
        :label-width="formLabelWidth"
        prop="cardBack"
      >
        <el-image
          v-if="form.cardBack"
          style="width: 100px; height: 100px"
          :src="form.cardBack"
          :preview-src-list="[form.cardBack]"
        >
        </el-image>

        <!-- <fortress-upload
          type="image"
          name="cardBack"
          :formData="form"
        ></fortress-upload> -->
      </el-form-item>
      <el-form-item
        label="道路运输许可证"
        :label-width="formLabelWidth"
        prop="permit"
      >
        <el-image
          v-if="form.permit"
          style="width: 100px; height: 100px"
          :src="form.permit"
          :preview-src-list="[form.permit]"
        >
        </el-image>
        <!-- <fortress-upload
          type="image"
          name="permit"
          :formData="form"
        ></fortress-upload> -->
        <!-- <el-input
          v-model="form.permit"
          autocomplete="off"
          placeholder="请输入道路运输许可证"
        ></el-input> -->
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
        <el-input
          v-model="form.remark"
          autocomplete="off"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger"
        >取 消</el-button
      >
      <el-button type="primary" size="medium" @click="submitForm('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  pdmemberInfo,
  pdmemberShopSave,
  pdmemberEdit,
} from "@/api/pdworld/pdmember.js";
import { dictList, enumList, urlList } from "@/api/public.api.js";

export default {
  data() {
    return {
      visible: false,
      region: [],
      form: {
        sort: "",
        userName: "",
        password: "",
        phone: "",
        nickName: "",
        avatar: "",
        gender: "",
        birthday: "",
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
        address: "",
        type: "0",
        auditStatus: "",
        branch: "",
        enterpriseName: "",
        cardType: "",
        person: "",
        personCode: "",
        cardFront: "",
        cardBack: "",
        capital: "",
        business: "",
        creditCode: "",
        regDate: "",
        businessTerm: "",
        residence: "",
        regAuthority: "",
        awardDate: "",
        permit: "",
        remark: "",
        isRec: "2",
        isAgentrec: "2",
        isIndex: "2",
        transportType: "1",
      },
      formLabelWidth: "120px",
      rules: {
      },
      sexList: [],
      MemberTypeList: [],
      YesNoList: [],
      branchList: [],
      PDTypeList: [],
    };
  },
  created() {},
  methods: {
    queryDictList() {
      this.sexList = [];
      dictList("sex").then((resp) => {
        if (resp.code == 0) {
          this.sexList = resp.data;
        }
      });
    },
    queryEnumList() {
      this.PDTypeList = [];
      enumList("PDType", "").then((resp) => {
        if (resp.code == 0) {
          this.PDTypeList = resp.data;
        }
      });
      this.MemberTypeList = [];
      enumList("MemberType", "").then((resp) => {
        if (resp.code == 0) {
          this.MemberTypeList = resp.data;
        }
      });
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    queryUrlList() {
      this.branchList = [];
      urlList("/pdworld/pdPark/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.branchList.push({ code: item.id, name: item.storeName });
            });
          }
        }
      });
    },
    //省市区三级联动
    regionHandler(regions) {
      console.log("regionHandler", regions);
      this.form.province = regions[0].id;
      this.form.city = regions[1].id;
      this.form.area = regions[2].id;

      this.form.provinceName = regions[0].name;
      this.form.cityName = regions[1].name;
      this.form.areaName = regions[2].name;
    },
    init(id) {
      this.resetForm();
      this.queryDictList();
      this.queryEnumList();
      this.queryUrlList();
      //修改
      if (id) {
        pdmemberInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              this.region.push(this.form.province);
              this.region.push(this.form.city);
              this.region.push(this.form.area);
              // console.log(this.$refs.fortressRegion);
              // this.$refs.fortressRegion.init(this.region);
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      } else {
        this.$nextTick(() => {
          // this.$refs.fortressRegion.init([]);
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            //如果是审核通过,把会员状态改为商家
            if (this.form.auditStatus==1){
              this.form.type = 0
            }
            pdmemberEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            pdmemberShopSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.region = [];
      this.form = {
        sort: "",
        userName: "",
        password: "",
        phone: "",
        nickName: "",
        avatar: "",
        gender: "",
        birthday: "",
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
        address: "",
        type: "0",
        auditStatus: "",
        branch: "",
        enterpriseName: "",
        cardType: "",
        person: "",
        personCode: "",
        cardFront: "",
        cardBack: "",
        capital: "",
        business: "",
        creditCode: "",
        regDate: "",
        businessTerm: "",
        residence: "",
        regAuthority: "",
        awardDate: "",
        permit: "",
        remark: "",
        isRec: "2",
        isAgentrec: "2",
        isIndex: "2",
        transportType: "1",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
