import request from "@/common/request"

//获取当前用户可访问的角色
export const rolePage = (page) => {
    return request("get", "/system/sysRole/page", page);
}
//获取当前用户可访问的角色列表
export const roleList = () => {
    return request("get", "/system/sysRole/list");
}
//角色详情
export const roleInfo = (id) => {
    return request('get', '/system/sysRole/info', {
        id: id
    })
}
//保存角色信息
export const roleSave = (data) => {
    return request('post', '/system/sysRole/save', data)
}
//修改角色信息
export const roleEdit = (data) => {
    return request('post', '/system/sysRole/edit', data)
}
//删除角色信息
export const roleDel = (id) => {
    return request('post', '/system/sysRole/delete?id=' + id)
}
//角色菜单权限详情
export const menuAuthInfo = (id) => {
    return request('get', '/system/sysRole/menuAuthInfo', {
        roleId: id
    })
}
//角色菜单权限保存
export const menuAuthSave = (data) => {
    return request('post', '/system/sysRole/menuAuthSave', data)
}
//角色数据权限详情
export const dataAuthInfo = (id) => {
    return request('get', '/system/sysRole/dataAuthInfo', {
        roleId: id
    })
}
//角色数据权限保存
export const dataAuthSave = (roleId, data) => {
    return request('post', '/system/sysRole/dataAuthSave/' + roleId, data)
}