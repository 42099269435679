<template>
  <div class="my-progress">
    <div class="my-progress-outer" :style="setProgressBgStyle" ref="progress">
      <div class="my-progress-inner" :style="setProgressStyle">
        <div class="tips" ref="tips">
          <span>{{tempPercent}}</span>
        </div>
      </div>
      <div v-if="showSlider" class="my-progress-slider" ref="slider" :style="setSliderStyle"></div>
    </div>
    <el-input v-model="tempPercent" placeholder @input="inputHandle" max="100" type="number"></el-input>
    <!-- <span v-if="showPerText">{{tempPercent}}%</span> -->
  </div>
</template>

<script>
// 使用了element的颜色
const colorTable = {
  success: '#13ce66',
  fail: '#ff4949',
  warning: '#e6a23c',
  default: '#0cafcc'
}
export default {
  name: 'CProgress',
  props: {
    percent: {
      type: Number,
      default: 0
    },
    showSlider: {
      type: Boolean,
      default: true
    },
    showPerText: {
      type: Boolean,
      default: true
    },
    // 进度条的宽度
    width: {
      type: Number,
      default: 320
    },
    bgColor: {
      type: String,
      default: '#ebeef5'
    },
    progressColor: {
      type: String,
      default: '#0cafcc'
    },
    // 滑块的宽度
    sliderWidth: {
      type: Number,
      default: 12
    },
    // 颜色的类型
    type: {
      type: String,
      default: colorTable.default
    }
  },
  data () {
    return {
      sliderLeft: 0, // 滑块相对父元素发x坐标
      progressWidth: 0, // 进度条当前的的宽度
      tempPercent: 0
    }
  },
  computed: {
    // 设置进度条的背景样式
    setProgressBgStyle () {
      return {
        // 加上滑块的宽度
        width: `${this.width + this.sliderWidth / 2}px`
      }
    },
    // 设置进度条的样式
    setProgressStyle () {
      const color = colorTable[this.type] || this.progressColor
      return {
        width: `${this.progressWidth}px`,
        background: color
      }
    },
    // 设置滑块的样式
    setSliderStyle () {
      const color = colorTable[this.type] || this.progressColor
      return {
        border: `1px solid ${color}`,
        width: `${this.sliderWidth}px`,
        height: `${this.sliderWidth}px`,
        left: `${this.sliderLeft}px`
      }
    }
  },
  mounted () {
    this.sliderLeft = this.width / 100 * this.percent
    this.progressWidth = this.sliderLeft + this.sliderWidth / 2 // 滑块的x坐标加上滑块的宽度
    this.tempPercent = this.percent
    this.addListener()
  },
  methods: {

    addListener () {
      const slider = this.$refs.slider
      const tips = this.$refs.tips
      const progress = this.$refs.progress
      let isClickSlider = false
      let distance = 0 // 滑块与点击坐标的绝对距离
      slider.onmouseover = (e) => {
        tips.classList.add('my-progress-tips')
        slider.classList.add('my-progress-translation')
      }
      slider.onmouseout = (e) => {
        tips.classList.remove('my-progress-tips')
        slider.classList.remove('my-progress-translation')
      }
      progress.onclick = (e) => {
        console.log(e);
        // 阻止事件冒泡
        if (e.target == slider) {
          return
        }
        let curX = progress.offsetLeft
        this.sliderLeft = e.offsetX - 6
        if (this.sliderLeft <= 0) {
          this.sliderLeft = 0
        }
        if (this.sliderLeft >= this.width) {
          this.sliderLeft = this.width
        }
        this._countCurPercent()
      }
      slider.onmousedown = (e) => {
        e.preventDefault();
        isClickSlider = true
        let curX = slider.offsetLeft
        distance = e.pageX - curX // 得出绝对距离
        document.onmousemove = (e) => {
          if (isClickSlider) {
            // 判断是否已经超出进度条的长度
            if ((e.pageX - distance) >= 0 && (e.pageX - distance) <= (this.width - 0)) {
              this.sliderLeft = e.pageX - distance
              this._countCurPercent()
            }
          }
        }
      }
      document.onmouseup = () => {
        isClickSlider = false
      }
    },
    // 算出百分比
    _countCurPercent () {
      this.tempPercent = Math.ceil(parseInt(this.sliderLeft / this.width * 256))
      this.progressWidth = this.sliderLeft + 6
      // 取整的时候宽度可能不为0，所以在0和100的时候也将宽度取整
      if (this.tempPercent <= 0) {
        this.progressWidth = 6
        this.sliderLeft = 0
      }
      if (this.tempPercent >= 256) {
        this.progressWidth = this.width + 6
        this.sliderLeft = this.width
      }
      this.$emit('percentChange', this.tempPercent)
    },
    inputHandle (e) {
      if (e >= 256) {
        e = 256
      }
      if (e) {
        this.tempPercent = parseInt(e)
      } else {
        this.tempPercent = 0
      }
      this.percent = this.tempPercent
      this.sliderLeft = this.width / 256 * this.percent
      this.progressWidth = this.sliderLeft + this.sliderWidth // 滑块的x坐标加上滑块的宽度
      this.tempPercent = this.percent
      this._countCurPercent()
    }
  },

}
</script>

<style scoped lang="scss">
.my-progress {
  width: 100%;
  $width: 300px;
  $radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  span {
    margin-left: 5px;
    font-size: 14px;
    color: #666;
  }

  .my-progress-outer {
    width: $width;
    height: 2px;
    border-radius: $radius;
    background: #ebeef5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .my-progress-inner {
      width: 100px;
      height: 2px;
      background: #0cafcc;
      border-radius: $radius;
      position: relative;
      .tips {
        width: 0;
        height: 0;
        overflow: hidden;
        transition: top 0.3s;
      }
      .my-progress-tips {
        position: absolute;
        right: -14px;
        top: -47px;
        width: 28px;
        height: 28px;
        background: #0cafcc;
        border-radius: 50px 50px 0 50px;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        span {
          color: #fff;
          display: inline-block;
          transform: rotate(-45deg);
          margin: 0 auto;
        }
      }
    }
    .my-progress-slider {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #0cafcc;
      border: 1px solid #fff;
      position: absolute;
      z-index: 10;
      left: 10px;
      box-shadow: none;
      transition: box-shadow 0.3s;
    }
    .my-progress-translation {
      box-shadow: 0px 0px 1px 8px rgba(169, 232, 248, 0.7);
      // border-width: 10px !important;
      // animation: fadeBig 0.5s infinite;
    }
  }
  /deep/ .el-input {
    margin-left: 20px;
    width: 80px;
    height: 40px;
    .el-input__inner {
      height: 40px;
      &:focus {
        border-color: #0cafcc;
      }
    }
  }
}
</style>
<style>
/* @keyframes fadeBig {
  0% {
    opacity: 0;
    border: 10px solid red;
  }
  100% {
    opacity: 1;
    border: 10px solid red;
  }
} */
</style>
