<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">发布人ID:</label>
                    <div class="form-item-content">{{ objInfo.memberIdName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">发布人名称:</label>
                    <div class="form-item-content">{{ objInfo.createdPerson }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">联系人:</label>
                    <div class="form-item-content">{{ objInfo.linkPerson }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">联系电话:</label>
                    <div class="form-item-content">{{ objInfo.phone }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">地址:</label>
                    <div class="form-item-content">{{ objInfo.address }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">车牌号:</label>
                    <div class="form-item-content">{{ objInfo.carNum }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">出发地:</label>
                    <div class="form-item-content">{{ objInfo.startPlace }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">到达地:</label>
                    <div class="form-item-content">{{ objInfo.destination }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">车辆类型:</label>
                    <div class="form-item-content">{{ objInfo.carTypeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">车长:</label>
                    <div class="form-item-content">{{ objInfo.carLong }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">载重:</label>
                    <div class="form-item-content">{{ objInfo.carWeight }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">运价:</label>
                    <div class="form-item-content">{{ objInfo.price }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">说明备注:</label>
                    <div class="form-item-content">{{ objInfo.remake }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { pdcarInfo } from "@/api/pdworld/pdcar.js";
    import { dictList,urlList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                carTypeList:[],
                memberIdList:[],
            }
        },
        created() {
        },
        methods: {
          queryDictList(){
              this.carTypeList = [];
              dictList("carType").then((resp) => {
                if (resp.code == 0) {
                  this.carTypeList = resp.data;
                }
              });
          },
          queryUrlList(){
              this.memberIdList = [];
              urlList("/pdworld/pdMember/list").then((resp) => {
                if (resp.code == 0) {
                  if (resp.data) {
                    resp.data.forEach((item) => {
                      this.memberIdList.push({ code: item.id, name: item.nickName });
                    });
                  }
                }
              });
          },
            init(id) {
                this.queryDictList();
                this.queryUrlList();
                if (id) {
                   pdcarInfo(id).then(res => {
                        this.objInfo = res.data
                              let memberIdObj = this.memberIdList.find((item) => {
                                return item.code == this.objInfo.memberId;
                              });
                              this.objInfo.memberIdName = memberIdObj ? memberIdObj.name : "";
                              let carTypeObj = this.carTypeList.find((item) => {
                                return item.code == this.objInfo.carType;
                              });
                              this.objInfo.carTypeName = carTypeObj ? carTypeObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
