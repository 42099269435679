<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="会员ID" :label-width="formLabelWidth" prop="memberId">
                <el-input
                        v-model="form.memberId"
                        autocomplete="off"
                        placeholder="请输入会员ID"
                ></el-input>
            </el-form-item>
            <el-form-item label="余额" :label-width="formLabelWidth" prop="balanceMoney">
                <el-input
                        v-model="form.balanceMoney"
                        autocomplete="off"
                        placeholder="请输入余额"
                ></el-input>
            </el-form-item>
            <el-form-item label="冻结金额" :label-width="formLabelWidth" prop="frozenMoney">
                <el-input
                        v-model="form.frozenMoney"
                        autocomplete="off"
                        placeholder="请输入冻结金额"
                ></el-input>
            </el-form-item>
            <el-form-item label="总金额" :label-width="formLabelWidth" prop="amountMoney">
                <el-input
                        v-model="form.amountMoney"
                        autocomplete="off"
                        placeholder="请输入总金额"
                ></el-input>
            </el-form-item>
            <el-form-item label="剩余积分" :label-width="formLabelWidth" prop="balanceScore">
                <el-input
                        v-model="form.balanceScore"
                        autocomplete="off"
                        placeholder="请输入剩余积分"
                ></el-input>
            </el-form-item>
            <el-form-item label="冻结积分" :label-width="formLabelWidth" prop="frozenScore">
                <el-input
                        v-model="form.frozenScore"
                        autocomplete="off"
                        placeholder="请输入冻结积分"
                ></el-input>
            </el-form-item>
            <el-form-item label="总积分" :label-width="formLabelWidth" prop="amountScore">
                <el-input
                        v-model="form.amountScore"
                        autocomplete="off"
                        placeholder="请输入总积分"
                ></el-input>
            </el-form-item>
            <el-form-item label="剩余诚保资金" :label-width="formLabelWidth" prop="balanceIntegrityMoney">
                <el-input
                        v-model="form.balanceIntegrityMoney"
                        autocomplete="off"
                        placeholder="请输入剩余诚保资金"
                ></el-input>
            </el-form-item>
            <el-form-item label="冻结诚保资金" :label-width="formLabelWidth" prop="frozenIntegrityMoney">
                <el-input
                        v-model="form.frozenIntegrityMoney"
                        autocomplete="off"
                        placeholder="请输入冻结诚保资金"
                ></el-input>
            </el-form-item>
            <el-form-item label="总诚保资金" :label-width="formLabelWidth" prop="amountIntegrityMoney">
                <el-input
                        v-model="form.amountIntegrityMoney"
                        autocomplete="off"
                        placeholder="请输入总诚保资金"
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { memberaccountInfo, memberaccountSave, memberaccountEdit } from "@/api/account/memberaccount.js";
    
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    memberId:"",
                    balanceMoney:"",
                    frozenMoney:"",
                    amountMoney:"",
                    balanceScore:"",
                    frozenScore:"",
                    amountScore:"",
                    balanceIntegrityMoney:"",
                    frozenIntegrityMoney:"",
                    amountIntegrityMoney:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
            };
        },
        created() {
        },
        methods: {
            init(id) {
                this.resetForm();
                //修改
                if (id) {
                    memberaccountInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        memberaccountEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        memberaccountSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    memberId:"",
                    balanceMoney:"",
                    frozenMoney:"",
                    amountMoney:"",
                    balanceScore:"",
                    frozenScore:"",
                    amountScore:"",
                    balanceIntegrityMoney:"",
                    frozenIntegrityMoney:"",
                    amountIntegrityMoney:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
