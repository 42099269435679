<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="客户名称" :label-width="formLabelWidth" prop="custName">
        <el-input v-model="form.custName" autocomplete="off" placeholder="请输入客户名称"></el-input>
      </el-form-item>
      <el-form-item label="秘钥id" :label-width="formLabelWidth" prop="secretId">
        <el-input v-model="form.secretId" autocomplete="off" placeholder="请输入秘钥id" readonly></el-input>
        <el-button type="primary" size="medium" @click="genSecret" style="margin-top:10px">生成秘钥对</el-button>
      </el-form-item>
      <el-form-item label="秘钥key" :label-width="formLabelWidth" prop="secretKey">
        <el-input v-model="form.secretKey" autocomplete="off" placeholder="请输入秘钥key" readonly></el-input>
      </el-form-item>
      <el-form-item label="过期日期" :label-width="formLabelWidth" prop="expiredDate">
        <el-date-picker
          v-model="form.expiredDate"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="启用状态" :label-width="formLabelWidth" prop="status">
        <el-switch
          v-model="form.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
        <span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66">启用</span>
        <span v-if="form.status != 1" style="margin-left: 17px; color: #ff4949">禁用</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { apisecretInfo, apisecretSave, apisecretEdit, genSecret } from "@/api/system/apisecret.js"

export default {
  data () {
    return {
      visible: false,
      form: {
        custName: "",
        secretId: "",
        secretKey: "",
        expiredDate: "",
        status: 1,
      },
      formLabelWidth: "120px",
      rules: {
        custName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        secretId: [{ required: true, message: "请生成秘钥ID", trigger: "blur" }],
        secretKey: [{ required: true, message: "请生成秘钥KEY", trigger: "blur" }],
      },
    }
  },
  created () { },
  methods: {
    init (id) {
      this.resetForm()
      //修改
      if (id) {
        apisecretInfo(id).then(resp => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              }
            })
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            })
          }
        })
      }
      this.visible = true
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.visible = false
          //修改
          if (this.form.id) {
            apisecretEdit(this.form).then(resp => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
            //新增
          } else {
            apisecretSave(this.form).then(resp => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    resetForm () {
      this.form = {
        custName: "",
        secretId: "",
        secretKey: "",
        expiredDate: "",
        status: "",
      }
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields()
        }
      })
    },
    genSecret () {
      this.$confirm('此操作将为您更新秘钥id和秘钥key, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        genSecret().then(resp => {
          if (resp.code == 0) {
            this.form.secretId = resp.data.secretId
            this.form.secretKey = resp.data.secretKey
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            })
          }
        })
      }).catch(() => {

      });

    },
  },
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
