<template>
  <div>
    <el-drawer
      title=""
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <!-- <div class="form-item">
                    <label class="form-item-label">所属商家ID:</label>
                    <div class="form-item-content">{{ objInfo.memberIdName }}</div>
                </div> -->
<!--        <div class="form-item">-->
<!--          <label class="form-item-label">始发地网点ID:</label>-->
<!--          <div class="form-item-content">{{ objInfo.parkIdName }}</div>-->
<!--        </div>-->
<!--        <div class="form-item">-->
<!--          <label class="form-item-label">始发地:</label>-->
<!--          <div class="form-item-content">{{ objInfo.startPlace }}</div>-->
<!--        </div>-->
<!--        <div class="form-item">-->
<!--          <label class="form-item-label">到达地:</label>-->
<!--          <div class="form-item-content">{{ objInfo.destination }}</div>-->
<!--        </div>-->
        <div class="form-item">
          <label class="form-item-label">运输时效:</label>
          <div class="form-item-content">{{ objInfo.transportation }}</div>
        </div>
        <!--                <div class="form-item">-->
        <!--                    <label class="form-item-label">类型（专线运输/货运代理）:</label>-->
        <!--                    <div class="form-item-content">{{ objInfo.typeName }}</div>-->
        <!--                </div>-->
        <div class="form-item">
          <label class="form-item-label">是否首页推荐:</label>
          <div class="form-item-content">{{ objInfo.isIndexrecName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">是否搜索页推荐 搜索页置顶。:</label>
          <div class="form-item-content">{{ objInfo.isSearchrecName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">权重:</label>
          <div class="form-item-content">{{ objInfo.sort }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">发货省:</label>
          <div class="form-item-content">{{ objInfo.consigner }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">发货省名称:</label>
          <div class="form-item-content">{{ objInfo.consignerName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">发货市:</label>
          <div class="form-item-content">{{ objInfo.consignerCity }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">发货市名称:</label>
          <div class="form-item-content">{{ objInfo.consignerCityName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">发货区:</label>
          <div class="form-item-content">{{ objInfo.consignerArea }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">发货区名称:</label>
          <div class="form-item-content">{{ objInfo.consignerAreaName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">收货省:</label>
          <div class="form-item-content">{{ objInfo.province }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">收货省名称:</label>
          <div class="form-item-content">{{ objInfo.provinceName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">收货市:</label>
          <div class="form-item-content">{{ objInfo.provinceCity }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">收货市名称:</label>
          <div class="form-item-content">{{ objInfo.provinceCityName }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">收货区:</label>
          <div class="form-item-content">{{ objInfo.provinceArea }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">收货区名称:</label>
          <div class="form-item-content">{{ objInfo.areaName }}</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { pdrouteInfo } from "@/api/pdworld/pdroute.js";
import { enumList, urlList } from "@/api/public.api.js";
export default {
  data() {
    return {
      drawer: true,
      direction: "rtl",
      objInfo: {},
      PDTypeList: [],
      YesNoList: [],
      memberIdList: [],
      parkIdList: [],
    };
  },
  created() {},
  methods: {
    queryEnumList() {
      this.PDTypeList = [];
      enumList("PDType", "").then((resp) => {
        if (resp.code == 0) {
          this.PDTypeList = resp.data;
        }
      });
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    queryUrlList() {
      this.memberIdList = [];
      urlList("/pdworld/pdMember/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.memberIdList.push({ code: item.id, name: item.nickName });
            });
          }
        }
      });
      this.parkIdList = [];
      urlList("/pdworld/pdPark/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.parkIdList.push({ code: item.id, name: item.storeName });
            });
          }
        }
      });
    },
    init(id) {
      this.queryEnumList();
      this.queryUrlList();
      if (id) {
        pdrouteInfo(id).then((res) => {
          this.objInfo = res.data;
          let memberIdObj = this.memberIdList.find((item) => {
            return item.code == this.objInfo.memberId;
          });
          this.objInfo.memberIdName = memberIdObj ? memberIdObj.name : "";
          let parkIdObj = this.parkIdList.find((item) => {
            return item.code == this.objInfo.parkId;
          });
          this.objInfo.parkIdName = parkIdObj ? parkIdObj.name : "";
          let typeObj = this.PDTypeList.find((item) => {
            return item.code == this.objInfo.type;
          });
          this.objInfo.typeName = typeObj ? typeObj.name : "";
          let isIndexrecObj = this.YesNoList.find((item) => {
            return item.code == this.objInfo.isIndexrec;
          });
          this.objInfo.isIndexrecName = isIndexrecObj ? isIndexrecObj.name : "";
          let isSearchrecObj = this.YesNoList.find((item) => {
            return item.code == this.objInfo.isSearchrec;
          });
          this.objInfo.isSearchrecName = isSearchrecObj
            ? isSearchrecObj.name
            : "";
        });
      }
      this.drawer = true;
    },
    // 关闭抽屉
    handleClose(done) {
      done();
    },
  },
};
</script>
<style scoped>
.from-title {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  color: #17233d;
  border-bottom: 1px solid #eee;
}
.info-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 20px;
  font-size: 14px;
}
.form-item-label {
  text-align: right;
  float: left;
  width: 25%;
}
.form-item-content {
  margin-left: 30%;
  width: 70%;
}
</style>
