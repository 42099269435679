<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="是否发送所有" :label-width="formLabelWidth" prop="status">
        <el-switch
            v-model="form.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2"
            disabled>
        </el-switch>
      </el-form-item>

      <el-form-item v-show="form.status===2" label="选择接收人" :label-width="formLabelWidth" prop="receiveIds">
        <el-input v-model="form.displayReceive" autocomplete="off" placeholder="请选择接收人" @focus="dialogHandle">
        </el-input>
      </el-form-item>
      <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
        <fortress-select
            v-model="form.type"
            :dictList="NoticeTypeList"
            placeholder="请选择类型"
            clearable
            disabled
        ></fortress-select>
      </el-form-item>
      <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
<!--        <fortress-editor v-model="form.content"></fortress-editor>-->
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="form.content">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
    <el-dialog
        append-to-body
        :close-on-click-modal="false"
        fullscreen
        title="选择接收人"
        :visible.sync="dialogVisible"
        width="60%"
        height="200px;"
    >
      <div class="padding20">
        <el-row style="margin-bottom: 7px">
          <el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
            <el-form-item v-show="collapse.flag">
              <el-input v-model="queryForm.nickName" autocomplete="off" placeholder="请输入昵称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="queryForm.userName" autocomplete="off" placeholder="请输入用户名" clearable></el-input>
            </el-form-item>

            <el-form-item>
              <el-input v-model="queryForm.phone" autocomplete="off" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
            <el-form-item v-show="collapse.flag">
              <fortress-select
                  v-model="queryForm.type"
                  :dictList="MemberTypeList"
                  placeholder="请选择会员类型[会员/商家]"
                  clearable
                  filterable
              ></fortress-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({current:1})">查询
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="text" @click="handleCollapseState"
              >{{ collapse.label }}<i :class="collapse.icon"></i
              ></el-button>
            </el-form-item>
          </el-form>
          <div style="float: right; padding-right: 17px">
            <i
                class="el-icon-refresh"
                style="margin-right: 10px; cursor: pointer"
                @click="refresh"
            ></i>
          </div>
        </el-row>
        <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            @selection-change="onSelectionChange"
        >
          <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
          <el-table-column
              prop="userName"
              label="用户名"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              prop="phone"
              label="手机号"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              prop="type"
              label="会员类型[会员/商家]"
              :show-overflow-tooltip="true"
              :formatter="typeFmt"
          >
          </el-table-column>
        </el-table>
        <pagination :total="page.total" :current-page="page.current" :page-size="page.size"
                    @refreshData="queryList"></pagination>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="devSubmit">确 定</el-button>
			</span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import {noticeInfo, noticeSave, noticeEdit} from "@/api/pdworld/notice.js";
import {enumList,} from "@/api/public.api.js";
import FortressPagination from "@/components/fortress-pagination.vue";
import {pdmemberPage} from "@/api/pdworld/pdmember";

export default {
  data() {
    return {
      visible: false,
      form: {
        type: "1",
        receiveIds: "",
        content: "",
        status: 1,
      },
      formLabelWidth: "120px",
      myconfig: window.$conf.commonConf.ueConfig,
      rules: {
        type: [
          {required: true, message: "请输入类型", trigger: "blur"},
        ],
        content: [
          {required: true, message: "请输入内容", trigger: "blur"},
        ],
      },
      NoticeTypeList: [],
      YesNoList: [],

      dialogVisible: false,
      tableData: [],
      queryForm: {
        userName: "",
        phone: "",
        nickName: "",
        type: 1,
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
      MemberTypeList: [],
    };
  },
  components: {
    pagination: FortressPagination,
  },
  created() {
    this.queryDictList();
    this.queryEnumList();
    this.queryUrlList();
    this.queryList();
  },
  methods: {
    onSelectionChange(selection) {
      this.selection = selection
    },
    devSubmit() {
      this.dialogVisible = false
      this.form.receiveIds = this.selection.map(item => item.id).join(",")
      this.form.displayReceive = this.selection.map(item => item.nickName).join(",")
    },
    queryDictList() {
    },
    queryEnumList() {
      this.MemberTypeList = [];
      enumList("MemberType", "").then((resp) => {
        if (resp.code == 0) {
          this.MemberTypeList = resp.data;
        }
      });
      this.NoticeTypeList = [];
      enumList("NoticeType", "").then((resp) => {
        if (resp.code == 0) {
          this.NoticeTypeList = resp.data;
        }
      });
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    queryUrlList() {
    },
    refresh() {
      this.tableData = [];
      this.queryList({current: 1});
    },
    //查询表格数据
    queryList(page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      pdmemberPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu = this.page.total % this.page.size == 0 ? parseInt(this.page.total / this.page.size) : parseInt((this.page.total / this.page.size) + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({current: cu});
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    resetQueryForm() {
      this.queryForm = {
        userName: "",
        phone: "",
        nickName: "",
        type: 1,
      };
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    typeFmt(row) {
      let name = "";
      if (this.MemberTypeList) {
        this.MemberTypeList.forEach(item => {
          if (item.code == row.type) {
            name = item.name;
          }
        })
      }
      return name;
    },
    dialogHandle() {
      this.tableData = []
      this.resetQueryForm()
      this.dialogVisible = true
      this.queryList()
    },
    init(id) {
      this.resetForm();
      this.queryEnumList();
      //修改
      if (id) {
        noticeInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            noticeEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            noticeSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        type: "1",
        receiveIds: "",
        status: 1,
        content: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
