<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="所属快递公司" :label-width="formLabelWidth" prop="companyId">
                <fortress-select
                        v-model="form.companyId"
                        :dictList="companyIdList"
                        placeholder="请选择所属快递公司"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="网点名称" :label-width="formLabelWidth" prop="branchName">
                <el-input
                        v-model="form.branchName"
                        autocomplete="off"
                        placeholder="请输入网点名称"
                ></el-input>
            </el-form-item>
          <!--省市区-->
          <el-form-item label="地区" :label-width="formLabelWidth">
            <fortress-region
                ref="fortressRegion"
                @ftregion="regionHandler"
            ></fortress-region>
          </el-form-item>
            <el-form-item label="网点地址" :label-width="formLabelWidth" prop="address">
                <el-input
                        v-model="form.address"
                        autocomplete="off"
                        placeholder="请输入网点地址"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系方式" :label-width="formLabelWidth" prop="phone">
                <el-input
                        v-model="form.phone"
                        autocomplete="off"
                        placeholder="请输入联系方式"
                ></el-input>
            </el-form-item>
            <el-form-item label="网点图片" :label-width="formLabelWidth" prop="branchImg">
                <fortress-upload
                  type="image"
                  name="branchImg"
                  :formData="form"
                ></fortress-upload>
            </el-form-item>

          <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
            <el-input-number step-strictly :min="0" v-model="form.sort" autocomplete="off"></el-input-number>
          </el-form-item>
            <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                <el-input
                        v-model="form.remark"
                        autocomplete="off"
                        placeholder="请输入备注"
                ></el-input>
            </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { pdexpressagebranchInfo, pdexpressagebranchSave, pdexpressagebranchEdit } from "@/api/pdworld/pdexpressagebranch.js";
    import { urlList, } from "@/api/public.api.js";

    export default {
        data() {
            return {
                visible: false,
                form: {
                    companyId:"",
                    branchName:"",
                    address:"",
                    phone:"",
                    branchImg:"",
                    remark:"",
                    province:"",
                  sort:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
                companyIdList:[],
            };
        },
        created() {
        },
        methods: {
          //省市区三级联动
          regionHandler(regions) {
            console.log("regionHandler", regions);
            this.form.province=regions[0].id;
            this.form.city=regions[1].id;
            this.form.area=regions[2].id;

            this.form.provinceName=regions[0].name;
            this.form.cityName=regions[1].name;
            this.form.areaName=regions[2].name;
          },
            queryUrlList(){
                this.companyIdList = [];
                urlList("/pdworld/pdExpressageCompany/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.companyIdList.push({ code: item.id, name: item.expressageCompany });
                      });
                    }
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryUrlList();
                //修改
                if (id) {
                    pdexpressagebranchInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                              this.region.push(this.form.province);
                              this.region.push(this.form.city);
                              this.region.push(this.form.area);
                              console.log(this.$refs.fortressRegion)
                              this.$refs.fortressRegion.init(this.region);
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                else{
                  this.$nextTick(() => {
                    this.$refs.fortressRegion.init([]);
                  });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        pdexpressagebranchEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        pdexpressagebranchSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
              this.region=[]
                this.form = {
                    companyId:"",
                    branchName:"",
                    address:"",
                    phone:"",
                    branchImg:"",
                    remark:"",
                    province:"",
                  sort:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
