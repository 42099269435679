<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">商品id:</label>
                    <div class="form-item-content">{{ objInfo.goodsId }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">规格设置（存规格的id 会有多个）:</label>
                    <div class="form-item-content">{{ objInfo.specs }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">名称:</label>
                    <div class="form-item-content">{{ objInfo.name }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商品原价:</label>
                    <div class="form-item-content">{{ objInfo.productPrice }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商品现价:</label>
                    <div class="form-item-content">{{ objInfo.marketPrice }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商品成本:</label>
                    <div class="form-item-content">{{ objInfo.costPrice }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商品库存 -1 不限库存:</label>
                    <div class="form-item-content">{{ objInfo.stock }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商品重量:</label>
                    <div class="form-item-content">{{ objInfo.weight }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">排序:</label>
                    <div class="form-item-content">{{ objInfo.sort }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商品编码:</label>
                    <div class="form-item-content">{{ objInfo.goodsSn }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">商品条码:</label>
                    <div class="form-item-content">{{ objInfo.productSn }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">备注:</label>
                    <div class="form-item-content">{{ objInfo.remark }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建者:</label>
                    <div class="form-item-content">{{ objInfo.createUserName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">更新者:</label>
                    <div class="form-item-content">{{ objInfo.updateUser }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">更新时间:</label>
                    <div class="form-item-content">{{ objInfo.updateTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">状态:</label>
                    <div class="form-item-content">{{ objInfo.status }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">删除状态 YesNoEnum。 yes删除；no未删除:</label>
                    <div class="form-item-content">{{ objInfo.isDeleted }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { goodsoptionInfo } from "@/api/goods/goodsoption.js";
    
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
            }
        },
        created() {
        },
        methods: {
            init(id) {
                if (id) {
                   goodsoptionInfo(id).then(res => {
                        this.objInfo = res.data
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
