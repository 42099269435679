<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent @keyup.enter.native="queryList">
        <el-form-item>
          <el-input prefix-icon="el-icon-search" v-model="queryForm.consigner" autocomplete="off" placeholder="请输入发货省"
                    clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-input prefix-icon="el-icon-search" v-model="queryForm.consignerCity" autocomplete="off"
                    placeholder="请输入发货市" clearable></el-input>
        </el-form-item>

        <el-form-item v-show="collapse.flag">
          <el-input prefix-icon="el-icon-search" v-model="queryForm.province" autocomplete="off" placeholder="请输入收货省"
                    clearable></el-input>
        </el-form-item>

        <el-form-item v-show="collapse.flag">
          <el-input prefix-icon="el-icon-search" v-model="queryForm.provinceCity" autocomplete="off"
                    placeholder="请输入收货市" clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="medium" icon="el-icon-search" @click="queryList({current:1})">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="handleCollapseState"
          >{{ collapse.label }}<i :class="collapse.icon"></i
          ></el-button>
        </el-form-item>
      </el-form>
      <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          v-if="$tools.hasPermission('pdworld:routeAdvertising:save')"
          @click="handleEdit()"
      >添加
      </el-button
      >
      <el-button
          v-if="$tools.hasPermission('pdworld:routeAdvertising:deleteBatch')"
          size="medium"
          type="danger"
          icon="el-icon-delete"
          @click="handleDeleteBatch()"
      >删除
      </el-button
      >
      <div style="float: right; padding-right: 17px">
        <i
            class="el-icon-download"
            style="margin-right: 10px; cursor: pointer"
            @click="handleExport"
        ></i>
        <i
            class="el-icon-refresh"
            style="margin-right: 10px; cursor: pointer"
            @click="refresh"
        ></i>

        <el-popover placement="bottom" width="180" trigger="click" title="列展示" popper-class="columnShows">
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
                v-for="item in tableList"
                :label="item.propName"
                :key="item.propName"
                :value="item.propName"
            >{{ item.propDesc }}
            </el-checkbox
            >
          </el-checkbox-group>
          <i
              slot="reference"
              class="el-icon-s-operation"
              style="cursor: pointer"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
    >
      <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>

      <el-table-column
          v-if="$tools.isShow('consignerName', tableShowList)"
          prop="consignerName"
          label="发货省"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          v-if="$tools.isShow('consignerCityName', tableShowList)"
          prop="consignerCityName"
          label="发货市"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          v-if="$tools.isShow('provinceName', tableShowList)"
          prop="provinceName"
          label="收货省"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          v-if="$tools.isShow('provinceCityName', tableShowList)"
          prop="provinceCityName"
          label="收货市"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          v-if="$tools.isShow('remark', tableShowList)"
          prop="remark"
          label="备注"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          v-if="$tools.isShow('path', tableShowList)"
          prop="path"
          label="跳转url"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          v-if="$tools.isShow('imgUrl', tableShowList)"
          prop="imgUrl"
          label="图片url"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
              v-if="scope.row.imgUrl"
              :src="scope.row.imgUrl"
              style="width: 100px; height: 100px"
              :preview-src-list="[scope.row.imgUrl]"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" min-width="180">
        <template slot-scope="scope">
          <!--                    <el-button-->
          <!--                            v-if="$tools.hasPermission('pdworld:routeAdvertising:info')"-->
          <!--                            size="mini"-->
          <!--                            plain-->
          <!--                            @click="handleInfo(scope.row.id)"-->
          <!--                    >详情</el-button-->
          <!--                    >-->
          <el-button
              v-if="$tools.hasPermission('pdworld:routeAdvertising:edit')"
              size="mini"
              plain
              @click="handleEdit(scope.row.id)"
          >编辑
          </el-button
          >
          <el-button
              v-if="$tools.hasPermission('pdworld:routeAdvertising:delete') && scope.row.id!=1"
              size="mini"
              plain
              type="danger"
              @click="handleDelete(scope.row)"
          >删除
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="page.total" :current-page="page.current" :page-size="page.size"
                @refreshData="queryList"></pagination>
    <!-- 新增、编辑 -->
    <form-edit
        v-if="formEditVisible"
        ref="formEdit"
        @refreshData="queryList"
    ></form-edit>
    <!-- 详情 -->
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
  </div>
</template>
<script>
import {
  routeadvertisingPage,
  routeadvertisingDel,
  routeadvertisingDelBatch,
  routeadvertisingDynamicExport,
} from "@/api/pdworld/routeadvertising.js";
import {viewTableList} from "@/api/public.api.js";
import FormEdit from "./routeadvertising-edit";
import FormInfo from "./routeadvertising-info";
import FortressPagination from "@/components/fortress-pagination.vue";

export default {
  data() {
    return {
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      queryForm: {
        consigner: "",
        consignerCity: "",
        province: "",
        provinceCity: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    pagination: FortressPagination,
  },
  created() {
    this.initTableList();
    this.queryList();
  },
  methods: {
    refresh() {
      this.tableData = [];
      this.queryList({current: 1});
    },
    //查询表格数据
    queryList(page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      routeadvertisingPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu = this.page.total % this.page.size == 0 ? parseInt(this.page.total / this.page.size) : parseInt((this.page.total / this.page.size) + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({current: cu});
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm('确认删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        routeadvertisingDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
                (this.page.total - 1) % this.page.size == 0
                    ? (this.page.total - 1) / this.page.size
                    : (this.page.total - 1) / this.page.size + 1;
            if (this.page.current > totalPages && this.page.current != 1) {
              this.page.current = this.page.current - 1;
            }
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }).catch(() => {
      });
    },
    //批量删除
    handleDeleteBatch() {
      let checkedRow = this.$refs.multipleTable.selection;
      if (checkedRow.length > 0) {
        let ids = [];
        checkedRow.forEach((item) => {
          ids.push(item.id);
        });
        this.$confirm("确认删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              routeadvertisingDelBatch(ids).then((resp) => {
                if (resp.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  //判断是否最后一页的最后一条记录
                  let totalPages =
                      (this.page.total - checkedRow.length) % this.page.size == 0
                          ? (this.page.total - checkedRow.length) / this.page.size
                          : (this.page.total - checkedRow.length) / this.page.size +
                          1;
                  if (this.page.current > totalPages && this.page.current != 1) {
                    this.page.current = this.page.current - 1;
                  }
                  this.queryList();
                } else {
                  this.$msg({
                    message: resp.msg,
                    type: "error",
                  });
                }
              });
            })
            .catch(() => {
            });
      } else {
        this.$msg({
          message: "请选择数据进行删除",
          type: "error",
        });
      }
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        consigner: "",
        consignerCity: "",
        province: "",
        provinceCity: "",
      };
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList() {
      viewTableList("pd_route_advertising").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
    //可见字段excel导出
    handleExport() {
      if (!this.tableShowList || this.tableShowList.length < 1) {
        this.$msg({
          message: "请选择要导出的列",
          type: "error",
        });
        return;
      }
      let colNum = 1;
      let headerList = [];
      this.tableShowList.forEach((item) => {
        let nameData = this.tableList.find((item2) => {
          return item2.propName == item;
        });
        let name = "";
        if (nameData && nameData.propDesc) {
          name = nameData.propDesc;
        } else {
          name = "未定义字段" + colNum++;
        }
        headerList.push({name: name, code: item});
      });
      routeadvertisingDynamicExport({headerList: headerList, dto: this.queryForm});
    },
  },
};
</script>
<style scoped>
</style>
