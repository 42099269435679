<template>
  <el-dialog title="充值会员" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="充值时长(月)" :label-width="formLabelWidth" prop="year">
        <el-input-number
            v-model="form.year"
            :min="1"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  memberRecharge,
} from "@/api/pdworld/pdmember.js";
import * as ftVal from "@/common/fortressValidator.js";

export default {
  data() {
    return {
      visible: false,
      form: {
        year: 0,
        id: "",
      },
      formLabelWidth: "120px",
      rules: {
        year: [
          {required: true, message: "请输入充值时长", trigger: "blur"},
          {validator: ftVal.validatePInteger, trigger: "blur"},
        ],
      },
    };
  },
  created() {
  },
  methods: {
    init(id) {
      this.resetForm();
      this.form.id = id;
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          memberRecharge(this.form).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.$emit("refreshData");
              this.visible = false;
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        }
      });
    },
    resetForm() {
      this.form = {
        year: 0,
        id: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
