<!--  -->
<template>
  <div v-if="sonShow">
    <div
      class="line"
      v-for="(item,index) in allData"
      :key="item.id"
      v-show="(item.children&&item.children.length)||item.type==1||item.type==2"
    >
      <div class="t">
        <el-checkbox
          :key="index"
          :indeterminate="item.isIndeterminate"
          v-model="item.checkAll"
          @change="handleCheckAllChange(index,item,$event)"
        >
          <span v-if="item.type==1">{{item.name}}(菜单)</span>
          <span v-if="item.type==2">{{item.name}}(内页)</span>
        </el-checkbox>
      </div>
      <div class="tt">
        <my-item :data="item.children" :all="all"></my-item>
      </div>
    </div>
    <div class="other">
      <el-checkbox
        @change="handleCheckedCountryChange(items,$event)"
        class="anniu"
        v-for="(items,indexs) in allData"
        :key="items.id"
        :label="items.id"
        v-model="items.checkAll"
        v-show="!items.children&&(items.type&&items.type==3)"
      >{{items.name}}(按钮)</el-checkbox>
    </div>
  </div>
</template>
<script>
export default {
  name: "my-item",
  props: {
    all: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },

  },
  data () {
    return {
      sonShow: false,
      allData: [],
      reconstitutionData: []
    };
  },
  created () {
    this.sonShow = true
    this.allData = this.data
    if (this.all.length > 0) {
      this.reconstitutionDataHandle(this.all, this.reconstitutionData)
    }

  },
  beforeDestroy () {
    this.sonShow = false
  },
  components: {},

  computed: {},

  methods: {
    handleCheckAllChange (index, item, e) { //二级操作
      this.$set(item, 'checkAll', e)
      // if (e == false) {
      this.$set(item, 'isIndeterminate', false)
      // }
      this.sonSelectLoop(item, e)
      this.getIsCheckAll(item, e)
    },
    sonSelectLoop (data, status) {
      if (data.children) {
        data.children.forEach(items => {
          this.$set(items, 'checkAll', status)
          this.sonSelectLoop(items, status)
        });
      }
    },
    getIsCheckAll (data, e) {
      this.reconstitutionData.forEach(item => {
        if (item.id == data.parentId) {
          var tickCount = 0, unTickCount = 0, ArrLength = item.children.length
          for (var j = 0; j < ArrLength; j++) {//全选checkbox状态
            if (item.children[j].checkAll == true && (item.children[j].isIndeterminate == false || item.children[j].isIndeterminate == null)) tickCount++
            if (item.children[j].checkAll == false && (item.children[j].isIndeterminate == false || item.children[j].isIndeterminate == null)) unTickCount++
          }
          if (tickCount == ArrLength) {//二级全勾选
            this.$set(item, 'checkAll', true)
            this.$set(item, 'isIndeterminate', false)
          } else if (unTickCount == ArrLength) {//二级全不勾选
            this.$set(item, 'checkAll', false)
            this.$set(item, 'isIndeterminate', false)
          } else {
            this.$set(item, 'checkAll', false)
            this.$set(item, 'isIndeterminate', true) //添加一级不确定状态
          }
          this.getIsCheckAll(item, e)
        }

      })

    },
    handleCheckedCountryChange (data, e) {//三级操作
      this.reconstitutionData.forEach(item => {
        if (item.id == data.parentId) {
          //父级数据
          var tickCount = 0, unTickCount = 0, len = item.children.length
          item.children.forEach(items => {
            if (items.id == data.id) {
              this.$set(item, 'checkAll', e)
            }
            if (items.checkAll == true && (items.isIndeterminate == false || items.isIndeterminate == null)) tickCount++
            if (items.checkAll == false && (items.isIndeterminate == false || items.isIndeterminate == null)) unTickCount++
          })
          if (tickCount == len) {//三级级全勾选
            this.$set(item, 'checkAll', true)
            this.$set(item, 'isIndeterminate', false)
          } else if (unTickCount == len) {//三级级全不勾选
            this.$set(item, 'checkAll', false)
            this.$set(item, 'isIndeterminate', false)
          } else {
            this.$set(item, 'checkAll', false)
            this.$set(item, 'isIndeterminate', true)
          }
          this.getIsCheckAll(item, e)
        }

      })

    },
    //重构数据
    reconstitutionDataHandle (data, dd) {
      let children = [];
      for (var i in data) {
        dd.push(data[i]);
        if (data[i].children) {
          for (var j in data[i].children) {
            children.push(data[i].children[j]);
          }
        }
      }
      if (children.length > 0) {
        this.reconstitutionDataHandle(children, dd);
      }
    },

  }
}

</script>
<style lang='scss' scoped>
.line {
  padding-left: 20px;
  .other {
    padding-left: 20px;
  }
}
</style>