<template>
	<div class='padding20'>
		<div class="title">{{$route.query.name}}</div>
		<el-tabs tab-position="left" >
			<el-tab-pane label="账户信息" v-if="$tools.hasPermission('account:memberAccount:pageList')">
        <div class="right">
          <a-memberaccount :memberId="$route.query.id"></a-memberaccount>
        </div>
      </el-tab-pane>
      <el-tab-pane label="充值订单" v-if="$tools.hasPermission('account:memberAccountRechargeOrder:pageList')">
        <div class="right">
          <a-memberaccountrechargeorder :memberId="$route.query.id"></a-memberaccountrechargeorder>
        </div>
      </el-tab-pane>
      <el-tab-pane label="账户流水" v-if="$tools.hasPermission('account:memberAccountSerial:pageList')">
        <div class="right">
          <a-memberaccountserial :memberId="$route.query.id"></a-memberaccountserial>
        </div>
      </el-tab-pane>
      <el-tab-pane label="诚保金流水" v-if="$tools.hasPermission('account:memberAccountIntegritySerial:pageList')">
        <div class="right">
          <a-memberaccountintegrityserial :memberId="$route.query.id"></a-memberaccountintegrityserial>
        </div>
      </el-tab-pane>
      <el-tab-pane label="积分流水" v-if="$tools.hasPermission('account:memberAccountScoreSerial:pageList')">
        <div class="right">
          <a-memberaccountscoreserial :memberId="$route.query.id"></a-memberaccountscoreserial>
        </div>
      </el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import aMemberaccountintegrityserial from "../account/memberaccountintegrityserial.vue";
import aMemberaccountrechargeorder from "../account/memberaccountrechargeorder.vue";
import aMemberaccountserial from "../account/memberaccountserial.vue";
import aMemberaccountscoreserial from "../account/memberaccountscoreserial.vue";
import aMemberaccount from "../account/memberaccount.vue";

export default {
	name: 'memberInfo',
	props: {},
	data() {
		return {
			current: 0
		}
	},
	computed: {},
	methods: {},
	components: {
		aMemberaccountintegrityserial,
		aMemberaccountrechargeorder,
		aMemberaccountserial,
		aMemberaccountscoreserial,
		aMemberaccount
	}
}
</script>

<style lang='scss' scoped>
.padding20{
	.title{
		font-size: 26px;
		font-weight: 700;
		padding-bottom: 30px;
		letter-spacing: 2px;
	}
	.right{
		width: 100%;
		max-height: 800px;
		overflow: auto;
	}
}
</style>
