import request from "@/common/request"

//商家基础信息分页展示
export const pdshopinfoPage = (queryForm) => {
        return request("get", "/pdworld/pdShopinfo/page", queryForm);
    }
    //商家基础信息列表展示
export const pdshopinfoList = (queryForm) => {
        return request("get", "/pdworld/pdShopinfo/list", queryForm);
    }
    //商家基础信息详情
export const pdshopinfoInfo = (id) => {
    return request('get', '/pdworld/pdShopinfo/info', {
        id: id
    })
}
export const pdshopotherInfo = (id) => {
        return request('get', '/pdworld/pdShopinfo/infoByMemberId', {
            id: id
        })
    }
    //商家基础信息信息保存
export const pdshopinfoSave = (data) => {
        return request('post', '/pdworld/pdShopinfo/save', data)
    }
    //商家基础信息信息修改
export const pdshopinfoEdit = (data) => {
        return request('post', '/pdworld/pdShopinfo/edit', data)
    }
    //商家基础信息信息删除
export const pdshopinfoDel = (id) => {
        return request('post', '/pdworld/pdShopinfo/delete?id=' + id)
    }
    //商家基础信息批量删除
export const pdshopinfoDelBatch = (ids) => {
        return request('post', '/pdworld/pdShopinfo/deleteBatch', ids)
    }
    //商家基础信息动态表头导出
export const pdshopinfoDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdShopinfo/exportDynamic', data)
}