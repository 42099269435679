<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" append-to-body :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="物流圈" :label-width="formLabelWidth" prop="logisticsDynamicsId">
        <fortress-select
            v-model="form.logisticsDynamicsId"
            :dictList="logisticsDynamicsIdList"
            placeholder="请选择物流圈"
            clearable
        ></fortress-select>
      </el-form-item>
      <el-form-item label="发布人" :label-width="formLabelWidth" prop="publisherId">
        <fortress-select
            v-model="form.publisherId"
            :dictList="publisherIdList"
            placeholder="请选择发布人"
            clearable
        ></fortress-select>
      </el-form-item>
      <el-form-item label="评论内容
" :label-width="formLabelWidth" prop="content">
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入评论内容
"
            v-model="form.content"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  logisticsdynamicscommentInfo,
  logisticsdynamicscommentSave,
  logisticsdynamicscommentEdit
} from "@/api/pdworld/logisticsdynamicscomment.js";
import {urlList,} from "@/api/public.api.js";

export default {
  data() {
    return {
      visible: false,
      form: {
        logisticsDynamicsId: "",
        publisherId: "",
        content: "",
      },
      formLabelWidth: "120px",
      rules: {
        logisticsDynamicsId: [
          {required: true, message: "请输入物流圈", trigger: "blur"},
        ],
        publisherId: [
          {required: true, message: "请输入发布人", trigger: "blur"},
        ],
        content: [
          {required: true, message: "请输入评论内容", trigger: "blur"},
        ],
      },
      logisticsDynamicsIdList: [],
      publisherIdList: [],
    };
  },
  created() {
  },
  methods: {
    queryUrlList() {
      this.logisticsDynamicsIdList = [];
      urlList("/pdworld/logisticsDynamics/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.logisticsDynamicsIdList.push({code: item.id, name: item.id});
            });
          }
        }
      });
      this.publisherIdList = [];
      urlList("/pdworld/pdMember/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.publisherIdList.push({code: item.id, name: item.userName});
            });
          }
        }
      });
    },
    init(id) {
      this.resetForm();
      this.queryUrlList();
      //修改
      if (id) {
        logisticsdynamicscommentInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            logisticsdynamicscommentEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            logisticsdynamicscommentSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        logisticsDynamicsId: "",
        publisherId: "",
        content: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
