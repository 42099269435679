import request from "@/common/request"

//帮助中心分页展示
export const helparticlePage = (queryForm) => {
    return request("get", "/helparticle/helpArticle/page", queryForm);
}
//帮助中心列表展示
export const helparticleList = (queryForm) => {
    return request("get", "/helparticle/helpArticle/list", queryForm);
}
//帮助中心详情
export const helparticleInfo = (id) => {
    return request('get', '/helparticle/helpArticle/info', {
        id: id
    })
}
//帮助中心信息保存
export const helparticleSave = (data) => {
    return request('post', '/helparticle/helpArticle/save', data)
}
//帮助中心信息修改
export const helparticleEdit = (data) => {
    return request('post', '/helparticle/helpArticle/edit', data)
}
//帮助中心信息删除
export const helparticleDel = (id) => {
    return request('post', '/helparticle/helpArticle/delete?id=' + id)
}
//帮助中心批量删除
export const helparticleDelBatch = (ids) => {
    return request('post', '/helparticle/helpArticle/deleteBatch', ids)
}
//帮助中心动态表头导出
export const helparticleDynamicExport = (data) => {
    return request("eptpost", '/helparticle/helpArticle/exportDynamic', data)
}
