import request from "@/common/request"

//会员等级设置分页展示
export const memberlevelPage = (queryForm) => {
    return request("get", "/level/memberLevel/page", queryForm);
}
//会员等级设置列表展示
export const memberlevelList = (queryForm) => {
    return request("get", "/level/memberLevel/list", queryForm);
}
//会员等级设置详情
export const memberlevelInfo = (id) => {
    return request('get', '/level/memberLevel/info', {
        id: id
    })
}
//会员等级设置信息保存
export const memberlevelSave = (data) => {
    return request('post', '/level/memberLevel/save', data)
}
//会员等级设置信息修改
export const memberlevelEdit = (data) => {
    return request('post', '/level/memberLevel/edit', data)
}
//会员等级设置信息删除
export const memberlevelDel = (id) => {
    return request('post', '/level/memberLevel/delete?id=' + id)
}
//会员等级设置批量删除
export const memberlevelDelBatch = (ids) => {
    return request('post', '/level/memberLevel/deleteBatch', ids)
}
//会员等级设置动态表头导出
export const memberlevelDynamicExport = (data) => {
    return request("eptpost", '/level/memberLevel/exportDynamic', data)
}
