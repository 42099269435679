import { render, staticRenderFns } from "./sysuser-detail-edit.vue?vue&type=template&id=0cfe426a&scoped=true&"
import script from "./sysuser-detail-edit.vue?vue&type=script&lang=js&"
export * from "./sysuser-detail-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cfe426a",
  null
  
)

export default component.exports