<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        v-if="$tools.hasPermission('system:sysRegion:save')"
        @click="handleEdit()"
      >添加</el-button>
      <div style="float: right; padding-right: 17px">
        <i class="el-icon-refresh" style="margin-right: 10px; cursor: pointer" @click="refresh"></i>

        <el-popover
          placement="bottom"
          width="180"
          trigger="click"
          title="列展示"
          popper-class="columnShows"
        >
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
              v-for="item in tableList"
              :label="item.propName"
              :key="item.propName"
              :value="item.propName"
            >{{ item.propDesc }}</el-checkbox>
          </el-checkbox-group>
          <i slot="reference" class="el-icon-s-operation" style="cursor: pointer"></i>
        </el-popover>
      </div>
    </el-row>
    <!-- loading -->
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          v-if="$tools.isShow('name', tableShowList)"
          prop="name"
          label="区域名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('id', tableShowList)"
          prop="id"
          label="区域标识"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('treeLevel', tableShowList)"
          prop="treeLevel"
          label="区域类型"
          :show-overflow-tooltip="true"
          :formatter="treeLevelFmt"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('sort', tableShowList)"
          prop="sort"
          label="排序"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('updateTime', tableShowList)"
          prop="updateTime"
          label="更新时间"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="100">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('system:sysRegion:edit')"
              size="mini"
              plain
              @click="handleEdit(scope.row.id)"
            >编辑</el-button>
            <el-button
              v-if="$tools.hasPermission('system:sysRegion:delete')"
              size="mini"
              plain
              type="danger"
              @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <!-- 新增、编辑 -->
    <form-edit v-if="formEditVisible" ref="formEdit" @refreshData="queryList"></form-edit>
  </div>
</template>
<script>
import { sysregionPage, sysregionDel } from "@/api/system/sysregion.js";
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./sysregion-edit";
export default {
  data () {
    return {
      parentShow: '',

      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
      queryForm: {
        parentId: 0,
      },
    };
  },
  components: {
    "form-edit": FormEdit,
  },
  activated () {
    this.tableData = []
    this.queryList()
  },
  created () {
    this.queryForm.parentId = 0;
    this.queryList(this.queryForm);
    this.initTableList();
  },
  methods: {
    refresh () {
      this.tableData = []
      this.queryList()
    },
    //查询表格数据
    queryList (queryForm) {
      this.parentShow = true
      this.tableData = [];
      if (!queryForm) {
        queryForm = {};
        queryForm.parentId = 0;
      }
      sysregionPage(queryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false
            this.tableData = resp.data;
          }, 600)
        } else {
          this.parentShow = false
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit (id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo (id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //删除
    handleDelete (row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sysregionDel(row.id).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => { });
    },

    //切换查询条件收缩框
    handleCollapseState () {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList () {
      viewTableList("castle_sys_region").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
    treeLevelFmt (row) {
      let typeName = "未启用";
      if (row.treeLevel == 1) {
        typeName = "省份直辖市";
      } else if (row.treeLevel == 2) {
        typeName = "地市";
      } else if (row.treeLevel == 3) {
        typeName = "区县";
      }
      return typeName;
    },
    //懒加载数据
    load (tree, treeNode, resolve) {
      this.queryForm.parentId = tree.id;
      sysregionPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          resolve(resp.data);
        }
      });
    },
  },
};
</script>
<style scoped>
</style>