import request from "@/common/request"

//全局资讯分类表分页展示
export const pdarticletypePage = (queryForm) => {
    return request("get", "/pdworld/pdArticleType/page", queryForm);
}
//全局资讯分类表列表展示
export const pdarticletypeList = (queryForm) => {
    return request("get", "/pdworld/pdArticleType/list", queryForm);
}
//全局资讯分类表详情
export const pdarticletypeInfo = (id) => {
    return request('get', '/pdworld/pdArticleType/info', {
        id: id
    })
}
//全局资讯分类表信息保存
export const pdarticletypeSave = (data) => {
    return request('post', '/pdworld/pdArticleType/save', data)
}
//全局资讯分类表信息修改
export const pdarticletypeEdit = (data) => {
    return request('post', '/pdworld/pdArticleType/edit', data)
}
//全局资讯分类表信息删除
export const pdarticletypeDel = (id) => {
    return request('post', '/pdworld/pdArticleType/delete?id=' + id)
}
//全局资讯分类表批量删除
export const pdarticletypeDelBatch = (ids) => {
    return request('post', '/pdworld/pdArticleType/deleteBatch', ids)
}
//全局资讯分类表动态表头导出
export const pdarticletypeDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdArticleType/exportDynamic', data)
}
