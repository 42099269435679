<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="上级区域" :label-width="formLabelWidth" prop="parentId">
        <el-input placeholder="选择区域" v-model="form.parentName" readonly @focus="handleRegion">
          <el-button slot="append" icon="el-icon-search" @click="handleRegion"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="区域名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off" placeholder="请输入区域名称"></el-input>
      </el-form-item>
      <el-form-item label="区域标识" :label-width="formLabelWidth" prop="id">
        <el-input
          v-model.number="form.id"
          type="number"
          readonly
          autocomplete="off"
          placeholder="请输入区域标识"
          v-if="editFlag"
        ></el-input>
        <el-input
          v-model.number="form.id"
          type="number"
          autocomplete="off"
          placeholder="请输入区域标识"
          v-if="!editFlag"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
        <el-input-number v-model="form.sort" :min="1" label="排序"></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
    <el-dialog
      title="选择区域"
      :visible.sync="parentIdVisible"
      :close-on-click-modal="false"
      append-to-body
      custom-class="regionDialog"
      width="50%"
    >
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px:border:none"
        row-key="id"
        :show-header="false"
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :max-height="600"
        highlight-current-row
        @current-change="handleCurrentChange"
      >
        <el-table-column prop="name" label="区域名称" :show-overflow-tooltip="true"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="parentIdVisible = false" size="medium" type="danger">取 消</el-button>
        <el-button type="primary" size="medium" @click="submitParentId">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import {
  sysregionInfo,
  sysregionSave,
  sysregionEdit,
  sysregionPage,
} from "@/api/system/sysregion.js";

export default {
  data () {
    return {
      visible: false,
      parentIdVisible: false,
      editFlag: false,
      form: {
        id: "",
        parentId: "",
        parentName: "",
        name: "",
        treeLevel: "",
        leaf: "",
        sort: "",
        createUser: "",
        createTime: "",
        updateUser: "",
        updateTime: "",
      },
      formLabelWidth: "120px",
      rules: {
        name: [{ required: true, message: "请输入区域名称", trigger: "blur" }],
        id: [{ required: true, message: "请输入区域标识", trigger: "blur" }],
        sort: [{ required: true, message: "输入排序", trigger: "blur" }],
      },
      tableData: [],
      props: {
        label: "name",
        isLeaf: "isLeaf",
      },
      chooseParent: {
        id: "",
        name: "",
      },
    };
  },
  created () {
    sysregionPage({ parentId: 0 }).then((resp) => {
      if (resp.code == 0) {
        this.tableData = resp.data;
      } else {
        this.$msg({
          message: resp.msg,
          type: "error",
        });
      }
    });
  },
  methods: {
    init (id) {
      this.resetForm();
      //修改
      if (id) {
        this.editFlag = true;
        sysregionInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              this.chooseParent.id = this.form.parentId;
              this.chooseParent.name = this.form.parentName;
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      } else {
        this.editFlag = false;
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.visible = false;
          //修改
          if (this.editFlag) {
            sysregionEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            sysregionSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm () {
      this.form = {
        id: "",
        parentId: "",
        parentName: "",
        name: "",
        treeLevel: "",
        leaf: "",
        sort: "",
        createUser: "",
        createTime: "",
        updateUser: "",
        updateTime: "",
      };
      this.chooseParent = {};
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
    //选择上级区域
    handleRegion () {
      this.parentIdVisible = true;
    },
    //懒加载数据
    load (tree, treeNode, resolve) {
      sysregionPage({ parentId: tree.id }).then((resp) => {
        if (resp.code == 0) {
          resolve(resp.data);
        }
      });
    },
    //切换父节点
    handleCurrentChange (data) {
      this.chooseParent = data;
    },
    //选中父节点
    submitParentId () {
      this.form.parentId = this.chooseParent.id;
      this.form.parentName = this.chooseParent.name;
      this.parentIdVisible = false;
    },
  },
};
</script>
<style lang='scss'>
.regionDialog {
  height: 500px;
}
.regionDialog .el-dialog__body {
  height: 380px;
  overflow: hidden;
}
.regionDialog .el-table--scrollable-y .el-table__body-wrapper {
  max-height: 340px !important;
}
.regionDialog .el-table td {
  border-bottom: none;
}
</style>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>