import request from "@/common/request"

//会员积分流水分页展示
export const memberaccountscoreserialPage = (queryForm) => {
    return request("get", "/account/memberAccountScoreSerial/page", queryForm);
}
//会员积分流水列表展示
export const memberaccountscoreserialList = (queryForm) => {
    return request("get", "/account/memberAccountScoreSerial/list", queryForm);
}
//会员积分流水详情
export const memberaccountscoreserialInfo = (id) => {
    return request('get', '/account/memberAccountScoreSerial/info', {
        id: id
    })
}
//会员积分流水信息保存
export const memberaccountscoreserialSave = (data) => {
    return request('post', '/account/memberAccountScoreSerial/save', data)
}
//会员积分流水信息修改
export const memberaccountscoreserialEdit = (data) => {
    return request('post', '/account/memberAccountScoreSerial/edit', data)
}
//会员积分流水信息删除
export const memberaccountscoreserialDel = (id) => {
    return request('post', '/account/memberAccountScoreSerial/delete?id=' + id)
}
//会员积分流水批量删除
export const memberaccountscoreserialDelBatch = (ids) => {
    return request('post', '/account/memberAccountScoreSerial/deleteBatch', ids)
}
//会员积分流水动态表头导出
export const memberaccountscoreserialDynamicExport = (data) => {
    return request("eptpost", '/account/memberAccountScoreSerial/exportDynamic', data)
}
