import request from "@/common/request"

//预约/订单表分页展示
export const pdorderPage = (queryForm) => {
        return request("get", "/pdworld/pdOrder/page", queryForm);
    }
    //预约/订单表列表展示
export const pdorderList = (queryForm) => {
        return request("get", "/pdworld/pdOrder/list", queryForm);
    }
    //预约/订单表详情
export const pdorderInfo = (id) => {
        return request('get', '/pdworld/pdOrder/info', {
            id: id
        })
    }
    //预约/订单表信息保存
export const pdorderSave = (data) => {
        return request('post', '/pdworld/pdOrder/save', data)
    }
    //预约/订单表信息修改
export const pdorderEdit = (data) => {
        return request('post', '/pdworld/pdOrder/edit', data)
    }
    //预约/订单表信息删除
export const pdorderDel = (id) => {
        return request('post', '/pdworld/pdOrder/delete?id=' + id)
    }
    //预约/订单表批量删除
export const pdorderDelBatch = (ids) => {
        return request('post', '/pdworld/pdOrder/deleteBatch', ids)
    }
    //预约/订单表动态表头导出
export const pdorderDynamicExport = (data) => {
        return request("eptpost", '/pdworld/pdOrder/exportDynamic', data)
    }
    //受理
export const orderAccept = (id) => {
    return request("post", 'api/pdworld/pdOrder/accept?id=' + id)
}