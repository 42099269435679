<!-- APP -->
<template>
	<div class="APP">
		<el-form label-width="200px" ref="form" :model="formData" :rules="rules">
			<el-form-item label="App应用ID" prop="appId">
				<el-input placeholder="请输入内容" v-model="formData.appId" clearable>
					<template slot="append">
						<el-tooltip effect="dark">
							<div slot="content">
								这里填写
								<a href="https://open.weixin.qq.com/" target="_blank">微信开放平台</a>
								申请的移动应用appid
							</div>
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="支付商户号(Mch_ld)" prop="mchId">
				<el-input placeholder="请输入内容" v-model="formData.mchId" clearable>
					<template slot="append">
						<el-tooltip effect="dark" content="Top Left 提示文字">
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="商户证书序列号" prop="mchSerialNo">
				<el-input placeholder="请输入内容" v-model="formData.mchSerialNo" clearable>
					<template slot="append">
						<el-tooltip effect="dark">
							<div slot="content">
								<a href="https://pay.weixin.qq.com/wiki/doc/apiv3/wechatpay/wechatpay7_0.shtml#part-5" target="_blank">
									查看证书序列号
								</a>
							</div>
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="V3密钥" prop="apiV3Key">
				<el-input placeholder="请输入内容" v-model="formData.apiV3Key" clearable>
					<template slot="append">
						<el-tooltip effect="dark">
							<div slot="content">
								自己设置的
								<a href="https://pay.weixin.qq.com/index.php/core/cert/api_cert#/" target="_blank">32位的秘钥</a>
								，如忘记需重置
							</div>
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item :inline="false" label="商户API私钥地址" prop="keyPath">
				<template #label>
					商户API私钥地址
					<el-tooltip effect="dark">
						<div slot="content">
							上传apiclient_key.pem路径
						</div>
						<i class="el-icon-info"></i>
					</el-tooltip>
				</template>
				<fortress-upload type="file" name="keyPath" :limit="1" :formData="formData"></fortress-upload>
			</el-form-item>
			<el-form-item label="异步通知回调地址" prop="notifyUrl">
				<el-input placeholder="请输入内容" v-model="formData.notifyUrl" clearable>
					<template slot="append">
						<el-tooltip effect="dark" content="支付结果通知地址,必须是https开头的可访问地址">
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submit">提交</el-button>
				<el-button type="info" @click="cancel">取消</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import request from "@/common/request"
export default {
	name: "APP",
	props: {
		data: {},
	},
	data() {
		return {
			formData: {
				apiV3Key: "",
				appId: "",
				appSecret: null,
				id: "",
				keyPath: [],
				mchId: "",
				mchKey: "",
				mchSerialNo: null,
				miniAppId: null,
				notifyUrl: null,
				payTypeCode: "APP",
				payTypeName: "微信APP支付",
				returnUrl: null,
				sceneCode: "",
				status: 1,
			},
			rules: {
				appId: [{ required: true, message: "请输入APPID", trigger: "blur" }],
				mchId: [{ required: true, message: "请输入支付商户号", trigger: "blur" }],
				mchSerialNo: [{ required: true, message: "请输入商户证书序列号", trigger: "blur" }],
				apiV3Key: [{ required: true, message: "请输入V3密钥", trigger: "blur" }],
				keyPath: [{ required: true, message: "请输入商户API私钥地址", trigger: "blur" }],
				notifyUrl: [{ required: true, message: "请输入回调地址-", trigger: "blur" }],
			},
		}
	},
	computed: {},
	methods: {
		submit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return
				if (this.formData.keyPath) {
					let filesJson = []
					this.formData.keyPath.forEach(item => {
						filesJson.push({ name: item.name, url: item.url })
					})
					this.formData.keyPath = JSON.stringify(filesJson)
				}

				const { code, msg } = await request("post", "/pay/payWxConfig/edit", this.formData)
				if (code == 0) {
					this.$message.success("提交成功")
					this.$router.back()
				} else {
					this.$message.error(msg)
				}
			})
		},
		cancel() {
			this.$router.back()
		},
	},
	watch: {
		data: {
			handler(val) {
				this.formData = val || {}
			},
			deep: true,
			immediate: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.APP {
}
</style>
