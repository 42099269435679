<template>
    <div>
        <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
            <div class="info-item">
                <h5 class="from-title">基本信息</h5>
                <div class="form-item">
                    <label class="form-item-label">会员ID:</label>
                    <div class="form-item-content">{{ objInfo.memberId }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">流水积分:</label>
                    <div class="form-item-content">{{ objInfo.score }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">流水收支类型:</label>
                    <div class="form-item-content">{{ objInfo.paymentTypeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">会员账户id:</label>
                    <div class="form-item-content">{{ objInfo.accountId }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">当前剩余可用积分:</label>
                    <div class="form-item-content">{{ objInfo.balanceScore }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">流水类型:</label>
                    <div class="form-item-content">{{ objInfo.typeName }}</div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">创建时间:</label>
                    <div class="form-item-content">{{ objInfo.createTime }}
                    </div>
                </div>
                <div class="form-item">
                    <label class="form-item-label">备注:</label>
                    <div class="form-item-content">{{ objInfo.remark }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import { memberaccountscoreserialInfo } from "@/api/account/memberaccountscoreserial.js";
    import { enumList, } from "@/api/public.api.js";
    export default {
        data() {
            return {
                drawer: true,
                direction: "rtl",
                objInfo: {},
                SerialPaymentTypeList:[],
                ScoreSerialTypeList:[],
            }
        },
        created() {
        },
        methods: {
          queryEnumList(){
              this.SerialPaymentTypeList = [];
              enumList("SerialPaymentType","").then((resp) => {
                if (resp.code == 0) {
                  this.SerialPaymentTypeList = resp.data;
                }
              });
              this.ScoreSerialTypeList = [];
              enumList("ScoreSerialType","").then((resp) => {
                if (resp.code == 0) {
                  this.ScoreSerialTypeList = resp.data;
                }
              });
          },
            init(id) {
                this.queryEnumList();
                if (id) {
                   memberaccountscoreserialInfo(id).then(res => {
                        this.objInfo = res.data
                              let paymentTypeObj = this.SerialPaymentTypeList.find((item) => {
                                return item.code == this.objInfo.paymentType;
                              });
                              this.objInfo.paymentTypeName = paymentTypeObj ? paymentTypeObj.name : "";
                              let typeObj = this.ScoreSerialTypeList.find((item) => {
                                return item.code == this.objInfo.type;
                              });
                              this.objInfo.typeName = typeObj ? typeObj.name : "";
                   })

                }
                this.drawer = true
            },
            // 关闭抽屉
            handleClose(done) {
                done()
            },
        },
    }
</script>
<style scoped>
    .from-title {
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        font-size: 16px;
        color: #17233d;
        border-bottom: 1px solid #eee;
    }
    .info-item {
        margin-bottom: 40px;
    }

    .form-item {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .form-item-label {
        text-align: right;
        float: left;
        width: 25%;
    }
    .form-item-content {
        margin-left: 30%;
        width: 70%;
    }
</style>
