import request from "@/common/request"

//物流圈评论表分页展示
export const logisticsdynamicscommentPage = (queryForm) => {
    return request("get", "/pdworld/logisticsDynamicsComment/page", queryForm);
}
//物流圈评论表列表展示
export const logisticsdynamicscommentList = (queryForm) => {
    return request("get", "/pdworld/logisticsDynamicsComment/list", queryForm);
}
//物流圈评论表详情
export const logisticsdynamicscommentInfo = (id) => {
    return request('get', '/pdworld/logisticsDynamicsComment/info', {
        id: id
    })
}
//物流圈评论表信息保存
export const logisticsdynamicscommentSave = (data) => {
    return request('post', '/pdworld/logisticsDynamicsComment/save', data)
}
//物流圈评论表信息修改
export const logisticsdynamicscommentEdit = (data) => {
    return request('post', '/pdworld/logisticsDynamicsComment/edit', data)
}
//物流圈评论表信息删除
export const logisticsdynamicscommentDel = (id) => {
    return request('post', '/pdworld/logisticsDynamicsComment/delete?id=' + id)
}
//物流圈评论表批量删除
export const logisticsdynamicscommentDelBatch = (ids) => {
    return request('post', '/pdworld/logisticsDynamicsComment/deleteBatch', ids)
}
//物流圈评论表动态表头导出
export const logisticsdynamicscommentDynamicExport = (data) => {
    return request("eptpost", '/pdworld/logisticsDynamicsComment/exportDynamic', data)
}
