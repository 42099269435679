<template>
	<div class="payscene-setting padding20">
		<div class="tab">
			<div
				:class="['tab-item', { active: tab == index }]"
				@click="onTabChange(index)"
				v-for="(item, index) in componentsData"
				:key="item.id"
			>
				{{ item.payTypeName }}
			</div>
		</div>
		<component :is="type" :current="tab" :data="componentsData" :active="active"></component>
	</div>
</template>

<script>
import request from "@/common/request"
import wx from "./components/payscene-setting-wx.vue"
import ali from "./components/payscene-setting-ali.vue"

export default {
	name: "payscene-setting",
	data() {
		return {
			tab: 0,
			active: "",
			message: {
				ALI: [],
				WX: [],
			},
		}
	},
	computed: {
		type() {
			return this.$route.query.type === "wx" ? wx : ali
		},
		componentsData() {
			return this.message[this.$route.query.type.toUpperCase()] || []
		},
	},
	async created() {
		try {
			const { code, data, msg } = await request("get", "/pay/payScene/configList", {
				code: this.$route.query.code,
			})
			if (code != 0) {
				throw new Error(msg)
			}
			if (data.WX) {
				data.WX.map(d => {
					if (d.keyPath) {
						d.keyPath = JSON.parse(d.keyPath)
					}
					d.notifyUrl = d.notifyUrl || `https://域名/topay/notify/${d.payTypeCode}/${d.sceneCode}`
				})
			}
			if (data.ALI) {
				data.ALI.map(d => {
					d.notifyUrl = d.notifyUrl || `https://域名/topay/notify/${d.payTypeCode}/${d.sceneCode}`
				})
			}

			this.message = data
			this.onTabChange(+this.$route.query.current || 0)
		} catch (error) {
			this.$message.error(error)
		}
	},
	methods: {
		onTabChange(current) {
			this.tab = current
			this.active = this.componentsData[current].payTypeCode
		},
	},
	components: {
		wx,
		ali,
	},
}
</script>

<style lang="scss" scoped>
.payscene-setting {
	.tab {
		background: #f0f0f0;
		text-align: center;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #676e7f;
		font-size: 14px;
		padding-left: 40px;
		border-radius: 5px 5px 0 0;
		padding-top: 10px;
		margin-bottom: 10px;
		.tab-item {
			padding: 5px 10px;
			border-radius: inherit;
			margin-right: 20px;
			cursor: pointer;
			&.active {
				background: #fff;
			}
		}
	}
}
</style>
