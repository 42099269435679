import request from "@/common/request"

//商家的问答分页展示
export const pdqaPage = (queryForm) => {
    return request("get", "/pdworld/pdQa/page", queryForm);
}
//商家的问答列表展示
export const pdqaList = (queryForm) => {
    return request("get", "/pdworld/pdQa/list", queryForm);
}
//商家的问答详情
export const pdqaInfo = (id) => {
    return request('get', '/pdworld/pdQa/info', {
        id: id
    })
}
//商家的问答信息保存
export const pdqaSave = (data) => {
    return request('post', '/pdworld/pdQa/save', data)
}
//商家的问答信息修改
export const pdqaEdit = (data) => {
    return request('post', '/pdworld/pdQa/edit', data)
}
//商家的问答信息删除
export const pdqaDel = (id) => {
    return request('post', '/pdworld/pdQa/delete?id=' + id)
}
//商家的问答批量删除
export const pdqaDelBatch = (ids) => {
    return request('post', '/pdworld/pdQa/deleteBatch', ids)
}
//商家的问答动态表头导出
export const pdqaDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdQa/exportDynamic', data)
}
