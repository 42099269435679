<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="会员ID" :label-width="formLabelWidth" prop="memberId">
                <el-input
                        v-model="form.memberId"
                        autocomplete="off"
                        placeholder="请输入会员ID"
                ></el-input>
            </el-form-item>
            <el-form-item label="流水金额" :label-width="formLabelWidth" prop="money">
                <el-input
                        v-model="form.money"
                        autocomplete="off"
                        placeholder="请输入流水金额"
                ></el-input>
            </el-form-item>
            <el-form-item label="流水收支类型" :label-width="formLabelWidth" prop="paymentType">
                <fortress-select
                        v-model="form.paymentType"
                        :dictList="SerialPaymentTypeList"
                        placeholder="请选择流水收支类型"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="会员账户id" :label-width="formLabelWidth" prop="accountId">
                <el-input
                        v-model="form.accountId"
                        autocomplete="off"
                        placeholder="请输入会员账户id"
                ></el-input>
            </el-form-item>
            <el-form-item label="当前剩余可用金额" :label-width="formLabelWidth" prop="balanceMoney">
                <el-input
                        v-model="form.balanceMoney"
                        autocomplete="off"
                        placeholder="请输入当前剩余可用金额"
                ></el-input>
            </el-form-item>
            <el-form-item label="流水类型" :label-width="formLabelWidth" prop="type">
                <fortress-select
                        v-model="form.type"
                        :dictList="AccountSerialTypeList"
                        placeholder="请选择流水类型"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="创建时间" :label-width="formLabelWidth" prop="createTime">
                <el-input
                        v-model="form.createTime"
                        autocomplete="off"
                        placeholder="请输入创建时间"
                ></el-input>
            </el-form-item>
            <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入备注"
                        v-model="form.remark"
                >
                </el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { memberaccountserialInfo, memberaccountserialSave, memberaccountserialEdit } from "@/api/account/memberaccountserial.js";
    import { enumList, } from "@/api/public.api.js";
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    memberId:"",
                    money:"",
                    paymentType:"",
                    accountId:"",
                    balanceMoney:"",
                    type:"",
                    createTime:"",
                    remark:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
                SerialPaymentTypeList:[],
                AccountSerialTypeList:[],
            };
        },
        created() {
        },
        methods: {
            queryEnumList(){
                this.SerialPaymentTypeList = [];
                enumList("SerialPaymentType","").then((resp) => {
                  if (resp.code == 0) {
                    this.SerialPaymentTypeList = resp.data;
                  }
                });
                this.AccountSerialTypeList = [];
                enumList("AccountSerialType","").then((resp) => {
                  if (resp.code == 0) {
                    this.AccountSerialTypeList = resp.data;
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryEnumList();
                //修改
                if (id) {
                    memberaccountserialInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        memberaccountserialEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        memberaccountserialSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    memberId:"",
                    money:"",
                    paymentType:"",
                    accountId:"",
                    balanceMoney:"",
                    type:"",
                    createTime:"",
                    remark:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
