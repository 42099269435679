import request from "@/common/request"

//邮件发送记录表分页展示
export const castlemessageemailrecordPage = (queryForm) => {
    return request("get", "/message/mail/castleMessageEmailRecord/page", queryForm);
}
//邮件发送记录表列表展示
export const castlemessageemailrecordList = (queryForm) => {
    return request("get", "/message/mail/castleMessageEmailRecord/list", queryForm);
}
//邮件发送记录表详情
export const castlemessageemailrecordInfo = (id) => {
    return request('get', '/message/mail/castleMessageEmailRecord/info', {
        id: id
    })
}
//邮件发送记录表信息保存
export const castlemessageemailrecordSave = (data) => {
    return request('post', '/message/mail/castleMessageEmailRecord/save', data)
}
//邮件发送记录表信息修改
export const castlemessageemailrecordEdit = (data) => {
    return request('post', '/message/mail/castleMessageEmailRecord/edit', data)
}
//邮件发送记录表信息删除
export const castlemessageemailrecordDel = (id) => {
    return request('post', '/message/mail/castleMessageEmailRecord/delete?id=' + id)
}
//邮件发送记录表批量删除
export const castlemessageemailrecordDelBatch = (ids) => {
    return request('post', '/message/mail/castleMessageEmailRecord/deleteBatch', ids)
}
//邮件发送记录表动态表头导出
export const castlemessageemailrecordDynamicExport = (data) => {
    return request("eptpost", '/message/mail/castleMessageEmailRecord/exportDynamic', data)
}
