<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="商品id" :label-width="formLabelWidth" prop="goodsId">
                <el-input
                        v-model="form.goodsId"
                        autocomplete="off"
                        placeholder="请输入商品id"
                ></el-input>
            </el-form-item>
            <el-form-item label="参数名" :label-width="formLabelWidth" prop="name">
                <el-input
                        v-model="form.name"
                        autocomplete="off"
                        placeholder="请输入参数名"
                ></el-input>
            </el-form-item>
            <el-form-item label="值" :label-width="formLabelWidth" prop="value">
                <el-input
                        v-model="form.value"
                        autocomplete="off"
                        placeholder="请输入值"
                ></el-input>
            </el-form-item>
            <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
                <el-input
                        v-model="form.sort"
                        autocomplete="off"
                        placeholder="请输入排序"
                ></el-input>
            </el-form-item>
            <el-form-item label="创建者" :label-width="formLabelWidth" prop="createUser">
                <el-input
                        v-model="form.createUser"
                        autocomplete="off"
                        placeholder="请输入创建者"
                ></el-input>
            </el-form-item>
            <el-form-item label="创建时间" :label-width="formLabelWidth" prop="createTime">
                <el-input
                        v-model="form.createTime"
                        autocomplete="off"
                        placeholder="请输入创建时间"
                ></el-input>
            </el-form-item>
            <el-form-item label="更新者" :label-width="formLabelWidth" prop="updateUser">
                <el-input
                        v-model="form.updateUser"
                        autocomplete="off"
                        placeholder="请输入更新者"
                ></el-input>
            </el-form-item>
            <el-form-item label="更新时间" :label-width="formLabelWidth" prop="updateTime">
                <el-input
                        v-model="form.updateTime"
                        autocomplete="off"
                        placeholder="请输入更新时间"
                ></el-input>
            </el-form-item>
            <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
                <el-input
                        v-model="form.status"
                        autocomplete="off"
                        placeholder="请输入状态"
                ></el-input>
            </el-form-item>
            <el-form-item label="删除状态 YesNoEnum。 yes删除；no未删除" :label-width="formLabelWidth" prop="isDeleted">
                <el-input
                        v-model="form.isDeleted"
                        autocomplete="off"
                        placeholder="请输入删除状态 YesNoEnum。 yes删除；no未删除"
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { goodsparamsInfo, goodsparamsSave, goodsparamsEdit } from "@/api/goods/goodsparams.js";
    
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    id:"",
                    goodsId:"",
                    name:"",
                    value:"",
                    sort:"",
                    createUser:"",
                    createTime:"",
                    updateUser:"",
                    updateTime:"",
                    status:"",
                    isDeleted:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
            };
        },
        created() {
        },
        methods: {
            init(id) {
                this.resetForm();
                //修改
                if (id) {
                    goodsparamsInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        goodsparamsEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        goodsparamsSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    id:"",
                    goodsId:"",
                    name:"",
                    value:"",
                    sort:"",
                    createUser:"",
                    createTime:"",
                    updateUser:"",
                    updateTime:"",
                    status:"",
                    isDeleted:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
