import request from "@/common/request"

//发布货源分页展示
export const pdgoodsPage = (queryForm) => {
    return request("get", "/pdworld/pdGoods/page", queryForm);
}
//发布货源列表展示
export const pdgoodsList = (queryForm) => {
    return request("get", "/pdworld/pdGoods/list", queryForm);
}
//发布货源详情
export const pdgoodsInfo = (id) => {
    return request('get', '/pdworld/pdGoods/info', {
        id: id
    })
}
//发布货源信息保存
export const pdgoodsSave = (data) => {
    return request('post', '/pdworld/pdGoods/save', data)
}
//发布货源信息修改
export const pdgoodsEdit = (data) => {
    return request('post', '/pdworld/pdGoods/edit', data)
}
//发布货源信息删除
export const pdgoodsDel = (id) => {
    return request('post', '/pdworld/pdGoods/delete?id=' + id)
}
//发布货源批量删除
export const pdgoodsDelBatch = (ids) => {
    return request('post', '/pdworld/pdGoods/deleteBatch', ids)
}
//发布货源动态表头导出
export const pdgoodsDynamicExport = (data) => {
    return request("eptpost", '/pdworld/pdGoods/exportDynamic', data)
}
