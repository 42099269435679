<template>
  <div class="padding20">
    <el-row style="margin-bottom: 17px">
      <el-form :inline="true" :model="queryForm" class="el-common-from">
        <el-form-item label="表名">
          <el-input
            prefix-icon="el-icon-search"
            v-model="queryForm.tbName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-button
          type="primary"
          @click="queryList({ current: 1 })"
          size="medium"
          icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          type="primary"
          @click="resetQueryForm"
          size="medium"
          icon="el-icon-refresh-left"
          >重置</el-button
        >
      </el-form>
      <el-button
        type="primary"
        size="medium"
        v-if="$tools.hasPermission('devtools:tbconfig:save')"
        @click="handleTbImport()"
        style="margin-top: 10px; width: 150px"
        icon="el-icon-plus"
        >导入数据库表</el-button
      >
    </el-row>
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
      >
        <el-table-column
          prop="tbName"
          label="表名"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="tbDesc"
          label="表说明"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('devtools:tbconfig:edit')"
              size="mini"
              @click="handleSyn(scope.row)"
              >同步</el-button
            >
            <el-button
              v-if="$tools.hasPermission('devtools:tbconfig:edit')"
              size="mini"
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-if="$tools.hasPermission('devtools:code:generate')"
              size="mini"
              @click="handleCodeGen(scope.row.id)"
              >生成代码</el-button
            >
            <el-button
              v-if="$tools.hasPermission('devtools:menu:generate')"
              size="mini"
              @click="handleMenGen(scope.row.id)"
              >生成菜单</el-button
            >
            <el-button
              v-if="$tools.hasPermission('devtools:tbconfig:delete')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>

    <!-- 导入数据库表 start-->
    <el-dialog title="导入数据库表" :visible.sync="tbImportVisible">
      <el-form
        ref="tbImportForm"
        :model="tbImportForm"
        :rules="tbImportFormRule"
      >
        <el-form-item label="数据源" :label-width="formLabelWidth" prop="dbId">
          <el-select
            v-model="tbImportForm.dbId"
            placeholder="请选择数据源"
            @change="handleImportDbChange"
          >
            <el-option
              v-for="item in dbList"
              :key="item.id"
              :label="item.dbName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选择表"
          :label-width="formLabelWidth"
          prop="tbName"
        >
          <el-select
            v-model="tbImportForm.tbName"
            filterable
            placeholder="请选择表"
            style="width: 380px"
          >
            <el-option
              v-for="item in tbImportList"
              :key="item.tbName"
              :label="item.tbName + ' | ' + item.tbDesc"
              :value="item.tbName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="tbImportVisible = false" size="medium" type="danger"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="submitTbImportForm('tbImportForm')"
          size="medium"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 导入数据库表 end-->

    <!-- 表字段编辑 -->
    <table-col-config
      v-if="tableColConfigVisible"
      ref="tableColConfig"
      @refreshData="queryList"
    ></table-col-config>

    <!-- 代码生成编辑框 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>

    <!--  -->
    <dev-menu-gen v-if="devMenuGenVisible" ref="devMenuGen"></dev-menu-gen>
  </div>
</template>
<script>
import {
  tbconfigPage,
  tbconfigDel,
  tbUnderDbList,
  tbconfigInit,
  tbconfigSyn,
} from "@/api/devtools/devtbconfig.js";
import { dbconfigList } from "@/api/devtools/devdbconfig.js";
import TableColConfig from "./devcolconfig";
import FortressPagination from "@/components/fortress-pagination.vue";
import FormEdit from "./devtbconfig-edit";
import DevMenuGen from "./devmenugen";
export default {
  data() {
    return {
      parentShow: "",
      tableData: [],
      queryForm: { tbName: "" },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      formLabelWidth: "120px",

      tbImportVisible: false,
      tbImportForm: {
        dbId: 0,
        tbName: "",
      },
      tbImportFormRule: {
        dbId: [{ required: true, message: "请选择数据源", trigger: "blur" }],
        tbName: [{ required: true, message: "请选择表", trigger: "blur" }],
      },
      dbList: [],
      tbImportList: [],

      tableColConfigVisible: false,
      formEditVisible: false,
      devMenuGenVisible: false,
    };
  },
  components: {
    "form-edit": FormEdit,
    "table-col-config": TableColConfig,
    "dev-menu-gen": DevMenuGen,
    pagination: FortressPagination,
  },
  created() {
    this.tableData = [];
    this.queryList();
  },
  methods: {
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        tbName: "",
      };
    },
    //查询列表
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      tbconfigPage(this.queryForm)
        .then((resp) => {
          if (resp.code == 0) {
            setTimeout(() => {
              this.parentShow = false;
              this.tableData = resp.data.records;
              this.page.total = resp.data.total;
              //查询页大于总页数，重新查询
              let cu =
                this.page.total % this.page.size == 0
                  ? parseInt(this.page.total / this.page.size)
                  : parseInt(this.page.total / this.page.size + 1);
              if (cu > 0 && this.page.current > cu) {
                this.queryList({ current: cu });
              }
            }, 600);
          } else {
            this.parentShow = false;
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          this.parentShow = false;
        });
    },
    //同步
    handleSyn(row) {
      this.$confirm("确认同步该表结构, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        tbconfigSyn(row).then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        tbconfigDel(row.id).then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            //判断是否最后一页的最后一条记录
            let totalPages =
              (this.page.total - 1) % this.page.size == 0
                ? (this.page.total - 1) / this.page.size
                : (this.page.total - 1) / this.page.size + 1;
            if (this.page.current > totalPages && this.page.current != 1) {
              this.page.current = this.page.current - 1;
            }
            this.queryList();
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    submitDel() {},
    //打开编辑窗口 表字段列表
    handleEdit(id) {
      this.tableColConfigVisible = true;
      this.$nextTick(() => {
        this.$refs.tableColConfig.init(id);
      });
    },
    //打开代码生成窗口
    handleCodeGen(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //导入数据库表
    handleTbImport() {
      this.tbImportVisible = true;
      this.tbImportForm = {};
      dbconfigList().then((resp) => {
        if (resp.code == 0) {
          this.dbList = resp.data;
        }
      });
    },
    //切换数据源
    handleImportDbChange(val) {
      this.tbImportList = [];
      tbUnderDbList(val).then((resp) => {
        if (resp.code == 0) {
          this.tbImportList = resp.data;
        }
      });
    },
    //导入数据库表 提交
    submitTbImportForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.tbImportVisible = false;
          tbconfigInit(this.tbImportForm).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //生成菜单
    handleMenGen(id) {
      this.devMenuGenVisible = true;
      this.$nextTick(() => {
        this.$refs.devMenuGen.init(id);
      });
    },
  },
};
</script>
<style scoped></style>
