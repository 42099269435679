import request from "@/common/request"

//登录操作日志分页展示
export const logloginPage = (queryForm) => {
    return request("get", "/log/logLogin/page", queryForm);
}
//登录操作日志列表展示
export const logloginList = (queryForm) => {
    return request("get", "/log/logLogin/list", queryForm);
}
//登录操作日志详情
export const logloginInfo = (id) => {
    return request('get', '/log/logLogin/info', {
        id: id
    })
}
//登录操作日志信息保存
export const logloginSave = (data) => {
    return request('post', '/log/logLogin/save', data)
}
//登录操作日志信息修改
export const logloginEdit = (data) => {
    return request('post', '/log/logLogin/edit', data)
}
//登录操作日志信息删除
export const logloginDel = (id) => {
    return request('post', '/log/logLogin/delete?id=' + id)
}
//登录操作日志批量删除
export const logloginDelBatch = (ids) => {
    return request('post', '/log/logLogin/deleteBatch', ids)
}
