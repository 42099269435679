<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
		<el-form ref="form" :model="form" :rules="rules">
			<el-form-item label="分站名称" :label-width="formLabelWidth" prop="siteName">
				<el-input v-model="form.siteName" autocomplete="off" placeholder="请输入分站名称"></el-input>
			</el-form-item>
			<!-- <el-form-item label="分站所属市编码" :label-width="formLabelWidth" prop="siteCityCode"> -->
			<!--                <fortress-region-->
			<!--                    ref="fortressRegion"-->
			<!--                    @ftregion="regionsHandler"-->
			<!--                ></fortress-region>-->
			<!-- <el-input v-model="form.siteCityCode" autocomplete="off" placeholder="请输入分站所属市编码"></el-input>
			</el-form-item>
			<el-form-item label="分站所属市" :label-width="formLabelWidth" prop="siteCity">
				<el-input v-model="form.siteCity" autocomplete="off" placeholder="请输入分站所属市"></el-input>
			</el-form-item> -->
			<el-form-item label="分站所属市" :label-width="formLabelWidth" prop="siteCityCode">
        <el-cascader
            v-model="siteCity"
            :options="regionList"
            :props="{
						label: 'name',
						value: 'id',
						leaf: 'leaf',
					}"
            @change="onChangeRegion"
            :disabled="!!form.id"
				></el-cascader>
			</el-form-item>
			<el-form-item label="分站备注" :label-width="formLabelWidth" prop="siteRemark">
				<el-input v-model="form.siteRemark" autocomplete="off" placeholder="请输入分站备注" type="textarea"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
			<el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { sysregionTree } from "@/api/system/sysregion.js"
import { subsiteInfo, subsiteSave, subsiteEdit } from "@/api/pdworld/subsite.js"

export default {
	data() {
		return {
			visible: false,
			form: {
				siteName: "",
				siteCityCode: "",
				siteCity: "",
				siteRemark: "",
			},
			formLabelWidth: "120px",
			rules: {},
			siteCity: "",
			regionList: [],
		}
	},
	async created() {
		const { data } = await sysregionTree()

		data.map(item => {
			if (item.children) {
				item.children.map(item => {
					item.children = null
				})
			}
		})

		this.regionList = data
	},
	methods: {
		onChangeRegion([provider, city]) {
			const p = this.regionList.find(item => item.id == provider)
			const c = p.children.find(item => item.id == city)
			this.form.siteCityCode = c.id
			this.form.siteCity = c.name
		},
		regionsHandler(regions) {
			this.form.consigner = regions[0].id
			this.form.consignerName = regions[0].name
			this.form.consignerCity = regions[1].id
			this.form.consignerCityName = regions[1].name
			this.form.consignerArea = regions[2].id
			this.form.consignerAreaName = regions[2].name
		},
		init(id) {
			this.resetForm()
			//修改
			if (id) {
				subsiteInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
							this.siteCity = resp.data.siteCityCode
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
			}
			// else{
			//   this.$nextTick(() => {
			//     this.$refs.fortressRegion.init([]);
			//   });
			// }
			this.visible = true
		},
		//提交表单数据
		submitForm() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					//修改
					if (this.form.id) {
						subsiteEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						subsiteSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
								this.visible = false
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				siteName: "",
				siteCityCode: "",
				siteCity: "",
				siteRemark: "",
			}
			this.siteCity = ""
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
}
</script>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
