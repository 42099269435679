<template>
  <!--    <el-dialog @close="close" :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">-->
  <div class="padding20">
    <el-backtop target=".padding20"></el-backtop>
    <!-- <div v-if="form.id" style="margin-bottom:10px">修改商品：{{ title }}</div> -->
    <!-- <div v-else>新增商品</div> -->
    <el-menu
      :default-active="activeIndex"
      class="el-menu-edit"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">基本信息</el-menu-item>
      <el-menu-item index="2">库存/规格</el-menu-item>
      <el-menu-item index="3">参数</el-menu-item>
      <el-menu-item index="4">分享</el-menu-item>
    </el-menu>
    <el-form
      label-position="right"
      v-show="activeIndex=='2'"
      style="margin-top: 20px"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="规格">
        <el-checkbox :true-label="1" :false-label="2" v-model="form.isSpec">启用商品规格</el-checkbox>
      </el-form-item>
      <vuedraggable class="wrapper" v-model="form.specList" handle=".move" v-if="form.isSpec == 1">
        <transition-group>
          <el-form-item v-for="(item,index) in form.specList" :key="index">
            <el-card>
              <el-input style="width: 70%;" placeholder="规格名称(比如:颜色)" v-model="item.name"></el-input>
              <el-button @click="addSpecItem(index)">添加规格项</el-button>
              <el-button circle icon="el-icon-close" size="small" @click="deleteSpec(index)"></el-button>
              <el-button
                style="cursor: move;margin-left: 0"
                class="move"
                icon="el-icon-sort"
                size="small"
                circle
              ></el-button>
              <div>
                <vuedraggable class="wrapper" v-model="item.specItemList" handle=".move-item">
                  <transition-group>
                    <span v-for="(items,indexs) in item.specItemList" :key="indexs">
                      <el-input
                        style="width: 20%;margin-left: 5px"
                        placeholder="规格项名称(比如:红色)"
                        size="small"
                        v-model="items.name"
                      ></el-input>
                      <el-button
                        circle
                        icon="el-icon-close"
                        size="small"
                        @click="deleteSpecItem(index , indexs)"
                      ></el-button>
                      <el-button
                        style="cursor: move;margin-left: 0"
                        class="move-item"
                        icon="el-icon-sort"
                        size="small"
                        circle
                      ></el-button>
                    </span>
                  </transition-group>
                </vuedraggable>
              </div>
            </el-card>
          </el-form-item>
        </transition-group>
      </vuedraggable>

      <el-form-item v-if="form.isSpec == 1">
        <el-button @click="refreshTable">刷新表格</el-button>
        <!--        <el-button @click="test">test</el-button>-->
        <el-button @click="addSpec()">添加规格</el-button>注：规格发生变动后请先刷新表格
        <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          border
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column
            v-for="(item,index) in tableHeaderList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.prop.startsWith('param') ? 80 : 180"
          >
            <template slot-scope="scope">
              <el-input v-if="!item.prop.startsWith('param')" v-model="scope.row[item.prop]"></el-input>
              <div v-else>{{scope.row[item.prop]}}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>

    <el-form
      v-show="activeIndex=='3'"
      style="margin-top: 20px"
      ref="form"
      :model="form"
      :rules="rules"
    >
      <el-form-item>
        <el-col :span="5" style="text-align: center">参数名</el-col>
        <el-col :span="12" style="margin-left: 10px ;text-align: center">参数值</el-col>
      </el-form-item>
      <vuedraggable class="wrapper" v-model="form.paramsList" handle=".move">
        <transition-group>
          <el-form-item v-for="(item, index) in form.paramsList" :key="index">
            <el-col :span="5">
              <el-input placeholder="参数名" v-model="item.name"></el-input>
            </el-col>
            <el-col :span="12" style="margin-left: 5px">
              <el-input placeholder="参数值" v-model="item.value"></el-input>
            </el-col>
            <el-col :span="6" style="margin-left: 10px">
              <el-button @click.prevent="removeParam(item)">删除</el-button>
              <el-button style="cursor: move" class="move" icon="el-icon-sort" circle></el-button>
            </el-col>
          </el-form-item>
        </transition-group>
      </vuedraggable>
      <el-form-item style="text-align: center">
        <el-button @click="addParam">添加参数</el-button>
      </el-form-item>
    </el-form>
    <el-form
      v-show="activeIndex=='1'"
      style="margin-top: 20px"
      ref="form"
      :model="form"
      :rules="rules"
    >
      <!-- 基本信息  -->
      <div class="info-part">
        <div class="info-part-tit">基本信息</div>
        <div class="info-part-con">
          <div class="height20"></div>
          <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
            <el-input-number step-strictly :min="0" v-model="form.sort" autocomplete="off"></el-input-number>
          </el-form-item>
          <el-form-item label="商品名称" :label-width="formLabelWidth" prop="name">
            <el-input v-model="form.name" autocomplete="off" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="所属分类" :label-width="formLabelWidth" prop="categoryId">
            <!--                <el-input-->
            <!--                        v-model="form.categoryId"-->
            <!--                        autocomplete="off"-->
            <!--                        placeholder="请输入所属分类"-->
            <!--                ></el-input>-->
            <el-select v-model="form.categoryId" clearable placeholder="请选择">
              <el-option :label="selParent.label" :value="selParent.id">
                <el-tree
                  :data="categoryList"
                  :props="defaultProps"
                  accordion
                  highlight-current
                  @node-click="handleNodeClick"
                ></el-tree>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="info-part">
        <div class="info-part-tit">商品信息</div>
        <div class="info-part-con">
          <div class="height20"></div>
          <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
            <fortress-radio-group v-model="form.type" :dictList="GoodsType_goodsList"></fortress-radio-group>
          </el-form-item>
          <el-form-item label="属性" :label-width="formLabelWidth">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item,index) in propertyList"
                :key="index"
                :checked="item.value == 1"
                @change="propertyChange($event,item.name)"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="原价（元）" :label-width="formLabelWidth" prop="productPrice">
            <el-input-number
              :min="0"
              :precision="2"
              v-model="form.productPrice"
              autocomplete="off"
              placeholder="请输入原价"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="现价（元）" :label-width="formLabelWidth" prop="marketPrice">
            <el-input-number
              :min="0"
              :precision="2"
              v-model="form.marketPrice"
              autocomplete="off"
              placeholder="请输入现价"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="主图片" :label-width="formLabelWidth" prop="thumb">
            <div class="w100">
              <fortress-upload type="image" name="thumb" :formData="form"></fortress-upload>
            </div>
          </el-form-item>
          <el-form-item label="主视频" :label-width="formLabelWidth" prop="video">
            <div class="w100">
              <fortress-upload type="video" name="video" :formData="form"></fortress-upload>
            </div>
          </el-form-item>
          <el-form-item label="其他图片" :label-width="formLabelWidth" prop="thumb">
            <div class="w100">
              <fortress-upload
                :size="9"
                :multiple="true"
                type="image"
                name="imgList"
                :formData="form"
              ></fortress-upload>
            </div>
          </el-form-item>
          <el-form-item label="详情信息" :label-width="formLabelWidth" prop="content">
            <fortress-editor v-model="form.content"></fortress-editor>
          </el-form-item>
          <el-form-item label="条码" :label-width="formLabelWidth" prop="productSn">
            <div class="w100">
              <fortress-upload type="image" name="productSn" :formData="form"></fortress-upload>
            </div>
          </el-form-item>
          <el-form-item label="编码" :label-width="formLabelWidth" prop="goodsSn">
            <el-input v-model="form.goodsSn" autocomplete="off" placeholder="请输入编码"></el-input>
          </el-form-item>
        </div>
      </div>
      <!-- 商品属性 -->
      <div class="info-part">
        <div class="info-part-tit">商品属性</div>
        <div class="info-part-con">
          <div class="height20"></div>
          <el-form-item label="减库存方式" :label-width="formLabelWidth" prop="totalCnf">
            <fortress-radio-group v-model="form.totalCnf" :dictList="GoodsTotalCnf_goodsList"></fortress-radio-group>
          </el-form-item>
          <el-form-item label="库存" :label-width="formLabelWidth" prop="total">
            <el-input-number :min="0" v-model="form.total" autocomplete="off" placeholder="请输入库存"></el-input-number>
          </el-form-item>
          <el-form-item label="购买赠送积分" :label-width="formLabelWidth" prop="credit">
            <el-input-number
              :min="0"
              v-model="form.credit"
              autocomplete="off"
              placeholder="请输入购买赠送积分"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="单次最多购买量" :label-width="formLabelWidth" prop="maxBuy">
            <el-input-number
              :min="0"
              v-model="form.maxBuy"
              autocomplete="off"
              placeholder="请输入单次最多购买量"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="用户最多购买量" :label-width="formLabelWidth" prop="userMaxBuy">
            <el-input-number
              :min="0"
              v-model="form.userMaxBuy"
              autocomplete="off"
              placeholder="请输入用户最多购买量"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="重量" :label-width="formLabelWidth" prop="weight">
            <el-input v-model="form.weight" autocomplete="off" placeholder="请输入重量"></el-input>
          </el-form-item>
          <el-form-item label="配送方式" :label-width="formLabelWidth" prop="dispatchType">
            <fortress-radio-group
              v-model="form.dispatchType"
              :dictList="GoodsDispatchType_goodsList"
            ></fortress-radio-group>
          </el-form-item>
          <el-form-item label="运费" :label-width="formLabelWidth" prop="freight">
            <el-input-number
              :precision="2"
              :min="0"
              v-model="form.freight"
              autocomplete="off"
              placeholder="请输入运费"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="分享标题" :label-width="formLabelWidth" prop="shareTitle">
            <el-input v-model="form.shareTitle" autocomplete="off" placeholder="请输入分享标题"></el-input>
          </el-form-item>
          <el-form-item label="分享图标" :label-width="formLabelWidth" prop="shareIcon">
            <div class="w100">
              <fortress-upload type="image" name="shareIcon" :formData="form"></fortress-upload>
            </div>
          </el-form-item>
          <!--            <el-form-item label="是否参与会员折扣" :label-width="formLabelWidth" prop="isNodisCount">-->
          <!--                <fortress-radio-group-->
          <!--                        v-model="form.isNodisCount"-->
          <!--                        :dictList="YesNoList"-->
          <!--                >-->
          <!--                </fortress-radio-group>-->
          <!--            </el-form-item>-->
          <el-form-item label="已售数量" :label-width="formLabelWidth" prop="sales">
            <el-input-number :min="0" v-model="form.sales" autocomplete="off" placeholder="请输入已售数量"></el-input-number>
          </el-form-item>
          <el-form-item label="是否自动上下架" :label-width="formLabelWidth" prop="isAutoShelves">
            <fortress-radio-group v-model="form.isAutoShelves" :dictList="YesNoList"></fortress-radio-group>
          </el-form-item>
          <el-form-item label="自动上架时间" :label-width="formLabelWidth" prop="upShelvesDate">
            <el-date-picker
              v-model="form.upShelvesDate"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="自动下架时间" :label-width="formLabelWidth" prop="offShelvesDate">
            <el-date-picker
              v-model="form.offShelvesDate"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="标签" :label-width="formLabelWidth" prop="tags">
            <el-select v-model="checkedTags" filterable multiple placeholder="请选择">
              <el-option-group v-for="group in tagsList" :key="group.name" :label="group.name">
                <el-option v-for="item in group.tagsList" :key="item" :label="item" :value="item"></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
            <el-input type="textarea" v-model="form.remark" autocomplete="off" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
            <el-switch
              v-model="form.status"
              :active-value="1"
              active-text="启用"
              :inactive-value="2"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" size="medium" @click="submitForm()">保存商品</el-button>
      <el-button @click="back" size="medium" type="danger">返回列表</el-button>
    </div>
  </div>
  <!--    </el-dialog>-->
</template>
<script>
import { goodsInfo, goodsSave, goodsEdit } from "@/api/goods/goods.js";
import { goodstaggroupList } from "@/api/goods/goodstaggroup.js";
import { enumList, } from "@/api/public.api.js";
import { treeList } from "@/api/goods/goodscategory";
import vuedraggable from 'vuedraggable';

export default {
  components: {
    vuedraggable
  },
  data () {
    return {
      specName: '',
      paramsHeaderList: [],
      standByHeaderList: [],
      tableHeaderList: [
        {
          prop: "stock",
          label: "库存"
        },
        {
          prop: "productPrice",
          label: "原价"
        },
        {
          prop: "marketPrice",
          label: "现价"
        },
        {
          prop: "costPrice",
          label: "成本价"
        },
        {
          prop: "goodsSn",
          label: "编码"
        },
        {
          prop: "productSn",
          label: "条码"
        },
      ],
      standbyDataList: [],
      tableData: [],
      title: '',
      activeIndex: "1",
      checkList: [],// 不使用
      checkedTags: [],
      defaultProps: {
        label: "name", // 显示名称
        children: "children", // 子级字段名
      },
      propertyList: [
        {
          name: 'isNew',
          value: 2,
          label: '新上'
        },
        {
          name: 'isHot',
          value: 2,
          label: '热卖'
        },
        {
          name: 'isDiscount',
          value: 2,
          label: '促销'
        },
        {
          name: 'isRecommand',
          value: 2,
          label: '推荐'
        },
        {
          name: 'isSendFree',
          value: 2,
          label: '包邮'
        },
        {
          name: 'isNodisCount',
          value: 2,
          label: '参与会员折扣'
        },
        {
          name: 'isSearchShow',
          value: 2,
          label: '搜索隐藏'
        },
        {
          name: 'isInvoice',
          value: 2,
          label: '开发票'
        },
      ]
      ,
      visible: false,
      form: {
        name: "",
        categoryId: "",
        type: "",
        sort: "",
        thumb: "",
        video: "",
        content: "",
        productSn: "",
        goodsSn: "",
        productPrice: "",
        marketPrice: "",
        totalCnf: "",
        total: "",
        credit: "",
        maxBuy: "",
        userMaxBuy: "",
        weight: "",
        dispatchType: "",
        isNew: "",
        isHot: "",
        isDiscount: "",
        isRecommand: "",
        isSendFree: "",
        freight: "",
        shareTitle: "",
        shareIcon: "",
        isNodisCount: "",
        sales: "",
        isSearchShow: "",
        isAutoShelves: "",
        upShelvesDate: "",
        offShelvesDate: "",
        isInvoice: "",
        tags: "",
        remark: "",
        status: "",
        imgList: [],
        paramsList: [],
        isSpec: 2,
        specList: [],
        optionList: [],
      },
      formLabelWidth: "120px",
      myconfig: window.$conf.commonConf.ueConfig,
      rules: {
        name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        categoryId: [
          { required: true, message: "请输入所属分类", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请输入类型", trigger: "blur" },
        ],
        totalCnf: [
          { required: true, message: "请输入减库存方式", trigger: "blur" },
        ],
        dispatchType: [
          { required: true, message: "请输入配送方式", trigger: "blur" },
        ],
      },
      GoodsType_goodsList: [],
      GoodsTotalCnf_goodsList: [],
      GoodsDispatchType_goodsList: [],
      categoryList: [],
      YesNoList: [
        {
          code: "1",
          name: "是"
        }, {
          code: "2",
          name: "否"
        },
      ],
      selParent: {
        id: "",
        label: "",
      },
      tagsList: [],
      results: [],
      result: [],
      paramsValueList: []
    };
  },
  created () {
    this.init(this.$route.query.id)
    this.standbyDataList = JSON.parse(JSON.stringify(this.tableData));
    this.standByHeaderList = JSON.parse(JSON.stringify(this.tableHeaderList));
    this.initTable()
  },
  methods: {
    test () {
      console.log(this.tableHeaderList)
    },
    deleteSpec (index) {
      this.$confirm('确定删除此规格?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.specList.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
      });

    },
    deleteSpecItem (index, indexs) {
      this.form.specList[index].specItemList.splice(indexs, 1)
    },
    //, 添加规格
    addSpec () {
      this.form.specList.push({
        id: '',
        goodsId: this.form.id || '',
        name: '',
        level: '',
        specItemList: []
      })
    },
    addSpecItem (index) {
      this.form.specList[index].specItemList.push(
        {
          id: '',
          goodsId: this.form.id || '',
          specId: this.form.specList[index].id || '',
          name: ''
        }
      )
    },
    // 初始化表格
    initTable () {
      var mergeColNames = []
      var headerList = JSON.parse(JSON.stringify(this.standByHeaderList));

      this.paramsHeaderList.forEach(e => {

        headerList.unshift(e)
      })
      for (var i = this.paramsHeaderList.length - 1; i >= 0; i--) {
        mergeColNames.push(this.paramsHeaderList[i].prop)
      }

      this.tableHeaderList = headerList
      console.log("头部", headerList)
      console.log("头部", mergeColNames)
      this.tableData = this.mergeTableRow({
        data: this.tableData,
        mergeColNames: mergeColNames, // 需要合并的列，默认合并列相同的数据
        firstMergeColNames: [mergeColNames[0]], // 受影响的列，只合并以firstMerge为首的同类型数据
        firstMerge: mergeColNames[0] // 以哪列为基础进行合并，一般为第一列
      })
    },
    doExchange (arr, depth) {
      for (var i = 0; i < arr[depth].length; i++) {
        this.result[depth] = arr[depth][i]
        if (depth != arr.length - 1) {
          this.doExchange(arr, depth + 1)
        } else {
          this.results.push(this.result.join(','))
        }
      }
    },
    initData () {
      this.results = [];
      this.result = [];
      // 无规格数据  则清空表
      if (!this.paramsValueList || this.paramsValueList.length < 1) {
        this.tableData = []
        return
      }
      var arr = this.paramsValueList

      this.doExchange(arr, 0);
      if (this.tableData.length < 1) {
        this.tableData.push({
          stock: '',
          productPrice: '',
          marketPrice: '',
          costPrice: '',
          goodsSn: '',
          productSn: '',
        })
      }
      console.log(this.results.length, this.results)
      var newList = []
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData.length < this.results.length) {
          this.tableData.push({
            stock: '',
            productPrice: '',
            marketPrice: '',
            costPrice: '',
            goodsSn: '',
            productSn: '',
          })
        }
        if (this.results[i]) {
          var paramArr = this.results[i].split(",")
          var index = 1
          paramArr.forEach(param => {
            var name = "param" + index
            this.tableData[i][name] = param
            index++
          })
        }

        newList.push(this.tableData[i])
        if (i == this.results.length - 1) {
          break
        }
      }
      this.tableData = newList
      console.log(this.tableData)
    },
    mergeTableRow (config) {
      // https://blog.csdn.net/zsl471260400/article/details/90675667
      let data = config.data
      const { mergeColNames, firstMergeColNames, firstMerge } = config
      if (!mergeColNames || mergeColNames.length === 0) {
        return data
      }
      mergeColNames.forEach((m) => {
        const mList = {}
        data = data.map((v, index) => {
          const rowVal = v[m]
          if (mList[rowVal] && mList[rowVal].newIndex === index) {
            const flag = firstMergeColNames.filter((f) => {
              return f === m
            }).length !== 0
            const mcFlag = mergeColNames.filter((mc) => {
              return mc === firstMerge
            }).length === 0
            if ((mcFlag && flag) || (flag && data[index][firstMerge + '-span'] && data[index][firstMerge + '-span'].rowspan === 1)) {
              v[m + '-span'] = {
                rowspan: 1,
                colspan: 1
              }
            } else {
              data[mList[rowVal]['index']][m + '-span'].rowspan++
              v[m + '-span'] = {
                rowspan: 0,
                colspan: 0
              }
              mList[rowVal]['num']++
              mList[rowVal]['newIndex']++
            }
          } else {
            mList[rowVal] = { num: 1, index: index, newIndex: index + 1 }
            v[m + '-span'] = {
              rowspan: 1,
              colspan: 1
            }
          }
          return v
        })
      })
      return data
    },
    refreshTable () {
      if (this.form.specList && this.form.specList.length > 0) {
        for (let i = 0; i < this.form.specList.length; i++) {
          var data = this.form.specList[i]
          if (!data.specItemList || data.specItemList.length < 1) {
            this.$message.error("操作失败,含有未添加规格项的规格")
            return
          }
        }
      }
      // 将表头同步至表格数据
      // 添加规格表头
      // let dataList = Array.from(JSON.parse(JSON.stringify(this.standbyDataList)))
      let dataList = []
      this.paramsValueList = []
      this.paramsHeaderList = []
      var i = 1
      if (this.form.specList && this.form.specList.length > 0) {
        this.form.specList.forEach(spec => {
          var tableHeadName = 'param' + i
          this.paramsHeaderList.unshift({
            prop: tableHeadName,
            label: spec.name
          })
          var paramNameList = []
          spec.specItemList.forEach(specItem => {
            var data = {
              stock: '',
              productPrice: '',
              marketPrice: '',
              costPrice: '',
              goodsSn: '',
              productSn: '',
            }
            data[tableHeadName] = specItem.name
            dataList.push(data)
            paramNameList.push(specItem.name)
          })
          this.paramsValueList.push(paramNameList)
          paramNameList = []
          i++
        })
      }

      // this.tableData = dataList
      this.initData()
      this.initTable()
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      const span = column['property'] + '-span'
      if (row[span]) {
        return row[span]
      }
    },
    addParam () {
      this.form.paramsList.push({
        value: '',
        name: ''
      });
    },
    removeParam (item) {
      this.$confirm('确定删除此参数?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var index = this.form.paramsList.indexOf(item)
        if (index !== -1) {
          this.form.paramsList.splice(index, 1)
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {

      });

    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = key
    },
    back () {
      this.$parent.$parent.$parent.handleCloseTag(this.$route.path)
      this.$router.push("/goods/goods")
    },
    close () {
      this.checkList = []
      this.form = {
        name: "",
        categoryId: "",
        type: "",
        sort: "",
        thumb: "",
        video: "",
        content: "",
        productSn: "",
        goodsSn: "",
        productPrice: "",
        marketPrice: "",
        totalCnf: "",
        total: "",
        credit: "",
        maxBuy: "",
        userMaxBuy: "",
        weight: "",
        dispatchType: "",
        isNew: "",
        isHot: "",
        isDiscount: "",
        isRecommand: "",
        isSendFree: "",
        freight: "",
        shareTitle: "",
        shareIcon: "",
        isNodisCount: "",
        sales: "",
        isSearchShow: "",
        isAutoShelves: "",
        upShelvesDate: "",
        offShelvesDate: "",
        isInvoice: "",
        tags: "",
        remark: "",
        status: "",
        imgList: [],
        paramsList: [],
        specList: [],
        isSpec: 2,
        optionList: []
      },
        this.propertyList = [
          {
            name: 'isNew',
            value: 2,
            label: '新上'
          },
          {
            name: 'isHot',
            value: 2,
            label: '热卖'
          },
          {
            name: 'isDiscount',
            value: 2,
            label: '促销'
          },
          {
            name: 'isRecommand',
            value: 2,
            label: '推荐'
          },
          {
            name: 'isSendFree',
            value: 2,
            label: '包邮'
          },
          {
            name: 'isNodisCount',
            value: 2,
            label: '参与会员折扣'
          },
          {
            name: 'isSearchShow',
            value: 2,
            label: '搜索隐藏'
          },
          {
            name: 'isInvoice',
            value: 2,
            label: '开发票'
          },
        ]
    },
    propertyChange (status, type) {
      if (status) {
        console.log("选中")
        this.form[type] = 1
      } else {
        console.log("取消选中")
        this.form[type] = 2
      }
    },
    getTagList () {
      goodstaggroupList({ status: 1 }).then(res => {
        this.tagsList = res.data
      })
    },
    //查找父节点
    findParentCode (parentId, dataScope) {
      dataScope.forEach(m => {
        if (parentId == m.id) {
          this.selParent.id = m.id
          this.selParent.label = m.name
          return
        } else if (m.children) {
          this.findParentCode(parentId, m.children)
        }
      })
    },
    //树形菜单展示
    handleNodeClick (data) {
      this.selParent.id = data.id
      this.selParent.label = data.name
      this.form.categoryId = data.id
    },
    getCategoryList () {
      treeList().then((res) => {
        if (res.code == 0) {
          this.categoryList = res.data;
        } else {
          this.$msg({
            message: res.msg,
            type: "error",
          });
        }
      })
    },
    queryEnumList () {
      this.GoodsType_goodsList = [];
      enumList("GoodsType", "goods").then((resp) => {
        if (resp.code == 0) {
          this.GoodsType_goodsList = resp.data;
        }
      });
      this.GoodsTotalCnf_goodsList = [];
      enumList("GoodsTotalCnf", "goods").then((resp) => {
        if (resp.code == 0) {
          this.GoodsTotalCnf_goodsList = resp.data;
        }
      });
      this.GoodsDispatchType_goodsList = [];
      enumList("GoodsDispatchType", "goods").then((resp) => {
        if (resp.code == 0) {
          this.GoodsDispatchType_goodsList = resp.data;
        }
      });
    },
    init (id) {
      this.resetForm();
      this.queryEnumList();
      this.getCategoryList()
      this.getTagList()
      //修改
      if (id) {
        goodsInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              this.title = this.form["name"]
              if (this.form.tags) {
                this.checkedTags = this.form.tags.split(",")
              }
              if (this.form.categoryId) {
                this.findParentCode(this.form.categoryId, this.categoryList)
              }
              this.propertyList.forEach(e => {
                e.value = this.form[e.name]
                if (e.value == 1) {
                  this.checkList.push(e.label)
                }
              })
              console.log(this.propertyList)
              if (this.form.paramsList.length < 1) {
                this.addParam()
              }
              this.tableData = this.form.optionList
              this.refreshTable()
            });

          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
      } else {
        this.addParam()
      }
      this.visible = true;
    },
    //提交表单数据
    submitForm () {
      // console.log(this.form)
      // this.form.imgList = [
      //   "https://hcses-1251334741.cos.ap-nanjing.myqcloud.com/fortress/20211124/1637740586096.jpg",
      //   "https://hcses-1251334741.cos.ap-nanjing.myqcloud.com/fortress/20211124/1637740590688.jpg"
      // ]
      console.log(this.tableData)
      // 判断有无未添加规格项的规格
      if (this.form.specList && this.form.specList.length > 0) {
        for (let i = 0; i < this.form.specList.length; i++) {
          var data = this.form.specList[i]
          if (!data.specItemList || data.specItemList.length < 1) {
            this.$message.error("操作失败,含有未添加规格项的规格")
            return
          }
        }
      }

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.tags = this.checkedTags.toString()
          this.form.optionList = this.tableData
          //修改
          if (this.form.id) {
            goodsEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
                this.init(this.form.id)
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            goodsSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
                // this.init()
                this.back()
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          this.$message.error('含有未填的必填项')
          return false;
        }
      });
    },
    resetForm () {
      this.checkList = []
      this.form = {
        name: "",
        categoryId: "",
        type: "",
        sort: "",
        thumb: "",
        video: "",
        content: "",
        productSn: "",
        goodsSn: "",
        productPrice: "",
        marketPrice: "",
        totalCnf: "",
        total: "",
        credit: "",
        maxBuy: "",
        userMaxBuy: "",
        weight: "",
        dispatchType: "",
        isNew: "2",
        isHot: "2",
        isDiscount: "2",
        isRecommand: "2",
        isSendFree: "2",
        freight: "",
        shareTitle: "",
        shareIcon: "",
        isNodisCount: "2",
        sales: "",
        isSearchShow: "2",
        isAutoShelves: "2",
        upShelvesDate: "",
        offShelvesDate: "",
        isInvoice: "2",
        tags: "",
        remark: "",
        status: "1",
        imgList: [],
        paramsList: [],
        specList: [],
        isSpec: 2,
        optionList: []
      };
      this.checkedTags = []
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
  },

};
</script>
<style scoped lang='scss'>
.el-menu-edit {
  border: none;
  li {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &::v-deep .el-menu-item.is-active {
    color: #fff !important;
    border-radius: 4px;
    border: none;
    background: #0d6efd;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
.info-part {
  padding: 0 20px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  background: #f6f9ff;
  border-radius: 6px;
  overflow: hidden;
  .info-part-tit {
    width: 250px;
    text-align: center;
    padding-top: 22px;
    font-weight: bold;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border-right: 3px solid #fff;
  }
  .info-part-con {
    width: 0;
    flex: 1;
    .height20 {
      height: 22px;
    }
    .el-select {
      width: 100%;
    }
    .w100 {
      // width: 100%;
      display: inline-block;
    }
  }
}
</style>
