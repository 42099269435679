<template>
  <div>
    <el-drawer
      title
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawer-info"
    >
      <div class="info-item">
        <h5 class="from-title">基本信息</h5>
        <div class="form-item">
          <label class="form-item-label">名称:</label>
          <div class="form-item-content">{{ infoList.name }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">上级菜单:</label>
          <div class="form-item-content">{{ infoParent }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">排序:</label>
          <div class="form-item-content">{{ infoList.sort }}</div>
        </div>
        <div class="form-item">
          <label class="form-item-label">备注:</label>
          <div class="form-item-content">{{ infoList.remark || "无" }}</div>
        </div>
      </div>
      <!-- <div class="info-item">
				<h5 class="from-title">其他信息</h5>
				<div class="form-item">
					<label class="form-item-label">名称:</label>
					<div class="form-item-content">名称名称</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">上级部门:</label>
					<div class="form-item-content">名称名称</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">备注:</label>
					<div class="form-item-content">名称名称</div>
				</div>
      </div>-->
    </el-drawer>
  </div>
</template>
<script>
import { menuInfo } from "@/api/system/menu.js"
export default {
  data () {
    return {
      drawer: true,
      direction: "rtl",
      infoList: {},
      infoParent: "无",
    }
  },
  created () { },
  methods: {
    init (id) {
      if (id) {
        menuInfo(id).then(res => {
          this.infoList = res.data
          if (this.infoList.parentId) {
            menuInfo(this.infoList.parentId).then(resp => {
              this.infoParent = resp.data.name
            })
          }
        })
      }
      this.drawer = true
    },
    // 关闭抽屉
    handleClose (done) {
      done()
    },
  },
}
</script>
<style scoped></style>
