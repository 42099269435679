import request from "@/common/request"

//支付场景表分页展示
export const payscenePage = queryForm => {
	return request("get", "/pay/payScene/page", queryForm)
}
//支付场景表列表展示
export const paysceneList = queryForm => {
	return request("get", "/pay/payScene/list", queryForm)
}
//支付场景表详情
export const paysceneInfo = id => {
	return request("get", "/pay/payScene/info", {
		id: id,
	})
}
//支付场景表信息保存
export const paysceneSave = data => {
	return request("post", "/pay/payScene/save", data)
}
//支付场景表信息修改
export const paysceneEdit = data => {
	return request("post", "/pay/payScene/edit", data)
}
//支付场景表信息删除
export const paysceneDel = id => {
	return request("post", "/pay/payScene/delete?id=" + id)
}
//支付场景表动态表头导出
export const paysceneDynamicExport = data => {
	return request("eptpost", "/pay/payScene/exportDynamic", data)
}
