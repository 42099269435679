<template>
	<el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
		<el-form ref="form" :model="form" :rules="rule">
			<el-form-item label="类型" :label-width="formLabelWidth" prop="platform">
				<fortress-radio-group v-model="form.platform" :dictList="platformList"></fortress-radio-group>
			</el-form-item>
			<el-form-item label="存储编码" :label-width="formLabelWidth" prop="ftCode">
				<el-input v-model="form.ftCode" placeholder="请输入存储编码" auto-complete="off" />
			</el-form-item>

			<!-- 本地存储 -->
			<div v-if="form.platform == '1'">
				<el-form-item label="存储路径" :label-width="formLabelWidth" prop="ossPlatFormDto.localFilePosition">
					<el-input v-model="form.ossPlatFormDto.localFilePosition" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="访问路径" :label-width="formLabelWidth" prop="ossPlatFormDto.localFileUrl">
					<el-input v-model="form.ossPlatFormDto.localFileUrl" autocomplete="off"></el-input>
				</el-form-item>
			</div>

			<!-- 阿里云存储 -->
			<div v-if="form.platform == '2'">

        <el-form-item label="存储空间" :label-width="formLabelWidth" prop="ossPlatFormDto.aliyunBucket">
          <el-input v-model="form.ossPlatFormDto.aliyunBucket" autocomplete="off"></el-input>
        </el-form-item>
				<el-form-item label="Endpoint" :label-width="formLabelWidth" prop="ossPlatFormDto.aliyunEndpoint">
					<el-input v-model="form.ossPlatFormDto.aliyunEndpoint" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="访问域名" :label-width="formLabelWidth" prop="ossPlatFormDto.aliyunDomain">
					<el-input v-model="form.ossPlatFormDto.aliyunDomain" autocomplete="off">
            <template slot="prepend">https://</template>
            <template slot="append">
              <el-tooltip effect="dark" content="阿里云自定义域名 或 阿里云Bucket域名" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </template>
          </el-input>
				</el-form-item>
				<el-form-item label="AccessKey" :label-width="formLabelWidth" prop="ossPlatFormDto.aliyunAccessKey">
					<el-input v-model="form.ossPlatFormDto.aliyunAccessKey" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="SecretKey" :label-width="formLabelWidth" prop="ossPlatFormDto.aliyunSecretKey">
					<el-input v-model="form.ossPlatFormDto.aliyunSecretKey" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="路径前缀" :label-width="formLabelWidth" prop="ossPlatFormDto.aliyunPathPrefix">
					<el-input v-model="form.ossPlatFormDto.aliyunPathPrefix" autocomplete="off"></el-input>
				</el-form-item>
			</div>

			<!-- 京东云存储 -->
			<div v-if="form.platform == '3'">
				<el-form-item label="访问域名" :label-width="formLabelWidth" prop="ossPlatFormDto.jdcloudEndpoint">
					<el-input v-model="form.ossPlatFormDto.jdcloudEndpoint" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="存储空间" :label-width="formLabelWidth" prop="ossPlatFormDto.jdcloudBucket">
					<el-input v-model="form.ossPlatFormDto.jdcloudBucket" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="AccessKey" :label-width="formLabelWidth" prop="ossPlatFormDto.jdcloudAccessKey">
					<el-input v-model="form.ossPlatFormDto.jdcloudAccessKey" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="SecretKey" :label-width="formLabelWidth" prop="ossPlatFormDto.jdcloudSecretKey">
					<el-input v-model="form.ossPlatFormDto.jdcloudSecretKey" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="区域编码" :label-width="formLabelWidth" prop="ossPlatFormDto.jdcloudRegion">
					<el-input v-model="form.ossPlatFormDto.jdcloudRegion" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="路径前缀" :label-width="formLabelWidth" prop="ossPlatFormDto.jdcloudPathPrefix">
					<el-input v-model="form.ossPlatFormDto.jdcloudPathPrefix" autocomplete="off"></el-input>
				</el-form-item>
			</div>

			<!-- 腾讯云存储 -->
			<div v-if="form.platform == '4'">
				<el-form-item label="访问域名" :label-width="formLabelWidth" prop="ossPlatFormDto.cosEndpoint">
					<el-input v-model="form.ossPlatFormDto.cosEndpoint" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="存储空间" :label-width="formLabelWidth" prop="ossPlatFormDto.cosBucketName">
					<el-input v-model="form.ossPlatFormDto.cosBucketName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="AppId" :label-width="formLabelWidth" prop="ossPlatFormDto.cosAppId">
					<el-input v-model="form.ossPlatFormDto.cosAppId" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="SecretId" :label-width="formLabelWidth" prop="ossPlatFormDto.cosSecretId">
					<el-input v-model="form.ossPlatFormDto.cosSecretId" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="SecretKey" :label-width="formLabelWidth" prop="ossPlatFormDto.cosSecretKey">
					<el-input v-model="form.ossPlatFormDto.cosSecretKey" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="地域" :label-width="formLabelWidth" prop="ossPlatFormDto.cosRegion">
					<el-input v-model="form.ossPlatFormDto.cosRegion" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="路径前缀" :label-width="formLabelWidth" prop="ossPlatFormDto.cosPathPrefix">
					<el-input v-model="form.ossPlatFormDto.cosPathPrefix" autocomplete="off"></el-input>
				</el-form-item>
			</div>

			<el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
				<el-input v-model="form.remark" autocomplete="off" type="textarea" :rows="2"></el-input>
			</el-form-item>
			<el-form-item label="是否启用" :label-width="formLabelWidth" prop="status">
				<el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2"></el-switch>
				<span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66">启用</span>
				<span v-if="form.status == 2" style="margin-left: 17px; color: #ff4949">禁用</span>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="danger" @click="visible = false" size="medium">取 消</el-button>
			<el-button type="primary" @click="submitForm('form')" size="medium">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { configOssInfo, configOssSave, configOssEdit } from "@/api/system/configoss.js"
export default {
	props: ["platformList"],
	data() {
		return {
			visible: false,
			form: {
				id: "",
				ftCode: "",
				remark: "",
				platform: "1",
				status: 1,
				ossPlatFormDto: {
					localFilePosition: "",
					localFileUrl: "",
				},
			},
			formLabelWidth: "120px",
			rule: {
				ftCode: [{ required: true, message: "请输入存储编码", trigger: "blur" }],
				"ossPlatFormDto.localFilePosition": [{ required: true, message: "请输入存储路径", trigger: "blur" }],
				"ossPlatFormDto.localFileUrl": [{ required: true, message: "请输入文件访问路径", trigger: "blur" }],

				"ossPlatFormDto.aliyunEndpoint": [{ required: true, message: "请输入Endpoint", trigger: "blur" }],
				"ossPlatFormDto.aliyunDomain": [{ required: true, message: "请输入访问域名", trigger: "blur" }],
				"ossPlatFormDto.aliyunBucket": [{ required: true, message: "请输入存储空间", trigger: "blur" }],
				"ossPlatFormDto.aliyunAccessKey": [{ required: true, message: "请输入AccessKey", trigger: "blur" }],
				"ossPlatFormDto.aliyunSecretKey": [{ required: true, message: "请输入SecretKey", trigger: "blur" }],

				"ossPlatFormDto.jdcloudRegion": [{ required: true, message: "请输入区域编码", trigger: "blur" }],
				"ossPlatFormDto.jdcloudEndpoint": [{ required: true, message: "请输入访问域名", trigger: "blur" }],
				"ossPlatFormDto.jdcloudBucket": [{ required: true, message: "请输入存储空间", trigger: "blur" }],
				"ossPlatFormDto.jdcloudAccessKey": [{ required: true, message: "请输入AccessKey", trigger: "blur" }],
				"ossPlatFormDto.jdcloudSecretKey": [{ required: true, message: "请输入SecretKey", trigger: "blur" }],

				"ossPlatFormDto.cosRegion": [{ required: true, message: "请输入地域", trigger: "blur" }],
				"ossPlatFormDto.cosEndpoint": [{ required: true, message: "请输入访问域名", trigger: "blur" }],
				"ossPlatFormDto.cosBucketName": [{ required: true, message: "请输入存储空间", trigger: "blur" }],
				"ossPlatFormDto.cosSecretId": [{ required: true, message: "请输入SecretId", trigger: "blur" }],
				"ossPlatFormDto.cosSecretKey": [{ required: true, message: "请输入SecretKey", trigger: "blur" }],
				"ossPlatFormDto.cosAppId": [{ required: true, message: "请输入AppId", trigger: "blur" }],
			},
		}
	},
	computed: {},
	created() {},
	methods: {
		init(id) {
			this.resetForm()
			//修改
			if (id) {
				configOssInfo(id).then(resp => {
					if (resp.code == 0) {
						this.$nextTick(() => {
							this.form = {
								...this.form,
								...resp.data,
							}
							this.form.type = this.form.type + ""
						})
					} else {
						this.$msg({
							message: resp.msg,
							type: "error",
						})
					}
				})
				//新增
			}
			this.visible = true
		},
		//菜单类型切换
		typeChange(data) {
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].clearValidate() // 只清除清除验证
				}
			})
			this.form.type = data
		},
		//提交表单数据
		submitForm() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					this.visible = false
					//修改
					if (this.form.id) {
						configOssEdit(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
						//新增
					} else {
						configOssSave(this.form).then(resp => {
							if (resp.code == 0) {
								this.$message({
									type: "success",
									message: "操作成功!",
								})
								this.$emit("refreshData")
							} else {
								this.$msg({
									message: resp.msg,
									type: "error",
								})
							}
						})
					}
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		resetForm() {
			this.form = {
				id: "",
				ftCode: "",
				remark: "",
				platform: "1",
				status: 1,
				ossPlatFormDto: {
					localFilePosition: "",
					localFileUrl: "",
				},
			}
			this.$nextTick(() => {
				if (this.$refs["form"]) {
					this.$refs["form"].resetFields()
				}
			})
		},
	},
}
</script>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
	height: auto;
	max-height: 274px;
	padding: 7px;
	overflow: hidden;
	overflow-y: auto;
	font-weight: normal;
}
</style>
