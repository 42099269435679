import { render, staticRenderFns } from "./logisticsdynamics-info.vue?vue&type=template&id=5d161c08&scoped=true&"
import script from "./logisticsdynamics-info.vue?vue&type=script&lang=js&"
export * from "./logisticsdynamics-info.vue?vue&type=script&lang=js&"
import style0 from "./logisticsdynamics-info.vue?vue&type=style&index=0&id=5d161c08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d161c08",
  null
  
)

export default component.exports