<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="输入框" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off" placeholder="请输入输入框"></el-input>
      </el-form-item>
      <el-form-item label="富文本" :label-width="formLabelWidth" prop="content">
        <fortress-editor v-model="form.content"></fortress-editor>
      </el-form-item>
      <el-form-item label="作者" :label-width="formLabelWidth" prop="auth">
        <el-input v-model="form.auth" autocomplete="off" placeholder="请输入作者"></el-input>
      </el-form-item>
      <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
        <el-input v-model="form.phone" autocomplete="off" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="地址" :label-width="formLabelWidth" prop="address">
        <fortress-region
          ref="fortressRegion"
          @ftregion="regionHandler"
          clearable
          placeholder="请选择地区"
        ></fortress-region>
      </el-form-item>
      <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
        <el-input v-model="form.email" autocomplete="off" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="图片" :label-width="formLabelWidth" prop="images">
        <fortress-upload :size="7" type="image" name="images" :formData="form"></fortress-upload>
      </el-form-item>
      <el-form-item label="文件" :label-width="formLabelWidth" prop="files">
        <fortress-upload type="file" name="files" :formData="form"></fortress-upload>
      </el-form-item>
      <el-form-item label="视频" :label-width="formLabelWidth" prop="video">
        <fortress-upload type="video" name="video" :formData="form"></fortress-upload>
      </el-form-item>
      <el-form-item label="地图-经度" :label-width="formLabelWidth" prop="longitude">
        <el-input v-model="form.longitude" autocomplete="off" placeholder="请输入地图-经度"></el-input>
      </el-form-item>
      <el-form-item label="地图-纬度" :label-width="formLabelWidth" prop="latitude">
        <el-input v-model="form.latitude" autocomplete="off" placeholder="请输入地图-纬度"></el-input>
      </el-form-item>
      <el-form-item label="数字" :label-width="formLabelWidth" prop="vueNumber">
        <el-input v-model="form.vueNumber" autocomplete="off" placeholder="请输入数字"></el-input>
      </el-form-item>
      <el-form-item label="单选" :label-width="formLabelWidth" prop="vueRadio">
        <fortress-radio-group v-model="form.vueRadio" :dictList="dictDemoList"></fortress-radio-group>
      </el-form-item>
      <el-form-item label="多行文本" :label-width="formLabelWidth" prop="vueTextarea">
        <el-input type="textarea" :rows="2" placeholder="请输入多行文本" v-model="form.vueTextarea"></el-input>
      </el-form-item>
      <el-form-item label="复选" :label-width="formLabelWidth" prop="vueCheckboxCheckedList">
        <el-checkbox-group v-model="form.vueCheckboxCheckedList">
          <el-checkbox
            v-for="item in dictDemoList"
            :label="item.code"
            :key="item.code"
          >{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="下拉" :label-width="formLabelWidth" prop="vueSelect">
        <fortress-select
          v-model="form.vueSelect"
          :dictList="dictDemoList"
          placeholder="请选择下拉"
          clearable
          filterable
        ></fortress-select>
      </el-form-item>
      <el-form-item label="枚举下拉" :label-width="formLabelWidth" prop="selectEnum">
        <fortress-select
          v-model="form.selectEnum"
          :dictList="selectEnumList"
          placeholder="请选择枚举下拉"
          clearable
          filterable
        ></fortress-select>
      </el-form-item>
      <el-form-item label="URL下拉" :label-width="formLabelWidth" prop="selectUrl">
        <fortress-select
          v-model="form.selectUrl"
          :dictList="selectUrlList"
          placeholder="请选择下拉"
          filterable
          clearable
        ></fortress-select>
      </el-form-item>
      <el-form-item label="JSON下拉" :label-width="formLabelWidth" prop="selectJson">
        <fortress-select
          v-model="form.selectJson"
          :dictList="selectJsonList"
          placeholder="请选择下拉"
          clearable
          filterable
        ></fortress-select>
      </el-form-item>
      <el-form-item label="日期" :label-width="formLabelWidth" prop="vueDate">
        <el-date-picker
          v-model="form.vueDate"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="日期时间" :label-width="formLabelWidth" prop="vueDatetime">
        <el-date-picker
          v-model="form.vueDatetime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="行业" :label-width="formLabelWidth">
        <fortress-industry
          :checkId="form.industryId"
          @check="check"
          placeholder="请选择职位"
          title="请选择职位类别"
          width="60%"
        ></fortress-industry>
      </el-form-item>
      <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
        <el-switch
          v-model="form.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
        <span v-if="form.status == 1" style="margin-left: 17px; color: #13ce66">启用</span>
        <span v-if="form.status == 2" style="margin-left: 17px; color: #ff4949">禁用</span>
      </el-form-item>
      <el-form-item label="创建人" :label-width="formLabelWidth" prop="createUser">
        <el-input v-model="form.createUser" autocomplete="off" placeholder="请输入创建人"></el-input>
      </el-form-item>
      <el-form-item label="创建部门" :label-width="formLabelWidth" prop="createDept">
        <el-input v-model="form.createDept" autocomplete="off" placeholder="请输入创建部门"></el-input>
      </el-form-item>
      <el-form-item label="创建职位" :label-width="formLabelWidth" prop="createPost">
        <el-input v-model="form.createPost" autocomplete="off" placeholder="请输入创建职位"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { tmpdemoInfo, tmpdemoSave, tmpdemoEdit } from "@/api/system/tmpdemo.js";
import { dictList, enumList, urlList } from "@/api/public.api.js";
import * as ftVal from "@/common/fortressValidator.js";
export default {
  data () {
    return {
      visible: false,
      ckEditorObj: null,
      form: {
        name: "",
        content: "",
        auth: "",
        phone: "",
        email: "",
        province: "",
        city: "",
        area: "",
        provinceName: "",
        cityName: "",
        areaName: "",
        images: "",
        files: "",
        video: "",
        longitude: "",
        latitude: "",
        vueNumber: "",
        vueRadio: "",
        vueTextarea: "",
        vueCheckboxCheckedList: [],
        vueCheckbox: "",
        vueSelect: "",
        vueDate: "",
        vueDatetime: "",
        status: "",
        createUser: "",
        createDept: "",
        createPost: "",
        industryId: ""
      },
      formLabelWidth: "120px",
      rules: {
        name: [{ required: true, message: "请输入输入框", trigger: "blur" }],
        content: [{ required: true, message: "请输入富文本", trigger: "blur" }],
        auth: [{ required: true, message: "请输入作者", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: ftVal.validatePhone,
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            validator: ftVal.validateEMail,
            trigger: "blur",
          },
        ],
        images: [{ required: true, message: "请输入图片", trigger: "blur" }],
        files: [{ required: true, message: "请输入文件", trigger: "blur" }],
        video: [{ required: true, message: "请输入视频", trigger: "blur" }],
        longitude: [
          { required: true, message: "请输入地图-经度", trigger: "blur" },
        ],
        latitude: [
          { required: true, message: "请输入地图-纬度", trigger: "blur" },
        ],
        vueNumber: [
          { required: true, message: "请输入数字", trigger: "blur" },
          {
            validator: ftVal.validateInteger,
            trigger: "blur",
          },
        ],
        vueRadio: [{ required: true, message: "请输入单选", trigger: "blur" }],
        vueTextarea: [
          { required: true, message: "请输入多行文本", trigger: "blur" },
        ],
        vueCheckboxCheckedList: [
          { required: true, message: "请输入复选", trigger: "blur" },
        ],
        vueSelect: [{ required: true, message: "请输入下拉", trigger: "blur" }],
        vueDate: [{ required: true, message: "请输入日期", trigger: "blur" }],
        vueDatetime: [
          { required: true, message: "请输入日期时间", trigger: "blur" },
        ],
        status: [{ required: true, message: "请输入状态", trigger: "blur" }],
        createUser: [
          { required: true, message: "请输入创建人", trigger: "blur" },
        ],
        createDept: [
          { required: true, message: "请输入创建部门", trigger: "blur" },
        ],
        createPost: [
          { required: true, message: "请输入创建职位", trigger: "blur" },
        ],
      },
      dictDemoList: [],
      selectEnumList: [],
      selectUrlList: [],
      selectJsonList: [],
    };
  },
  created () { },
  methods: {
    check (industryData) {
      console.log(industryData)
    },
    //字典
    queryDictList () {
      this.dictDemoList = [];
      dictList("dictDemo").then((resp) => {
        if (resp.code == 0) {
          this.dictDemoList = resp.data;
        }
      });
    },
    //枚举
    queryEnumList () {
      this.selectEnumList = [];
      enumList("DataPermissionPost").then((resp) => {
        if (resp.code == 0) {
          this.selectEnumList = resp.data;
        }
      });
    },
    //url
    queryUrlList () {
      this.selectUrlList = [];
      urlList("/system/sysRole/list").then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            resp.data.forEach((item) => {
              this.selectUrlList.push({ code: item.id, name: item.name });
            });
          }
        }
      });
    },
    //json
    queryJsonList () {
      this.selectJsonList = [];
      let data =
        '[{"code":"1","name":"a"},{"code":"2","name":"b"},{"code":"3","name":"c"}]';
      this.selectJsonList = JSON.parse(data);
      console.log(this.selectJsonList);
    },
    init (id) {
      this.visible = true;
      this.resetForm();
      this.queryDictList();
      this.queryEnumList();
      this.queryUrlList();
      this.queryJsonList();
      //修改
      if (id) {
        tmpdemoInfo(id).then((resp) => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              };
              if (resp.data.files) {
                this.form.files = [];
                let filesArray = JSON.parse(resp.data.files);
                filesArray.forEach((item) => {
                  this.form.files.push({ name: item.name, url: item.url });
                });
              }
              if (resp.data.vueCheckbox) {
                this.form.vueCheckboxCheckedList =
                  resp.data.vueCheckbox.split(";");
              }
              let region = [];
              region.push(this.form.province);
              region.push(this.form.city);
              region.push(this.form.area);
              this.$refs.fortressRegion.init(region);
            });
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            });
          }
        });
        //新增
      } else {
        this.$refs.fortressRegion.init([]);
      }
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.files) {
            let fileJosn = [];
            this.form.files.forEach((item) => {
              fileJosn.push({ name: item.name, url: item.url });
            });
            this.form.files = JSON.stringify(fileJosn);
          }

          if (this.form.vueCheckboxCheckedList) {
            this.form.vueCheckbox = this.form.vueCheckboxCheckedList.join(";");
          }
          //修改
          if (this.form.id) {
            tmpdemoEdit(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
            //新增
          } else {
            tmpdemoSave(this.form).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$emit("refreshData");
                this.visible = false;
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm () {
      this.form = {
        name: "",
        content: "",
        auth: "",
        phone: "",
        email: "",
        province: "",
        city: "",
        area: "",
        provinceName: "",
        cityName: "",
        areaName: "",
        images: "",
        files: "",
        video: "",
        longitude: "",
        latitude: "",
        vueNumber: "",
        vueRadio: "",
        vueTextarea: "",
        vueCheckboxCheckedList: [],
        vueCheckbox: "",
        vueSelect: "",
        vueDate: "",
        vueDatetime: "",
        status: "",
        createUser: "",
        createDept: "",
        createPost: "",
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
    regionHandler (regions, regionsInfo) {
      this.region = regions;
      if (regions.length == 3) {
        this.form.province = regions[0];
        this.form.city = regions[1];
        this.form.area = regions[2];
        this.form.provinceName = regionsInfo[0].name;
        this.form.cityName = regionsInfo[1].name;
        this.form.areaName = regionsInfo[2].name;
      } else {
        this.form.province = "";
        this.form.city = "";
        this.form.area = "";
        this.form.provinceName = "";
        this.form.cityName = "";
        this.form.areaName = "";
      }
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
