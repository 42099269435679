import request from "@/common/request"

//短信配置表分页展示
export const configsmsPage = (queryForm) => {
    return request("get", "/message/sms/configSms/page", queryForm);
}
//短信配置表列表展示
export const configsmsList = (queryForm) => {
    return request("get", "/message/sms/configSms/list", queryForm);
}
//短信配置表详情
export const configsmsInfo = (id) => {
    return request('get', '/message/sms/configSms/info', {
        id: id
    })
}
//短信配置表信息保存
export const configsmsSave = (data) => {
    return request('post', '/message/sms/configSms/save', data)
}
//短信配置表信息修改
export const configsmsEdit = (data) => {
    return request('post', '/message/sms/configSms/edit', data)
}
//短信配置表信息删除
export const configsmsDel = (id) => {
    return request('post', '/message/sms/configSms/delete?id=' + id)
}
//短信配置表批量删除
export const configsmsDelBatch = (ids) => {
    return request('post', '/message/sms/configSms/deleteBatch', ids)
}
//发送短信
export const smsSend = (data) => {
    return request('post', '/message/sms/send', data)
}
