<template>
  <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="任务名称" :label-width="formLabelWidth" prop="taskName">
        <el-input
          v-model="form.taskName"
          autocomplete="off"
          placeholder="请输入任务名称"
          :disabled="form.id ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="参数" :label-width="formLabelWidth" prop="params">
        <el-input v-model="form.params" autocomplete="off" placeholder="请输入参数"></el-input>
      </el-form-item>
      <el-form-item label="cron表达式" :label-width="formLabelWidth" prop="cronExpression">
        <!-- <el-input v-model="form.cronExpression" autocomplete="off" placeholder="请输入cron表达式"></el-input> -->
        <cron-input v-model="form.cronExpression" size="medius" @change="change" @reset="reset" />
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
        <el-input
          type="textarea"
          :rows="2"
          v-model="form.remark"
          autocomplete="off"
          placeholder="请输入描述信息"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
      <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { configtaskInfo, configtaskSave, configtaskEdit } from "@/api/job/configtask.js"
import CronInput from '@/components/cron-input'
import { DEFAULT_CRON_EXPRESSION } from '@/constant/filed'
export default {
  data () {
    return {
      cron: DEFAULT_CRON_EXPRESSION,
      visible: false,
      form: {
        taskName: "",
        params: "",
        cronExpression: "",
        remark: "",
      },
      formLabelWidth: "120px",
      rules: {
        taskName: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
        cronExpression: [{ required: true, message: "请输入cron表达式", trigger: "blur" }],
      },
    }
  },
  components: {
    CronInput
  },
  // beforeCreate () {
  //   if (this.form.cronExpression == '') {
  //     this.form.cronExpression = this.cron
  //   }
  // },
  created () { },
  methods: {
    init (id) {

      this.resetForm()
      //修改
      if (id) {
        configtaskInfo(id).then(resp => {
          if (resp.code == 0) {
            this.$nextTick(() => {
              this.form = {
                ...this.form,
                ...resp.data,
              }
            })
            if (this.form.cronExpression == '') {
              this.form.cronExpression = this.cron
            }
          } else {
            this.$msg({
              message: resp.msg,
              type: "error",
            })
          }
        })
      }
      else {
        if (this.form.cronExpression == '') {
          this.form.cronExpression = this.cron
        }
      }
      this.visible = true
    },
    //提交表单数据
    submitForm () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.visible = false
          //修改
          if (this.form.id) {
            configtaskEdit(this.form).then(resp => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
            //新增
          } else {
            configtaskSave(this.form).then(resp => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                })
                this.$emit("refreshData")
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                })
              }
            })
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    resetForm () {
      this.form = {
        taskName: "",
        params: "",
        cronExpression: "",
        remark: "",
      }
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields()
        }
      })
    },
    change (cron) {
      this.form.cronExpression = cron
    },
    reset () {
      this.change(DEFAULT_CRON_EXPRESSION)
    }
  },
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 7px;
  overflow: hidden;
  overflow-y: auto;
  font-weight: normal;
}
</style>
