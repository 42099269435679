import request from "@/common/request"

//商品表分页展示
export const goodsPage = (queryForm) => {
    return request("get", "/goods/goods/page", queryForm);
}
//商品表列表展示
export const goodsList = (queryForm) => {
    return request("get", "/goods/goods/list", queryForm);
}
//商品表详情
export const goodsInfo = (id) => {
    return request('get', '/goods/goods/info', {
        id: id
    })
}
//商品表信息保存
export const goodsSave = (data) => {
    return request('post', '/goods/goods/save', data)
}
//商品表信息修改
export const goodsEdit = (data) => {
    return request('post', '/goods/goods/edit', data)
}
//商品表信息删除
export const goodsDel = (id) => {
    return request('post', '/goods/goods/delete?id=' + id)
}
//商品表批量删除
export const goodsDelBatch = (ids) => {
    return request('post', '/goods/goods/deleteBatch', ids)
}
//商品表动态表头导出
export const goodsDynamicExport = (data) => {
    return request("eptpost", '/goods/goods/exportDynamic', data)
}
