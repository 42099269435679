<template>
	<div>
		<el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose" custom-class="drawer-info">
			<div class="info-item">
				<h5 class="from-title">基本信息</h5>
				<div class="form-item">
					<label class="form-item-label">用户名:</label>
					<div class="form-item-content">{{ objInfo.userName }}</div>
				</div>
				<!--                <div class="form-item">-->
				<!--                    <label class="form-item-label">密码:</label>-->
				<!--                    <div class="form-item-content">{{ objInfo.password }}-->
				<!--                    </div>-->
				<!--                </div>-->
				<div class="form-item">
					<label class="form-item-label">手机号:</label>
					<div class="form-item-content">{{ objInfo.phone }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">昵称:</label>
					<div class="form-item-content">{{ objInfo.nickName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">头像:</label>
					<div class="form-item-content"><img :src="objInfo.avatar" /></div>
				</div>
				<div class="form-item">
					<label class="form-item-label">性别:</label>
					<div class="form-item-content">{{ objInfo.genderName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">生日:</label>
					<div class="form-item-content">{{ objInfo.birthday }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">省:</label>
					<div class="form-item-content">{{ objInfo.province }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">省名称:</label>
					<div class="form-item-content">{{ objInfo.provinceName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">市:</label>
					<div class="form-item-content">{{ objInfo.city }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">市名称:</label>
					<div class="form-item-content">{{ objInfo.cityName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">区:</label>
					<div class="form-item-content">{{ objInfo.area }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">区名称:</label>
					<div class="form-item-content">{{ objInfo.areaName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">详细地址:</label>
					<div class="form-item-content">{{ objInfo.address }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">会员类型[会员/商家]:</label>
					<div class="form-item-content">{{ objInfo.typeName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">审核状态（是否实名）:</label>
					<div class="form-item-content">{{ objInfo.auditStatusName }}</div>
				</div>
				<!-- <div class="form-item">
                    <label class="form-item-label">所属网点:</label>
                    <div class="form-item-content">{{ objInfo.branchName }}</div>
                </div> -->
				<div class="form-item">
					<label class="form-item-label">公司名称:</label>
					<div class="form-item-content">{{ objInfo.enterpriseName }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">营业执照类型:</label>
					<div class="form-item-content">{{ objInfo.cardType }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">法定代表人:</label>
					<div class="form-item-content">{{ objInfo.person }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">法人身份证号:</label>
					<div class="form-item-content">{{ objInfo.personCode }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">法人身份证正面:</label>
					<div class="form-item-content"><img :src="objInfo.cardFront" /></div>
				</div>
				<div class="form-item">
					<label class="form-item-label">法人身份证反面:</label>
					<div class="form-item-content"><img :src="objInfo.cardBack" /></div>
				</div>
				<div class="form-item">
					<label class="form-item-label">注册资本:</label>
					<div class="form-item-content">{{ objInfo.capital }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">经营范围:</label>
					<div class="form-item-content">{{ objInfo.business }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">统一社会信用代码:</label>
					<div class="form-item-content">{{ objInfo.creditCode }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">成立日期:</label>
					<div class="form-item-content">{{ objInfo.regDate }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">营业期限:</label>
					<div class="form-item-content">{{ objInfo.businessTerm }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">住所:</label>
					<div class="form-item-content">{{ objInfo.residence }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">登记机关:</label>
					<div class="form-item-content">{{ objInfo.regAuthority }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">发证日期:</label>
					<div class="form-item-content">{{ objInfo.awardDate }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">道路运输许可证:</label>
					<div class="form-item-content">{{ objInfo.permit }}</div>
				</div>
				<div class="form-item">
					<label class="form-item-label">备注:</label>
					<div class="form-item-content">{{ objInfo.remark }}</div>
				</div>
        <!--				<div class="form-item">-->
        <!--					<label class="form-item-label">是否推荐商家(搜索页右侧):</label>-->
        <!--					<div class="form-item-content">{{ objInfo.isRecName }}</div>-->
        <!--				</div>-->
        <!-- <div class="form-item">
                    <label class="form-item-label">是否货运代理推荐(首页):</label>
                    <div class="form-item-content">{{ objInfo.isAgentrecName }}</div>
                </div> -->
      </div>
		</el-drawer>
	</div>
</template>
<script>
import { pdmemberInfo } from "@/api/pdworld/pdmember.js"
import { dictList, enumList, urlList } from "@/api/public.api.js"
export default {
	data() {
		return {
			drawer: true,
			direction: "rtl",
			objInfo: {},
			sexList: [],
			MemberTypeList: [],
			YesNoList: [],
			branchList: [],
		}
	},
	created() {},
	methods: {
		queryDictList() {
			this.sexList = []
			dictList("sex").then(resp => {
				if (resp.code == 0) {
					this.sexList = resp.data
				}
			})
		},
		queryEnumList() {
			this.MemberTypeList = []
			enumList("MemberType", "").then(resp => {
				if (resp.code == 0) {
					this.MemberTypeList = resp.data
				}
			})
			this.YesNoList = []
			enumList("YesNo", "").then(resp => {
				if (resp.code == 0) {
					this.YesNoList = resp.data
				}
			})
		},
		queryUrlList() {
			this.branchList = []
			urlList("/pdworld/pdPark/list").then(resp => {
				if (resp.code == 0) {
					if (resp.data) {
						resp.data.forEach(item => {
							this.branchList.push({ code: item.id, name: item.storeName })
						})
					}
				}
			})
		},
		init(id) {
			this.queryDictList()
			this.queryEnumList()
			this.queryUrlList()
			if (id) {
				pdmemberInfo(id).then(res => {
					this.objInfo = res.data
					let genderObj = this.sexList.find(item => {
						return item.code == this.objInfo.gender
					})
					this.objInfo.genderName = genderObj ? genderObj.name : ""
					let typeObj = this.MemberTypeList.find(item => {
						return item.code == this.objInfo.type
					})
					this.objInfo.typeName = typeObj ? typeObj.name : ""
					let auditStatusObj = this.YesNoList.find(item => {
						return item.code == this.objInfo.auditStatus
					})
					this.objInfo.auditStatusName = auditStatusObj ? auditStatusObj.name : ""
					let branchObj = this.branchList.find(item => {
						return item.code == this.objInfo.branch
					})
					this.objInfo.branchName = branchObj ? branchObj.name : ""
					let isRecObj = this.YesNoList.find(item => {
						return item.code == this.objInfo.isRec
					})
					this.objInfo.isRecName = isRecObj ? isRecObj.name : ""
					let isAgentrecObj = this.YesNoList.find(item => {
						return item.code == this.objInfo.isAgentrec
					})
					this.objInfo.isAgentrecName = isAgentrecObj ? isAgentrecObj.name : ""
				})
			}
			this.drawer = true
		},
		// 关闭抽屉
		handleClose(done) {
			done()
		},
	},
}
</script>
<style scoped>
.from-title {
	margin-bottom: 20px;
	padding: 0 20px 20px 20px;
	font-size: 16px;
	color: #17233d;
	border-bottom: 1px solid #eee;
}
.info-item {
	margin-bottom: 40px;
}

.form-item {
	margin-bottom: 20px;
	font-size: 14px;
}
.form-item-label {
	text-align: right;
	float: left;
	width: 25%;
}
.form-item-content {
	margin-left: 30%;
	width: 70%;
}
.form-item-content img {
	width: 20%;
	object-fit: cover;
	border-radius: 10px;
}
</style>
