<template>
  <div class="padding20">
    <el-form :inline="true" :model="queryForm" class="el-common-from">
      <!-- <el-form-item>
				<el-input v-model="queryForm.id" autocomplete="off" placeholder="主键"></el-input>
      </el-form-item>-->

      <el-form-item>
        <el-input
          v-model="queryForm.paramCode"
          autocomplete="off"
          placeholder="参数编码"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="queryForm.paramValue"
          autocomplete="off"
          placeholder="参数值"
        ></el-input>
      </el-form-item>
      <el-form-item v-show="collapse.flag">
        <el-input
          v-model="queryForm.paramType"
          autocomplete="off"
          placeholder="类型"
        ></el-input>
      </el-form-item>
      <el-form-item v-show="collapse.flag">
        <el-input
          v-model="queryForm.paramDescription"
          autocomplete="off"
          placeholder="描述"
        ></el-input>
      </el-form-item>

      <el-form-item v-show="collapse.flag">
        <el-select
          v-model="queryForm.status"
          placeholder="请选择启用状态"
          clearable
        >
          <el-option label="启用" value="1"></el-option>
          <el-option label="未启用" value="2"></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item v-show="collapse.flag">
        <el-input v-model="queryForm.createUser" autocomplete="off" placeholder="创建人"></el-input>
      </el-form-item>

      <el-form-item v-show="collapse.flag">
        <el-input v-model="queryForm.createDept" autocomplete="off" placeholder="创建部门"></el-input>
      </el-form-item>

      <el-form-item v-show="collapse.flag">
        <el-input v-model="queryForm.createPost" autocomplete="off" placeholder="创建职位"></el-input>
      </el-form-item>-->
      <el-button
        type="primary"
        size="medium"
        @click="queryList({ current: 1 })"
        icon="el-icon-search"
        >查询</el-button
      >
      <el-button
        type="primary"
        size="medium"
        @click="resetQueryForm"
        icon="el-icon-refresh-left"
        >重置</el-button
      >
      <el-button type="text" @click="handleCollapseState">
        {{ collapse.label }}
        <i :class="collapse.icon"></i>
      </el-button>
    </el-form>
    <el-button
      type="primary"
      size="medium"
      v-if="$tools.hasPermission('system:configParams:save')"
      @click="handleEdit()"
      style="margin: 10px 0"
      icon="el-icon-plus"
      >添加</el-button
    >
    <div style="float: right; padding-right: 17px">
      <i
        class="el-icon-refresh"
        style="margin-right: 10px; cursor: pointer"
        @click="refresh"
      ></i>

      <el-popover
        placement="bottom"
        trigger="click"
        title="列展示"
        popper-class="columnShows"
      >
        <el-checkbox-group v-model="tableShowList">
          <el-checkbox
            v-for="item in tableList"
            :label="item.propName"
            :key="item.propName"
            :value="item.propName"
            >{{ item.propDesc }}</el-checkbox
          >
        </el-checkbox-group>
        <i
          slot="reference"
          class="el-icon-s-operation"
          style="cursor: pointer"
        ></i>
      </el-popover>
    </div>
    <!-- loading -->
    <loading :show="parentShow">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          show-overflow-tooltip
        ></el-table-column>
        <!-- <el-table-column
        v-if="$tools.isShow('id', tableShowList)"
        prop="id"
        label="主键"
        :show-overflow-tooltip="true"
        ></el-table-column>-->
        <el-table-column
          v-if="$tools.isShow('paramCode', tableShowList)"
          prop="paramCode"
          label="参数编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('paramValue', tableShowList)"
          prop="paramValue"
          label="参数值"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('paramType', tableShowList)"
          prop="paramType"
          label="类型"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('paramDescription', tableShowList)"
          prop="paramDescription"
          label="描述"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('status', tableShowList)"
          prop="status"
          label="状态"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('createUser', tableShowList)"
          prop="createUserName"
          label="创建人"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('createDept', tableShowList)"
          prop="createDeptName"
          label="创建部门"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          v-if="$tools.isShow('createPost', tableShowList)"
          prop="createPostName"
          label="创建职位"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="70">
          <template slot-scope="scope">
            <el-button
              v-if="$tools.hasPermission('system:configParams:info')"
              size="mini"
              @click="handleInfo(scope.row.id)"
              >详情</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:configParams:edit')"
              size="mini"
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-if="$tools.hasPermission('system:configParams:delete')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </loading>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <!-- 新增、编辑 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>
    <!-- 详情 -->
    <form-info v-if="formInfoVisible" ref="formInfo"></form-info>
  </div>
</template>
<script>
import {
  configparamsPage,
  configparamsDel,
} from "@/api/system/configparams.js";
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./configparams-edit";
import FormInfo from "./configparams-info";
import FortressPagination from "@/components/fortress-pagination.vue";
export default {
  data() {
    return {
      parentShow: "",

      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      queryForm: {
        id: "",
        paramCode: "",
        paramValue: "",
        paramType: "",
        paramDescription: "",
        status: "",
        createUser: "",
        createDept: "",
        createPost: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
    };
  },
  components: {
    "form-edit": FormEdit,
    "form-info": FormInfo,
    pagination: FortressPagination,
  },
  created() {
    this.tableData = [];
    this.queryList();
    this.initTableList();
  },
  methods: {
    refresh() {
      this.tableData = [];
      this.queryList({ current: 1 });
    },
    //查询表格数据
    queryList(page) {
      this.parentShow = true;
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      configparamsPage(this.queryForm).then((resp) => {
        if (resp.code == 0) {
          setTimeout(() => {
            this.parentShow = false;
            this.tableData = resp.data.records;
            this.page.total = resp.data.total;
            //查询页大于总页数，重新查询
            let cu =
              this.page.total % this.page.size == 0
                ? parseInt(this.page.total / this.page.size)
                : parseInt(this.page.total / this.page.size + 1);
            if (cu > 0 && this.page.current > cu) {
              this.queryList({ current: cu });
            }
          }, 600);
        } else {
          this.parentShow = false;
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(id);
      });
    },
    //打开详情窗口
    handleInfo(id) {
      this.formInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.formInfo.init(id);
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          configparamsDel(row.id).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              //判断是否最后一页的最后一条记录
              let totalPages =
                (this.page.total - 1) % this.page.size == 0
                  ? (this.page.total - 1) / this.page.size
                  : (this.page.total - 1) / this.page.size + 1;
              if (this.page.current > totalPages && this.page.current != 1) {
                this.page.current = this.page.current - 1;
              }
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    },
    //重置查询框
    resetQueryForm() {
      this.queryForm = {
        id: "",
        paramCode: "",
        paramValue: "",
        paramType: "",
        paramDescription: "",
        status: "",
        createUser: "",
        createDept: "",
        createPost: "",
      };
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList() {
      viewTableList("castle_config_params").then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
  },
};
</script>
<style></style>
