<template>
    <el-dialog :title="form.id ? '编辑' : '新增'" :visible.sync="visible" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="会员ID，预约人" :label-width="formLabelWidth" prop="memberId">
                <fortress-select
                        v-model="form.memberId"
                        :dictList="memberIdList"
                        placeholder="请选择会员ID，预约人"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="商家ID" :label-width="formLabelWidth" prop="shopId">
                <fortress-select
                        v-model="form.shopId"
                        :dictList="shopIdList"
                        placeholder="请选择商家ID"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="线路ID" :label-width="formLabelWidth" prop="lineId">
                <fortress-select
                        v-model="form.lineId"
                        :dictList="lineIdList"
                        placeholder="请选择线路ID"
                        clearable
                ></fortress-select>
            </el-form-item>
            <el-form-item label="订单号" :label-width="formLabelWidth" prop="orderNo">
                <el-input
                        v-model="form.orderNo"
                        autocomplete="off"
                        placeholder="请输入订单号"
                ></el-input>
            </el-form-item>
            <el-form-item label="评分" :label-width="formLabelWidth" prop="score">
                <el-input
                        v-model="form.score"
                        autocomplete="off"
                        placeholder="请输入评分"
                ></el-input>
            </el-form-item>
            <el-form-item label="预约时间" :label-width="formLabelWidth" prop="scheduledDate">
                <el-date-picker
                        v-model="form.scheduledDate"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="评分时间" :label-width="formLabelWidth" prop="scoreDate">
                <el-date-picker
                        v-model="form.scoreDate"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="预约人手机号" :label-width="formLabelWidth" prop="scheduledPhone">
                <el-input
                        v-model="form.scheduledPhone"
                        autocomplete="off"
                        placeholder="请输入预约人手机号"
                ></el-input>
            </el-form-item>
            <el-form-item label="货物名称" :label-width="formLabelWidth" prop="goodsName">
                <el-input
                        v-model="form.goodsName"
                        autocomplete="off"
                        placeholder="请输入货物名称"
                ></el-input>
            </el-form-item>
            <el-form-item label="货物重量" :label-width="formLabelWidth" prop="goodsWeight">
                <el-input
                        v-model="form.goodsWeight"
                        autocomplete="off"
                        placeholder="请输入货物重量"
                ></el-input>
            </el-form-item>
            <el-form-item label="货物件数" :label-width="formLabelWidth" prop="goodsNember">
                <el-input
                        v-model="form.goodsNember"
                        autocomplete="off"
                        placeholder="请输入货物件数"
                ></el-input>
            </el-form-item>
            <el-form-item label="货物体积" :label-width="formLabelWidth" prop="goodsVolume">
                <el-input
                        v-model="form.goodsVolume"
                        autocomplete="off"
                        placeholder="请输入货物体积"
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" >
            <el-button @click="visible = false" size="medium" type="danger">取 消</el-button>
            <el-button type="primary" size="medium" @click="submitForm('form')">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { pdorderInfo, pdorderSave, pdorderEdit } from "@/api/pdworld/pdorder.js";
    import { urlList, } from "@/api/public.api.js";
    
    export default {
        data() {
            return {
                visible: false,
                form: {
                    id:"",
                    memberId:"",
                    shopId:"",
                    lineId:"",
                    orderNo:"",
                    score:"",
                    scheduledDate:"",
                    scoreDate:"",
                    scheduledPhone:"",
                    goodsName:"",
                    goodsWeight:"",
                    goodsNember:"",
                    goodsVolume:"",
                },
                formLabelWidth: "120px",
                rules: {
                },
                memberIdList:[],
                shopIdList:[],
                lineIdList:[],
            };
        },
        created() {
        },
        methods: {
            queryUrlList(){
                this.memberIdList = [];
                urlList("/pdworld/pdMember/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.memberIdList.push({ code: item.id, name: item.nickName });
                      });
                    }
                  }
                });
                this.shopIdList = [];
                urlList("/pdworld/pdMember/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.shopIdList.push({ code: item.id, name: item.nickName });
                      });
                    }
                  }
                });
                this.lineIdList = [];
                urlList("/pdworld/pdRoute/list").then((resp) => {
                  if (resp.code == 0) {
                    if (resp.data) {
                      resp.data.forEach((item) => {
                        this.lineIdList.push({ code: item.id, name: item.startPlace });
                      });
                    }
                  }
                });
            },
            init(id) {
                this.resetForm();
                this.queryUrlList();
                //修改
                if (id) {
                    pdorderInfo(id).then((resp) => {
                        if (resp.code == 0) {
                            this.$nextTick(() => {
                                this.form = {
                                    ...this.form,
                                    ...resp.data,
                                };
                            });
                        } else {
                            this.$msg({
                                message: resp.msg,
                                type: "error",
                            });
                        }
                    });
                }
                this.visible = true;
            },
            //提交表单数据
            submitForm() {
                this.$refs["form"].validate((valid) => {
                if (valid) {
                    //修改
                    if (this.form.id) {
                        pdorderEdit(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                            this.$emit("refreshData");
                            this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    //新增
                    } else {
                        pdorderSave(this.form).then((resp) => {
                            if (resp.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.$emit("refreshData");
                                this.visible = false;
                            } else {
                                this.$msg({
                                    message: resp.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
                });
            },
            resetForm() {
                this.form = {
                    id:"",
                    memberId:"",
                    shopId:"",
                    lineId:"",
                    orderNo:"",
                    score:"",
                    scheduledDate:"",
                    scoreDate:"",
                    scheduledPhone:"",
                    goodsName:"",
                    goodsWeight:"",
                    goodsNember:"",
                    goodsVolume:"",
                };
                this.$nextTick(() => {
                    if (this.$refs["form"]) {
                        this.$refs["form"].resetFields();
                    }
                });
            },
        },
    };
</script>
<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        height: auto;
        max-height: 274px;
        padding: 7px;
        overflow: hidden;
        overflow-y: auto;
        font-weight: normal;
    }
</style>
